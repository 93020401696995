import { ClickableDiv, ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import classNames from 'classnames';
import React, { useState, useCallback } from 'react';
import { useFlowState } from '@getvim/flow-context-provider';
import { PreferredProviderListTitle, SearchResultsList } from '../list';
import { SearchResultsListProps } from '../list/SearchResultsList';
import './PreferredProvidersSearchResults.less';
import { SearchResultsProps } from './types';
import { SearchRequiredState } from '../../SourceConfigWrapper';

type PreferredProvidersSearchResultsProps = Pick<
  SearchResultsProps,
  | 'sendOutcome'
  | 'preferredProviders'
  | 'onProviderSelect'
  | 'currentSearchState'
  | 'referralViewedPayload'
  | 'costResultStatus'
  | 'getSelectionType'
  | 'isReferralRequestRequired'
  | 'onProviderAddressChange'
  | 'isReadonlyMode'
  | 'organizationHasPreferredProviders'
  | 'useSelectedLocationOfProvider'
> & { shouldDisplayNewPatientsIcon: boolean };

const MAX_RESULTS_DISPLAY = 2;

interface WithExpansionProps<T> {
  items: T[];
  listRenderer: (items: T[]) => JSX.Element;
}

const WithExpansion = <T,>({ items, listRenderer }: WithExpansionProps<T>) => {
  const [expanded, setExpanded] = useState(false);

  const onExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <div>
      {listRenderer(expanded ? items : items.slice(0, MAX_RESULTS_DISPLAY))}
      {items.length > MAX_RESULTS_DISPLAY && (
        <ClickableDiv className="expanded_search_results__container" onClick={onExpanded}>
          <Text
            className="expanded_search_results__title"
            weight={WeightEnum.bold}
            size={SizeEnum['12px']}
            colorName={ColorNameEnum.extraDark}
            text={expanded ? 'Show less' : 'Show all'}
          >
            <div className={classNames('expanded_search_results__title__icon', { expanded })}>
              <i className="icon-chevron-down" />
            </div>
          </Text>
        </ClickableDiv>
      )}
    </div>
  );
};

const PreferredList: React.FC<SearchResultsListProps> = ({ results, collapsable, ...rest }) => {
  return (
    <SearchResultsList isPreferredList results={results} collapsable={collapsable} {...rest} />
  );
};

const PreferredProvidersSearchResults: React.FC<PreferredProvidersSearchResultsProps> = ({
  preferredProviders,
  organizationHasPreferredProviders,
  ...props
}) => {
  const { sourceConfig } = useFlowState<SearchRequiredState>();
  const { collapsePreferredProviders } = sourceConfig;

  const listRenderer = useCallback(
    (results) => {
      return (
        <PreferredList
          titleElement={
            <PreferredProviderListTitle
              count={preferredProviders.length}
              organizationHasPreferredProviders={organizationHasPreferredProviders}
            />
          }
          results={results}
          {...props}
        />
      );
    },
    [organizationHasPreferredProviders, preferredProviders.length, props],
  );

  return preferredProviders.length ? (
    <div className="preferred-provider-search-results-list">
      {collapsePreferredProviders ? (
        <PreferredList
          titleElement={
            <PreferredProviderListTitle
              count={preferredProviders.length}
              organizationHasPreferredProviders={organizationHasPreferredProviders}
            />
          }
          results={preferredProviders}
          collapsable
          {...props}
        />
      ) : (
        <WithExpansion items={preferredProviders} listRenderer={listRenderer} />
      )}
    </div>
  ) : (
    <PreferredProviderListTitle
      organizationHasPreferredProviders={organizationHasPreferredProviders}
    />
  );
};

export default PreferredProvidersSearchResults;
