import { GetOrderAssistResult, ActionStatus } from '../types';

const getCostRequestStatus = (
  costAvailable: boolean,
  result?: GetOrderAssistResult,
  CostNotSupported?: boolean,
  noErrorFlag = false,
): ActionStatus => {
  if (CostNotSupported) return ActionStatus.NOT_SUPPORTED;
  if (!result) return ActionStatus.FAILED;
  if (costAvailable) {
    if (noErrorFlag) {
      const someHasCost =
        result.searchResults.some((item) => !!item?.costDetail) ||
        result.alternativeResults?.some((item) => !!item?.costDetail);
      return someHasCost ? ActionStatus.SUCCESS : ActionStatus.NOT_AVAILABLE;
    }
    const item = result?.searchResults?.[0] ?? result?.alternativeResults?.[0];
    if (item?.costDetail) {
      return ActionStatus.SUCCESS;
    }
    return ActionStatus.FAILED;
  }
  return ActionStatus.NOT_AVAILABLE;
};

export default getCostRequestStatus;
