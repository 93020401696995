import { InputStyle, InputStyleWrapper, LabeledInput } from '@getvim/atomic-ui';
import React, { useEffect, useState } from 'react';
import { InputWrapperProps } from '../../../types';
import './AdvancedSearchContainer.less';

interface NamesInput {
  firstName?: string;
  lastName?: string;
}

type NameInputsWrapperProps = Omit<InputWrapperProps<'firstName' | 'lastName'>, 'value'> & {
  value: NamesInput;
  hasError: boolean;
};

const NameInputsWrapper: React.FC<NameInputsWrapperProps> = ({ value, onChange, hasError }) => {
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);

  const onKeyPress = (event) => {
    if (/[0-9]/.test(event.key)) event.preventDefault();
  };

  useEffect(() => {
    if (!value?.firstName) {
      setIsFirstNameFocused(false);
    }
  }, [value?.firstName]);

  useEffect(() => {
    if (!value?.lastName) {
      setIsLastNameFocused(false);
    }
  }, [value?.lastName]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div className="input name-input-wrapper">
        <InputStyleWrapper className="input-wrapper" inputStyle={InputStyle.pillMedium}>
          <LabeledInput
            type="text"
            id="first-name-input"
            label="First name"
            value={value?.firstName ?? ''}
            onFocus={() => setIsFirstNameFocused(true)}
            active={!!value?.firstName || isFirstNameFocused}
            onBlur={() => {
              if (!value?.firstName) setIsFirstNameFocused(false);
            }}
            onKeyPress={onKeyPress}
            onChange={(e) => onChange({ firstName: e.target.value })}
            autoComplete="off"
            className="advanced-search-input"
          />
        </InputStyleWrapper>
      </div>

      <div className="input name-input-wrapper">
        <InputStyleWrapper
          className="input-wrapper"
          inputStyle={InputStyle.pillMedium}
          hasError={hasError}
        >
          <LabeledInput
            id="last-name-input"
            label="Last name"
            type="text"
            value={value?.lastName ?? ''}
            onFocus={() => setIsLastNameFocused(true)}
            active={!!value.lastName || isLastNameFocused}
            onBlur={() => {
              if (!value.lastName) setIsLastNameFocused(false);
            }}
            onKeyPress={onKeyPress}
            onChange={(e) => onChange({ lastName: e.target.value })}
            autoComplete="off"
            className="advanced-search-input"
          />
        </InputStyleWrapper>
      </div>
    </div>
  );
};

export default NameInputsWrapper;
