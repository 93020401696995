import React from 'react';
import { useField } from 'formik';
import {
  InputStyle,
  InputStyleWrapper,
  InputValidationMessage,
  LabeledInput,
} from '@getvim/atomic-ui';
import '../Procedures.less';
import PlusMinusIcons from './PlusMinusIcons';
import { getReferralRequestUIString } from '../../../../strings';

interface QuantityValueInputFieldProps {
  itemIndex: number;
}

export const QuantityValueInputField: React.FC<QuantityValueInputFieldProps> = ({ itemIndex }) => {
  const [, meta, helpers] = useField(`procedures[${itemIndex}].quantity.value`);

  const onBlur = () => {
    if (meta.value < 1) helpers.setValue(1);
  };
  const onChange = (event) => {
    helpers.setValue(parseInt(event.target.value, 10));
  };
  const onKeyPress = (event) => {
    if (/^([^0-9]*)$/.test(event.key)) event.preventDefault();
  };

  return (
    <div className="referral-request-input-container quantity-input half-width no-margin-bottom">
      <div>
        <InputStyleWrapper
          inputStyle={InputStyle.pillMedium}
          hasError={!!meta.error}
          rightIcon={<PlusMinusIcons value={meta.value} setValue={helpers.setValue} />}
        >
          <LabeledInput
            type="number"
            label={getReferralRequestUIString('referral_quantity_value_label')}
            active
            autoComplete="off"
            value={meta.value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
          />
          {!!meta.error && (
            <InputValidationMessage>
              {getReferralRequestUIString('referral_quantity_value_missing_label')}
            </InputValidationMessage>
          )}
        </InputStyleWrapper>
      </div>
    </div>
  );
};
