import { useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { useMemo } from 'react';
import { UserConfig } from '../types';

export const useSdkUserConfig = (): UserConfig['vimConnectUser'] => {
  const { user } = useVimUserMetadata();

  return useMemo(() => {
    if (!user) return;

    const {
      identifiers: { ehrUsername: linkedEhrUser, vimUserID: externalId } = {},
      contactInfo: { email } = {},
      demographics: { firstName, lastName } = {},
    } = user;

    return {
      email,
      externalId,
      firstName,
      lastName,
      linkedEhrUser,
    };
  }, [user]);
};
