import { cloneDeep } from 'lodash-es';
import { ContextOptions, ReferralContext, ReferralStep } from '../types';

export const switchStep = (
  activeStep: ReferralStep,
  contextOptions: ContextOptions,
  contextParams?: Partial<ReferralContext>,
): void => {
  const { context, updateContext } = contextOptions;
  const currentStepsAsInactive = cloneDeep(context.stepStatus);
  Object.keys(context.stepStatus).forEach((step) => {
    currentStepsAsInactive[step].isActive = false;
  });
  updateContext({
    ...context,
    stepStatus: {
      ...currentStepsAsInactive,
      [activeStep]: { ...context.stepStatus[activeStep], isActive: true },
    },
    ...contextParams,
  });
};

export const updateIsDone = (
  step: ReferralStep,
  isDone: boolean,
  contextOptions: ContextOptions,
): void => {
  const { context, updateContext } = contextOptions;
  updateContext({
    ...context,
    stepStatus: {
      ...context.stepStatus,
      [step]: { ...context.stepStatus[step], isDone },
    },
  });
};
