import React from 'react';
import { Banner, BannerTypes, ColorNameEnum } from '@getvim/atomic-ui';

interface ModifiedDistanceSearchMessageProps {
  distance: number;
}

export const ModifiedDistanceSearchMessage: React.FC<ModifiedDistanceSearchMessageProps> = ({
  distance,
}) => {
  const message = `Results below include locations with distances up to ${distance} miles`;
  return (
    <Banner
      text={message}
      type={BannerTypes.DISCLAIMER}
      icon={<i className="icon-exclamation-c" />}
      textColor={ColorNameEnum.ultraDark}
      className="atomic-banner-margin-bottom-zero"
    />
  );
};
