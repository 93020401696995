import { useFlowContext, useFlowControls } from '@getvim/flow-manager';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  trackServiceInformationScreenClicked,
  trackServiceInformationScreenViewed,
} from '../../../../../analytics/referral-request';
import { ReferralRequestFlowButtonName } from '../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import { ReferralRequestLoader } from '../loader';
import { ServiceInformation } from '../service-information';
import { DEFAULTS } from '../service-information/consts';
import { Stepper } from '../stepper';
import {
  ReferralContext,
  ReferralFlowManagerProps,
  ReferralRequestFormSubmission,
  ReferralRequestStatus,
  ReferralStep,
  ReferralSteps,
  ServiceInformation as ServiceInformationData,
} from '../types';
import { switchStep } from './utils';
import { orderAssistLogger } from '../../../logger';

type ServiceInformationStepProps = Pick<ReferralFlowManagerProps, 'onFreeTextQuery'> & {
  onSubmitForm: (
    serviceInformation: ServiceInformationData,
    context: ReferralContext,
  ) => Promise<ReferralRequestFormSubmission>;
};

export const ServiceInformationStep: React.FC<ServiceInformationStepProps> = ({
  onFreeTextQuery,
  onSubmitForm,
}) => {
  const { context, updateContext } = useFlowContext<ReferralContext>();
  const { prev, next } = useFlowControls<ReferralSteps>();

  const serviceInformation = useMemo(() => {
    return {
      serviceType: context.serviceInformation?.serviceType || DEFAULTS.serviceType,
      placeOfService: context.serviceInformation?.placeOfService || DEFAULTS.placeOfService,
      diagnoses: context.serviceInformation?.diagnoses || [],
      procedures: context.serviceInformation?.procedures || [
        { date: new Date(), quantity: { value: 1, type: 1 }, key: 0 },
      ],
    };
  }, [context]);

  useEffect(() => {
    trackServiceInformationScreenViewed({
      serviceInformation: {
        ...serviceInformation,
        procedures: context.serviceInformation?.procedures,
      },
    });
    // TODO fix this in other PR, you can fix this by sending the analytics on each page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitReferralRequest = useCallback(
    async (submitServiceInformation: ServiceInformationData) => {
      trackServiceInformationScreenClicked({
        serviceInformation: submitServiceInformation,
        buttonName: ReferralRequestFlowButtonName.SUBMIT,
      });

      if (!onSubmitForm) {
        orderAssistLogger.error(
          'cannot submit form - missing onSubmitForm property for ServiceInformationStep',
        );
        return;
      }

      const updatedContext = { ...context, serviceInformation: submitServiceInformation };
      updateContext({ ...updatedContext, isLoading: true });

      const { success, response, updateCodeStatus } = await onSubmitForm(
        submitServiceInformation,
        updatedContext,
      );

      if (success && !!response) {
        const requestSubmissionTime = Date.now();
        updateContext({
          ...updatedContext,
          response,
          requestSubmissionTime,
          isLoading: false,
          updateCodeStatus,
        });
      } else {
        updateContext({
          ...updatedContext,
          response: { status: ReferralRequestStatus.ERROR },
          isLoading: false,
        });
      }
      next(ReferralStep.RESULT);
    },
    [context, next, updateContext, onSubmitForm],
  );

  return context.isLoading ? (
    <ReferralRequestLoader />
  ) : (
    <>
      <Stepper steps={context.stepStatus} />
      <ServiceInformation
        serviceInformation={serviceInformation}
        onFreeTextQuery={onFreeTextQuery}
        onPrev={(formValues) => {
          trackServiceInformationScreenClicked({
            serviceInformation: formValues,
            buttonName: ReferralRequestFlowButtonName.BACK,
          });

          switchStep(
            ReferralStep.REQUESTING_PROVIDER,
            { context, updateContext },
            { serviceInformation: formValues },
          );
          prev();
        }}
        onSubmit={onSubmitReferralRequest}
      />
    </>
  );
};
