import { useMemo } from 'react';
import { useSdkAdapterConfig } from './adapter';
import { useSdkOrganizationConfig } from './organization';
import { useSdkProductConfig } from './product';
import { useSdkUserConfig } from './user';
import { useSdkWorkspaceConfig } from './workspace';
import { UserConfig } from '../types';

export const useSdkConfig = (): UserConfig | undefined => {
  const vimConnectUser = useSdkUserConfig();
  const organization = useSdkOrganizationConfig();
  const adapterConfig = useSdkAdapterConfig();
  const workspaceConfig = useSdkWorkspaceConfig();
  const product = useSdkProductConfig();

  const userConfig = useMemo<UserConfig | undefined>(() => {
    const { productConfig, vimSpecialtiesMap } = product ?? {};
    if (!vimConnectUser || !organization || !productConfig) return;

    return {
      vimConnectUser,
      organization,
      product: productConfig,
      adapterConfig,
      workspaceConfig,
      adapterName: organization.ehrSystem,
      vimSpecialtiesMap,
      isVimOsApp: true,
    };
  }, [vimConnectUser, organization, adapterConfig, workspaceConfig, product]);

  return userConfig;
};
