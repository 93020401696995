import { getPlaceOfServiceOptions } from '../../../consts/specialtyMapping';
import { SelectOption } from '../../../types';
import { isSpecialtyRequiresPlaceOfService } from '../../../utils/search';

interface UsePlaceOfServiceProps {
  vimSpecialtyId?: string;
}

export interface UsePlaceOfService {
  showPlaceOfService: boolean;
  placeOfServiceOptions: SelectOption[];
}

export const usePlaceOfService = ({
  vimSpecialtyId,
}: UsePlaceOfServiceProps): UsePlaceOfService => {
  const isPlaceOfServiceAllowed =
    !!vimSpecialtyId && isSpecialtyRequiresPlaceOfService(vimSpecialtyId);

  if (!isPlaceOfServiceAllowed) {
    return {
      showPlaceOfService: isPlaceOfServiceAllowed,
      placeOfServiceOptions: [],
    };
  }

  const options = getPlaceOfServiceOptions(vimSpecialtyId);

  return {
    showPlaceOfService: isPlaceOfServiceAllowed,
    placeOfServiceOptions: options,
  };
};
