import React from 'react';
import '../index.less';
import './Stepper.less';
import { Step } from './step';
import { StepProps } from './step/Step';
import { ReferralStep } from '../types';

export type StepperProps = {
  steps: {
    [key in ReferralStep]?: StepProps;
  };
};

const Stepper: React.FC<StepperProps> = ({ steps }) => {
  const stepsValues = Object.values(steps);
  return (
    <div className="stepper-container">
      {stepsValues.map((step: StepProps) => (
        <Step
          key={JSON.stringify(step)}
          text={step.text}
          isActive={step.isActive}
          isDone={step.isDone}
        />
      ))}
    </div>
  );
};
export default Stepper;
