import { Encounter } from '@getvim/react-app-infra';
import type { EhrStateWithPII } from '@getvim/internal-vim-os-sdk/types';
import { convertDiagnoses, convertProvider } from './common';

export const convertEncounterToRuntime = (
  ehrEncounter: EhrStateWithPII['encounter'],
): Encounter | undefined => {
  if (!ehrEncounter) {
    return;
  }

  const {
    identifiers,
    provider,
    assessment,
    basicInformation
  } = ehrEncounter;

  return {
    encounterId: identifiers?.ehrEncounterId,
    encounterDate: basicInformation?.encounterDateOfService,
    isLocked: basicInformation?.status === 'LOCKED',
    provider: convertProvider(provider),
    assessments: convertDiagnoses(assessment?.diagnosisCodes),
  };
};
