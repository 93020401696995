import { OrderAssistProviderOption, WriteBackProvider } from '../types';
import addressFormat from './addressFormatter';
import { getSelectedItemAddress } from './search/getSelectedAddress';

export const parseOptionToWritebackProvider = (
  option: OrderAssistProviderOption,
): WriteBackProvider => {
  const { details } = option;
  const address = getSelectedItemAddress(option);
  const provider = {
    npi: details.npi,
    specialty: details.specialties ?? [],
    demographics: {
      firstName: details.complexName.firstName,
      lastName: details.complexName.lastName,
    },
    organizations: [
      {
        name: details.acoName || address?.officeName,
        address: address
          ? {
              ...address,
              fullAddress: addressFormat(address),
            }
          : { zipCode: '' },
        contact_info: {
          mobilePhoneNumber: address?.phone,
          faxNumber: address?.fax,
        },
      },
    ],
  };
  return provider;
};
