import { OrderAssistOption } from '../types';
import addressFormat from './addressFormatter';
import { capitalizeAsName, isProviderResult } from './general';
import { getSelectedItemAddress } from './search/getSelectedAddress';

const formatForClipboard = (
  option: OrderAssistOption,
  shouldImproveCopyExportSearchResults = false,
): string => {
  const address = getSelectedItemAddress(option);
  if (!address) return '';
  const fullAddress = addressFormat(address);
  let officeName;
  if (shouldImproveCopyExportSearchResults) {
    officeName = address?.officeName ? `Office name: ${address.officeName}\n` : '';
  } else {
    officeName = address?.officeName ? `Office Name: ${address.officeName}\n` : '';
  }

  let sharedDetails =
    `Specialties: ${
      option.details.specialties?.length
        ? option.details.specialties
            .filter((s) => s.description)
            .map((s) => capitalizeAsName(s.description))
            .join(', ')
        : 'N/A'
    }\n` +
    `${officeName}` +
    `Address: ${fullAddress || 'N/A'}\n` +
    `Phone: ${address?.phone || 'N/A'}\n`;

  if (address.fax) {
    sharedDetails += `Fax: ${address.fax}\n`;
  }

  sharedDetails += `NPI: ${option.details.npi}`;

  if (isProviderResult(option)) {
    let providerDetails;
    if (shouldImproveCopyExportSearchResults) {
      providerDetails = `Provider name: ${capitalizeAsName(
        `${option.details.complexName.firstName} ${option.details.complexName.lastName}`,
      )}\n`;
    } else {
      providerDetails = `Provider Name: ${capitalizeAsName(
        `${option.details.complexName.firstName} ${option.details.complexName.lastName}`,
      )}\n`;
    }
    if (option.details.acoName) {
      providerDetails += `Organization: ${capitalizeAsName(option.details.acoName)}\n`;
    }
    return providerDetails + sharedDetails;
  }

  const facilityDetails = shouldImproveCopyExportSearchResults
    ? `Facility name: ${capitalizeAsName(option.details.name)}\n`
    : `Facility Name: ${capitalizeAsName(option.details.name)}\n`;
  return facilityDetails + sharedDetails;
};

export default formatForClipboard;
