import { logger } from '@getvim/vim-connect-logger';
import { orderAssistLogger } from '../components/app/logger';

const isVimOS = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isVimOs = queryParams.get('vim-os') === 'true';

  logger.setStaticTags({
    moduleName: `Order Assist - ${isVimOs ? `SDK app mode` : `Runtime app mode`}`,
  });

  orderAssistLogger.info('Order Assist app-mode', { isVimOs });
  return isVimOs;
};

export const isVimOsAppMode = isVimOS();
