import { useSdkStateConvertedToRuntime } from '@getvim-core-apps/vim-os-migration-api';
import { StandardEvents } from '@getvim/vim-connect';
import { useFlowState } from '@getvim/flow-context-provider';
import { useMemo } from 'react';
import { convertReferralSpecialty } from './specialty';
import { UserConfig } from '../../hooks';

interface LegacyRuntimeState {
  patient?: StandardEvents.TransformedPatientInContextPayload;
  referral?: StandardEvents.TransformedReferralViewedPayload;
  orders?: StandardEvents.OrdersViewedPayload;
}

export const useOrderAssistLegacyState = (): LegacyRuntimeState => {
  const {
    patient: ehrPatient,
    referral: ehrReferral,
    orders: ehrOrders,
  } = useSdkStateConvertedToRuntime();

  const {
    userConfig: { vimSpecialtiesMap },
  } = useFlowState<{
    userConfig: UserConfig;
  }>();

  const referral: StandardEvents.ReferralViewedPayload | undefined = useMemo(() => {
    if (!ehrReferral) return;

    const { specialty, ...restOfReferral } = ehrReferral;

    return {
      ...restOfReferral,
      specialty: convertReferralSpecialty(vimSpecialtiesMap, specialty),
      patient: ehrPatient,
    };
  }, [ehrReferral, ehrPatient, vimSpecialtiesMap]);

  const orders: StandardEvents.OrdersViewedPayload | undefined = useMemo(() => {
    if (!ehrOrders) return;

    return {
      selectedOrders: ehrOrders,
      patient: ehrPatient,
    };
  }, [ehrOrders, ehrPatient]);

  return { patient: ehrPatient, referral, orders };
};
