import { capitalize } from 'lodash-es';
import {
  OrderAssistFacilityOption,
  OrderAssistOption,
  OrderAssistOptionType,
  OrderAssistProviderOption,
} from '../types';

export const capitalizeAsName = (str: string): string => {
  return str
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};

export const isProviderResult = (
  result: OrderAssistOption,
): result is OrderAssistProviderOption => {
  return result.type === OrderAssistOptionType.Provider;
};

export const isFacilityResult = (
  result: OrderAssistOption,
): result is OrderAssistFacilityOption => {
  return result.type === OrderAssistOptionType.Facility;
};

export const sumLengthOfArrays = <T>(arrays: (T[] | undefined)[]): number => {
  return arrays.reduce((counter, array) => counter + (array ? array.length : 0), 0);
};
