import { InputStyle, InputStyleWrapper, InputValidationMessage, Select } from '@getvim/atomic-ui';
import { useField } from 'formik';
import React, { useState } from 'react';
import { NO_RESULTS, START_SEARCH } from '../../../../../../consts/selectInputMessages';
import { FreeTextResult } from '../../../../../../types';
import { logic } from '../../../../../../logic';
import { CptOption } from '../../../../types';
import {
  evaluationAndManagementCpts,
  notForUseWithMedicareCpts,
  notForUseWithMedicareMessage,
} from '../cptFilterData';
import '../Procedures.less';
import { getReferralRequestUIString } from '../../../../strings';

interface CptInputFieldProps {
  itemIndex: number;
}

const formatOptions = (options: FreeTextResult[] | undefined): CptOption[] => {
  if (!options) return [];
  return options.map(({ codes, description }) => ({
    value: {
      code: codes[0],
      description,
      toString() {
        return `${codes[0]} - ${description}`;
      },
    },
    label: `${codes[0]} - ${description}`,
  }));
};

let evaluationAndManagementOptions: FreeTextResult[];

export const CptInputField: React.FC<CptInputFieldProps> = ({ itemIndex }) => {
  const [, meta, helpers] = useField(`procedures[${itemIndex}].cpt`);
  const [options, setOptions] = useState<FreeTextResult[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getEvaluationAndManagementCpts = async (searchString: string) => {
    if (!searchString) return [];
    if (!evaluationAndManagementOptions) {
      const cptsResult = await logic.getCptsByCodes(evaluationAndManagementCpts);
      evaluationAndManagementOptions = cptsResult.map((cpt) => ({
        codes: [cpt.code],
        description: notForUseWithMedicareCpts.includes(cpt.code)
          ? `${notForUseWithMedicareMessage} ${cpt.description}`
          : cpt.description,
      }));
    }

    return evaluationAndManagementOptions.filter((option) =>
      `${option.codes[0]} - ${option.description}`
        .toLowerCase()
        .includes(searchString.toLowerCase()),
    );
  };

  const onSearch = async (searchString: string) => {
    if (!searchString) return setOptions(undefined);
    setIsLoading(true);

    const cptOptions: FreeTextResult[] = await getEvaluationAndManagementCpts(searchString);

    setOptions(cptOptions);
    setIsLoading(false);
  };

  const onChange = (event) => {
    if (!event.length) return helpers.setValue(undefined);
    helpers.setValue({ code: event[0]?.value.code, description: event[0]?.value?.description });
  };

  return (
    <div className="referral-request-input-container no-margin-bottom">
      <InputStyleWrapper
        className="referral-request-select-input-style-wrapper"
        inputStyle={InputStyle.pillMedium}
        hasError={meta.touched && !meta.value}
        rightIcon={<></>}
      >
        <div className="input">
          <Select
            label={getReferralRequestUIString('referral_quantity_type_procedure_code_label')}
            placeholder=""
            id="cpt-input"
            name="cpt-input"
            labelField="label"
            valueField="value"
            optionLabelField="value"
            keepSelectedInList
            hideValueOnInput
            options={formatOptions(options)}
            onChange={onChange}
            showChevronIcon
            searchFn={({ state }) => {
              onSearch(state.search);
            }}
            noDataLabel={options ? NO_RESULTS : START_SEARCH}
            noInputLabel={START_SEARCH}
            noResultsLabel={NO_RESULTS}
            closeOnSelect
            setTouched={() => helpers.setTouched(true, false)}
            loading={isLoading}
            clearable
            {...(meta.value?.code && meta.value?.description
              ? {
                  values: [
                    {
                      label: `${meta.value.code} - ${meta.value.description}`,
                      value: meta.value,
                    },
                  ],
                }
              : {})}
          />
          {meta.touched && !meta.value && (
            <InputValidationMessage>
              {getReferralRequestUIString('referral_quantity_type_procedure_code_missing_label')}
            </InputValidationMessage>
          )}
        </div>
      </InputStyleWrapper>
    </div>
  );
};
