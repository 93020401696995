import {
  AddressItem,
  AnalyticsHover,
  Badge,
  BadgeTypeEnum,
  MiniResultCard,
  SvgPreferredProvider,
} from '@getvim/atomic-ui';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import { WriteBacks } from '@getvim/vim-connect';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { sendAddressChangeAnalytic, sendTooltipHoveredAnalytic } from '../../../../../analytics';
import { SELECT_BUTTON_DISABLED_MESSAGE } from '../../../consts/selectInputMessages';
import {
  ActionStatus,
  AddressWithDistanceAndPhone,
  FloatingBadgesEnum,
  OrderAssistOption,
  SelectActionMethod,
  SelectProviderAction,
  SetAddressChangePayload,
} from '../../../types';

import { useFlowState } from '@getvim/flow-context-provider/build';
import { getOrderAssistUIString } from '../../../consts/strings';
import {
  acceptingNewPatientsBadge,
  caterpillarNetworkBadge,
  notAcceptingNewPatientsBadge,
} from '../../../logic/actions/providers/formatters';
import addressFormat from '../../../utils/addressFormatter';
import { capitalizeAsName } from '../../../utils/general';
import { SearchRequiredState } from '../../SourceConfigWrapper';
import { GetSelectProviderActionType } from '../../order-assist-app/hooks';

const buildAddressesItems = (addresses: AddressWithDistanceAndPhone[]): AddressItem[] =>
  addresses.map((address: AddressWithDistanceAndPhone) => {
    const formattedAddress = addressFormat(address);
    const { officeName, distance, phone, acceptNewPatients } = address;

    return {
      title: officeName,
      value: formattedAddress,
      text: formattedAddress,
      distance,
      phone,
      acceptNewPatients,
      fax: address.fax,
    };
  });

const badgesWithLongTooltip = new Set([
  BadgeTypeEnum.PremiumCarePhysician,
  BadgeTypeEnum.QualityCarePhysician,
  BadgeTypeEnum.NotEvaluatedForPremiumCare,
  BadgeTypeEnum.DoesNotMeetPremiumQualityCriteria,
  BadgeTypeEnum.DesignatedDiagnosticProvider,
  BadgeTypeEnum.high_tier,
  BadgeTypeEnum.middle_tier,
  BadgeTypeEnum.low_tier,
  BadgeTypeEnum.acceptingNewPatients,
  BadgeTypeEnum.notAcceptingNewPatients,
]);

const selectButtonTitle = (): Record<WriteBacks.SelectAction, string> => {
  return {
    [WriteBacks.SelectAction.COPY_TO_CLIPBOARD]: getOrderAssistUIString(
      'result_card_copy_details_button',
    ),
    [WriteBacks.SelectAction.WRITEBACK]: getOrderAssistUIString('result_card_select_button'),
  };
};

interface SearchResultsListItemProps {
  item: OrderAssistOption;
  index: number;
  onProviderSelect: SelectProviderAction;
  costResultStatus: ActionStatus;
  isAlternative?: boolean;
  isPreferredResult?: boolean;
  getSelectionType: GetSelectProviderActionType;
  onProviderAddressChange: (payload: SetAddressChangePayload) => void;
  isReadonlyMode?: boolean;
  useSelectedLocationOfProvider: boolean;
  shouldDisplayNewPatientsIcon: boolean;
}

const SearchResultsListItem: React.FC<SearchResultsListItemProps> = ({
  item,
  index,
  costResultStatus,
  onProviderSelect,
  isAlternative = false,
  isPreferredResult = false,
  getSelectionType,
  onProviderAddressChange,
  isReadonlyMode,
  useSelectedLocationOfProvider,
  shouldDisplayNewPatientsIcon,
}) => {
  const [shouldDisplayAcceptNewPatientsBadge, setShouldDisplayAcceptNewPatientsBadge] = useState<
    boolean | undefined
  >(item.locations[0].acceptNewPatients);
  const [shouldDisplayCaterpillarNetworkBadge, setShouldDisplayCaterpillarNetworkBadge] = useState<
    boolean | undefined
  >(item.locations[0].implementationSpecificFields?.caterpillarNetwork);
  const cardRank = item.rank;
  const addressesItems = buildAddressesItems(item.locations);
  const buttonTitle = selectButtonTitle()[getSelectionType(item)];
  const { userConfig } = useFlowState<SearchRequiredState>();
  const { sourceConfig } = useFlowState<SearchRequiredState>();
  const { supportsAcceptingNewPatientsLongTooltip } = sourceConfig;
  const [useNewPreferredBadge] = useFeatureFlag({
    defaultValue: false,
    flagName: 'useNewPreferredBadge',
    flagContext: { organizationId: userConfig.organization?.id },
  });

  const [useAcceptNewPatientsBadges] = useFeatureFlag({
    defaultValue: false,
    flagName: 'useAcceptNewPatientsBadges',
    flagContext: { organizationId: userConfig.organization?.id },
  });

  const [addPreferredBadgeHoverAnalytics] = useFeatureFlag({
    defaultValue: false,
    flagName: 'addPreferredBadgeHoverAnalytics',
  });

  const [useCaterpillarBadge] = useFeatureFlag({
    defaultValue: false,
    flagName: 'useCaterpillarNetworkBadge',
    flagContext: { organizationId: userConfig.organization.id },
    team: Team.Interfaces,
  });

  const name = item.details.name;

  const onSelectButtonClicked = useCallback(
    () =>
      onProviderSelect({
        provider: item,
        isAlternative: isPreferredResult,
        isPreferred: isPreferredResult,
        actionMethod: SelectActionMethod.IN_APP_CTA,
      }),
    [isPreferredResult, item, onProviderSelect],
  );

  const onHandleHoverAction = useCallback(() => {
    sendTooltipHoveredAnalytic({
      iconName: FloatingBadgesEnum.PreferredProvider,
      cardType: item.type,
      cardRank,
      isAlternativeResult: false,
      isPreferredResult,
    });
  }, [cardRank, isPreferredResult, item.type]);

  const getPreferredBadge = useCallback(() => {
    if (!useNewPreferredBadge) return undefined;
    return addPreferredBadgeHoverAnalytics ? (
      <AnalyticsHover onHoverAction={onHandleHoverAction}>
        <div className="preferred-provider-card__icon__container">
          <SvgPreferredProvider className="preferred-provider-card__icon__container_badge" />
        </div>
      </AnalyticsHover>
    ) : (
      <div className="preferred-provider-card__icon__container">
        <SvgPreferredProvider style={{ marginLeft: '2px' }} />
      </div>
    );
  }, [useNewPreferredBadge, addPreferredBadgeHoverAnalytics, onHandleHoverAction]);

  const getOnHoverTooltipCallback = useCallback(
    (badgeType: BadgeTypeEnum) => {
      return () =>
        sendTooltipHoveredAnalytic({
          iconName: badgeType,
          cardType: item.type,
          cardRank,
          isAlternativeResult: isAlternative,
          isPreferredResult,
        });
    },
    [cardRank, isAlternative, isPreferredResult, item.type],
  );

  const onHandleAddressChange = useCallback(
    (address: AddressItem) => {
      const addressIndex = addressesItems.indexOf(address);

      onProviderAddressChange({
        addressIndex,
        resultIndex: index,
        isPreferred: isPreferredResult,
        isAlternative,
      });

      sendAddressChangeAnalytic({
        name: capitalizeAsName(name),
        addressRank: addressIndex + 1,
        cardType: item.type,
        cardRank,
        isAlternativeResult: isAlternative,
        isPreferredResult,
      });

      setShouldDisplayAcceptNewPatientsBadge(item.locations[addressIndex].acceptNewPatients);
      setShouldDisplayCaterpillarNetworkBadge(
        item.locations[addressIndex]?.implementationSpecificFields?.caterpillarNetwork,
      );
    },
    [
      addressesItems,
      cardRank,
      index,
      isAlternative,
      isPreferredResult,
      item.locations,
      item.type,
      name,
      onProviderAddressChange,
    ],
  );

  const getBadges = useCallback(() => {
    const badges = [...item.badges];

    if (useCaterpillarBadge && shouldDisplayCaterpillarNetworkBadge) {
      badges.push(caterpillarNetworkBadge());
    }

    if (
      useAcceptNewPatientsBadges &&
      shouldDisplayAcceptNewPatientsBadge !== null &&
      shouldDisplayAcceptNewPatientsBadge !== undefined
    ) {
      badges.push(
        shouldDisplayAcceptNewPatientsBadge
          ? acceptingNewPatientsBadge(supportsAcceptingNewPatientsLongTooltip)
          : notAcceptingNewPatientsBadge(supportsAcceptingNewPatientsLongTooltip),
      );
    }

    return badges.map((badge) => (
      <AnalyticsHover
        key={badge.type}
        onHoverAction={getOnHoverTooltipCallback(badge.type)}
        className={classNames('badge-analytics-wrapper', {
          'new-patients-badge': [
              BadgeTypeEnum.notAcceptingNewPatients,
              BadgeTypeEnum.acceptingNewPatients,
          ].includes(badge.type),
          },
          {
            'caterpillar-badge': badge.type === BadgeTypeEnum.caterpillarNetwork,
        })}
      >
        <Badge
          key={badge.type}
          badgeType={badge.type}
          title={badge.title}
          tooltip={badge.description}
          hideTooltip={!badge.description}
          longTooltip={badgesWithLongTooltip.has(badge.type)}
          parseNewLinesTooltip
        />
      </AnalyticsHover>
    ));
  }, [
    item.badges,
    useAcceptNewPatientsBadges,
    useCaterpillarBadge,
    shouldDisplayAcceptNewPatientsBadge,
    shouldDisplayCaterpillarNetworkBadge,
    supportsAcceptingNewPatientsLongTooltip,
    getOnHoverTooltipCallback,
  ]);

  return (
    <div
      style={{ marginBottom: '10px' }}
      key={`${item.details.npi}${isPreferredResult ? '-preferred' : ''}`}
    >
      <MiniResultCard
        topSectionMainValue={
          costResultStatus === ActionStatus.SUCCESS
            ? item.costDetail?.cost ?? getOrderAssistUIString('result_card_no_cost_available_label')
            : undefined
        }
        topSectionSecondaryValue={
          item.costDetail && getOrderAssistUIString('result_card_estimated_out_of_pocket_label')
        }
        topSectionTooltip={item.costDetail?.message}
        name={capitalizeAsName(name)}
        secondaryName={item.details.acoName && capitalizeAsName(item.details.acoName)}
        specialties={
          item.details.specialties?.length
            ? item.details.specialties
                .filter((s) => s.description)
                .map((s) => capitalizeAsName(s.description))
            : [getOrderAssistUIString('result_card_specialty_unavailable_label')]
        }
        addresses={addressesItems}
        onSelectButtonClicked={onSelectButtonClicked}
        selectButtonText={buttonTitle}
        selectButtonDisabled={isReadonlyMode}
        selectButtonTooltip={isReadonlyMode ? SELECT_BUTTON_DISABLED_MESSAGE : undefined}
        onAddressChange={onHandleAddressChange}
        isPreferredResult={isPreferredResult}
        preferredProvider={item.details.preferredProvider}
        badges={getBadges()}
        preferredBadge={getPreferredBadge()}
        selectedLocationIndex={
          useSelectedLocationOfProvider ? item.selectedLocationIndex : undefined
        }
        shouldDisplayNewPatientsIcon={shouldDisplayNewPatientsIcon}
      />
    </div>
  );
};

export default SearchResultsListItem;
