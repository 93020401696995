import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { NotificationConfigMap, PlatformNotification } from './types';
import { useCallback, useMemo } from 'react';
import { orderAssistLogger } from '../../../components/app/logger';

export const useSdkNotifications = (): PlatformNotification => {
  const { pushNotification } = useApi();

  const triggerNotification = useCallback(
    (notificationConfigMap: NotificationConfigMap) => {
      try {
        pushNotification.show(notificationConfigMap.sdk);
      } catch (error) {
        orderAssistLogger.error('Failed to show notification', {
          error,
          notificationConfig: notificationConfigMap.sdk,
        });
      }
    },
    [pushNotification],
  );

  const triggerHideNotification = useCallback(() => {
    try {
      pushNotification.hide();
    } catch (error) {
      orderAssistLogger.error('Failed to hide notification', {
        error,
      });
    }
  }, [pushNotification]);

  return useMemo(
    () => ({ triggerNotification, triggerHideNotification }),
    [triggerNotification, triggerHideNotification],
  );
};
