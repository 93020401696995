import { ProductSource } from '@getvim-core-apps/organizations';
import type { SendOutcomeFunction, SendOutcomeFunctionProps, WidgetSource } from '../../types';
import { sendUHCOutcome } from '../uhc/uhcSendOutcome';

const outcomeBySource: { [key in WidgetSource]?: SendOutcomeFunction | undefined } = {
  [ProductSource.UnitedHealthCare]: sendUHCOutcome,
};

export const sendOutcomeBySource = (
  source: WidgetSource | undefined,
  props: SendOutcomeFunctionProps,
): void => {
  if (!source) return;
  const outcome = outcomeBySource[source];
  outcome?.(props);
};
