import { useFlowState } from '@getvim/flow-context-provider';
import React, { FC, useEffect, useState } from 'react';
import { UserConfig } from '../../../hooks';
import { isPatientEligible } from '../../../utils/patientEligibility';
import { Patient } from '../../app/types';
import { Button } from '../components';
import { useAppState } from '../../../stores/app-state/AppState.store';
import { useFeatureFlag } from '@getvim/feature-flags-react';

export const ReferralSelectProviderInlineWidget: FC = () => {
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const { userConfig, patient } = useFlowState<{ userConfig: UserConfig; patient: Patient }>();

  const { state: appState } = useAppState();

  const [useAppGlobalState] = useFeatureFlag({
    flagName: 'useAppGlobalState',
    defaultValue: false,
    flagContext: {
      organizationId: userConfig.organization?.id,
    },
  });
  useEffect(() => {
    const handleShouldDisplay = async (patientData: Patient) => {
      const isEligible = useAppGlobalState
        ? !!appState.isPatientEligible
        : await isPatientEligible(patientData, userConfig);
      setShouldDisplay(isEligible);
    };

    handleShouldDisplay(patient);
    // TODO do it like orders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.patientId, userConfig]);

  if (!shouldDisplay) {
    return null;
  }

  return <Button />;
};
