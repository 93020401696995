import { logger } from '@getvim/vim-connect-logger';
import { ReferralRequestStatus } from '../components/app/components/referral-request/types';

const badgesLogger = logger.scope('getStatusBadgeURL');

const badgesBaseURL = 'https://static.getvim.com/status-badges';

const statusBadges = {
  [ReferralRequestStatus.APPROVED]: 'approved.svg',
  [ReferralRequestStatus.APPROVED_AND_MODIFIED]: 'approved_and_modified.svg',
  [ReferralRequestStatus.CANCELLED]: 'cancelled.svg',
  [ReferralRequestStatus.NO_ACTION_REQUIRED]: 'no_action_required.svg',
  [ReferralRequestStatus.PENDING]: 'pending.svg',
};

const getStatusBadgeURL = (status?: string): string | undefined => {
  if (status && statusBadges[status]) {
    const badgeURL = `${badgesBaseURL}/${statusBadges[status]}`;
    badgesLogger.debug(`returning badge url of ${status}`, { status, badgeURL });
    return badgeURL;
  }
  badgesLogger.error(`cannot find badge for ${status}`, { status });
  return undefined;
};

export default getStatusBadgeURL;
