import { keyBy } from 'lodash-es';

// iso 639 mapping source https://www.loc.gov/standards/iso639-2/php/code_list.php
const languages = [
  { iso6391Code: 'ar', iso6392Code: 'ara', name: 'Arabic' },
  { iso6391Code: 'de', iso6392Code: 'deu', name: 'German' },
  { iso6391Code: 'en', iso6392Code: 'eng', name: 'English' },
  { iso6391Code: 'es', iso6392Code: 'spa', name: 'Spanish' },
  { iso6391Code: 'fr', iso6392Code: 'fra', name: 'French' },
  { iso6391Code: 'hi', iso6392Code: 'hin', name: 'Hindi' },
  { iso6391Code: 'ht', iso6392Code: 'hat', name: 'Haitian' },
  { iso6391Code: 'it', iso6392Code: 'ita', name: 'Italian' },
  { iso6391Code: 'ja', iso6392Code: 'jpn', name: 'Japanese' },
  { iso6391Code: 'ko', iso6392Code: 'kor', name: 'Korean' },
  { iso6391Code: 'pl', iso6392Code: 'pol', name: 'Polish' },
  { iso6391Code: 'pt', iso6392Code: 'por', name: 'Portuguese' },
  { iso6391Code: 'ru', iso6392Code: 'rus', name: 'Russian' },
  { iso6391Code: 'tl', iso6392Code: 'tgl', name: 'Tagalog' },
  { iso6391Code: 'vi', iso6392Code: 'vie', name: 'Vietnamese' },
  { iso6391Code: 'yi', iso6392Code: 'yid', name: 'Yiddish' },
  { iso6391Code: 'zh', iso6392Code: 'zho', name: 'Chinese' },
];

export const iso6391By6392 = keyBy(languages, 'iso6392Code');
