export const evaluationAndManagementCpts = [
  '99245',
  '99244',
  '99243',
  '99242',
  '92014',
  '92012',
  '92002',
  '92004',
  'G0466',
  'G0467',
  '99211',
  '99215',
  '99214',
  '99213',
  '99212',
  '99202',
  '99203',
  '99204',
  '99205',
  '99354',
  '99355',
];

export const notForUseWithMedicareCpts = ['99245', '99244', '99243', '99242'];

export const notForUseWithMedicareMessage = '(Not for use with Medicare)';
