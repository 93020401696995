import { analyticsManager } from './analyticsManager';
import { IRRELEVANT_ANALYTIC_FIELD } from './types';
import { NotificationType, NotificationTypeMetadata } from './types/notifications';

export const trackSearchNotificationTriggered = (
  notificationId: string,
  {
    highValue,
    preferred,
    inNetwork,
    zeroResults,
  }: NotificationTypeMetadata[NotificationType.PROVIDERS_RESULTS],
): void => {
  analyticsManager.track('search_result_notification_triggered', {
    notification_details: {
      notificationId,
      notificationType: NotificationType.PROVIDERS_RESULTS,
      type: {
        highValue,
        preferred,
        inNetwork,
        zeroResults,
        mappingFailed: false,
      },
      notification_metadata: {
        ehrNativeSpecialty: IRRELEVANT_ANALYTIC_FIELD,
      },
    },
  });
};

export const trackSpecialtyMappingNotificationTriggered = (
  notificationId: string,
  ehrNativeSpecialty: string,
): void => {
  analyticsManager.track('search_result_notification_triggered', {
    notification_details: {
      notificationId,
      notificationType: NotificationType.SPECIALTY_MAPPING,
      type: {
        highValue: false,
        preferred: false,
        inNetwork: false,
        zeroResults: false,
        mappingFailed: true,
      },
      notification_metadata: {
        ehrNativeSpecialty,
      },
    },
  });
};
