import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import { useFlowState } from '@getvim/flow-context-provider/build';
import { Entities } from '@getvim/vim-connect';
import React, { useEffect } from 'react';
import { UserConfig } from '../../../hooks';
import { calculateSource, useSourceConfig } from '../../../hooks/useSourceConfig';
import OrdersSelectProviderInlineWidget from './OrdersSelectProviderInlineWidget';

export const OrderSourceConfigWrapper: React.FC = () => {
  const { userConfig, patient } = useFlowState<{
    userConfig: UserConfig;
    patient: Entities.Patient;
  }>();

  const [shouldFloridaBlueSupportFacility] = useFeatureFlag({
    flagName: 'shouldFloridaBlueSupportFacility',
    team: Team.OrderOptimization,
    defaultValue: false,
    flagContext: { organizationId: userConfig?.organization?.id },
  });

  const {
    patientSourceConfig: { sourceConfig },
    setSourceConfigParams,
  } = useSourceConfig(shouldFloridaBlueSupportFacility);

  useEffect(() => {
    const calculatedSource = calculateSource(patient, userConfig);
    setSourceConfigParams(calculatedSource, patient);
  }, [patient, setSourceConfigParams, userConfig]);

  return <OrdersSelectProviderInlineWidget sourceConfig={sourceConfig} />;
};
