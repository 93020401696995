import { useCallback } from 'react';
import { isVimOsAppMode } from '../../../../../../vim-os-sdk-migration';
import { useRuntimeSelectProviderButton } from './useRuntimeSelectProviderButton';
import { useSdkSelectProviderButton } from './useSdkSelectProviderButton';
import { UseSelectProviderButtonProps } from './types';
import {
  DI_SPECIALTY_DESCRIPTION,
  DI_VIM_SPECIALTY_ID,
  LABS_SPECIALTY_DESCRIPTION,
  LABS_VIM_SPECIALTY_ID,
} from './consts';
import { Standard, StandardEvents } from '@getvim/vim-connect';
import { unionBy } from 'lodash-es';
import { trackSelectProviderButtonPressed } from '../../../../../../analytics';
import { CPT, Order } from '../../../../types';
import { SearchActionType } from '../../reducers/searchReducer';
import { SelectProviderButtonSource } from '../../../../../../analytics/types';
import { useAutoPopup } from '../../../../../../hooks/useAutoPopupApp';
import { useOrderAssistAppFeatureFlags } from '../../OrderAssistFFWrapper';

const useSelectProviderButtonHook = isVimOsAppMode
  ? useSdkSelectProviderButton
  : useRuntimeSelectProviderButton;

export const useSelectProviderButton = ({
  searchDispatch,
  onPopulateOrderAssist,
  patient,
  appDispatch,
  supportOrdersButton,
}: UseSelectProviderButtonProps): void => {
  const autoPopupOrderAssist = useAutoPopup();
  const { autoSearchWhenDIInjectedButtonClicked } = useOrderAssistAppFeatureFlags();

  const onDiOrdersSelectButtonClicked = useCallback(
    (selectedOrders?: Order[]) => {
      searchDispatch({ type: SearchActionType.RESET_STATE });
      const diOrders = selectedOrders?.filter(
        (order) => order.type === Standard.Entities.OrderType.DI,
      );
      const cpt = getOrdersCpt(diOrders);
      const speciality: StandardEvents.TransformedSpecialty = {
        description: DI_SPECIALTY_DESCRIPTION,
        vimSpecialty: {
          description: DI_SPECIALTY_DESCRIPTION,
          id: DI_VIM_SPECIALTY_ID,
        },
      };

      onPopulateOrderAssist({
        ...(autoSearchWhenDIInjectedButtonClicked ? { specialty: speciality } : {}),
        cpt,
      });

      trackSelectProviderButtonPressed(SelectProviderButtonSource.DI);
      autoPopupOrderAssist();
    },
    [
      autoSearchWhenDIInjectedButtonClicked,
      onPopulateOrderAssist,
      searchDispatch,
      autoPopupOrderAssist,
    ],
  );

  const onLabOrdersSelectButtonClicked = useCallback(
    (selectedOrders?: Order[]) => {
      searchDispatch({ type: SearchActionType.RESET_STATE });
      const labOrders = selectedOrders?.filter(
        (order) => order.type === Standard.Entities.OrderType.LAB,
      );
      const cpt = getOrdersCpt(labOrders);

      const speciality: StandardEvents.TransformedSpecialty = {
        description: LABS_SPECIALTY_DESCRIPTION,
        vimSpecialty: {
          description: LABS_SPECIALTY_DESCRIPTION,
          id: LABS_VIM_SPECIALTY_ID,
        },
      };

      onPopulateOrderAssist({
        specialty: speciality,
        cpt,
      });

      trackSelectProviderButtonPressed(SelectProviderButtonSource.LAB);
      autoPopupOrderAssist();
    },
    [onPopulateOrderAssist, searchDispatch, autoPopupOrderAssist],
  );

  const onReferralSelectButtonClicked = useCallback(() => {
    trackSelectProviderButtonPressed(SelectProviderButtonSource.REFERRAL);
    autoPopupOrderAssist();
  }, [autoPopupOrderAssist]);

  return useSelectProviderButtonHook(
    { patient, appDispatch, supportOrdersButton },
    {
      onDiOrdersSelectButtonClicked,
      onLabOrdersSelectButtonClicked,
      onReferralSelectButtonClicked,
    },
  );
};

const getOrdersCpt = (orders: Order[] = []): CPT[] => {
  const cptArrays = orders.map(({ cpts }) => cpts);
  return unionBy(cptArrays.flat(), 'code');
};
