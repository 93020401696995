import React from 'react';
import { Banner, BannerTypes, ColorNameEnum } from '@getvim/atomic-ui';

const NOTE_TEXT = (
  <span className="text-semibold">
    For in-network results, please select the patient's insurance details
  </span>
);

export const DisclaimerNote: React.FC = () => {
  return (
    <Banner
      text={NOTE_TEXT}
      type={BannerTypes.DISCLAIMER}
      icon={<i className="icon-megaphone" />}
      textColor={ColorNameEnum.ultraDark}
    />
  );
};
