import { StandardEvents } from '@getvim/vim-connect/build';
import { isEmpty, isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { SearchMethod } from '../../../../../analytics/types';

import { isPatientEligibleForSource } from '../../../../../utils/patientEligibility';
import {
  AppState,
  CPT,
  ICD,
  SourceConfigurations,
  WidgetSource,
  FreeTextType,
  OrderAssistFormData,
  OrderAssistFormErrors,
  Patient,
  Provider,
  SelectOption,
  DataFilters,
} from '../../../types';

import { getSelectOptions } from '../../../utils/icdCptFormat';
import { SearchFormValidator } from '../../search-form/types';
import { SearchActionType, SearchDispatch } from '../reducers/searchReducer';
import { OnSearchMethod } from './useSearch';

export interface OnPopulateOrderAssistCallbackProps {
  vimReferralId?: string;
  vimPatientId?: string;
  ehrNativeSpecialty?: string;
  specialty?: StandardEvents.TransformedSpecialty;
  cpt?: CPT[];
  icd?: ICD[];
  referringProvider?: Provider;
}

export type OnPopulateOrderAssistCallback = (eventData: OnPopulateOrderAssistCallbackProps) => void;

interface UsePopulateOrderAssist {
  onPopulateOrderAssist: OnPopulateOrderAssistCallback;
}

interface UsePopulateOrderAssistProps {
  searchDispatch: SearchDispatch;
  formData: OrderAssistFormData;
  filtersData?: DataFilters;
  onSearch: OnSearchMethod;
  searchFormValidator: SearchFormValidator;
  setAllDirty: () => void;
  sourceConfig: SourceConfigurations | undefined;
  referral: AppState['referral'];
  source: WidgetSource | undefined;
  patient: Patient | undefined;
  organizationId: number | undefined;
  onFailedSpecialtyMapping: (ehrSpecialty: string) => void;
}

export const usePopulateOrderAssist = ({
  searchDispatch,
  formData,
  filtersData,
  onSearch,
  searchFormValidator,
  setAllDirty,
  sourceConfig,
  referral: currReferral,
  source,
  patient,
  organizationId,
  onFailedSpecialtyMapping,
}: UsePopulateOrderAssistProps): UsePopulateOrderAssist => {
  const [shouldTriggerSearch, setShouldTriggerSearch] = useState(false);
  // I will refactor this file soon so I will keep this solution like this for now...
  const [lastEhrSpecailty, setLastEhrSpecialty] = useState<string>();
  const [populatePayload, setPopulatePayload] = useState<OnPopulateOrderAssistCallbackProps>();

  useEffect(() => {
    const populateForm = async () => {
      if (populatePayload && source && sourceConfig) {
        const {
          cpt,
          icd,
          specialty,
          referringProvider,
          vimPatientId,
          vimReferralId,
          ehrNativeSpecialty,
        } = populatePayload;

        const cptOptions: SelectOption[] =
          sourceConfig.supportIcdCpt && cpt ? getSelectOptions(cpt) : [];
        const icdOptions: SelectOption[] =
          sourceConfig.supportIcdCpt && icd ? getSelectOptions(icd) : [];

        const stateUpdate: Partial<OrderAssistFormData> = {
          ...(sourceConfig.supportIcdCpt && cpt && { cpt: cptOptions }),
          ...(sourceConfig.supportIcdCpt && icd && { icd: icdOptions }),
          referringProvider,
        };

        if (!specialty) {
          stateUpdate.specialty = undefined;
        } else {
          const specialtyId = specialty?.vimSpecialty?.id;
          const specialtyDescription = specialty?.vimSpecialty?.description;
          if (!specialtyId) {
            stateUpdate.specialty = undefined;

            if (ehrNativeSpecialty) {
              onFailedSpecialtyMapping(ehrNativeSpecialty);
            }
          } else {
            searchDispatch({
              type: SearchActionType.ON_FREE_TEXT_FINISH,
              payload: {
                freeTextType: FreeTextType.Nucc,
                results: [
                  {
                    id: specialtyId,
                    description: specialtyDescription ?? '',
                  },
                ],
                isError: false,
              },
            });

            stateUpdate.specialty = {
              vimSpecialtyDescription: specialtyDescription,
              vimSpecialtyId: specialtyId,
              searchDisplay: specialtyDescription ?? '',
            };
          }
        }

        const referralSpecialty = currReferral?.specialty?.vimSpecialty;
        const shouldAutoSearch = !isEqual(
          stateUpdate.specialty?.vimSpecialtyId,
          referralSpecialty?.id,
        );

        searchDispatch({
          type: SearchActionType.UPDATE_FORM_DATA,
          payload: {
            ...stateUpdate,
            ...(shouldAutoSearch && {
              lastName: undefined,
              firstName: undefined,
              npi: undefined,
              facilityName: undefined,
            }),
            vimPatientId,
            vimReferralId,
          },
        });

        if (shouldAutoSearch && isPatientEligibleForSource(patient, source)) {
          searchDispatch({ type: SearchActionType.RESET_SEARCH_RESULTS });
          setLastEhrSpecialty(populatePayload.ehrNativeSpecialty);
          setShouldTriggerSearch(true);
        } else {
          setLastEhrSpecialty(undefined);
        }

        setPopulatePayload(undefined);
      }
    };
    populateForm();
  }, [
    currReferral?.specialty?.vimSpecialty,
    onFailedSpecialtyMapping,
    organizationId,
    patient,
    populatePayload,
    searchDispatch,
    source,
    sourceConfig,
  ]);

  useEffect(() => {
    if (shouldTriggerSearch && !isEmpty(formData)) {
      setAllDirty();

      const [hasErrors]: [boolean, OrderAssistFormErrors] = searchFormValidator.validator({
        formData,
      });
      if (!hasErrors) {
        searchDispatch({
          type: SearchActionType.RESET_FILTERS,
        });
        onSearch({
          ehrNativeSpecialty: lastEhrSpecailty,
          searchFormData: formData,
          filtersData: undefined,
          method: SearchMethod.AUTOMATIC,
          preferredProviders: { fetchPreferredProviders: true },
        });
      }
      setShouldTriggerSearch(false);
    }
  }, [
    formData,
    onSearch,
    searchFormValidator,
    setAllDirty,
    shouldTriggerSearch,
    filtersData,
    lastEhrSpecailty,
    searchDispatch,
  ]);

  const onPopulateOrderAssist = useCallback((eventData: OnPopulateOrderAssistCallbackProps) => {
    setPopulatePayload(eventData);
  }, []);

  return { onPopulateOrderAssist };
};
