import React, { FC } from 'react';
import { AlignEnum, ColorNameEnum, Text } from '@getvim/atomic-ui';
import './ProcedureStatus.less';

interface ProcedureStatusCardItemProps {
  label?: string;
  value?: string;
}

const ProcedureStatusCardItem: FC<ProcedureStatusCardItemProps> = ({ label, value }) => {
  const valueText = value ? `- ${value}` : '';

  return (
    <div className="procedure-status-card-item">
      <Text className="item-label" colorName={ColorNameEnum.theme} align={AlignEnum.left}>
        {label} {valueText && <b>{valueText}</b>}
      </Text>
    </div>
  );
};

export default ProcedureStatusCardItem;
