import {
  ReferralRequestEventNames,
  TimeoutScreenClickedPayload,
} from '../types/referralRequestAnalyticsEvents.types';
import { referralRequestAnalyticsClient } from '../clients/referralRequest.client';

export const trackTimeoutScreenViewed = (): void => {
  referralRequestAnalyticsClient.track(ReferralRequestEventNames.TIMEOUT_SCREEN_VIEWED, {});
};

export const trackTimeoutScreenClicked = (payload: TimeoutScreenClickedPayload): void => {
  const { buttonName } = payload;
  referralRequestAnalyticsClient.track(ReferralRequestEventNames.TIMEOUT_SCREEN_CLICKED, {
    buttonName,
  });
};
