import { useEffect } from 'react';
import { registerWidget } from '@getvim/utils-vim-connect-communication';
import { isVimOsAppMode } from '../../../vim-os-sdk-migration';

export const useWidgetHealthCheck = (): void => {
  useEffect(() => {
    if (isVimOsAppMode) {
      return;
    }

    return registerWidget(() => {});
  }, []);
};
