import { ModuleNames, useVimCommunication } from '@getvim/vim-app-infra';
import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { useCallback } from 'react';
import { orderAssistLogger } from '../../../logger';
import { RuntimeOutgoingEvents } from '../../../types';
import { isVimOsAppMode } from '../../../../../vim-os-sdk-migration';

type CopyAction = (text: string) => Promise<boolean>;

const useSdkCopyToClipboard = (): CopyAction => {
  const { copyToClipboard } = useApi();

  return useCallback(
    async (text): Promise<boolean> => {
      try {
        await copyToClipboard(text);
        return true;
      } catch (error) {
        orderAssistLogger.error('Error copying to clipboard', { text, error });
        return false;
      }
    },
    [copyToClipboard],
  );
};

const useRuntimeCopyToClipboard = (): CopyAction => {
  const { vimCommunication } = useVimCommunication();

  return useCallback(
    async (text): Promise<boolean> => {
      try {
        const data = await vimCommunication?.sendSyncEvent(
          ModuleNames.Runtime,
          RuntimeOutgoingEvents.CopyToClipboard,
          { input: { text } },
        );
        return data.success;
      } catch (error) {
        orderAssistLogger.error('Error copying to clipboard', { text, error });
        return false;
      }
    },
    [vimCommunication],
  );
};

export const useClipboard: () => CopyAction = isVimOsAppMode
  ? useSdkCopyToClipboard
  : useRuntimeCopyToClipboard;
