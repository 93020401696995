import { InputStyle, InputStyleWrapper, LabeledInput } from '@getvim/atomic-ui';
import React, { useEffect, useState } from 'react';
import { InputWrapperProps } from '../../../types';
import './AdvancedSearchContainer.less';

type FacilityNameInputProps = InputWrapperProps<'facilityName'> & {
  hasError: boolean;
};

const FacilityNameInputWrapper: React.FC<FacilityNameInputProps> = ({
  value,
  onChange,
  hasError,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!value) {
      setIsFocused(false);
    }
  }, [value]);

  return (
    <div className="input">
      <InputStyleWrapper
        className="input-wrapper"
        inputStyle={InputStyle.pillMedium}
        hasError={hasError}
      >
        <LabeledInput
          type="text"
          label="Facility name"
          id="facility-name-input"
          value={value ?? ''}
          onFocus={() => setIsFocused(true)}
          active={!!value || isFocused}
          onBlur={() => {
            if (!value) setIsFocused(false);
          }}
          className="advanced-search-input"
          onChange={(e) => onChange({ facilityName: e.target.value })}
        />
      </InputStyleWrapper>
    </div>
  );
};

export default FacilityNameInputWrapper;
