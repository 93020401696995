import { ClickableDiv, ColorNameEnum, SizeEnum, Text } from '@getvim/atomic-ui';
import { StandardEvents } from '@getvim/vim-connect';
import classNames from 'classnames';
import React, { useState, useCallback } from 'react';
import {
  ExtraInfo,
  OrderAssistFormData,
  OrderAssistOption,
  SelectProviderAction,
  SendOutcomeFunction,
  SetAddressChangePayload,
  ActionStatus,
} from '../../../types';
import { GetSelectProviderActionType } from '../../order-assist-app/hooks';
import NoResults from '../components/NoResults';
import SearchResultsListItem from './SearchResultsListItem';

export interface SearchResultsListProps {
  results: OrderAssistOption[];
  titleElement: JSX.Element;
  referralViewedPayload?: StandardEvents.TransformedReferralViewedPayload;
  onProviderSelect: SelectProviderAction;
  getSelectionType: GetSelectProviderActionType;
  extraInfo?: ExtraInfo;
  currentSearchState: OrderAssistFormData;
  costResultStatus: ActionStatus;
  sendOutcome: SendOutcomeFunction;
  isAlternativeResults?: boolean;
  isPreferredList?: boolean;
  hasPreferredList?: boolean;
  collapsable?: boolean;
  isReferralRequestRequired: boolean;
  onProviderAddressChange: (payload: SetAddressChangePayload) => void;
  isReadonlyMode?: boolean;
  useSelectedLocationOfProvider: boolean;
  shouldDisplayNewPatientsIcon: boolean;
}

const costResultStatusToMessage = {
  [ActionStatus.FAILED]: {
    iconName: 'icon-x-circle',
    text: 'Error retrieving costs, try again in a few moments',
  },
  [ActionStatus.NOT_AVAILABLE]: {
    iconName: 'icon-warning-2',
    text: 'No cost estimation is available for this search',
  },
};

const SearchResultsList: React.FC<SearchResultsListProps> = ({
  results = [],
  titleElement,
  onProviderSelect,
  costResultStatus,
  isAlternativeResults,
  isPreferredList,
  hasPreferredList = false,
  getSelectionType,
  collapsable = false,
  onProviderAddressChange,
  isReadonlyMode,
  useSelectedLocationOfProvider,
  shouldDisplayNewPatientsIcon,
}: SearchResultsListProps) => {
  const resultElements = results.map((item, index) => {
    const isResultPreferred =
      isPreferredList || (!hasPreferredList && item?.details?.preferredProvider);
    return (
      <SearchResultsListItem
        key={item?.details?.npi}
        item={item}
        index={index}
        costResultStatus={costResultStatus}
        getSelectionType={getSelectionType}
        onProviderSelect={onProviderSelect}
        isAlternative={isAlternativeResults}
        isPreferredResult={isResultPreferred}
        onProviderAddressChange={onProviderAddressChange}
        isReadonlyMode={isReadonlyMode}
        useSelectedLocationOfProvider={useSelectedLocationOfProvider}
        shouldDisplayNewPatientsIcon={shouldDisplayNewPatientsIcon}
      />
    );
  });

  const renderContent = () => {
    if (!results?.length) {
      return <NoResults />;
    }
    if (costResultStatus === ActionStatus.NOT_SUPPORTED) return <>{resultElements}</>;
    return (
      <>
        {!isPreferredList && costResultStatus !== ActionStatus.SUCCESS && (
          <div className="search-results-list__cost-error-message">
            <i className={costResultStatusToMessage[costResultStatus].iconName} />
            <Text
              size={SizeEnum['12px']}
              colorName={ColorNameEnum.ultraDark}
              text={costResultStatusToMessage[costResultStatus].text}
            />
          </div>
        )}

        {resultElements}
      </>
    );
  };
  const [resultsListExpanded, setResultsListExpanded] = useState(false);

  const onExpandResultsList = useCallback(() => {
    setResultsListExpanded(!resultsListExpanded);
  }, [resultsListExpanded]);

  return (
    <div className="search-results-list">
      {collapsable ? (
        <>
          <ClickableDiv
            className={classNames('collapsable-results-list', {
              resultsListExpanded,
            })}
            onClick={onExpandResultsList}
          >
            {titleElement}
          </ClickableDiv>
          {resultsListExpanded && <div className="collapse-content">{renderContent()}</div>}
        </>
      ) : (
        <>
          {titleElement}
          {renderContent()}
        </>
      )}
    </div>
  );
};

export default SearchResultsList;
