import React, { createContext, useContext, useReducer } from 'react';
import { AppState, AppStateAction, AppStateActionType } from './appState.types';

const initialState: AppState = {};

const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
}>({ state: initialState, dispatch: () => {} });

export const useAppState = () => useContext(AppContext);

const reducer = (state: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case AppStateActionType.SET_PATIENT_ELIGIBLE:
      return state.isPatientEligible === action.payload
        ? state
        : { ...state, isPatientEligible: action.payload };

    case AppStateActionType.CLEAR_PATIENT:
      return state.isPatientEligible === undefined
        ? state
        : { ...state, isPatientEligible: undefined };

    default:
      return state;
  }
};

export const AppStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
