import { FormDataSpecialty } from '../../types';

export interface QuickSpecialtyOption {
  text: string;
  value: {
    specialty: FormDataSpecialty;
    placeOfService: string | undefined;
  };
}

export const DI_QUICK_SPECIALTY_BUTTON_GROUP_OPTIONS: QuickSpecialtyOption[] = [
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Clinic/Center - Radiology',
        vimSpecialtyId: 'f_84_16',
        searchDisplay: 'Clinic/Center - Radiology',
      },
      placeOfService: undefined,
    },
    text: 'Radiology & X-ray',
  },
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Clinic/Center - Magnetic Resonance Imaging (MRI)',
        vimSpecialtyId: 'f_84_52',
        searchDisplay: 'Clinic/Center - Magnetic Resonance Imaging (MRI)',
      },
      placeOfService: '22',
    },
    text: 'MRI',
  },
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Clinic/Center - Radiology, Mammography',
        vimSpecialtyId: 'f_84_30',
        searchDisplay: 'Clinic/Center - Radiology, Mammography',
      },
      placeOfService: undefined,
    },
    text: 'Mammography',
  },
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Clinic/Center - Rehabilitation, Cardiac Facilities',
        vimSpecialtyId: 'f_84_21',
        searchDisplay: 'Clinic/Center - Rehabilitation, Cardiac Facilities',
      },
      placeOfService: undefined,
    },
    text: 'Cardio',
  },
];

export const REFERRAL_QUICK_SPECIALTY_BUTTON_GROUP_OPTIONS: QuickSpecialtyOption[] = [
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Gastroenterology',
        vimSpecialtyId: 'md_284_0',
        searchDisplay: 'Gastroenterology',
      },
      placeOfService: undefined,
    },
    text: 'Gastro',
  },
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Cardiology',
        vimSpecialtyId: 'md_46_0',
        searchDisplay: 'Cardiology',
      },
      placeOfService: undefined,
    },
    text: 'Cardio',
  },
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Orthopedic Surgery',
        vimSpecialtyId: 'md_642_0',
        searchDisplay: 'Orthopedic Surgery',
      },
      placeOfService: undefined,
    },
    text: 'Ortho Sx',
  },
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Obstetrics & Gynecology',
        vimSpecialtyId: 'md_577_0',
        searchDisplay: 'Obstetrics & Gynecology',
      },
      placeOfService: undefined,
    },
    text: 'Ob & Gyn',
  },
  {
    value: {
      specialty: {
        vimSpecialtyDescription: 'Ophthalmology',
        vimSpecialtyId: 'md_612_0',
        searchDisplay: 'Ophthalmology',
      },
      placeOfService: undefined,
    },
    text: 'Ophthalmo',
  },
];

export const RESET_SPECIALTY: QuickSpecialtyOption = {
  text: 'More',
  value: {
    specialty: {
      vimSpecialtyDescription: undefined,
      vimSpecialtyId: undefined,
      searchDisplay: '',
    },
    placeOfService: undefined,
  },
};
