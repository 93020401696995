import type { Diagnosis, ProceduresCodes } from '@getvim/internal-vim-os-sdk/types';
import { Encounter, Referral } from '@getvim/react-app-infra';
export const convertDiagnoses = (diagnoses: Diagnosis[] | undefined): Encounter['assessments'] => {
  if (!diagnoses) {
    return;
  }

  return diagnoses.map(({ code, description, note }) => {
    return {
      code,
      description: description ?? '',
      note,
    };
  });
};

export const convertProcedureCodes = (
  procedureCodes: ProceduresCodes[] | undefined,
): Referral['cpts'] => {
  if (!procedureCodes) {
    return;
  }

  return procedureCodes.map(({ code, description }) => {
    return {
      code,
      name: description,
    };
  });
};
