import React, { useCallback, useMemo } from 'react';
import { ButtonGroupComponent } from '@getvim/atomic-ui';
import {
  DI_QUICK_SPECIALTY_BUTTON_GROUP_OPTIONS,
  QuickSpecialtyOption,
  REFERRAL_QUICK_SPECIALTY_BUTTON_GROUP_OPTIONS,
} from './specialties.consts';
import './quick-specialty.less';
import { isEqual } from 'lodash-es';
import { OrderAssistFormData, QuickSpecialtySearchParams } from '../../types';
import { QuickSpecialtyTypes } from '../search-form/types';

export interface CustomAction extends QuickSpecialtyOption {
  onSelect: () => void;
}

interface QuickSpecialtyProps {
  type: QuickSpecialtyTypes;
  onChange: (quickSpecialty: QuickSpecialtySearchParams) => void;
  value: OrderAssistFormData;
  customActions: CustomAction[] | undefined;
}

const mapTypeToOptions = new Map<QuickSpecialtyTypes, QuickSpecialtyOption[]>([
  [QuickSpecialtyTypes.DI, DI_QUICK_SPECIALTY_BUTTON_GROUP_OPTIONS],
  [QuickSpecialtyTypes.REFERRAL, REFERRAL_QUICK_SPECIALTY_BUTTON_GROUP_OPTIONS],
]);

export const QuickSpecialty: React.FC<QuickSpecialtyProps> = ({
  type,
  onChange,
  value,
  customActions = [],
}) => {
  const buttonGroupValue: Partial<OrderAssistFormData> = useMemo(
    () => ({
      specialty: value.specialty,
      placeOfService: value.placeOfService,
    }),
    [value.placeOfService, value.specialty],
  );

  const onChangeHandle = useCallback(
    (quickSpecialty: QuickSpecialtySearchParams) => {
      if (isEqual(quickSpecialty, buttonGroupValue)) return;
      onChange(quickSpecialty);
    },
    [buttonGroupValue, onChange],
  );

  const options = useMemo(() => {
    const items = mapTypeToOptions.get(type);
    if (!items) {
      return null;
    }

    return [...items, ...customActions];
  }, [type, customActions]);

  if (!options) {
    return null;
  }

  return (
    <div role="none" className="quick-specialty">
      <ButtonGroupComponent
        className="button-group"
        direction="row"
        buttonType="tiny"
        bgColor="groupOptionOutline"
        width="default"
        items={options}
        onChange={onChangeHandle}
        value={buttonGroupValue}
        noGaps
      />
    </div>
  );
};
