import React from 'react';
import { Banner, BannerTypes, ColorNameEnum } from '@getvim/atomic-ui';
import { UpdateCodeStatus } from '../../../types';

interface Props {
  status: UpdateCodeStatus;
}

const SUCCESS_MESSAGE =
  'The referral authorization code has been copied to the Auth Code field in your EHR.';
const ERROR_MESSAGE =
  'Oops. Something went wrong. You can manually copy the referral authorization code number below.';

export const UpdateCodeStatusBanner: React.FC<Props> = ({ status }: Props) => {
  switch (status) {
    case UpdateCodeStatus.SUCCESS:
      return (
        <Banner
          text={SUCCESS_MESSAGE}
          type={BannerTypes.SUCCESS}
          icon={<i className="icon-check-circle-2" />}
          textColor={ColorNameEnum.ultraDark}
        />
      );
    case UpdateCodeStatus.FAILURE:
      return (
        <Banner
          text={ERROR_MESSAGE}
          type={BannerTypes.ERROR}
          icon={<i className="icon-exclamation-c" />}
          textColor={ColorNameEnum.ultraDark}
        />
      );
    default:
      return null;
  }
};
