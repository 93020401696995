export const serviceTypeMapping: Record<string, string> = {
  '1': 'Medical Care',
  '73': 'Diagnostic Medical',
  '76': 'Dialysis',
  '61': 'In-vitro Fertilization',
};

export const placeOfServiceMapping: Record<string, string> = {
  '11': 'Office',
  '19': 'Outpatient Hospital Off Campus',
  '22': 'Outpatient Hospital On Campus',
};

export const DEFAULTS = {
  serviceType: '1',
  placeOfService: '11',
};

export const quantityTypeMapping = {
  0: 'Units',
  1: 'Visits',
};

export const availityDisclaimer = `The information you are about to submit is sent to the
relevant payer via Availity's infrastructure. Vim is not responsible for any denial or
approval of referral requests`;

export const ICD_MAX_AMOUNT = 12;
export const CPT_MAX_AMOUNT = 16;
export const CPT_ADD_MSG = `+ Add another procedure code`;
export const CPT_MAX_MSG_FIRST_LINE = `Max. number of procedure codes selected`;
export const CPT_MAX_MSG_SECOND_LINE = `Remove one in order to add a new procedure`;
