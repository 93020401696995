import { AlignEnum, SizeEnum, Text } from '@getvim/atomic-ui';
import { isEmpty, omitBy } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { InputWrapperProps } from '../../../types';
import './AdvancedSearchContainer.less';
import AdvancedSearchHeader from './AdvancedSearchHeader';
import FacilityNameInputWrapper from './FacilityNameInputWrapper';
import NameInputsWrapper from './NameInputsWrapper';
import NpiInputWrapper from './NpiInputWrapper';

export interface AdvancedSearchInput {
  npi?: string;
  firstName?: string;
  lastName?: string;
  facilityName?: string;
}

export type AdvancedSearchContainerProps = Omit<
  InputWrapperProps<'npi' | 'firstName' | 'lastName' | 'facilityName'>,
  'value'
> & {
  value: AdvancedSearchInput;
  validationError?: string;
  supportFacilityName?: boolean;
  inputDirtiness: { [key in keyof AdvancedSearchInput]?: boolean };
};

const AdvancedSearchContainer: React.FC<AdvancedSearchContainerProps> = ({
  value,
  onChange,
  validationError,
  supportFacilityName = false,
  inputDirtiness,
}) => {
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(!isEmpty(omitBy(value, isEmpty)));
  const isDirty =
    inputDirtiness.facilityName ||
    inputDirtiness.firstName ||
    inputDirtiness.lastName ||
    inputDirtiness.npi;

  const toggle = useCallback(() => {
    setOpenAdvancedSearch(!openAdvancedSearch);
  }, [openAdvancedSearch]);

  return (
    <div className="advanced-search-container">
      <AdvancedSearchHeader onClick={toggle} isExpanded={openAdvancedSearch} />
      {openAdvancedSearch ? (
        <div className="advanced-search-expand">
          <NpiInputWrapper value={value?.npi} onChange={onChange} />
          <NameInputsWrapper
            value={{ firstName: value?.firstName, lastName: value?.lastName }}
            onChange={onChange}
            hasError={!!validationError}
          />
          {supportFacilityName && (
            <FacilityNameInputWrapper
              value={value?.facilityName}
              onChange={onChange}
              hasError={!!validationError}
            />
          )}

          {isDirty && !!validationError && (
            <div className="advanced-search-error-message">
              <Text className="label" size={SizeEnum['12px']} align={AlignEnum.left}>
                {validationError}
              </Text>
            </div>
          )}
        </div>
      ) : undefined}
    </div>
  );
};

export default AdvancedSearchContainer;
