import { WriteBacks } from '@getvim/vim-connect';
import { OrderAssistOption } from './results';

export enum SelectActionMethod {
  IN_APP_CTA = 'in_app_cta',
  NOTIFICATION_CTA = 'notification_cta',
}
export interface SelectProviderActionParams {
  provider: OrderAssistOption;
  actionMethod: SelectActionMethod;
  isAlternative?: boolean;
  isPreferred?: boolean;
}

export type SelectProviderAction = (
  params: SelectProviderActionParams,
) => Promise<{ selectionType: WriteBacks.SelectAction; success: boolean }>;
