import { ProductSource } from '@getvim-core-apps/organizations';

export interface SourceConfigurations {
  supportIcdCpt: boolean;
  supportLanguage: boolean;
  supportOrdersButton: boolean;
  supportPlaceOfService: boolean;
  supportFacilityName: boolean;
  supportCost: boolean;
  supportWiderDistanceFilter: boolean;
  defaultDistanceFilter: number;
  useSourceFreeText: boolean;
  supportInsurancePlanInput: boolean;
  collapsePreferredProviders: boolean;
  supportReferralRequest: boolean;
  supportsAcceptingNewPatientsLongTooltip: boolean;
}

export enum SourceConfigurationTypes {
  AUTHENTICATED = 'authenticated',
}

export interface SourceConfig {
  [SourceConfigurationTypes.AUTHENTICATED]: SourceConfigurations;
}

export type WidgetSource = ProductSource;
