import { UHC } from '../types';

const sortDisclaimersByCategory = (
  disclaimers: UHC.Disclaimer[] | undefined,
): Record<UHC.Disclaimer['categoryName'], UHC.Disclaimer['message'][]> => {
  disclaimers?.sort((a, b) => {
    if (a.categoryRank > b.categoryRank) return 1;
    if (a.categoryRank === b.categoryRank) {
      if (a.internalCategoryRank > b.internalCategoryRank) return 1;
      if (a.internalCategoryRank < b.internalCategoryRank) return -1;
      return 0;
    }
    return -1;
  });
  const sortedByCategory: Record<string, string[]> = {};
  disclaimers?.forEach(({ categoryName, message }) => {
    if (!sortedByCategory[categoryName]) {
      sortedByCategory[categoryName] = [];
    }
    sortedByCategory[categoryName].push(message);
  });
  return sortedByCategory;
};

export default sortDisclaimersByCategory;
