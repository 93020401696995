import {
  ReferralRequestEventNames,
  ReferralRequestStartScreenClickedPayload,
} from '../types/referralRequestAnalyticsEvents.types';
import { referralRequestAnalyticsClient } from '../clients/referralRequest.client';

export const trackReferralRequestStartScreenViewed = (): void => {
  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.REFERRAL_REQUEST_START_SCREEN_VIEWED,
    {},
  );
};

export const trackReferralRequestStartScreenClicked = (
  payload: ReferralRequestStartScreenClickedPayload,
): void => {
  const { buttonName } = payload;
  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.REFERRAL_REQUEST_START_SCREEN_CLICKED,
    {
      buttonName,
    },
  );
};
