import { ModuleNames, useVimCommunication } from '@getvim/vim-app-infra';
import { Common, RuntimeContract } from '@getvim/vim-connect';
import { useCallback } from 'react';
import { RuntimeOutgoingEvents } from '../../../../types';
import { orderAssistLogger } from '../../../../logger';
import { WritebackReferralCallback, WritebackReferralInput } from './types';

const WRITE_BACK_TIMEOUT = 60_000;

const DEFAULT_WRITEBACK_CONFIG: Partial<RuntimeContract.Incoming.UpdateReferralWritebackConfiguration> =
  {
    displayLoadingWidget: true,
    loadingWidgetId: 'order-assist',
  };

export const useRuntimeWriteback = (): WritebackReferralCallback => {
  const { vimCommunication } = useVimCommunication();

  return useCallback<WritebackReferralCallback>(
    async (
      data: WritebackReferralInput,
      config?: Partial<RuntimeContract.Incoming.UpdateReferralWritebackConfiguration>,
    ) => {
      orderAssistLogger.info('writing back referral data', { data });

      return await Common.timeoutAfter(
        async () => {
          return await vimCommunication?.sendSyncEvent(
            ModuleNames.Runtime,
            RuntimeOutgoingEvents.WriteBack,
            {
              payload: {
                writeBack: 'updateReferral',
                data,
                config: { ...DEFAULT_WRITEBACK_CONFIG, ...config },
              },
            },
          );
        },
        WRITE_BACK_TIMEOUT,
        true,
      );
    },
    [vimCommunication],
  );
};
