import React, { useCallback, useEffect } from 'react';
import { usePopoverState, DropdownMenu, DropdownMenuItem } from '@getvim/atomic-ui';
import { CloseMethod } from '../../../../../analytics/types';
import { orderAssistLogger } from '../../../logger';
import { DownloadModal, DownloadState } from '../../modals/DownloadModal';
import { getPdfDownloadState, DownloadStatus } from './PdfDownloadStates';
import {
  ExportAnalyticsEventNames,
  ExportMenuAnalyticsContext,
} from '../../../../../analytics/types/exportAnalytics.types';
import { exportAnalyticsClient } from '../../../../../analytics/clients/exportAnalytics.client';
import { useIsAppOpen } from '../../../../../hooks/useIsAppOpen';

export interface OrderAssistExportMenuProps {
  dropdownMenuItems: DropdownMenuItem<boolean>[];
  isReadonlyMode?: boolean;
  downloadState?: DownloadState;
  onExportModalClose?: () => void;
  screenName: ExportMenuAnalyticsContext['screen_name'];
  additionalInformation?: ExportMenuAnalyticsContext['additional_information'];
}

const OrderAssistExportMenu: React.FC<OrderAssistExportMenuProps> = ({
  dropdownMenuItems,
  isReadonlyMode,
  downloadState,
  onExportModalClose,
  screenName,
  additionalInformation,
}) => {
  useEffect(() => {
    exportAnalyticsClient.init();
  }, []);

  useEffect(() => {
    exportAnalyticsClient.updateContext({
      screen_name: screenName,
      additional_information: additionalInformation,
    });
  }, [additionalInformation, screenName]);

  const popover = usePopoverState({
    placement: 'bottom-start',
  });

  const resetMenu = useCallback((trigger: 'action_completed' | 'closed_menu') => {
    exportAnalyticsClient.resetMenu();
    orderAssistLogger.info('removing export menu id', {
      trigger,
      analyticsContext: exportAnalyticsClient.getContext(),
    });
  }, []);

  const onDelayedMouseOver = () => {
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_HOVERED, {});
  };

  const onToggle = (interaction: 'opened' | 'closed') => {
    if (downloadState) {
      // download modal is open
      return;
    }
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_CLICKED, {
      interaction_type: interaction,
    });

    if (interaction === 'closed') {
      resetMenu('closed_menu');
    }
  };

  const onClose = (closeMethod: CloseMethod) => {
    onExportModalClose?.();
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_MODAL_CLOSED, {
      close_method: closeMethod,
    });
    resetMenu('action_completed');
  };

  const isAppOpen = useIsAppOpen(true);
  useEffect(() => {
    if (!isAppOpen && popover.visible) {
      orderAssistLogger.info('hiding export menu becasue left the app');
      popover.hide();
      onToggle('closed');
    }
  }, [isAppOpen, popover, onToggle]);

  return (
    <div>
      <DropdownMenu
        dropdownMenuItems={dropdownMenuItems}
        onDelayedMouseOver={onDelayedMouseOver}
        mouseHoverTime={2000}
        onToggle={onToggle}
        popover={popover}
        disabled={isReadonlyMode}
      />
      <DownloadModal
        downloadState={downloadState}
        onClose={onClose}
        prefetchStateImages={[
          getPdfDownloadState(DownloadStatus.loading),
          getPdfDownloadState(DownloadStatus.success),
          getPdfDownloadState(DownloadStatus.error),
        ]}
      />
    </div>
  );
};

export default OrderAssistExportMenu;
