import { extractPatientSourceIdentifiers } from '@getvim-core-apps/organizations';
import { orderAssistLogger } from '../components/app/logger';
import { WidgetSource } from '../components/app/types';
import { OrderAssistAppAnalyticsEvents } from './types/orderAssistAppAnalytics.types';
import { SearchIdType, searchIdsManager } from '../utils/searchIdsManager';
import { EhrContext } from './types';
import { vimPatientIdsManager } from '../utils/vimPatientIdsManager';
import { isVimOsAppMode } from '../vim-os-sdk-migration';
import { OrderAssistAnalyticsClient } from './clients/types';

interface OrderAssistAnalyticsBaseProperties {
  datasource?: WidgetSource;
  ehr_context: EhrContext;
  is_vim_os: boolean;
}

export class AnalyticsManager {
  private static analyticsClient: OrderAssistAnalyticsClient;

  private baseProperties: OrderAssistAnalyticsBaseProperties = {
    ehr_context: EhrContext.OUT_OF_CONTEXT,
    is_vim_os: isVimOsAppMode,
  };

  private upsertBaseProperties(properties: Partial<OrderAssistAnalyticsBaseProperties>) {
    this.baseProperties = { ...this.baseProperties, ...properties };
  }

  public static setAnalyticsClient(client: OrderAssistAnalyticsClient) {
    AnalyticsManager.analyticsClient = client;
  }

  public setDatasource(datasource: WidgetSource | undefined) {
    orderAssistLogger.debug('[AnalyticsManager]: datasource updated', { datasource });
    this.upsertBaseProperties({ datasource });
  }

  public getDatasource() {
    return this.baseProperties.datasource;
  }

  public setEhrContext(ehr_context: EhrContext) {
    orderAssistLogger.debug('[AnalyticsManager]: flow type updated', { ehr_context });
    this.upsertBaseProperties({ ehr_context });
  }

  public track<T extends keyof OrderAssistAppAnalyticsEvents>(
    event: T,
    eventProperties: OrderAssistAppAnalyticsEvents[T],
  ) {
    const searchSessionId = searchIdsManager.getId(SearchIdType.SESSION_ID);
    const searchId = searchIdsManager.getId(SearchIdType.SEARCH_ID);
    const vimPatientId = vimPatientIdsManager.getVimPatientId();

    const dataSource = this.getDatasource();
    const contentSupplierId =
      dataSource &&
      extractPatientSourceIdentifiers(
        vimPatientIdsManager.getPatientContentSupplierIdentifiers(),
        dataSource,
      )?.contentSupplierId;

    AnalyticsManager.analyticsClient.trackAnalytics({
      event,
      properties: {
        ...eventProperties,
        ...this.baseProperties,
        ...(searchId ? { search_id: searchId } : {}),
        ...(searchSessionId ? { search_session_id: searchSessionId } : {}),
        vim_patient_id: vimPatientId,
        ...(contentSupplierId ? { content_supplier_patient_id: contentSupplierId } : {}),
        is_vim_os: isVimOsAppMode,
      },
    });
  }
}

export const analyticsManager = new AnalyticsManager();
