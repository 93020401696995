import * as React from 'react';

const PreferredProviderSvg = ({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) => (
  <svg
    className={className}
    style={style}
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.182 14.555a.5.5 0 0 0-.364 0l-1.586.619-1.117-1.286a.5.5 0 0 0-.322-.169l-1.692-.189-.392-1.657a.5.5 0 0 0-.206-.3l-1.411-.953.423-1.65a.5.5 0 0 0-.044-.36l-.805-1.5 1.141-1.264a.5.5 0 0 0 .129-.34L1.92 3.803l1.598-.589a.5.5 0 0 0 .272-.24l.776-1.516 1.689.222a.5.5 0 0 0 .353-.088L8 .612l1.392.98a.5.5 0 0 0 .353.088l1.689-.222.776 1.515a.5.5 0 0 0 .272.241l1.599.589-.017 1.703a.5.5 0 0 0 .129.34l1.142 1.263-.806 1.5a.5.5 0 0 0-.044.362l.423 1.649-1.41.954a.5.5 0 0 0-.207.299l-.392 1.657-1.692.19a.5.5 0 0 0-.322.168l-1.117 1.286-1.586-.62Z"
      stroke="#FFFFFF"
      strokeLinejoin="round"
    />
    <path
      d="M10.626 5.168a.5.5 0 0 1 .787.615l-.04.05-4 4.5a.5.5 0 0 1-.681.06l-.046-.04-2-2a.5.5 0 0 1 .66-.748l.048.041 1.625 1.625 3.647-4.103Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default PreferredProviderSvg;
