import { EHR, GENERIC_SDK } from '@getvim/vim-os-sdk-api';
import { AppToOSMessageTypes, HandshakePayload } from '@getvim/vim-os-api';
import { Logger } from '@getvim-os/logger';
import { EhrAPI, OSToAppEhrConvertor, OsCommunicator } from './';
import { InternalResourceUpdater } from './ehr-updates/internalResourceUpdater';
import { HubApi } from './hubApi';
import { SessionContextApi } from './sessionContextApi';

type SDK_PROPS<EHR_STATE extends EHR.EHR_STATE_TEMPLATE> = {
  payload: HandshakePayload;
  osMessageChannel: MessageChannel;
  oSToAppEhrConvertor: OSToAppEhrConvertor<EHR_STATE>;
  osCommunicator?: OsCommunicator;
  logger?: Logger;
  tokensResponse?: { idToken: string };
};
export abstract class CommonVimSDK<EHR_STATE extends EHR.EHR_STATE_TEMPLATE>
  implements GENERIC_SDK<EHR_STATE>
{
  constructor({
    payload,
    osMessageChannel,
    oSToAppEhrConvertor,
    logger,
    tokensResponse,
    ...otherProps
  }: SDK_PROPS<EHR_STATE>) {
    const osCommunicator =
      otherProps.osCommunicator || new OsCommunicator(payload, osMessageChannel, logger);
    const incomingOsMessagePort = osMessageChannel.port1;
    this.ehr = new EhrAPI<EHR_STATE>(
      osCommunicator,
      incomingOsMessagePort,
      oSToAppEhrConvertor,
      new InternalResourceUpdater(incomingOsMessagePort, osCommunicator, logger),
    );
    this.sessionContext = new SessionContextApi({ handshakePayload: payload, tokensResponse });
    this.utils = {
      copyToClipboard: async (text: string): Promise<void> => {
        await osCommunicator.sendAwaitedMessage({
          type: AppToOSMessageTypes.COPY_TO_CLIPBOARD,
          payload: text,
        });
      },
    };
    this.hub = new HubApi(incomingOsMessagePort, osCommunicator);
  }

  public sessionContext: GENERIC_SDK<EHR_STATE>['sessionContext'];

  public ehr: GENERIC_SDK<EHR_STATE>['ehr'];

  public hub: GENERIC_SDK<EHR_STATE>['hub'];
  public utils: GENERIC_SDK<EHR_STATE>['utils'];
}
