import React from 'react';
import { AppStateProvider } from './app-state/AppState.store';
import { EhrStateProvider } from './ehr-state/EhrState.store';

export const StatesProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <EhrStateProvider>
      <AppStateProvider>{children}</AppStateProvider>
    </EhrStateProvider>
  );
};
