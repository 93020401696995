import {
  Geo,
  InputStyle,
  InputStyleWrapper,
  InputValidationMessage,
  LocationSelect,
} from '@getvim/atomic-ui';
import React, { useCallback, useMemo } from 'react';
import { orderAssistLogger } from '../../logger';
import { Address, InputWrapperProps } from '../../types';
import addressFormat, { formatGeoToAddress } from '../../utils/addressFormatter';
import './AddressInputWrapper.less';

interface AddressInputWrapperProps extends InputWrapperProps<'address'> {
  isGoogleApiLoaded: boolean;
  value: Address | undefined;
  validationError?: string;
  isDirty?: boolean;
}

const formatToGeo = (address?: Address): Geo | undefined => {
  if (!address) return undefined;
  return {
    address: addressFormat(address),
    zip: address.zipCode,
  };
};

const AddressInputWrapper: React.FC<AddressInputWrapperProps> = ({
  onChange,
  isGoogleApiLoaded,
  value,
  validationError,
  isDirty,
}: AddressInputWrapperProps) => {
  const setInputChange = (geoInput: Geo | null) => {
    onChange({ address: formatGeoToAddress(geoInput) });
  };

  const shouldFetchGeo = useCallback(() => {
    const shouldFetch = value && !value?.zipCode && value.address1 && value.city;
    if (shouldFetch) {
      orderAssistLogger.info('Fetching GEO from GoogleAPI', { ehrAddress: value });
    }
    return shouldFetch;
  }, [value]);

  const geo: Geo | undefined = useMemo(() => formatToGeo(value), [value]);

  const getLocationProps = useCallback(
    () =>
      shouldFetchGeo()
        ? {
            geo,
          }
        : { defaultAddressInput: geo?.address },
    [geo, shouldFetchGeo],
  );

  return (
    <InputStyleWrapper
      className="location-input-wrapper input-wrapper "
      leftIcon={<i className="icon-search-2" />}
      rightIcon={<></>}
      inputStyle={InputStyle.pillMedium}
      hasError={isDirty && !!validationError}
    >
      <div className="input">
        <LocationSelect
          label="Location"
          placeholder=" "
          showChevronIcon
          isGoogleApiLoaded={isGoogleApiLoaded}
          onChange={(changedGeo) => setInputChange(changedGeo)}
          {...getLocationProps()}
        />
      </div>
      {isDirty && !!validationError ? (
        <InputValidationMessage>{validationError}</InputValidationMessage>
      ) : null}
    </InputStyleWrapper>
  );
};

export default AddressInputWrapper;
