import { VimSpecialtyMap, VimSpecialtyTuple } from '../../../components/app/types';

export const convertVimSpecialtyMap = (
  vimSpecialtyTuples?: VimSpecialtyTuple[],
): VimSpecialtyMap | undefined => {
  if (!vimSpecialtyTuples) return;

  const vimSpecialtyMap = {};
  vimSpecialtyTuples.forEach(({ specialtyName, vimSpecialty }) => {
    vimSpecialtyMap[specialtyName] = vimSpecialty;
  });

  return vimSpecialtyMap;
};
