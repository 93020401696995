import React, { useMemo, useState, FunctionComponent, Fragment, useCallback } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import Button, { ButtonProps } from '../../atoms/button';
import Text from '../../atoms/text';
import InputStyleWrapper, { InputStyle } from '../../atoms/inputStyleWrapper';
import { AlignEnum, ColorNameEnum, SizeEnum, WeightEnum } from '../../types';
import AutosizeTextarea from '../../atoms/autosizeTextarea';
import Tooltip from '../../atoms/tooltip';

import './index.less';

const TooltipPropsTypes = {
  content: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
};

const ItemPropTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  selectClassName: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  checkbox: PropTypes.bool,
  disabled: PropTypes.bool,
  textInputValue: PropTypes.string,
  tooltip: PropTypes.shape(TooltipPropsTypes),
};

export const ButtonGroupPropTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropTypes).isRequired).isRequired,
  selectPlaceholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column', 'default']),
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  searchStopPropagation: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  showTextInput: PropTypes.bool,
  textInputPlaceholder: PropTypes.string,
  onTextInputChange: PropTypes.func,
  shouldColorSelectedItems: PropTypes.bool,
  pinnedItemsTitle: PropTypes.string,
  topPinnedItems: PropTypes.arrayOf(PropTypes.shape(ItemPropTypes).isRequired),
  noResultsMessage: PropTypes.string,
  noGaps: PropTypes.bool,
  shouldUseAutoSizeInput: PropTypes.bool,
};

export type ButtonGroupProps = InferProps<typeof ButtonGroupPropTypes> &
  Pick<ButtonProps, 'styleOverrides'> &
  Omit<ButtonProps, 'onChange'>;

const GroupItem = ({
  item,
  showCheckbox,
  value,
  itemClassName,
  shouldColorSelectedItems = true,
  clickHandler,
  showTextInput,
  textInputPlaceholder,
  onTextInputChange,
  maxLength,
  shouldUseAutoSizeInput = false,
  rest,
}) => {
  const selected = showCheckbox ? value.includes(item.value) : isEqual(value, item.value);
  const textLength = item.textInputValue?.length ?? 0;

  return (
    <Fragment key={item.value}>
      <Button
        role="radio"
        className={classNames(
          item.value,
          itemClassName,
          { showCheckbox },
          { buttonGroupSelected: selected },
          { 'selected-no-bg': !shouldColorSelectedItems },
          { 'show-tooltip': item.tooltip },
        )}
        onClick={(e) =>
          clickHandler(e, item.value, item.onClick, item.onClickCallback, item.onSelect)
        }
        buttonType="link"
        value={item.value}
        selected={selected}
        disabled={!!item.disabled}
        {...rest}
      >
        {item.tooltip && (
          <Tooltip
            tooltipContent={item.tooltip.content}
            referenceClassName="button-group-item-tooltip-ref"
            className="button-group-item-tooltip"
            bgColor="light"
            placement={item.tooltip.placement}
          />
        )}
        {item.title ? <span className="item-title">{item.title}</span> : undefined}
        {item.checkbox !== undefined && (
          <input
            type="checkbox"
            checked={!!item.checkbox}
            onClick={(e) => clickHandler(e, item.value, item.onClick, item.onClickCallback)}
          />
        )}
        {showCheckbox && <div className="inline-checkbox" />}
        {item.icon && <i className={`item-icon ${item.icon}`} />}
        <span>{item.text}</span>
        {item.secondaryDescription}
        {item.comment && <span className="comment"> / {item.comment}</span>}
      </Button>
      {showTextInput && (
        <div className="btn-group-inline-input">
          {shouldUseAutoSizeInput ? (
            <>
              <InputStyleWrapper className="autosize-textarea-input" inputStyle={InputStyle.clean}>
                <div className="input">
                  <AutosizeTextarea
                    placeholder={textInputPlaceholder}
                    onTextInputChange={(textInputValue) => {
                      onTextInputChange(textInputValue);
                    }}
                    maxLength={maxLength}
                  />
                </div>
                {maxLength && (
                  <div className="character-counter">
                    <text className={textLength < maxLength ? 'length' : 'length error'}>
                      {textLength}/{maxLength}
                    </text>
                  </div>
                )}
              </InputStyleWrapper>
            </>
          ) : (
            <>
              <InputStyleWrapper inputStyle={InputStyle.clean} key={item.value}>
                <input
                  type="text"
                  placeholder={textInputPlaceholder ?? ''}
                  value={item.textInputValue ?? ''}
                  maxLength={maxLength}
                  onChange={(e) => onTextInputChange?.(e.target.value)}
                />
                {maxLength && (
                  <text className={textLength < maxLength ? 'length' : 'length error'}>
                    {textLength}/{maxLength}
                  </text>
                )}
              </InputStyleWrapper>
            </>
          )}
        </div>
      )}
    </Fragment>
  );
};

const Index: FunctionComponent<ButtonGroupProps> = ({
  items,
  value,
  className,
  itemClassName,
  direction,
  selectPlaceholder,
  onChange,
  searchable,
  searchPlaceholder,
  searchStopPropagation,
  showCheckbox,
  showTextInput,
  textInputPlaceholder,
  onTextInputChange,
  shouldColorSelectedItems = true,
  pinnedItemsTitle,
  topPinnedItems,
  maxLength,
  noGaps,
  noResultsMessage,
  shouldUseAutoSizeInput,
  onSelect,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filterItems = useCallback(
    (itemsToFilter) => {
      if (!itemsToFilter) return [];

      const normalizedSearchTerm = searchTerm.trim().toLowerCase();

      if (!searchable || searchTerm === '') return itemsToFilter;

      return itemsToFilter.filter(({ text }) => text.toLowerCase().includes(normalizedSearchTerm));
    },
    [searchable, searchTerm],
  );

  const filteredItems = useMemo(() => filterItems(items), [items, filterItems]);

  const filteredTopItems = useMemo(
    () => filterItems(topPinnedItems),
    [topPinnedItems, filterItems],
  );

  const clickHandler = async (
    e: React.MouseEvent,
    option: number | string,
    onClick?: (...args: any) => any,
    onClickCallback?: {
      onStart?: () => void;
      onSuccess?: () => void;
      onError?: () => void;
      onFinish?: (...args: any) => void;
    },
    onSelect?: () => void,
  ) => {
    if (onClick) {
      let result;
      try {
        onClickCallback?.onStart?.();
        result = await onClick(option);
        onClickCallback?.onSuccess?.();
      } catch (error) {
        onClickCallback?.onError?.();
      } finally {
        onClickCallback?.onFinish?.(result);
      }
    }
    e.preventDefault();
    if (onChange) await onChange(option);
    if (onSelect) onSelect();
  };

  const onTextInputClick = (event: React.MouseEvent) => {
    if (searchStopPropagation) {
      event.stopPropagation();
    }
  };

  const onRightIconClick = (event: React.MouseEvent) => {
    if (searchStopPropagation) {
      event.stopPropagation();
    }
    setSearchTerm('');
  };

  return (
    <div>
      {searchable && (
        <InputStyleWrapper
          inputStyle={InputStyle.pillSm}
          leftIcon={<i className="icon-search-2" />}
          rightIcon={searchTerm ? <i className="icon-x clear-icon" /> : undefined}
          rightIconOnClick={onRightIconClick}
          className="clickable"
        >
          <div className="input">
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              placeholder={searchPlaceholder || ''}
              type="text"
              onClick={onTextInputClick}
            />
          </div>
        </InputStyleWrapper>
      )}
      <div
        data-id="button-group"
        className={classNames('icon-list', className, {
          'column-list': direction === 'column',
          'no-gaps': noGaps,
        })}
        role="radiogroup"
      >
        {filteredTopItems?.length > 0 && (
          <>
            {pinnedItemsTitle && (
              <Text
                text={pinnedItemsTitle}
                size={SizeEnum['12px']}
                colorName={ColorNameEnum.extraDark}
                weight={WeightEnum.bold}
                align={AlignEnum.left}
                className={classNames('pinned-items-title')}
              />
            )}
            {filteredTopItems.map((item) => (
              <GroupItem
                key={`filtered-top-item-${item.value}`}
                item={item}
                showCheckbox={showCheckbox}
                value={value}
                itemClassName={classNames(itemClassName, 'top-pinned-items')}
                clickHandler={clickHandler}
                showTextInput={showTextInput}
                textInputPlaceholder={textInputPlaceholder}
                onTextInputChange={onTextInputChange}
                maxLength={maxLength}
                shouldUseAutoSizeInput={shouldUseAutoSizeInput}
                rest={rest}
              />
            ))}
          </>
        )}
        {filteredItems?.length > 0
          ? filteredItems.map((item) => (
              <GroupItem
                key={`filtered-item-${item.text}-${item.value}`}
                item={item}
                showCheckbox={showCheckbox}
                value={value}
                itemClassName={itemClassName}
                shouldColorSelectedItems={shouldColorSelectedItems}
                clickHandler={clickHandler}
                showTextInput={showTextInput}
                textInputPlaceholder={textInputPlaceholder}
                onTextInputChange={onTextInputChange}
                shouldUseAutoSizeInput={shouldUseAutoSizeInput}
                rest={rest}
                maxLength={maxLength}
              />
            ))
          : noResultsMessage && <div className="button-group-no-results">{noResultsMessage}</div>}
      </div>
    </div>
  );
};

Index.propTypes = ButtonGroupPropTypes;

Index.defaultProps = {
  className: undefined,
  direction: 'default',
  selectPlaceholder: '',
  onChange: () => {},
  searchPlaceholder: undefined,
  textInputPlaceholder: '',
  onTextInputChange: () => {},
  noGaps: false,
  noResultsMessage: 'No results',
};

export default Index;
