import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { ActivationStatus } from '@getvim/internal-vim-os-sdk/types';
import { Entities } from '@getvim/vim-connect';
import { ModifyApplication } from '@getvim/vim-connect-communication';
import { useCallback } from 'react';
import { orderAssistLogger } from '../components/app/logger';
import { isVimOsAppMode } from '../vim-os-sdk-migration';

type ModifyAppViewStatePayload = {
  enable: boolean;
  reason?: Entities.UIElements.ModifyReason;
};

const useRuntimeModifyApp = (): [(payload: ModifyAppViewStatePayload) => void] => {
  const modify = useCallback((payload: ModifyAppViewStatePayload): void => {
    const { enable, reason } = payload;

    orderAssistLogger.debug('Trigger modify app view event', payload);
    ModifyApplication.modify({
      disabled: !enable,
      loaded: true,
      reason,
    });
  }, []);

  return [modify];
};

const useSdkModifyApp = () => {
  const { setActivationStatus } = useApi();

  const modify = useCallback(
    (payload: ModifyAppViewStatePayload): void => {
      orderAssistLogger.debug('Trigger modify app view event', payload);
      const { enable } = payload;
      const status = enable ? ActivationStatus.ENABLED : ActivationStatus.DISABLED;
      setActivationStatus(status);
    },
    [setActivationStatus],
  );

  return [modify];
};

export const useModifyAppViewState = isVimOsAppMode ? useSdkModifyApp : useRuntimeModifyApp;
