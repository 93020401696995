import { useEffect } from 'react';
import { useOrderAssistLegacyState } from '../../../../../../vim-os-sdk-migration/ehr-state';
import { OrderAssistEventHandlers } from './types';

export const useVimOsSdkEventHandlers = ({
  onPatientInContextEvent,
  onPatientOutOfContextEvent,
  onOrderViewedEvent,
  onOrderClosedEvent,
  onReferralViewedEvent,
  onReferralClosedEvent,
}: OrderAssistEventHandlers) => {
  const { patient, referral, orders } = useOrderAssistLegacyState();

  useEffect(() => {
    if (patient) {
      onPatientInContextEvent(patient);
    } else {
      onPatientOutOfContextEvent();
    }
  }, [patient, onPatientInContextEvent, onPatientOutOfContextEvent]);

  useEffect(() => {
    if (referral && referral.patient) {
      onReferralViewedEvent(referral);
    } else if (!referral) {
      onReferralClosedEvent();
    }
  }, [referral, onReferralViewedEvent, onReferralClosedEvent]);

  useEffect(() => {
    if (!orders) {
      onOrderClosedEvent();
      return;
    }

    const { selectedOrders, patient } = orders;
    if (selectedOrders?.length === 1 && patient) {
      onOrderViewedEvent({
        order: selectedOrders[0],
        patient,
      });
    }
  }, [orders, onOrderViewedEvent, onOrderClosedEvent]);
};
