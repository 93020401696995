import React from 'react';
import './Pagination.less';

interface PaginationProps {
  currentPage: number;
  goPrevious: () => void;
  goNext: () => void;
  goNextDisable: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  goPrevious,
  goNext,
  goNextDisable,
}) => {
  return (
    <div className="pagination">
      <button
        className={currentPage !== 1 ? 'clickable' : ''}
        disabled={currentPage === 1}
        onClick={goPrevious}
        type="button"
      >
        <i className="icon-chevron-left" />
      </button>
      <div style={{ padding: '0 20px' }}>{`Page ${currentPage}`}</div>
      <button
        className={!goNextDisable ? 'clickable' : ''}
        disabled={goNextDisable}
        onClick={goNext}
        type="button"
      >
        <i className="icon-chevron-right" />
      </button>
    </div>
  );
};
