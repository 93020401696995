import { FindResult } from './find';
import { OrderAssistOption } from './results';

export interface GetPreferredProvidersResponse {
  results: FindResult[];
  organizationHasPreferredProviders: boolean;
}

export interface PreferredProvidersResult {
  results: OrderAssistOption[];
  organizationHasPreferredProviders: boolean;
}

export enum PreferredProvidersState {
  NOT_CONFIGURED,
  NOT_FOUND,
  MATCHING_PROVIDERS,
}
