import hash from 'object-hash';
import { Patient, SearchInput } from '../components/app/types';

export type SearchSessionPayload = Omit<DatedSearchSessionPayload, 'date' | 'type'>;

interface DatedSearchSessionPayload {
  vimReferralId?: string;
  vimSpecialtyId: SearchInput['vimSpecialtyId'];
  cpts?: string[];
  userExternalId?: string;
  patientId: Patient['vimPatientId'];
  date: string;
  type: SearchIdType;
}

export enum SearchIdType {
  SESSION_ID = 'SESSION_ID',
  SEARCH_ID = 'SEARCH_ID',
}

interface SearchIds {
  searchId: string | undefined;
  searchSessionId: string | undefined;
}

class SearchIdsManager {
  private sessionId: string | undefined;

  private searchId: string | undefined;

  public getId(type: SearchIdType): string | undefined {
    return type === SearchIdType.SESSION_ID ? this.sessionId : this.searchId;
  }

  private handleUpdateId(payload: DatedSearchSessionPayload): void {
    const id = hash.MD5(payload);
    if (payload.type === SearchIdType.SESSION_ID) {
      this.sessionId = id;
    } else {
      this.searchId = id;
    }
  }

  public handleShouldUpdate(payload: Omit<DatedSearchSessionPayload, 'date' | 'type'>): SearchIds {
    this.handleUpdateId({
      ...payload,
      date: new Date().toLocaleDateString(),
      type: SearchIdType.SESSION_ID,
    });
    this.handleUpdateId({
      ...payload,
      date: new Date().toISOString(),
      type: SearchIdType.SEARCH_ID,
    });
    return { searchId: this.searchId, searchSessionId: this.sessionId };
  }
}

export const searchIdsManager = new SearchIdsManager();
