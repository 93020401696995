import { useCallback } from 'react';
import { Notifications } from '@getvim/vim-connect-communication';
import { widgetId } from '../../../consts';
import { NotificationConfigMap, PlatformNotification } from './types';

export const useRuntimeNotifications = (): PlatformNotification => {
  const triggerNotification = useCallback((notificationConfigMap: NotificationConfigMap) => {
    Notifications.Actions.showNotification(notificationConfigMap.runtime);
  }, []);

  const triggerHideNotification = useCallback((notificationId: string) => {
    Notifications.Actions.hideNotification({
      widgetId,
      notificationId,
    });
  }, []);

  return { triggerNotification, triggerHideNotification };
};
