import { StandardEvents } from '@getvim/vim-connect';
import { Provider } from '../components/app/types';
import { analyticsManager } from './analyticsManager';
import { parseVimProviderToAnalyticsProvider } from './provider';

const trackEhrTargetProviderSelected = (
  previousProvider: Provider | undefined,
  selectedProvider: Provider | undefined,
): void => {
  analyticsManager.track('target_selected_native', {
    previous_provider: parseVimProviderToAnalyticsProvider(previousProvider),
    selected_provider: parseVimProviderToAnalyticsProvider(selectedProvider),
  });
};

export const handleReferralTargetProviderAnalytic = (
  currentReferral?: StandardEvents.TransformedReferralViewedPayload,
  newReferral?: StandardEvents.TransformedReferralViewedPayload,
): void => {
  const didReferralNotSwapped = currentReferral?.vimReferralId === newReferral?.vimReferralId;
  const didTargetProviderChange =
    currentReferral?.targetProvider?.npi !== newReferral?.targetProvider?.npi ||
    currentReferral?.targetProvider?.demographics?.lastName !==
      newReferral?.targetProvider?.demographics?.lastName ||
    currentReferral?.targetProvider?.demographics?.firstName !==
      newReferral?.targetProvider?.demographics?.firstName;

  if (newReferral?.targetProvider && didReferralNotSwapped && didTargetProviderChange) {
    trackEhrTargetProviderSelected(currentReferral?.targetProvider, newReferral?.targetProvider);
  }
};
