import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { sendSyncEventToVimConnectHubApp } from '@getvim/utils-vim-connect-communication';
import { isVimOsAppMode } from '../vim-os-sdk-migration';
import { widgetId } from '../consts';
import { WidgetOutgoingEventName } from '../components/app/types';
import { useCallback } from 'react';

const useRuntimeOpenApp = () => {
  return useCallback((data?: any) => {
    sendSyncEventToVimConnectHubApp(widgetId, WidgetOutgoingEventName.OpenOrderAssist, data);
  }, []);
};

const useSdkAutoPopup = () => {
  const { autoPopup } = useApi();
  return autoPopup;
};

export const useAutoPopup = isVimOsAppMode ? useSdkAutoPopup : useRuntimeOpenApp;
