import { v4 as uuidv4 } from 'uuid';
import { ScopedLogger, logger } from '@getvim/vim-connect-logger';
import {
  AnalyticsContext,
  ReferralRequestAnalyticsEvents,
  ReferralRequestEventNames,
} from '../types/referralRequestAnalyticsEvents.types';
import {
  ReferralStep,
  ReferralSteps,
} from '../../components/app/components/referral-request/types';
import { WriteBackProvider, WidgetSource } from '../../components/app/types';
import { writebackProviderToAnalyticsProvider } from '../referral-request/formatters';
import { vimPatientIdsManager } from '../../utils/vimPatientIdsManager';
import { OrderAssistAnalyticsClient } from './types';
import { isVimOsAppMode } from '../../vim-os-sdk-migration';

export class ReferralRequestAnalyticsManager {
  private context?: AnalyticsContext;
  private logger: ScopedLogger;
  private static analyticsClient: OrderAssistAnalyticsClient;

  constructor(analyticsClientName: string) {
    this.logger = logger.scope(`${analyticsClientName}] - Analytics Client`);
  }

  public track(
    event: ReferralRequestEventNames,
    eventProperties: ReferralRequestAnalyticsEvents[ReferralRequestEventNames],
  ) {
    ReferralRequestAnalyticsManager.analyticsClient.trackAnalytics({
      event,
      properties: {
        ...eventProperties,
        referralRequestContext: this.context,
        vim_patient_id: vimPatientIdsManager.getVimPatientId(),
        is_vim_os: isVimOsAppMode,
      },
    });
  }

  public static setAnalyticsClient(client: OrderAssistAnalyticsClient) {
    ReferralRequestAnalyticsManager.analyticsClient = client;
  }

  public setCurrentScreen(currentScreen: keyof ReferralSteps) {
    if (this.context) {
      this.context.current_screen = currentScreen;
    }
  }

  updateContext(context: Partial<AnalyticsContext>) {
    this.context = { ...this.context, ...context };
  }

  public setDatasource(datasource?: WidgetSource) {
    if (this.context) {
      this.context.datasource = datasource;
    }
  }

  public initiateSession(selectedProvider: WriteBackProvider): string {
    const referralRequestSessionId = uuidv4();
    this.context = {
      ...this.context,
      referral_request_session_id: referralRequestSessionId,
      selected_provider: writebackProviderToAnalyticsProvider(selectedProvider),
      current_screen: ReferralStep.REFERRAL_REQUIRED,
    };
    this.logger.debug('initiated new session', { referralRequestSessionId });
    return referralRequestSessionId;
  }
}

export const referralRequestAnalyticsClient = new ReferralRequestAnalyticsManager(
  'Referral Request',
);
