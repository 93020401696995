import { OrderAssistFormData, Patient } from '../../types';

interface FormatSearchDetailsOptions {
  searchData: OrderAssistFormData;
  patient?: Patient;
}

export const formatSearchDetails = (options: FormatSearchDetailsOptions): string => {
  const { searchData, patient } = options;
  const specialty = searchData.specialty?.searchDisplay;
  const zipCode = searchData.address?.zipCode || patient?.demographics?.address?.zipCode;
  const { cpt } = searchData;
  const { icd } = searchData;
  const displayData = [
    ...(specialty ? [specialty] : []),
    ...(zipCode ? [`near ZIP ${zipCode}`] : []),
    ...(cpt?.length ? [`CPT: ${cpt.map((cptItem) => cptItem.value).join(' & ')}`] : []),
    ...(icd?.length ? [`ICD: ${icd.map((icdItem) => icdItem.value).join(' & ')}`] : []),
  ];
  return displayData.join(', ');
};
