import { CPT } from '../patient-data';

export interface ICD {
  name?: string;
  code?: string;
}

export enum OrderType {
  LAB = 'LAB',
  DI = 'DI',
  PROCEDURE = 'PROCEDURE',
}

export interface Order {
  id?: string;
  type?: OrderType;
  icd: ICD[];
  cpts: CPT[];
  encounterId?: string;
  createdDate?: string; // YYYY-MM-DD
}
