import { Referral } from '@getvim/react-app-infra';
import type { EhrStateWithPII } from '@getvim/internal-vim-os-sdk/types';
import {
  convertDiagnoses,
  convertProvider,
  convertSpecialty,
  convertProcedureCodes,
} from './common';

export const convertReferralToRuntime = (
  ehrReferral: EhrStateWithPII['referral'],
): Referral | undefined => {
  if (!ehrReferral) {
    return;
  }

  const {
    basicInformation,
    identifiers,
    targetProvider,
    referringProvider,
    procedureCodes,
    conditions,
  } = ehrReferral;

  return {
    referralId: identifiers?.ehrReferralId,
    vimReferralId: identifiers?.vimReferralId,
    specialty: convertSpecialty(basicInformation?.specialty),
    diagnosis: convertDiagnoses(conditions?.diagnosis),
    cpts: convertProcedureCodes(procedureCodes?.cpts),
    targetProvider: convertProvider(targetProvider),
    referringProvider: convertProvider(referringProvider),
    status: basicInformation?.status as Referral['status'],
    priority: basicInformation?.priority as Referral['priority'],
    startDate: basicInformation?.startDate,
    endDate: basicInformation?.endDate,
    createdDate: basicInformation?.createdDate,
    isLocked: basicInformation?.isLocked,
    numberOfVisits: basicInformation?.numberOfVisits,
    authCode: basicInformation?.authCode,
    reasons: basicInformation?.reasons,
    notes: basicInformation?.notes,
    facilityName: basicInformation?.facilityName,
  };
};
