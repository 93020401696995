import { ModuleNames } from '@getvim/vim-app-infra';
import { Entities } from '@getvim/vim-connect';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { VimCommunicationOAProvider } from '../VimCommunicationOAProvider';
import { SelectButtonTypes, SelectButtonWrapper } from './SelectButtonWrapper';
import { OrderSourceConfigWrapper } from './orders';
import { ReferralSelectProviderInlineWidget } from './referral';

const SelectButtonContainer: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/referral`}>
        <VimCommunicationOAProvider widgetId={ModuleNames.OrderAssistReferralsSelectButtonWidget}>
          <SelectButtonWrapper selectType={SelectButtonTypes.Referral}>
            <ReferralSelectProviderInlineWidget />
          </SelectButtonWrapper>
        </VimCommunicationOAProvider>
      </Route>
      <Route exact path={`${path}/lab-orders`}>
        <VimCommunicationOAProvider widgetId={ModuleNames.OrderAssistLabOrdersSelectButtonWidget}>
          <SelectButtonWrapper
            selectType={SelectButtonTypes.Orders}
            ordersType={Entities.OrderType.LAB}
          >
            <OrderSourceConfigWrapper />
          </SelectButtonWrapper>
        </VimCommunicationOAProvider>
      </Route>
      <Route exact path={`${path}/di-orders`}>
        <VimCommunicationOAProvider widgetId={ModuleNames.OrderAssistDIOrdersSelectButtonWidget}>
          <SelectButtonWrapper
            selectType={SelectButtonTypes.Orders}
            ordersType={Entities.OrderType.DI}
          >
            <OrderSourceConfigWrapper />
          </SelectButtonWrapper>
        </VimCommunicationOAProvider>
      </Route>
    </Switch>
  );
};

export default SelectButtonContainer;
