import { ProviderAnalyticsProperties } from './types';
import { OrderAssistProviderOption, Provider } from '../components/app/types';

export const parseVimProviderToAnalyticsProvider = (
  provider?: Provider,
): ProviderAnalyticsProperties | undefined => {
  if (!provider) {
    return undefined;
  }

  const { npi, providerEhrId, demographics, organization } = provider;
  const { firstName, lastName } = demographics ?? {};
  return {
    npi,
    ehr_id: providerEhrId,
    firstName,
    lastName,
    address: organization?.address?.fullAddress,
  };
};

export const parseOptionProviderToAnalyticsProvider = (
  selectedOption: OrderAssistProviderOption,
  selectedAddress?: string,
  acceptNewPatients?: boolean,
): ProviderAnalyticsProperties | undefined => {
  const { details, badges } = selectedOption;
  if (!details) {
    return undefined;
  }

  const { npi, complexName } = details;
  const { firstName, lastName } = complexName ?? {};
  return {
    npi,
    firstName,
    lastName,
    address: selectedAddress,
    acceptNewPatients,
    performance: badges,
  };
};
