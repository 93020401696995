import { WriteBacks } from '@getvim/vim-connect';
import { useCallback } from 'react';
import { orderAssistLogger } from '../../../../logger';
import { isVimOsAppMode } from '../../../../../../vim-os-sdk-migration';
import { useRuntimeWriteback } from './useRuntimeReferralWriteback';
import { useSdkWriteback } from './useSdkReferralWriteback';
import type { UseWriteBack, WritebackReferralInput } from './types';

const writebackReferralHook = isVimOsAppMode ? useSdkWriteback : useRuntimeWriteback;

export const useWriteBack = (): UseWriteBack => {
  const writebackReferral = writebackReferralHook();

  const writeProvider: UseWriteBack['writeProvider'] = useCallback(
    async (targetProvider) => {
      try {
        const formattedProvider = targetProvider
          ? `Vim Select ${WriteBacks.formatProvider(targetProvider, true)}`
          : undefined;

        const data: WritebackReferralInput = {
          targetProvider,
          notes: [formattedProvider].filter(Boolean).join('\n'),
        };

        await writebackReferral(data);

        return { success: true };
      } catch (error: any) {
        orderAssistLogger.error('Failed to writeback referral target provider', {
          targetProvider,
          error,
        });

        const errorResult = error?.errorName || error?.message;
        return { success: false, error: errorResult };
      }
    },
    [writebackReferral],
  );

  const writeAuthCode: UseWriteBack['writeAuthCode'] = useCallback(
    async (authCode) => {
      try {
        const data: WritebackReferralInput = {
          authCode,
        };

        await writebackReferral(data, { clearEmptyFields: false });
        return { success: true };
      } catch (error: any) {
        orderAssistLogger.error('Failed to writeback referral authCode', {
          authCode,
          error,
        });
        return { success: false, error: error.message };
      }
    },
    [writebackReferral],
  );

  return { writeProvider, writeAuthCode };
};
