import React, { FC, useCallback, useState, ReactElement } from 'react';
import { AlignEnum, ClickableDiv, ColorNameEnum, SizeEnum, Text } from '@getvim/atomic-ui';
import classNames from 'classnames';
import './ProcedureStatus.less';

type ProcedureStatusCardProps = {
  procedureCode: string;
  className?: string;
  subHeader?: ReactElement;
  onToggle?(expanded: boolean): void;
  collapsible?: boolean;
};

const ProcedureStatusCard: FC<ProcedureStatusCardProps> = ({
  children,
  procedureCode,
  className,
  subHeader,
  onToggle,
  collapsible = true,
}) => {
  const [expanded, setExpanded] = useState(false);

  const onClick = useCallback(
    (event) => {
      const newExpanded = !expanded;
      onToggle?.(newExpanded);
      setExpanded(newExpanded);
      event.stopPropagation();
    },
    [expanded, onToggle],
  );

  return (
    <ClickableDiv className={classNames('procedure-status-card', className, { collapsible })}>
      {procedureCode && (
        <ClickableDiv
          className="procedure-status-card-title"
          onClick={collapsible ? onClick : undefined}
        >
          <Text
            className="procedure-status-card-title-text"
            size={SizeEnum['12px']}
            align={AlignEnum.left}
            colorName={ColorNameEnum.theme}
          >
            Procedure code <b>{procedureCode}</b>
          </Text>
        </ClickableDiv>
      )}
      {subHeader && <div className="subheader">{subHeader}</div>}
      <div
        className={!collapsible || expanded ? 'card-content-visible' : 'card-content-hidden'}
        style={{ fontSize: '12px' }}
      >
        <ClickableDiv
          className="icon-chevron-2 card-arrow"
          onClick={collapsible ? onClick : undefined}
        />
        {children}
      </div>
    </ClickableDiv>
  );
};

export default ProcedureStatusCard;
