import { useApi, useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { UpdatableEhrFields } from '@getvim/internal-vim-os-sdk/types';
import { useMemo } from 'react';
import { Common } from '@getvim/vim-connect';
import { UserConfig } from '../types';

export const useSdkAdapterConfig = (): UserConfig['adapterConfig'] => {
  const { manifestSupport } = useVimUserMetadata();
  const {
    resourceUpdate: { updatableFields },
  } = useApi();

  return useMemo(() => {
    return {
      vimConnectCapabilities: {
        supportsUpdateReferral: !!manifestSupport?.EHR.resourceUpdates?.referral,
      },
      unsupportedWritebackFields: {
        updateReferral: calculateUnsupportedWritebackFields(updatableFields.referral),
      },
    };
  }, [manifestSupport?.EHR.resourceUpdates?.referral, updatableFields.referral]);
};

const calculateUnsupportedWritebackFields = (
  referralUpdatableFields?: UpdatableEhrFields['referral'],
): Common.AdapterConfig['unsupportedWritebackFields']['updateReferral'] => {
  const isAuthCodeSupportedForWriteback = !!referralUpdatableFields?.authCode;

  if (isAuthCodeSupportedForWriteback) {
    return [];
  }

  return ['authCode'];
};
