import { Standard } from '@getvim/vim-connect';

export type EhrState = {
  order?: Standard.Entities.Order;
  referral?: Standard.Events.TransformedReferralViewedPayload;
};

export enum EhrStateActionType {
  SET_ORDER = 'SET_ORDER',
  CLEAR_ORDER = 'CLEAR_ORDER',
  SET_REFERRAL = 'SET_REFERRAL',
  CLEAR_REFERRAL = 'CLEAR_REFERRAL',
  RESET_STATE = 'RESET_STATE',
}

export type EhrStateAction =
  | { type: EhrStateActionType.SET_ORDER; payload: Standard.Entities.Order }
  | { type: EhrStateActionType.CLEAR_ORDER }
  | { type: EhrStateActionType.SET_REFERRAL; payload: Standard.Entities.Referral }
  | { type: EhrStateActionType.CLEAR_REFERRAL }
  | { type: EhrStateActionType.RESET_STATE };
