import { cloneDeep, isEmpty, isEqual, isNil, omit, omitBy, set } from 'lodash-es';
import { Dispatch, Reducer } from 'react';
import {
  SetAddressChangePayload,
  SearchState,
  FreeTextResult,
  FreeTextType,
  GetOrderAssistResult,
  NuccFreeTextResult,
  OrderAssistFormData,
  SearchFilters,
  QuickSpecialtySearchParams,
} from '../../../types';

import { isSpecialtyRequiresPlaceOfService } from '../../../utils/search';
import { logReducerAction } from './utils/logReducerAction';

const freeTextTypeToSearchStateField = {
  [FreeTextType.Icd]: 'icdQuery',
  [FreeTextType.Cpt]: 'cptQuery',
  [FreeTextType.Nucc]: 'specialtyQuery',
};

export enum SearchActionType {
  RESET_STATE = 'RESET_STATE',
  UPDATE_FORM_DATA = 'UPDATE_FORM_DATA',
  RESET_FORM_CONDITIONAL_PARAMS = 'RESET_FORM_CONDITIONAL_PARAMS',
  RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS',
  RESET_SELECT_OPTIONS = 'RESET_SELECT_OPTIONS',
  ON_SEARCH_FILTERS_SAVE = 'ON_SEARCH_FILTERS_SAVE',
  ON_QUICK_SPECIALTY_CHANGE = 'ON_QUICK_SPECIALTY_CHANGE',
  ON_FREE_TEXT_START = 'ON_FREE_TEXT_START',
  ON_FREE_TEXT_FINISH = 'ON_FREE_TEXT_FINISH',
  ON_SEARCH_START = 'ON_SEARCH_START',
  ON_SEARCH_FINISHED = 'ON_SEARCH_FINISHED',
  SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS',
  SET_SELECTED_PROVIDER = 'SET_SELECTED_PROVIDER',
  RESET_FILTERS = 'RESET_FILTERS',
}

export type SearchAction =
  | {
      type: SearchActionType.UPDATE_FORM_DATA;
      payload: Partial<OrderAssistFormData>;
    }
  | { type: SearchActionType.RESET_FORM_CONDITIONAL_PARAMS }
  | { type: SearchActionType.RESET_SEARCH_RESULTS }
  | { type: SearchActionType.RESET_STATE }
  | { type: SearchActionType.RESET_SELECT_OPTIONS }
  | {
      type: SearchActionType.ON_SEARCH_FILTERS_SAVE;
      payload: {
        filtersData: SearchState['filtersData'];
      };
    }
  | {
      type: SearchActionType.ON_QUICK_SPECIALTY_CHANGE;
      payload: QuickSpecialtySearchParams;
    }
  | {
      type: SearchActionType.ON_SEARCH_START;
      payload: {
        isModifiedSearch: boolean;
      };
    }
  | {
      type: SearchActionType.ON_SEARCH_FINISHED;
      payload: {
        formStateOnSearch: {
          searchFormData: OrderAssistFormData;
          resultsFilters?: SearchFilters;
        };
        searchResults?: GetOrderAssistResult;
        searchError?: boolean;
        updatePreferredResult: boolean;
        displayPreferredProvidersListFF: SearchState['displayPreferredProvidersListFF'];
        organizationHasPreferredProviders: SearchState['organizationHasPreferredProviders'];
        preferredProviders: SearchState['preferredProviders'];
        shouldModifySearch: boolean;
      };
    }
  | {
      type: SearchActionType.SET_SELECTED_ADDRESS;
      payload: SetAddressChangePayload;
    }
  | { type: SearchActionType.ON_FREE_TEXT_START; payload: { freeTextType: FreeTextType } }
  | {
      type: SearchActionType.ON_FREE_TEXT_FINISH;
      payload: {
        freeTextType: FreeTextType;
        results?: FreeTextResult[] | NuccFreeTextResult[];
        isError?: boolean;
      };
    }
  | {
      type: SearchActionType.SET_SELECTED_PROVIDER;
      payload: SearchState['selectedProvider'];
    }
  | { type: SearchActionType.RESET_FILTERS };

export type SearchDispatch = Dispatch<SearchAction>;

const defaultSearchResultState: Pick<
  SearchState,
  | 'searchQuery'
  | 'isCostAvailable'
  | 'lastProviderCacheId'
  | 'benefits'
  | 'disclaimers'
  | 'displayPreferredProvidersListFF'
  | 'organizationHasPreferredProviders'
  | 'preferredProviders'
> = {
  searchQuery: undefined,
  isCostAvailable: false,
  lastProviderCacheId: undefined,
  benefits: undefined,
  disclaimers: undefined,
  displayPreferredProvidersListFF: false,
  organizationHasPreferredProviders: false,
  preferredProviders: [],
};

const defaultSelectOptionsState: Pick<SearchState, 'icdQuery' | 'cptQuery' | 'specialtyQuery'> = {
  icdQuery: undefined,
  cptQuery: undefined,
  specialtyQuery: undefined,
};

export const defaultState: SearchState = {
  ...defaultSearchResultState,
  ...defaultSelectOptionsState,
  formData: {},
  formDataMetadata: undefined,
  currentSearchInput: {},
  filtersData: undefined,
  selectedProvider: undefined,
};

export const searchReducer: Reducer<SearchState, SearchAction> = (state, action): SearchState => {
  logReducerAction('searchReducer', state, action);

  switch (action.type) {
    case SearchActionType.RESET_STATE: {
      return {
        ...state,
        ...defaultState,
      };
    }

    case SearchActionType.UPDATE_FORM_DATA: {
      const { vimPatientId, vimReferralId, ...formData } = action.payload;
      const updatedFormData = omitBy(
        { ...state.formData, ...formData },
        isNil,
      ) as unknown as OrderAssistFormData;

      const isPlaceOfServiceRequired =
        !!updatedFormData?.specialty?.vimSpecialtyId &&
        isSpecialtyRequiresPlaceOfService(updatedFormData?.specialty?.vimSpecialtyId);

      return {
        ...state,
        formData: isPlaceOfServiceRequired
          ? updatedFormData
          : omit(updatedFormData, ['placeOfService']),
        formDataMetadata: { vimPatientId, vimReferralId },
      };
    }

    case SearchActionType.RESET_FORM_CONDITIONAL_PARAMS: {
      return {
        ...state,
        formData: { address: state.formData.address, specialty: state.formData.specialty },
      };
    }

    case SearchActionType.RESET_SEARCH_RESULTS: {
      return { ...state, ...defaultSearchResultState };
    }

    case SearchActionType.RESET_SELECT_OPTIONS: {
      return { ...state, ...defaultSelectOptionsState };
    }

    case SearchActionType.ON_SEARCH_FILTERS_SAVE: {
      return {
        ...state,
        filtersData: { ...state.filtersData, ...action.payload?.filtersData },
      };
    }

    case SearchActionType.ON_QUICK_SPECIALTY_CHANGE: {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
        specialtyQuery: {},
      };
    }

    case SearchActionType.ON_SEARCH_START: {
      const { isModifiedSearch } = action.payload;

      return {
        ...state,
        searchQuery: { isLoading: true, isModifiedSearch },
        selectedProvider: undefined,
        currentSearchInput: cloneDeep(state.formData),
      };
    }

    case SearchActionType.ON_SEARCH_FINISHED: {
      if (!state.searchQuery?.isLoading) return state;

      const {
        formStateOnSearch,
        searchResults,
        searchError,
        updatePreferredResult,
        displayPreferredProvidersListFF,
        organizationHasPreferredProviders,
        preferredProviders,
        shouldModifySearch,
      } = action.payload;

      // If the results that arrived are not of the most recent search - ignore them
      if (
        !isEqual(formStateOnSearch, {
          searchFormData: state.formData,
          resultsFilters: state.filtersData?.resultsFilters,
        })
      ) {
        return state;
      }

      const stateUpdate: Partial<SearchState> = {
        searchQuery: {
          ...state.searchQuery,
          results: searchResults,
          hasError: searchError,
          isLoading: false,
          shouldModifySearch,
        },
      };

      if (searchResults?.providerCacheId) {
        stateUpdate.lastProviderCacheId = searchResults.providerCacheId;
      }

      if (searchResults?.costAvailable) {
        stateUpdate.isCostAvailable = true;
      }

      if (searchResults?.disclaimers?.length) {
        stateUpdate.disclaimers = searchResults.disclaimers;
      }

      if (!isEmpty(searchResults?.benefits)) {
        stateUpdate.benefits = searchResults?.benefits;
      }

      if (updatePreferredResult) {
        stateUpdate.preferredProviders = preferredProviders;
        stateUpdate.displayPreferredProvidersListFF = displayPreferredProvidersListFF;
        stateUpdate.organizationHasPreferredProviders = organizationHasPreferredProviders;
      }

      return { ...state, ...stateUpdate };
    }

    case SearchActionType.ON_FREE_TEXT_START: {
      const { freeTextType } = action.payload;

      const stateUpdate: Partial<SearchState> = {
        [freeTextTypeToSearchStateField[freeTextType]]: { isLoading: true },
      };

      return { ...state, ...stateUpdate };
    }

    case SearchActionType.ON_FREE_TEXT_FINISH: {
      const { freeTextType, results, isError } = action.payload;

      const stateUpdate: Partial<SearchState> = {
        [freeTextTypeToSearchStateField[freeTextType]]: { results, hasError: isError },
      };

      return { ...state, ...stateUpdate };
    }

    case SearchActionType.SET_SELECTED_PROVIDER: {
      return { ...state, selectedProvider: action.payload };
    }

    case SearchActionType.SET_SELECTED_ADDRESS: {
      const { addressIndex, resultIndex, isAlternative, isPreferred } = action.payload;
      const resultType: keyof GetOrderAssistResult = isAlternative
        ? 'alternativeResults'
        : 'searchResults';
      const copyState = cloneDeep(state);

      const index = [
        ...(isPreferred ? ['preferredProviders'] : ['searchQuery', 'results', resultType]),
        resultIndex,
        'selectedLocationIndex',
      ];
      set(copyState, index, addressIndex);
      return copyState;
    }

    case SearchActionType.RESET_FILTERS: {
      return { ...state, filtersData: undefined };
    }

    default: {
      return state;
    }
  }
};
