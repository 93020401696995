import { StandardEvents } from '@getvim/vim-connect';
import { useCallback, useState } from 'react';
import { handleReferralTargetProviderAnalytic } from '../../../../../analytics';

export interface UseReferralViewedAnalytics {
  onProviderSelectedWithVim: () => void;
  onProviderSelectedWithVimFailed: () => void;
  onReferralViewedAnalytics: (
    newReferralViewedPayload: StandardEvents.TransformedReferralViewedPayload,
  ) => void;
  onReferralClosedAnalytics: () => void;
}

export const useReferralViewedAnalytics = (): UseReferralViewedAnalytics => {
  const [providerSelectedWithVim, setProviderSelectedWithVim] = useState(false);
  const [referralViewedPayload, setReferralViewedPayload] = useState<
    StandardEvents.TransformedReferralViewedPayload | undefined
  >(undefined);

  const onProviderSelectedWithVim = useCallback(() => {
    setProviderSelectedWithVim(true);
  }, []);

  const onProviderSelectedWithVimFailed = useCallback(() => {
    setProviderSelectedWithVim(false);
  }, []);

  const onReferralViewedAnalytics = useCallback(
    (newReferralViewedPayload: StandardEvents.TransformedReferralViewedPayload) => {
      if (!providerSelectedWithVim) {
        handleReferralTargetProviderAnalytic(referralViewedPayload, newReferralViewedPayload);
      }

      const isADifferentReferral =
        newReferralViewedPayload.vimReferralId !== referralViewedPayload?.vimReferralId;
      const isValidNewTargetProvider =
        newReferralViewedPayload?.targetProvider?.npi ||
        newReferralViewedPayload.targetProvider?.demographics?.lastName;
      // Reset selectedWithVim indication only when changing referrals or after a valid targetProvider (with last name or npi) was added to the referralViewed payload
      if (isADifferentReferral || (providerSelectedWithVim && isValidNewTargetProvider)) {
        setProviderSelectedWithVim(false);
      }

      if (referralViewedPayload?.referralId !== newReferralViewedPayload.referralId) {
        setReferralViewedPayload(newReferralViewedPayload);
      }
    },
    [providerSelectedWithVim, referralViewedPayload],
  );

  const onReferralClosedAnalytics = useCallback(() => {
    setReferralViewedPayload(undefined);
    setProviderSelectedWithVim(false);
  }, []);

  return {
    onProviderSelectedWithVim,
    onProviderSelectedWithVimFailed,
    onReferralViewedAnalytics,
    onReferralClosedAnalytics,
  };
};
