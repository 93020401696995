import { orderAssistLogger } from '../logger';

export const wrapWithExecutionLogger = async <T>(
  actionName: string,
  action: Promise<T>,
): Promise<T> => {
  const startTime = performance.now();
  const result = await action;
  const endTime = performance.now();
  orderAssistLogger.info(`Order Assist ${actionName} took ${(endTime - startTime) / 1000} seconds`);
  return result;
};
