import { v4 as uuidv4 } from 'uuid';
import { omit } from 'lodash-es';
import {
  ExportMenuAnalyticsContext,
  ExportAnalyticsEvents,
  ExportAnalyticsEventNames,
} from '../types/exportAnalytics.types';
import { orderAssistLogger } from '../../components/app/logger';
import { vimPatientIdsManager } from '../../utils/vimPatientIdsManager';
import { isVimOsAppMode } from '../../vim-os-sdk-migration';
import { OrderAssistAnalyticsClient } from './types';

const contextPropertyOmitByEvent = {
  [ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_HOVERED]: ['export_menu_id'],
};

const modifyContextByEvent = (
  event: ExportAnalyticsEventNames,
  context: ExportMenuAnalyticsContext,
): Partial<ExportMenuAnalyticsContext> => {
  return omit(context, contextPropertyOmitByEvent[event]);
};

export class ExportAnalyticsManager {
  private context: ExportMenuAnalyticsContext = {};
  private static analyticsClient: OrderAssistAnalyticsClient;

  public track(
    event: ExportAnalyticsEventNames,
    eventProperties: ExportAnalyticsEvents[ExportAnalyticsEventNames],
  ) {
    if (!this.context.export_menu_id) this.init();
    ExportAnalyticsManager.analyticsClient.trackAnalytics({
      event,
      properties: {
        ...eventProperties,
        exportContext: modifyContextByEvent(event, this.context),
        vim_patient_id: vimPatientIdsManager.getVimPatientId(),
        is_vim_os: isVimOsAppMode,
      },
    });
  }

  public static setAnalyticsClient(client: OrderAssistAnalyticsClient) {
    ExportAnalyticsManager.analyticsClient = client;
  }

  init() {
    this.context.export_menu_id = uuidv4();
    orderAssistLogger.debug('initiated new export analytics context', this.context.export_menu_id);
  }

  getContext() {
    return this.context;
  }

  setContext(context: Partial<ExportMenuAnalyticsContext>) {
    this.context = context;
  }

  updateContext(context: Partial<ExportMenuAnalyticsContext>) {
    this.context = { ...this.context, ...context };
  }

  resetMenu() {
    this.context.export_menu_id = undefined;
  }
}

export const exportAnalyticsClient = new ExportAnalyticsManager();
