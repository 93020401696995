import type {} from '@getvim/env-plugin'; // this is for window.$vim_environment type

import { Infra, Standard } from '@getvim/vim-connect';

export const widgetId = Infra.Apps.Product.OrderAssist;
const devEnvironments = ['local', 'dev', 'staging', 'demo'];
export const isProduction = !devEnvironments.includes(window.$vim_environment.APP_ENV);
export const referralRequestFlowDelayMs = 2500;
export const SUPPORTED_ORDER_TYPES = [
  Standard.Entities.OrderType.LAB,
  Standard.Entities.OrderType.DI,
];
