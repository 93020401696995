import { ButtonStyle } from '@getvim/internal-vim-os-sdk/types';
import { ActionButtonAction, BtnType } from '@getvim/utils-vim-connect-communication';
import { SearchNotificationConfigInput } from './types';

export const getSearchNotificationActionButtons = (
  payload: Omit<SearchNotificationConfigInput, 'notificationConfig'>,
) => {
  const {
    buttonsConfig: { resultSize, showNotificationForZeroResults },
    actionCallbacks: { onFindSpecialtiesCallback },
  } = payload;

  if (showNotificationForZeroResults && resultSize === 0) {
    return configureFindSpecialistsButton(onFindSpecialtiesCallback);
  }

  return configureResultsButtons(payload);
};

const configureFindSpecialistsButton = (onFindSpecialtiesCallback) => {
  const findSpecialistsButtonText = 'Find specialists';

  return {
    sdk: {
      rightButton: {
        text: findSpecialistsButtonText,
        buttonStyle: ButtonStyle.PRIMARY,
        openAppButton: false,
        callback: onFindSpecialtiesCallback,
      },
    },
    runtime: [
      {
        text: findSpecialistsButtonText,
        action: ActionButtonAction.FIND_SPECIALISTS,
        buttonType: BtnType.TINY,
        style: {
          'margin-left': 'auto',
        },
      },
    ],
  };
};

const configureResultsButtons = (
  payload: Omit<SearchNotificationConfigInput, 'notificationConfig'>,
) => {
  const {
    buttonsConfig: { resultSize, hasNextPage, isFacility, isReadonlyMode },
    actionCallbacks: { onSelectProviderCallback, onSeeMoreResultsCallback },
  } = payload;

  const actionButtonText = isFacility ? 'Copy details' : 'Select';
  const moreResultSize = resultSize - 1;
  const seeMoreButtonText = `See ${Math.min(moreResultSize, 9)}${
    hasNextPage || moreResultSize > 9 ? '+' : ''
  } more results`;

  return {
    sdk: {
      leftButton: {
        text: seeMoreButtonText,
        buttonStyle: ButtonStyle.LINK,
        openAppButton: true,
        callback: onSeeMoreResultsCallback,
      },
      rightButton: {
        text: actionButtonText,
        buttonStyle: ButtonStyle.PRIMARY,
        openAppButton: true,
        callback: onSelectProviderCallback,
      },
    },
    runtime: [
      {
        text: seeMoreButtonText,
        action: ActionButtonAction.SEE_MORE_RESULT,
        buttonType: BtnType.LINK,
        className: 'text-ultra-dark',
        style: {
          'text-decoration': 'underline',
          'font-size': '14px',
        },
      },
      {
        text: actionButtonText,
        action: ActionButtonAction.SELECT,
        buttonType: BtnType.TINY,
        disabled: isReadonlyMode,
      },
    ],
  };
};
