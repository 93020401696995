import { BadgeTypeEnum } from '@getvim/atomic-ui';
import phoneFormatter from 'phone-formatter';
import {
  CostEstimation,
  FindResult,
  GetResultsOptions,
  Location,
  Performance,
  ProviderType,
} from '../../../../types/find';
import {
  FacilityResultSpecificFields,
  ProviderResultSpecificFields,
} from '../../../../types/find/uhc';
import {
  AddressWithDistanceAndPhone,
  BadgeDetails,
  ComplexName,
  CostDetail,
  OrderAssistOption,
  OrderAssistOptionType,
} from '../../../../types';
import { getOrderAssistUIString } from '../../../../consts/strings';

const getCostDetail = (costEstimation: CostEstimation | undefined): CostDetail | undefined => {
  if (!costEstimation) {
    return undefined;
  }
  const { min, max, description } = costEstimation;
  const isSameCost = min === max;
  const costText = isSameCost ? `$${min}` : `$${min} - $${max}`;
  return {
    cost: costText,
    message: description ?? '',
  };
};

export const acceptingNewPatientsBadge = (isLongTooltipText: boolean) => {
  return isLongTooltipText
    ? {
        type: BadgeTypeEnum.acceptingNewPatients,
        title: 'Accepting All Patients',
        description:
          "Accepting All Patients. Includes New and Existing Patients. We recommend you contact the provider's office to confirm. The provider might be accepting existing patients only or no patients at all at this time and this update is not reflected here yet.",
      }
    : {
        type: BadgeTypeEnum.acceptingNewPatients,
        title: 'Accepting new patients',
        description: 'Accepting new patients',
      };
};
export const notAcceptingNewPatientsBadge = (isLongTooltipText: boolean) => {
  return isLongTooltipText
    ? {
        type: BadgeTypeEnum.notAcceptingNewPatients,
        title: 'Not Accepting New Patients',
        description:
          "Not Accepting New Patients. We recommend you contact the provider's office to confirm. The provider might be accepting new or existing patients at this time and this update is not reflected here yet.",
      }
    : {
        type: BadgeTypeEnum.notAcceptingNewPatients,
        title: 'Not accepting new patients',
        description: 'Not accepting new patients',
      };
};

export const caterpillarNetworkBadge = () => {
  return {
    type: BadgeTypeEnum.caterpillarNetwork,
    title: 'Caterpillar\nNetwork\nProvider',
    description: 'Caterpillar Network Providers',
  };
};

const getBadges = (performances: Performance[]): BadgeDetails[] => {
  return performances.map((performance) => {
    const { code, description } = performance;
    return {
      type: code as BadgeTypeEnum,
      description,
      title: '',
    };
  });
};

const getLocations = (locations: Location[]): AddressWithDistanceAndPhone[] => {
  return locations.map((location) => {
    const {
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      distance,
      phoneNumber,
      fax,
      officeName,
      acceptNewPatients,
      implementationSpecificFields,
    } = location;

    const formattedPhone =
      phoneNumber?.number && phoneNumber?.number !== 'N/A'
        ? phoneFormatter.format(phoneNumber.number, '(NNN) NNN-NNNN')
        : getOrderAssistUIString('phone_number_not_available_label');

    const formattedFax =
      fax?.number && fax?.number !== 'N/A'
        ? phoneFormatter.format(fax.number, '(NNN) NNN-NNNN')
        : undefined;

    return {
      address1: addressLine1,
      address2: addressLine2,
      officeName,
      city,
      state,
      zipCode: zip,
      distance: distance ?? '',
      acceptNewPatients,
      phone: formattedPhone,
      fax: formattedFax,
      implementationSpecificFields,
    };
  });
};

const getComplexName = (findResult: FindResult): ComplexName | undefined => {
  const { firstName, lastName } = findResult;

  return {
    firstName: firstName as string,
    lastName: lastName as string,
  };
};

export const getResults = (options: GetResultsOptions): OrderAssistOption[] => {
  const { results, rankStarter } = options;
  return results.map((findResult: FindResult, index: number) => {
    const {
      providerType,
      npi,
      name,
      locations,
      specialties,
      preferredProvider,
      implementationSpecificFields,
    } = findResult;
    const isProvider = providerType === ProviderType.PROVIDER;
    const resultSpecificFields = implementationSpecificFields as ProviderResultSpecificFields &
      FacilityResultSpecificFields;
    const {
      tin,
      mpin,
      tier,
      tierType,
      providerGroupIndicator,
      organizationName,
      acceptingNewPatients,
      organizationTypeDescription,
      preferredLab,
      freeStandingFacility,
      premiumDesignationLong,
      premiumDesignationText,
      plnDdpCd,
    } = resultSpecificFields || {};

    const firstLocation: Location | undefined = locations?.[0];
    const { performance, costEstimation, highValuePerformance } = firstLocation || {};

    return {
      type: isProvider ? OrderAssistOptionType.Provider : OrderAssistOptionType.Facility,
      details: {
        complexName: getComplexName(findResult),
        name,
        npi,
        tin,
        mpin,
        preferredProvider,
        acoName: organizationName,
        specialties,
        highValuePerformance,
      },
      locations: getLocations(locations ?? []),
      badges: getBadges(performance ?? []),
      costDetail: getCostDetail(costEstimation),
      extraInfo: {
        tier,
        tierType,
        providerGroupIndicator,
        ...(isProvider
          ? {
              acceptingNewPatients,
              premiumDesignationText,
              premiumDesignationLong,
            }
          : {
              organizationTypeDescription,
              preferredLab,
              freeStandingFacility,
              plnDdpCd,
            }),
      },
      rank: (rankStarter ?? 0) + index + 1,
    };
  });
};
