/* eslint-disable react/no-unknown-property */
import React from 'react';
import classNames from 'classnames';
import { upperFirst } from 'lodash-es';
import Text from '../text/index';
import { AlignEnum, ColorNameEnum, SizeEnum } from '../../types/enums/textStyle';

import './index.less';

export type BenefitsBadgeProps = {
  benefitsMessage?: string;
  tooltip?: string;
};

const BenefitsBadge: React.FC<BenefitsBadgeProps> = ({ benefitsMessage, tooltip }) => (
  <div
    className={classNames('benefits-badge-wrap', { 'show-tooltip': tooltip })}
    tooltip-data={upperFirst(tooltip?.toLowerCase())}
  >
    <Text
      className="icon-lightning-e benefits-icon"
      colorName={ColorNameEnum.themeSecondary}
      size={SizeEnum['14px']}
      align={AlignEnum.left}
      inline
    />
    <Text
      text={upperFirst(benefitsMessage?.toLowerCase())}
      colorName={ColorNameEnum.ultraDark}
      size={SizeEnum['12px']}
      align={AlignEnum.left}
      inline
    />
  </div>
);

export default BenefitsBadge;
