import React from 'react';
import { procedureResponseToAnalyticsProcedure } from '../../../../../analytics/referral-request/formatters';
import { StatusScreenPayload } from '../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import { ProcedureResponse, ReferralRequestStatus, UpdateCodeStatus } from '../types';
import RequestError from './error/RequestError';
import './RequestResult.less';
import ResultStatus from './status/ResultStatus';
import { getStatusToUiStatus } from '../ReferraRequestStates';
import NoAnswer from './no-answer/NoAnswer';

interface ReferralRequiredContainerProps {
  status: ReferralRequestStatus;
  procedures: ProcedureResponse[];
  statusReason?: string;
  certificationNumber?: string;
  requestSubmissionTime: number;
  onRetry: () => void;
  onClose: (data?: StatusScreenPayload) => void;
  updateCodeStatus: UpdateCodeStatus;
}

const ReferralResultContainer: React.FC<ReferralRequiredContainerProps> = ({
  status,
  procedures,
  statusReason,
  certificationNumber,
  requestSubmissionTime,
  onRetry,
  onClose,
  updateCodeStatus,
}) => {
  switch (status) {
    case ReferralRequestStatus.ERROR:
      return <RequestError onClose={onClose} onRetry={onRetry} />;
    case ReferralRequestStatus.NO_ANSWER:
      return <NoAnswer onClose={onClose} />;
    default:
      return (
        <ResultStatus
          onClose={() =>
            onClose({
              requestStatus: getStatusToUiStatus(status),
              cpts: procedures.map((procedure) => procedureResponseToAnalyticsProcedure(procedure)),
              statusReason,
              certificationId: certificationNumber,
            })
          }
          status={status}
          procedures={procedures}
          reason={statusReason}
          certificationNumber={certificationNumber}
          requestSubmissionTime={requestSubmissionTime}
          updateCodeStatus={updateCodeStatus}
        />
      );
  }
};
export default ReferralResultContainer;
