import { StandardEvents } from '@getvim/vim-connect';
import { useVimCommunication } from '@getvim/vim-app-infra';
import { useEffect } from 'react';
import { Patient, WidgetIncomingEvent } from '../../../../types';
import { OrderAssistEventHandlers } from './types';

export const useRuntimeEventHandlers = ({
  onPatientInContextEvent,
  onPatientOutOfContextEvent,
  onOrderViewedEvent,
  onOrderClosedEvent,
  onOrdersViewedEvent,
  onOrdersClosedEvent,
  onReferralViewedEvent,
  onReferralClosedEvent,
  onRuntimeNotificationActionEvent,
}: OrderAssistEventHandlers) => {
  const { vimCommunication } = useVimCommunication();

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.PatientInContext,
      async ({ data }: { data: Patient }) => await onPatientInContextEvent(data),
    );
  }, [vimCommunication, onPatientInContextEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.PatientOutOfContext,
      async () => await onPatientOutOfContextEvent(),
    );
  }, [vimCommunication, onPatientOutOfContextEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.ReferralViewed,
      async ({ data }: { data: StandardEvents.TransformedReferralViewedPayload }) =>
        await onReferralViewedEvent(data),
    );
  }, [vimCommunication, onReferralViewedEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.ReferralClosed,
      async () => await onReferralClosedEvent(),
    );
  }, [vimCommunication, onReferralClosedEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.OrderViewed,
      async ({ data }: { data: StandardEvents.OrderViewedPayload }) =>
        await onOrderViewedEvent(data),
    );
  }, [vimCommunication, onOrderViewedEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.OrderClosed,
      async () => await onOrderClosedEvent(),
    );
  }, [vimCommunication, onOrderClosedEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.OrdersViewed,
      async () => await onOrdersViewedEvent(),
    );
  }, [vimCommunication, onOrdersViewedEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.OrdersClosed,
      async () => await onOrdersClosedEvent(),
    );
  }, [vimCommunication, onOrdersClosedEvent]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(
      WidgetIncomingEvent.NotificationActionButtonClick,
      async ({ data }: { data: StandardEvents.NotificationActionButtonClick }) =>
        await onRuntimeNotificationActionEvent(data),
    );
  }, [vimCommunication, onRuntimeNotificationActionEvent]);
};
