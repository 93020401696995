import { ProductSource } from '@getvim-core-apps/organizations';
import { Button, Tooltip } from '@getvim/atomic-ui';
import { Form, Formik } from 'formik';
import React from 'react';
import { array, date, mixed, number, object, string } from 'yup';
import { OnFreeTextQueryMethod } from '../../order-assist-app/hooks';
import { ServiceInformation as ServiceInformationType } from '../types';
import { availityDisclaimer, placeOfServiceMapping, serviceTypeMapping } from './consts';
import { IcdInputField, MappingSelectInputField, ProceduresInputField } from './input-fields';
import './ServiceInformation.less';
import { getReferralRequestUIString } from '../strings';

interface ServiceInformationProps {
  serviceInformation: ServiceInformationType;
  onFreeTextQuery: OnFreeTextQueryMethod;
  onPrev: (serviceInformation) => void;
  onSubmit: (serviceInformation: ServiceInformationType) => void;
}

const ServiceInformationSchema = object().shape({
  serviceType: mixed<string>().oneOf(['1', '73', '76', '61']).required('Service is required'),
  placeOfService: mixed<string>()
    .oneOf(['11', '19', '22'])
    .required('Place of Service is required'),
  diagnoses: array()
    .min(1)
    .of(
      object().shape({
        code: string().required('ICD diagnosis code is required'),
        description: string().required('Diagnosis code description is required'),
      }),
    )
    .required('Diagnosis codes are required'),
  procedures: array()
    .min(1)
    .of(
      object().shape({
        cpt: object().shape({
          code: string().required('Procedure code is required'),
          description: string().required('Procedure description is required'),
        }),
        date: date().required('Date is required'),
        quantity: object().shape({
          type: number().oneOf([0, 1]).required(),
          value: number().moreThan(0).required(),
        }),
      }),
    ),
});

const ServiceInformation: React.FC<ServiceInformationProps> = ({
  serviceInformation,
  onFreeTextQuery,
  onPrev,
  onSubmit,
}) => {
  return (
    <>
      <div className="title">
        {getReferralRequestUIString('referral_quantity_service_information_title')}
      </div>
      <div className="search-form">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={serviceInformation}
          validationSchema={ServiceInformationSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ values, isValid }) => (
            <Form>
              <MappingSelectInputField
                fieldName="serviceType"
                mapping={serviceTypeMapping}
                title={getReferralRequestUIString(
                  'referral_quantity_service_information_service_type',
                )}
              />
              <MappingSelectInputField
                fieldName="placeOfService"
                mapping={placeOfServiceMapping}
                title={getReferralRequestUIString(
                  'referral_quantity_service_information_place_of_service',
                )}
              />
              <div className="title">Diagnosis</div>
              <IcdInputField
                onFreeTextQuery={(payload, insurer) =>
                  onFreeTextQuery(payload, insurer, ProductSource.UnitedHealthCare)
                }
              />
              <ProceduresInputField />
              <p className="service-information-disclaimer no-user-select">{availityDisclaimer}</p>
              <div className="service-information-form-buttons">
                <Button
                  text="Back"
                  buttonType="small"
                  bgColor="themedOutline"
                  onClick={() => onPrev(values)}
                  className="control-button"
                />
                <Tooltip
                  bgColor="light"
                  pillStyle
                  placement="top"
                  tooltipContent="Please fill all the required fields"
                  hideTooltip={isValid}
                  className="tooltip"
                >
                  <div>
                    <Button
                      text="Submit"
                      buttonType="small"
                      width="block"
                      type="submit"
                      disabled={!isValid}
                      className="control-button"
                    />
                  </div>
                </Tooltip>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ServiceInformation;
