import React from 'react';
import './LanguagePopover.less';
import { ButtonGroupComponent, LanguageItem } from '@getvim/atomic-ui';

interface LanguagePopoverProps {
  items: LanguageItem[];
  onLanguageChange?: (value: string) => void;
  value: string;
  searchPlaceholder?: string;
}

const LanguagePopover: React.FC<LanguagePopoverProps> = ({
  value,
  items,
  onLanguageChange,
  searchPlaceholder,
}) => {
  return (
    <ButtonGroupComponent
      searchable
      bgColor="default"
      buttonType="dropdownOption"
      width="block"
      direction="column"
      color="extraDarkGray"
      onChange={onLanguageChange}
      multiLines
      value={value}
      items={items}
      searchPlaceholder={searchPlaceholder}
      searchStopPropagation
    />
  );
};

export default LanguagePopover;
