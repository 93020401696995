import React from 'react';
import SomethingWentWrong from '../../../../../assets/something-went-wrong.svg';
import ImageMessageView from './ImageMessageView';
import { DataFilters } from '../../../types';
import { SearchMethod } from '../../../../../analytics/types';
import { PreferredProvidersProps } from '../../order-assist-app/hooks';
import { getOrderAssistUIString } from '../../../consts/strings';

interface SearchErrorProps {
  onTryAgain: (props: {
    searchPage?: number;
    filters?: DataFilters;
    method?: SearchMethod;
    preferredProvidersDetails?: PreferredProvidersProps;
  }) => void;
}

const SearchError: React.FC<SearchErrorProps> = ({ onTryAgain }) => {
  return (
    <ImageMessageView
      imageSrc={SomethingWentWrong}
      title={getOrderAssistUIString('search_failed_title')}
      details={getOrderAssistUIString('search_failed_details_prompt')}
      buttonProps={{
        text: getOrderAssistUIString('search_failed_try_again_button'),
        onClick: onTryAgain,
      }}
    />
  );
};
export default SearchError;
