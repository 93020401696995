import React from 'react';
import { Text, SizeEnum, WeightEnum, AlignEnum, Button, ButtonProps } from '@getvim/atomic-ui';

interface ImageMessageViewProps {
  imageSrc: string;
  title: string;
  details: string;
  buttonProps?: Pick<ButtonProps, 'text' | 'onClick'>;
}

const ImageMessageView: React.FC<ImageMessageViewProps> = ({
  imageSrc,
  title,
  details,
  buttonProps,
}) => {
  const { text: buttonText, onClick } = buttonProps ?? {};

  return (
    <div className="image-message-view">
      <img className="image" src={imageSrc} alt="title" />
      <div>
        <Text
          className="title"
          size={SizeEnum['14px']}
          weight={WeightEnum.semibold}
          align={AlignEnum.center}
          text={title}
        />
        <Text
          className="details"
          ellipsis
          size={SizeEnum['12px']}
          align={AlignEnum.center}
          text={details}
        />
      </div>
      {buttonProps && (
        <Button
          className="action-button"
          text={buttonText}
          onClick={onClick}
          bgColor="default"
          buttonType="small"
        />
      )}
    </div>
  );
};
export default ImageMessageView;
