import React from 'react';
import { Button, Banner, BannerTypes, ColorNameEnum } from '@getvim/atomic-ui';

interface OutOfNetworkMessageProps {
  goBackAction: () => void;
}

const OutOfNetworkContent = ({ goBackAction }: OutOfNetworkMessageProps) => (
  <span className="text-semibold">
    To verify whether specialists are in-network, please&nbsp;
    <Button
      color="extraDarkGray"
      buttonType="link"
      className="text-semibold text-underline"
      onClick={goBackAction}
    >
      go back and enter their insurance&nbsp;
    </Button>
    <Button
      color="extraDarkGray"
      buttonType="link"
      className="text-semibold text-underline"
      onClick={goBackAction}
    >
      information
    </Button>
  </span>
);

export const OutOfNetworkMessage: React.FC<OutOfNetworkMessageProps> = ({ goBackAction }) => (
  <Banner
    text={<OutOfNetworkContent goBackAction={goBackAction} />}
    type={BannerTypes.WARNING}
    icon={<i className="icon-exclamation-c" />}
    textColor={ColorNameEnum.ultraDark}
  />
);
