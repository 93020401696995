import { EHR } from '@getvim/vim-os-sdk-api';
import ResourceUpdateBuilder from './resourceUpdateBuilder';
import { EHRResource } from '@getvim/vim-os-api';

type UpdatableEncounter = EHR.UpdateEncounterParams;

export default class EncounterUpdateBuilder extends ResourceUpdateBuilder<
  typeof EHRResource.encounter
> {
  protected readonly ehrResource = EHRResource.encounter;

  public updateSubjective(subjective: NonNullable<UpdatableEncounter['subjective']>) {
    return this.setObjectField('subjective', subjective);
  }
  public updateObjective(objective: NonNullable<UpdatableEncounter['objective']>) {
    return this.setObjectField('objective', objective);
  }
  public updateAssessment(assessment: NonNullable<UpdatableEncounter['assessment']>) {
    return this.setObjectField('assessments', assessment);
  }
  public updatePlan(plan: NonNullable<UpdatableEncounter['plan']>) {
    return this.setObjectField('plans', plan);
  }

  public updatePatientInstructions(
    patientInstructions: NonNullable<UpdatableEncounter['patientInstructions']>,
  ) {
    return this.setObjectField('patientInstructions', patientInstructions);
  }
}
