import { widgetId } from '../../../consts';
import { NotificationConfigMap } from '../use-notifications/types';
import { getSearchNotificationActionButtons } from './actionButtons';
import { SearchNotificationConfigInput } from './types';

const timeoutInSec = 10;

export const getSearchNotificationConfig = (
  payload: SearchNotificationConfigInput,
): NotificationConfigMap => {
  const {
    notificationConfig: { notificationId, notificationText },
    ...actionButtonsPayload
  } = payload;

  const notificationActionButtons = getSearchNotificationActionButtons(actionButtonsPayload);

  return {
    sdk: {
      notificationId,
      text: notificationText,
      timeoutInSec,
      actionButtons: notificationActionButtons.sdk,
    },
    runtime: {
      widgetId,
      notificationId,
      text: notificationText,
      options: { timeoutInMs: timeoutInSec * 1000 },
      actionButtons: notificationActionButtons.runtime,
    },
  };
};
