import loadGooglePlacesAutoCompleteApi from '@getvim/google-api';
import { useEffect, useState } from 'react';
import { orderAssistLogger } from '../../../logger';
import { logic } from '../../../logic';
import { AppActionType, AppDispatch } from '../reducers/appReducer';
import { useFlowState } from '@getvim/flow-context-provider/build';
import { AppRequiredState } from '../OrderAssistAppWrapper';
import { isVimOsAppMode } from '../../../../../vim-os-sdk-migration';

interface UseGoogleApiOptions {
  appDispatch: AppDispatch;
}

export const useGoogleApi = async (options: UseGoogleApiOptions): Promise<void> => {
  const { appDispatch } = options;
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState<string>();
  const { userConfig } = useFlowState<AppRequiredState>();

  useEffect(() => {
    const getGoogleMapsApiKey = async () => {
      setGoogleMapsApiKey((await logic.getAppConfig()).googleApiKey);
    };

    if (userConfig) {
      if (isVimOsAppMode) {
        setGoogleMapsApiKey(userConfig.product?.googleApiKey);
      } else {
        getGoogleMapsApiKey();
      }
    }
  }, [userConfig]);

  useEffect(() => {
    if (!googleMapsApiKey) return;

    loadGooglePlacesAutoCompleteApi(googleMapsApiKey)
      .then((result) => {
        appDispatch({
          type: AppActionType.ON_GOOGLE_API_LOADED,
          payload: { isGoogleApiLoaded: result },
        });
      })
      .catch((error: Error) => orderAssistLogger.error('Error loading Google API', error));
  }, [appDispatch, googleMapsApiKey]);
};
