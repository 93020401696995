import { OrderAssistOption, Provider, SelectActionMethod } from '../components/app/types';
import { isFacilityResult, isProviderResult } from '../components/app/utils/general';
import { widgetId } from '../consts';
import { analyticsManager } from './analyticsManager';
import { parseOptionFacilityToAnalyticsFacility } from './facility';
import {
  parseOptionProviderToAnalyticsProvider,
  parseVimProviderToAnalyticsProvider,
} from './provider';
import { SelectWithVimCardDetails } from './types';

export const trackSelectWithVim = ({
  selectAction,
  status,
  isAlternativeResult,
  previousProvider,
  selectedOption,
  selectedAddress,
  acceptNewPatients,
  isPreferredResult,
  isReferralRequestRequired,
  method,
}: Omit<SelectWithVimCardDetails, 'cardType' | 'cardRank'> & {
  selectedOption: OrderAssistOption;
  previousProvider?: Provider;
  selectedAddress?: string;
  acceptNewPatients?: boolean;
  isReferralRequestRequired?: boolean;
  method: SelectActionMethod;
}): void => {
  analyticsManager.track('target_selected_with_vim', {
    app_name: widgetId,
    action_details: {
      cardRank: selectedOption.rank,
      cardType: selectedOption.type,
      selectAction,
      status,
      isAlternativeResult,
      isPreferredResult,
    },
    previous_provider: parseVimProviderToAnalyticsProvider(previousProvider),
    ...(isProviderResult(selectedOption)
      ? {
          selected_provider: parseOptionProviderToAnalyticsProvider(
            selectedOption,
            selectedAddress,
            acceptNewPatients,
          ),
        }
      : {}),
    ...(isFacilityResult(selectedOption)
      ? {
          selected_facility: parseOptionFacilityToAnalyticsFacility(
            selectedOption,
            selectedAddress,
          ),
        }
      : {}),
    isReferralRequestRequired,
    method,
  });
};
