import { orderAssistLogger } from '../../logger';
import { Provider, WriteBackProvider } from '../../types';
import {
  ProviderInput,
  QuantityType,
  RequestingProvider,
  RequestReferralInput,
  ServiceInformation,
} from './types';

interface BuildReferralRequestInput {
  serviceInformationData: ServiceInformation;
  targetProvider: WriteBackProvider;
  requestingProvider?: RequestingProvider;
  memberToken?: string;
}

export const referringProviderFormatter = (
  shouldUseNewOrganizationAddressPayloadForReferralRequest: boolean,
  provider?: Provider,
): RequestingProvider => ({
  /** We assume that the provider can't have multiple locations, so we'll use the first address from the 'organizations' field. */
  address: shouldUseNewOrganizationAddressPayloadForReferralRequest
    ? provider?.organization?.address
    : provider?.organizations?.[0]?.address,
  firstName: provider?.demographics.firstName,
  lastName: provider?.demographics.lastName,
  npi: provider?.npi,
  specialty: provider?.specialty?.[0],
});

export const writeBackProviderToProviderInput = (provider: WriteBackProvider): ProviderInput => ({
  location: {
    addressLine1: provider.organizations?.[0].address?.address1 ?? '',
    addressLine2: provider.organizations?.[0].address?.address2,
    city: provider.organizations?.[0].address?.city ?? '',
    state: provider.organizations?.[0].address?.state ?? '',
    zip: provider.organizations?.[0].address?.zipCode ?? '',
  },
  ...(provider.demographics.firstName && { firstName: provider.demographics.firstName }),
  lastName: provider.demographics.lastName,
  npi: provider.npi ?? '',
  vimSpecialtyId: provider.specialty?.[0]?.vimSpecialty?.id ?? 'error-no-specialty',
});

export const buildReferralRequestInput = (
  data: BuildReferralRequestInput,
): RequestReferralInput => {
  const { serviceInformationData, targetProvider, requestingProvider, memberToken } = data;
  const { serviceType, placeOfService, diagnoses, procedures } = serviceInformationData;

  const referralRequestInput: RequestReferralInput = {
    targetProvider: writeBackProviderToProviderInput(targetProvider),
    referringProvider: {
      npi: requestingProvider?.npi ?? '',
      firstName: requestingProvider?.firstName,
      lastName: requestingProvider?.lastName ?? '',
      vimSpecialtyId: requestingProvider?.specialty?.vimSpecialty?.id ?? 'error-no-specialty',
      location: {
        addressLine1: requestingProvider?.address?.address1 ?? '',
        addressLine2: requestingProvider?.address?.address2,
        city: requestingProvider?.address?.city ?? '',
        state: requestingProvider?.address?.state ?? '',
        zip: requestingProvider?.address?.zipCode ?? '',
      },
    },
    serviceTypeCode: serviceType ?? '',
    placeOfServiceCode: placeOfService ?? '',
    diagnosis: diagnoses?.map((diagnosis) => diagnosis.code) || [],
    procedures:
      procedures?.map((procedure) => ({
        cpt: procedure.cpt ?? { code: '', description: '' },
        date: procedure.date,
        quantity: procedure.quantity?.value ?? 1,
        quantityTypeCode: (procedure.quantity && QuantityType[procedure.quantity?.type]) ?? '',
      })) ?? [],
    memberToken: memberToken ?? '',
  };

  orderAssistLogger.info('building referralRequest request input', { data, referralRequestInput });

  return referralRequestInput;
};
