import { Dispatch, Reducer } from 'react';
import { isEqual, merge } from 'lodash-es';
import { AppState, Patient } from '../../../types';
import { logReducerAction } from './utils/logReducerAction';

export enum AppActionType {
  RESET_STATE = 'RESET_STATE',
  ON_GOOGLE_API_LOADED = 'ON_GOOGLE_API_LOADED',
  ON_PATIENT_IN_CONTEXT_EVENT = 'ON_PATIENT_IN_CONTEXT_EVENT',
  ON_REFERRAL_VIEWED_EVENT = 'ON_REFERRAL_VIEWED_EVENT',
  ON_ORDER_VIEWED_EVENT = 'ON_ORDER_VIEWED_EVENT',
  ON_ORDER_CLOSED_EVENT = 'ON_ORDER_CLOSED_EVENT',
  ON_REFERRAL_CLOSED_EVENT = 'ON_REFERRAL_CLOSED_EVENT',
  ON_GET_PRODUCT_CONFIG_EVENT = 'ON_GET_PRODUCT_CONFIG_EVENT',
  ON_UPDATE_MEMBER_TOKEN = 'ON_UPDATE_MEMBER_TOKEN',
  SET_IS_REFERRAL_REQUEST_REQUIRED = 'SET_IS_REFERRAL_REQUEST_REQUIRED',
  SET_LAST_UPDATED_REFERRAL_ID = 'SET_LAST_UPDATED_REFERRAL_ID',
}

export type AppAction =
  | { type: AppActionType.RESET_STATE }
  | { type: AppActionType.ON_GOOGLE_API_LOADED; payload: Pick<AppState, 'isGoogleApiLoaded'> }
  | { type: AppActionType.ON_PATIENT_IN_CONTEXT_EVENT; payload: { patient: AppState['patient'] } }
  | { type: AppActionType.ON_REFERRAL_VIEWED_EVENT; payload: AppState['referral'] }
  | { type: AppActionType.ON_ORDER_VIEWED_EVENT; payload: AppState['order'] }
  | { type: AppActionType.ON_ORDER_CLOSED_EVENT }
  | { type: AppActionType.ON_REFERRAL_CLOSED_EVENT }
  | {
      type: AppActionType.ON_UPDATE_MEMBER_TOKEN;
      payload: { memberTokens: Patient['memberTokens'] };
    }
  | {
      type: AppActionType.SET_IS_REFERRAL_REQUEST_REQUIRED;
      payload: boolean;
    }
  | {
      type: AppActionType.SET_LAST_UPDATED_REFERRAL_ID;
      payload: string;
    };

export type AppDispatch = Dispatch<AppAction>;

export const appReducer: Reducer<AppState, AppAction> = (
  state: AppState,
  action: AppAction,
): AppState => {
  logReducerAction('appReducer', state, action);

  switch (action.type) {
    case AppActionType.RESET_STATE: {
      return {
        ...state,
        patient: undefined,
        referral: undefined,
        order: undefined,
        isReferralRequestRequired: undefined,
        lastUpdatedReferralId: undefined,
      };
    }

    case AppActionType.ON_GOOGLE_API_LOADED: {
      return { ...state, ...action.payload };
    }

    case AppActionType.ON_PATIENT_IN_CONTEXT_EVENT: {
      return action.payload.patient === state.patient
        ? state
        : { ...state, patient: action.payload.patient };
    }

    case AppActionType.ON_REFERRAL_VIEWED_EVENT: {
      const incomingReferral = { ...state.referral, ...action.payload };
      return isEqual(state.referral, incomingReferral)
        ? state
        : { ...state, referral: incomingReferral };
    }

    case AppActionType.ON_ORDER_VIEWED_EVENT: {
      const incomingOrder = { ...state.order, ...action.payload };
      return isEqual(state.order, incomingOrder) ? state : { ...state, order: incomingOrder };
    }

    case AppActionType.ON_ORDER_CLOSED_EVENT: {
      return state.order === undefined ? state : { ...state, order: undefined };
    }

    case AppActionType.ON_REFERRAL_CLOSED_EVENT: {
      return state.referral === undefined && state.lastUpdatedReferralId === undefined
        ? state
        : { ...state, referral: undefined, lastUpdatedReferralId: undefined };
    }

    case AppActionType.ON_UPDATE_MEMBER_TOKEN: {
      if (
        !state.patient?.patientId ||
        isEqual(state.patient.memberTokens, action.payload.memberTokens) ||
        !action.payload.memberTokens
      ) {
        return state;
      }

      return {
        ...state,
        patient: {
          ...state.patient,
          memberTokens: merge(state.patient.memberTokens, action.payload.memberTokens),
        },
      };
    }

    case AppActionType.SET_IS_REFERRAL_REQUEST_REQUIRED: {
      return state.isReferralRequestRequired === action.payload
        ? state
        : { ...state, isReferralRequestRequired: action.payload };
    }

    case AppActionType.SET_LAST_UPDATED_REFERRAL_ID: {
      return state.lastUpdatedReferralId === action.payload
        ? state
        : { ...state, lastUpdatedReferralId: action.payload };
    }

    default: {
      return state;
    }
  }
};
