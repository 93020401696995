import React, { useEffect } from 'react';
import { ColorNameEnum, WeightEnum, SizeEnum, Button, Text } from '@getvim/atomic-ui';
import GhostSVG from '../../../../../../assets/ghost.svg';
import './RequestError.less';
import {
  trackErrorScreenClicked,
  trackErrorScreenViewed,
} from '../../../../../../analytics/referral-request/trackErrorScreen';
import { ErrorScreenButtonName } from '../../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import { getReferralRequestUIString } from '../../strings';

interface RequestErrorProps {
  onClose: () => void;
  onRetry: () => void;
}

const RequestError: React.FC<RequestErrorProps> = ({ onClose, onRetry }) => {
  useEffect(() => {
    trackErrorScreenViewed();
  }, []);

  return (
    <div className="request-error-container">
      <img className="ghostImage" src={GhostSVG} alt="title" />
      <div>
        <Text
          colorName={ColorNameEnum.theme}
          text="Oops..."
          weight={WeightEnum.bold}
          size={SizeEnum['18px']}
        />
        <Text
          colorName={ColorNameEnum.theme}
          text={getReferralRequestUIString('referral_result_error_title')}
          size={SizeEnum['14px']}
          className="error-title"
        />
        <Text
          colorName={ColorNameEnum.theme}
          text={getReferralRequestUIString('referral_result_error_subtitle')}
          size={SizeEnum['14px']}
          className="error-title"
        />
      </div>
      <div className="errorButtons">
        <Button
          onClick={() => {
            trackErrorScreenClicked({
              buttonName: ErrorScreenButtonName.BACK_TO_REFERRAL_REQUEST_FLOW,
            });
            onRetry();
          }}
          text={getReferralRequestUIString('referral_result_error_review_referral_request_button')}
          buttonType="small"
        />
        <Button
          className="closeButton"
          onClick={() => {
            trackErrorScreenClicked({
              buttonName: ErrorScreenButtonName.BACK_TO_SEARCH_APP,
            });
            onClose();
          }}
          text={getReferralRequestUIString('referral_result_error_back_to_order_assist_button')}
          buttonType="small"
          bgColor="themedOutline"
        />
      </div>
    </div>
  );
};

export default RequestError;
