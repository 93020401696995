import { z } from 'zod';
import { EHRResource } from '../ehrResource';

export const referralRealEstateSchema = z.enum(['REFERRAL_SELECT_PROVIDER']);
export const ReferralRealEstate = referralRealEstateSchema.enum;
export type ReferralRealEstate = z.infer<typeof referralRealEstateSchema>;

export const ordersRealEstateSchema = z.enum(['DI_SELECT_PROVIDER', 'LABS_SELECT_PROVIDER']);
export const OrdersRealEstate = ordersRealEstateSchema.enum;
export type OrdersRealEstate = z.infer<typeof ordersRealEstateSchema>;

export type LaunchButtonRealEstates = ReferralRealEstate | OrdersRealEstate;

export const enableLaunchButtonPayloadSchema = z.object({
  [EHRResource.referral]: z
    .object({
      [ReferralRealEstate.REFERRAL_SELECT_PROVIDER]: z.boolean().optional(),
    })
    .optional(),
  [EHRResource.orders]: z
    .object({
      [OrdersRealEstate.DI_SELECT_PROVIDER]: z.boolean().optional(),
      [OrdersRealEstate.LABS_SELECT_PROVIDER]: z.boolean().optional(),
    })
    .optional(),
  [EHRResource.encounter]: z.never().optional(),
  [EHRResource.patient]: z.never().optional(),
});

export type EnableLaunchButtonPayload = z.infer<typeof enableLaunchButtonPayloadSchema>;
