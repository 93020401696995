import { ActionButtonAction } from '@getvim/utils-vim-connect-communication';
import { Standard } from '@getvim/vim-connect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { trackSearchNotificationTriggered } from '../../../analytics';
import { orderAssistLogger } from '../../../components/app/logger';
import {
  OrderAssistOption,
  OrderAssistOptionType,
  Patient,
  SelectActionMethod,
  HandleNotificationActionProps,
} from '../../../components/app/types';

import { SearchIdType, searchIdsManager } from '../../../utils/searchIdsManager';
import { useAutoPopup } from '../../useAutoPopupApp';
import { useIsAppOpen } from '../../useIsAppOpen';
import { useOrderAssistAppFeatureFlags } from '../../../components/app/components/order-assist-app/OrderAssistFFWrapper';
import { isVimOsAppMode } from '../../../vim-os-sdk-migration';
import { getSearchNotificationConfig } from './config';
import {
  SearchNotificationConfigInput,
  UseSearchNotificationReturnValue,
  UseSearchNotificationsProps,
} from './types';
import { generateMessageByType, generateMessageForZeroResults } from './messages';
import { useNotification } from '../use-notifications';

export const useSearchNotifications = ({
  providerSelectionAction,
  searchResults,
  preferredProviders,
  backToSearch,
  isReadonlyMode = false,
}: UseSearchNotificationsProps): UseSearchNotificationReturnValue => {
  const [lastNotifiedSessionId, setLastSessionId] = useState<string>();
  const [lastHandledNotificationId, setLastNotificationIdHandled] = useState<string>();

  const [selectProviderActionClicked, setSelectProviderActionClicked] = useState<boolean>(false);

  const { showNotificationFF, showNotificationForZeroResults } = useOrderAssistAppFeatureFlags();

  const autoPopup = useAutoPopup();
  const isAppOpen = useIsAppOpen();
  const { triggerNotification, triggerHideNotification } = useNotification();

  const openApp = useCallback(() => {
    autoPopup({
      expandingType: Standard.Entities.UIElements.ExpandingType.VIM_NOTIFICATION_MANUAL,
    });
  }, [autoPopup]);

  const onSeeMoreResultsCallback = useCallback(() => {
    openApp();
  }, [openApp]);

  const onFindSpecialtiesCallback = useCallback(() => {
    openApp();
    backToSearch();
  }, [openApp, backToSearch]);

  const onSelectProviderCallback = useCallback(() => {
    setSelectProviderActionClicked(true);
  }, []);

  useEffect(() => {
    if (searchResults && selectProviderActionClicked) {
      const provider = {
        data:
          preferredProviders?.[0] ||
          searchResults?.searchResults?.[0] ||
          searchResults?.alternativeResults?.[0],
        isAlternative: !preferredProviders?.[0] && !searchResults?.searchResults?.[0],
      };

      if (provider.data) {
        openApp();
        providerSelectionAction({
          provider: provider.data,
          actionMethod: SelectActionMethod.NOTIFICATION_CTA,
        });
      }

      setSelectProviderActionClicked(false);
    }
  }, [
    searchResults,
    openApp,
    providerSelectionAction,
    preferredProviders,
    selectProviderActionClicked,
    setSelectProviderActionClicked,
  ]);

  const notifyProviderResult = useCallback(
    (
      patient: Patient,
      provider: OrderAssistOption,
      resultSize: number,
      hasNextPage: boolean,
      inNetwork: boolean,
      vimSpecialtyName: string,
      isModifiedSearch: boolean,
      resultsFilterDistance?: number,
    ) => {
      let notificationText;
      if (resultSize === 0) {
        if (!isModifiedSearch || !resultsFilterDistance) return;
        notificationText = generateMessageForZeroResults(vimSpecialtyName, resultsFilterDistance);
      } else {
        const generateMessage = generateMessageByType[provider.type];
        if (showNotificationFF && generateMessage && !isAppOpen) {
          notificationText = generateMessage(provider, patient);
        }
      }
      const sessionId = searchIdsManager.getId(SearchIdType.SESSION_ID);
      if (notificationText && sessionId) {
        const getSearchNotificationPayload: SearchNotificationConfigInput = {
          notificationConfig: {
            notificationId: sessionId,
            notificationText,
          },
          buttonsConfig: {
            isFacility: !!provider && provider.type === OrderAssistOptionType.Facility,
            resultSize,
            hasNextPage,
            isReadonlyMode,
            showNotificationForZeroResults,
          },
          actionCallbacks: {
            onSelectProviderCallback,
            onFindSpecialtiesCallback,
            onSeeMoreResultsCallback,
          },
        };

        const notificationConfigMap = getSearchNotificationConfig(getSearchNotificationPayload);

        triggerNotification(notificationConfigMap);
        setLastSessionId(sessionId);
        trackSearchNotificationTriggered(sessionId, {
          highValue: !!provider && !!provider.details.highValuePerformance,
          preferred: !!provider && !!provider.details.preferredProvider,
          inNetwork,
          zeroResults: resultSize === 0,
        });
      }
    },
    [
      showNotificationFF,
      isAppOpen,
      isReadonlyMode,
      showNotificationForZeroResults,
      triggerNotification,
      onSelectProviderCallback,
      onFindSpecialtiesCallback,
      onSeeMoreResultsCallback,
    ],
  );

  const hideNotification = useCallback(() => {
    if (lastNotifiedSessionId && showNotificationFF) {
      triggerHideNotification(lastNotifiedSessionId);
      setLastSessionId(undefined);
    }
  }, [lastNotifiedSessionId, showNotificationFF, triggerHideNotification]);

  const handleRuntimeNotificationAction = useCallback(
    ({ action, notificationId }: HandleNotificationActionProps) => {
      if (isVimOsAppMode) return;
      if (notificationId === lastHandledNotificationId) return;
      if (notificationId !== lastNotifiedSessionId) return;

      switch (action) {
        case ActionButtonAction.SEE_MORE_RESULT: {
          onSeeMoreResultsCallback();
          break;
        }
        case ActionButtonAction.FIND_SPECIALISTS: {
          onFindSpecialtiesCallback();
          break;
        }
        case ActionButtonAction.SELECT: {
          setSelectProviderActionClicked(true);
          break;
        }
        default: {
          orderAssistLogger.warning('Got unexpected action in search notification!', {
            action,
            notificationId,
          });
          break;
        }
      }
      setLastNotificationIdHandled(notificationId);
    },
    [
      lastHandledNotificationId,
      lastNotifiedSessionId,
      setSelectProviderActionClicked,
      onFindSpecialtiesCallback,
      onSeeMoreResultsCallback,
    ],
  );

  return useMemo(
    () => ({
      notifyProviderResult,
      hideNotification,
      handleNotificationAction: handleRuntimeNotificationAction,
    }),
    [notifyProviderResult, hideNotification, handleRuntimeNotificationAction],
  );
};
