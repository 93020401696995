import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import { isVimOsAppMode } from './vim-os-sdk-migration';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { featureFlagsClientOrderOptimization } from './services/featureFlags';
import { StatesProvider } from './stores';

const OASdkWrapper = ({ children }) =>
  isVimOsAppMode ? <VimSDKProvider>{children}</VimSDKProvider> : children;

ReactDOM.render(
  <OASdkWrapper>
    <FeatureFlagProvider featureFlagsClient={featureFlagsClientOrderOptimization}>
      <React.StrictMode>
        <StatesProvider>
          <App />
        </StatesProvider>
      </React.StrictMode>
    </FeatureFlagProvider>
  </OASdkWrapper>,
  document.getElementById('root'),
);
