import { InputStyle, InputStyleWrapper, InputValidationMessage, Select } from '@getvim/atomic-ui';
import { StandardEvents } from '@getvim/vim-connect';
import { useField } from 'formik';
import { isArray, isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import { NO_RESULTS, START_SEARCH } from '../../../../consts/selectInputMessages';
import { FreeTextType, NuccFreeTextResult, NuccSelectOption } from '../../../../types';
import { OnFreeTextQueryMethod } from '../../../order-assist-app/hooks';
import './InputField.less';

interface SpecialtyInputFieldProps {
  onFreeTextQuery: OnFreeTextQueryMethod;
}

const formatOptions = (options: NuccFreeTextResult[] | undefined): NuccSelectOption[] => {
  if (!options) return [];
  return options.map(({ id, description }) => ({
    value: id,
    label: description,
  }));
};

export const SpecialtyInputField: React.FC<SpecialtyInputFieldProps> = ({ onFreeTextQuery }) => {
  const [, meta, helpers] = useField<StandardEvents.TransformedSpecialty>('specialty');
  const [options, setOptions] = useState<NuccFreeTextResult[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSearch = async (searchString: string) => {
    if (!searchString) return setOptions(undefined);
    setIsLoading(true);
    const res = await onFreeTextQuery({ type: FreeTextType.Nucc, freeText: searchString });
    setOptions((res?.result as NuccFreeTextResult[]) ?? []);
    setIsLoading(false);
  };

  const onChange = (v: NuccSelectOption[]) => {
    if (!v.length) return helpers.setValue({});
    return helpers.setValue({
      description: v[0].label,
      vimSpecialty: {
        id: isArray(v[0].value) ? v[0].value[0] : v[0].value,
        description: v[0].label,
      },
    });
  };

  return (
    <div className="referral-request-input-container">
      <InputStyleWrapper
        className="referral-request-select-input-style-wrapper"
        inputStyle={InputStyle.pillMedium}
        hasError={meta.touched && !!meta.error}
        rightIcon={<></>}
      >
        <div className="input">
          <Select
            label="Specialty"
            placeholder=" "
            id="cpt-input"
            name="specialty"
            labelField="label"
            valueField="value"
            optionLabelField="value"
            clearable
            closeOnSelect
            keepSelectedInList
            hideValueOnInput
            options={formatOptions(options)}
            onChange={onChange}
            showChevronIcon
            searchFn={({ state }) => {
              onSearch(state.search);
            }}
            noDataLabel={options ? NO_RESULTS : START_SEARCH}
            noInputLabel={START_SEARCH}
            noResultsLabel={NO_RESULTS}
            {...(!isEmpty(meta.value)
              ? {
                  values: [
                    {
                      label: meta.value.description,
                      value: meta.value.vimSpecialty?.id,
                    },
                  ],
                }
              : {})}
            setTouched={() => helpers.setTouched(true, false)}
            loading={isLoading}
          />
          {meta.touched && !!meta.error && (
            <InputValidationMessage>Specialty is required</InputValidationMessage>
          )}
        </div>
      </InputStyleWrapper>
    </div>
  );
};
