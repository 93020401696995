import React, { useCallback } from 'react';
import { ProductSource } from '@getvim-core-apps/organizations';
import { Pagination } from '../../pagination';
import SearchDisclaimers from '../components/SearchDisclaimers';
import { SearchResultsListTitle, SearchResultsList } from '../list';
import PreferredProvidersSearchResults from './PreferredProvidersSearchResults';
import { SearchResultsProps } from './types';
import { OutOfNetworkMessage } from '../components/OutOfNetworkMessage';
import { ModifiedDistanceSearchMessage } from '../components/ModifiedDistanceSearchMessage';
import { getOrderAssistUIString } from '../../../consts/strings';
import { OrderAssistOptionType } from '../../../types';

const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  referralViewedPayload,
  currentSearchState,
  costResultStatus,
  disclaimers,
  changePage,
  sendOutcome,
  onProviderSelect,
  preferredProviders = [],
  displayPreferredProvidersListFF = false,
  organizationHasPreferredProviders,
  getSelectionType,
  goBackAction,
  isReferralRequestRequired,
  onProviderAddressChange,
  maxSearchDistance,
  isReadonlyMode,
  useSelectedLocationOfProvider,
  source,
}) => {
  const page = results.pagination.pageNumber;
  const shouldDisplayPremiumPhysicianProgramModal =
    source === ProductSource.UnitedHealthCare &&
    results.searchResults.some((result) => result.type === OrderAssistOptionType.Provider);

  const shouldDisplayNewPatientsIcon = source === ProductSource.UnitedHealthCare;

  const onHandleChangePreviousPage = useCallback(() => {
    changePage(page - 1);
  }, [changePage, page]);

  const onHandleChangeNextPage = useCallback(() => {
    changePage(page + 1);
  }, [changePage, page]);

  return (
    <div>
      {results.isOutOfNetworkResults && <OutOfNetworkMessage goBackAction={goBackAction} />}
      {maxSearchDistance && <ModifiedDistanceSearchMessage distance={maxSearchDistance} />}
      {displayPreferredProvidersListFF && (
        <PreferredProvidersSearchResults
          currentSearchState={currentSearchState}
          costResultStatus={costResultStatus}
          sendOutcome={sendOutcome}
          onProviderSelect={onProviderSelect}
          getSelectionType={getSelectionType}
          preferredProviders={preferredProviders}
          referralViewedPayload={referralViewedPayload}
          onProviderAddressChange={onProviderAddressChange}
          isReferralRequestRequired={isReferralRequestRequired}
          isReadonlyMode={isReadonlyMode}
          organizationHasPreferredProviders={organizationHasPreferredProviders}
          useSelectedLocationOfProvider={useSelectedLocationOfProvider}
          shouldDisplayNewPatientsIcon={shouldDisplayNewPatientsIcon}
        />
      )}
      {displayPreferredProvidersListFF && <hr className="search-results-divider" />}
      <SearchResultsList
        titleElement={
          <SearchResultsListTitle
            title={
              results.isOutOfNetworkResults
                ? getOrderAssistUIString('search_results_out_of_network_results_list_title')
                : getOrderAssistUIString('search_results_list_title')
            }
            withUhcPremiumPhysicianProgram={shouldDisplayPremiumPhysicianProgramModal}
          />
        }
        results={results.searchResults}
        extraInfo={{ distanceInfo: results?.distanceInfo }}
        getSelectionType={getSelectionType}
        referralViewedPayload={referralViewedPayload}
        onProviderSelect={onProviderSelect}
        sendOutcome={sendOutcome}
        currentSearchState={currentSearchState}
        costResultStatus={costResultStatus}
        hasPreferredList={displayPreferredProvidersListFF}
        onProviderAddressChange={onProviderAddressChange}
        isReferralRequestRequired={isReferralRequestRequired}
        isReadonlyMode={isReadonlyMode}
        useSelectedLocationOfProvider={useSelectedLocationOfProvider}
        shouldDisplayNewPatientsIcon={shouldDisplayNewPatientsIcon}
      />
      {!!results.alternativeResults?.length && <hr className="search-results-divider" />}
      {!!results.alternativeResults?.length && (
        <div className="search-results__alternative-results">
          <SearchResultsList
            isAlternativeResults
            titleElement={
              <SearchResultsListTitle
                title={getOrderAssistUIString('alternative_search_suggestions_title')}
              />
            }
            results={results.alternativeResults}
            extraInfo={{ distanceInfo: results?.distanceInfo }}
            getSelectionType={getSelectionType}
            referralViewedPayload={referralViewedPayload}
            onProviderSelect={onProviderSelect}
            sendOutcome={sendOutcome}
            currentSearchState={currentSearchState}
            costResultStatus={costResultStatus}
            hasPreferredList={displayPreferredProvidersListFF}
            onProviderAddressChange={onProviderAddressChange}
            isReferralRequestRequired={isReferralRequestRequired}
            isReadonlyMode={isReadonlyMode}
            useSelectedLocationOfProvider={useSelectedLocationOfProvider}
            shouldDisplayNewPatientsIcon={shouldDisplayNewPatientsIcon}
          />
        </div>
      )}

      <div className="search-results__pagination">
        <Pagination
          currentPage={page}
          goPrevious={onHandleChangePreviousPage}
          goNext={onHandleChangeNextPage}
          goNextDisable={!results.pagination?.hasNextPage}
        />
      </div>
      <SearchDisclaimers disclaimers={disclaimers} />
    </div>
  );
};

export default SearchResults;
