import { ProductSource } from '@getvim-core-apps/organizations';
import { OrderAssistFormDataWithOptions, WidgetSource } from '../../types';
import { formatInputUhc } from '../uhc/uhcFormatter';
import { SearchInputFormatProps, SearchInputFormatter } from './types';
import { noCptIcdFormatter } from './noCptIcdFormatter';

const formatNothing: SearchInputFormatter = ({ searchFormData, options }) => ({
  ...searchFormData,
  options: {
    pagination: {
      paginationNumber: options?.pagination?.paginationNumber ?? 1,
    },
  },
});

const formatterByInsurer: { [key in WidgetSource]?: SearchInputFormatter } = {
  [ProductSource.UnitedHealthCare]: formatInputUhc,
  [ProductSource.BlueCrossBlueShield]: noCptIcdFormatter,
};

export const formatInputBySource = (
  source: WidgetSource,
  props: SearchInputFormatProps,
): OrderAssistFormDataWithOptions => {
  const formatter = formatterByInsurer[source] ?? formatNothing;
  return formatter(props);
};
