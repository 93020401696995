import {
  ModuleNames,
  useVimCommunication,
  VimCommunicationContextType,
} from '@getvim/vim-app-infra';
import { StandardEvents } from '@getvim/vim-connect';
import { WidgetVimCommunication } from '@getvim/vim-connect-communication';
import { useEffect, useState } from 'react';
import { orderAssistLogger } from '../../../../logger';
import { WidgetIncomingEvent, Order, Patient } from '../../../../types';
import { AppActionType, AppDispatch } from '../../reducers/appReducer';
import { SelectButtonCallbacks, UseSelectProviderButtonProps } from './types';

const useSelectProviderWidgetsData = ({
  vimCommunication,
  patient,
  appDispatch,
}: {
  vimCommunication: VimCommunicationContextType<WidgetVimCommunication>;
  patient: Patient | undefined;
  appDispatch: AppDispatch;
}) => {
  const [diagnosis, setDiagnosis] = useState();
  const [selectedOrders, setSelectedOrders] = useState<Order[]>();

  useEffect(() => {
    return vimCommunication?.listenToEvent(WidgetIncomingEvent.ReferralViewed, ({ data }) => {
      const { diagnosis: newDiagnosis } = data || {};
      setDiagnosis(newDiagnosis);
    });
  }, [vimCommunication]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(WidgetIncomingEvent.ReferralClosed, () => {
      setDiagnosis(undefined);
    });
  }, [vimCommunication]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(WidgetIncomingEvent.OrdersViewed, ({ data }) => {
      const patientInContext = (data as StandardEvents.OrdersViewedPayload).patient;
      if (patientInContext?.patientId && patient?.patientId === patientInContext.patientId) {
        appDispatch({
          type: AppActionType.ON_UPDATE_MEMBER_TOKEN,
          payload: { memberTokens: patientInContext.memberTokens },
        });
      }

      const { selectedOrders: newSelectedOrders } = data || {};
      setSelectedOrders(newSelectedOrders);
    });
  }, [vimCommunication, patient?.patientId, appDispatch]);

  useEffect(() => {
    return vimCommunication?.listenToEvent(WidgetIncomingEvent.OrdersClosed, () => {
      setSelectedOrders(undefined);
    });
  }, [vimCommunication]);

  return { diagnosis, selectedOrders };
};

export const useRuntimeSelectProviderButton = (
  { patient, appDispatch }: Pick<UseSelectProviderButtonProps, 'patient' | 'appDispatch'>,
  {
    onDiOrdersSelectButtonClicked,
    onLabOrdersSelectButtonClicked,
    onReferralSelectButtonClicked,
  }: SelectButtonCallbacks,
): void => {
  const { vimCommunication } = useVimCommunication<WidgetVimCommunication>();

  const { diagnosis, selectedOrders } = useSelectProviderWidgetsData({
    vimCommunication,
    appDispatch,
    patient,
  });

  useEffect(() => {
    return vimCommunication?.listenToSyncEvent(
      WidgetIncomingEvent.PopulateOrderAssist,
      async ({ senderId }) => {
        switch (senderId) {
          case ModuleNames.OrderAssistLabOrdersSelectButtonWidget: {
            onLabOrdersSelectButtonClicked(selectedOrders);
            break;
          }

          case ModuleNames.OrderAssistDIOrdersSelectButtonWidget: {
            onDiOrdersSelectButtonClicked(selectedOrders);
            break;
          }

          case ModuleNames.OrderAssistReferralsSelectButtonWidget:
            onReferralSelectButtonClicked();
            break;
          default: {
            orderAssistLogger.error('Got an unexpected senderId in select provider event', {
              senderId,
            });
            break;
          }
        }

        return await Promise.resolve();
      },
    );
  }, [
    onDiOrdersSelectButtonClicked,
    onLabOrdersSelectButtonClicked,
    diagnosis,
    selectedOrders,
    vimCommunication,
    onReferralSelectButtonClicked,
  ]);
};
