import { ButtonStyle, PushNotificationPayload } from '@getvim/internal-vim-os-sdk/types';
import { ActionButtonAction, BtnType } from '@getvim/utils-vim-connect-communication';
import { Notifications } from '@getvim/vim-connect-communication';
import { widgetId } from '../../../consts';
import { NotificationConfigMap } from '../use-notifications/types';

const text = 'Click to find <b>in-network</b> and <b>high quality</b> specialists';
const buttonText = 'Open app';
const timeoutInSec = 10;

export const getSpecialtyNotificationConfig = (
  notificationId: string,
  autoPopUp: () => void,
): NotificationConfigMap => {
  return {
    sdk: {
      ...getSdkConfig(autoPopUp),
      notificationId,
    },
    runtime: {
      ...runtimeConfig,
      notificationId,
    },
  };
};

const getSdkConfig = (autoPopUp: () => void): Omit<PushNotificationPayload, 'notificationId'> => ({
  text,
  timeoutInSec,
  actionButtons: {
    rightButton: {
      text: buttonText,
      buttonStyle: ButtonStyle.PRIMARY,
      openAppButton: true,
      callback: autoPopUp,
    },
  },
});

const runtimeConfig: Notifications.Types.NotificationShowEventPayload = {
  widgetId,
  text,
  options: { timeoutInMs: timeoutInSec * 1000 },
  actionButtons: [
    {
      text: buttonText,
      action: ActionButtonAction.OPEN_APP,
      buttonType: BtnType.TINY,
      style: {
        'margin-left': 'auto',
      },
    },
  ],
};
