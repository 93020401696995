import {
  ReferralRequestEventNames,
  LeaveConfirmationModalButtonClickedPayload,
} from '../types/referralRequestAnalyticsEvents.types';
import { referralRequestAnalyticsClient } from '../clients/referralRequest.client';

export const trackLeaveConfirmationModalViewed = (): void => {
  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.LEAVE_CONFIRMATION_MODAL_VIEWED,
    {},
  );
};

export const trackLeaveConfirmationModalClicked = (
  payload: LeaveConfirmationModalButtonClickedPayload,
): void => {
  const { buttonName } = payload;
  referralRequestAnalyticsClient.track(ReferralRequestEventNames.LEAVE_CONFIRMATION_MODAL_CLICKED, {
    buttonName,
  });
};
