import { OrderAssistOption, OrderAssistOptionType, Patient } from '../../../components/app/types';
import { capitalizeAsName } from '../../../components/app/utils/general';

export const generateMessageForZeroResults = (vimSpecialtyName: string, distance: number) => {
  return `No <b>${vimSpecialtyName}</b> specialists found within <b>${distance} miles</b>. Find other high quality in-network specialists`;
};

export const generateMessageByType: Record<
  OrderAssistOptionType,
  (provider: OrderAssistOption, patient: Patient) => string
> = {
  [OrderAssistOptionType.Provider]: (provider: OrderAssistOption, patient: Patient) => {
    const {
      details: { name, preferredProvider, highValuePerformance },
      locations: [{ distance }],
    } = provider;

    return getProviderMessage(
      name,
      distance,
      patient.demographics.firstName,
      highValuePerformance,
      preferredProvider,
    );
  },
  [OrderAssistOptionType.Facility]: (provider: OrderAssistOption, patient: Patient) => {
    const {
      details: { name, preferredProvider, highValuePerformance },
      locations: [{ distance }],
    } = provider;

    return getProviderMessage(
      name,
      distance,
      patient.demographics.firstName,
      highValuePerformance,
      preferredProvider,
    );
  },
};

const getProviderMessage = (providerName, distance, patientName, isHighValue, isPreferred) => {
  const capitalizeProviderName = capitalizeAsName(providerName);
  const capitalizePatientName = capitalizeAsName(patientName);

  if (isPreferred && isHighValue) {
    return `<b>${capitalizeProviderName}</b> is high quality, <b>in-network</b>, preferred and is ${distance} mi away from ${capitalizePatientName}`;
  }
  if (isHighValue) {
    return `<b>${capitalizeProviderName}</b> is high quality, <b>in-network</b> and is ${distance} mi away from ${capitalizePatientName}`;
  }
  if (isPreferred) {
    return `<b>${capitalizeProviderName}</b> is <b>in-network</b>, preferred and is ${distance} mi away from ${capitalizePatientName}`;
  }

  return `<b>${capitalizeProviderName}</b> is <b>in-network</b> and is ${distance} mi away from ${capitalizePatientName}`;
};
