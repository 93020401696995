import { ModuleNames, useVimCommunication } from '@getvim/vim-app-infra';
import React, { FC } from 'react';
import LightVimIcon from '../../../assets/vim-icon-light.svg';
import VimLogoLightSVG from '../../../assets/vimLogoLight.svg';
import './index.less';
import { WidgetIncomingEvent } from '../../app/types';

const VimIcon = () => (
  <>
    <img className="vim-icon small" src={LightVimIcon} alt="Vim Logo" />
    <img className="vim-icon large" src={VimLogoLightSVG} alt="Vim Logo" />
  </>
);

export const Button: FC = () => {
  const { vimCommunication } = useVimCommunication();

  const onClick = async () => {
    await vimCommunication?.sendSyncEvent(
      ModuleNames.OrderAssistWidget,
      WidgetIncomingEvent.PopulateOrderAssist,
    );
  };

  return (
    <button type="button" className="vim-button-id widget-inline-size" onClick={onClick}>
      <span className="select-with-label">Select with</span>
      <VimIcon />
    </button>
  );
};
