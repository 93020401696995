import { Button, Tooltip } from '@getvim/atomic-ui';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { object, string } from 'yup';
import { trackRequestingProviderScreenViewed } from '../../../../../analytics/referral-request';
import { requestingProviderToAnalyticsProvider } from '../../../../../analytics/referral-request/formatters';

import { OnFreeTextQueryMethod } from '../../order-assist-app/hooks';
import { getReferralRequestUIString } from '../strings';
import { RequestingProvider } from '../types';
import './RequestingProviderDetails.less';
import {
  AddressInputField,
  NameInputField,
  NpiInputField,
  SpecialtyInputField,
} from './input-fields';

interface RequestingProviderDetailsProps {
  onNext: (formValues: RequestingProvider) => void;
  onFreeTextQuery: OnFreeTextQueryMethod;
  updateIsDone: (boolean: boolean) => void;
  requestingProvider?: RequestingProvider;
}

const requestingProviderDetailsSchema = object().shape({
  npi: string().length(10, 'NPI should include 10 digits').required('NPI is required'),
  firstName: string(),
  lastName: string().required('Last name is required'),
  specialty: object().shape({
    description: string().required(),
    vimSpecialty: object().shape({
      id: string().required(),
      description: string().optional(),
    }),
  }),
  address: object().shape({
    address1: string().required(),
  }),
});

const RequestingProviderDetails: React.FC<RequestingProviderDetailsProps> = ({
  onNext,
  onFreeTextQuery,
  updateIsDone,
  requestingProvider,
}) => {
  useEffect(() => {
    trackRequestingProviderScreenViewed({
      requestingProvider: requestingProviderToAnalyticsProvider(requestingProvider),
    });
    // TODO fix this in other PR, you can fix this by sending the analytics on each page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="title no-user-select">
        {getReferralRequestUIString('referral_requesting_provider_title')}
      </div>
      <div className="search-form">
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            npi: requestingProvider?.npi || '',
            firstName: requestingProvider?.firstName || '',
            lastName: requestingProvider?.lastName || '',
            specialty: requestingProvider?.specialty || {},
            address: requestingProvider?.address || {},
          }}
          onSubmit={onNext}
          validate={(values) => updateIsDone(requestingProviderDetailsSchema.isValidSync(values))}
          validationSchema={requestingProviderDetailsSchema}
        >
          {({ isValid }) => {
            return (
              <Form>
                <NpiInputField />
                <NameInputField
                  displayLabel={getReferralRequestUIString(
                    'referral_requesting_provider_first_name_label',
                  )}
                  fieldName="firstName"
                />
                <NameInputField
                  displayLabel={getReferralRequestUIString(
                    'referral_requesting_provider_last_name_label',
                  )}
                  fieldName="lastName"
                />
                <SpecialtyInputField onFreeTextQuery={onFreeTextQuery} />
                <AddressInputField />
                <div className="requesting-provider-form-buttons">
                  <Tooltip
                    bgColor="light"
                    pillStyle
                    placement="top"
                    tooltipContent="Please fill all the required fields"
                    hideTooltip={isValid}
                    className="tooltip"
                  >
                    <Button
                      text="Next"
                      buttonType="small"
                      width="smallMedium"
                      type="submit"
                      disabled={!isValid}
                    />
                  </Tooltip>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default RequestingProviderDetails;
