import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '../../atoms';
import Text from '../../atoms/text';
import Tooltip from '../../atoms/tooltip';
import { AddressItem, AddressPayload } from '../../types/AddressPayload';
import { SizeEnum, AlignEnum, ColorNameEnum, WeightEnum } from '../../types/enums';
import AddressContainer from './AddressContainer';
import './index.less';
import PreferredIcon from '../../atoms/svgs/preferredProvider';

export type MiniResultCardProps = {
  topSectionMainValue?: string;
  topSectionSecondaryValue?: string;
  topSectionTooltip?: string;
  name: string;
  secondaryName?: string;
  specialties: string[];
  addresses: AddressItem[];
  badges?: any;
  benefits?: any;
  selectButtonDisabled?: boolean;
  selectButtonTooltip?: string;
  onSelectButtonClicked: (selectedAddress: AddressItem) => void;
  selectButtonText: string;
  onAddressChange?: (address) => void;
  preferredProvider?: boolean;
  isPreferredResult: boolean;
  preferredBadge?: JSX.Element;
  selectedLocationIndex?: number;
  shouldDisplayNewPatientsIcon: boolean;
};

function MiniResultCard({
  topSectionMainValue,
  topSectionSecondaryValue,
  topSectionTooltip,
  name,
  secondaryName,
  specialties,
  addresses,
  badges,
  selectButtonDisabled = false,
  selectButtonTooltip,
  onSelectButtonClicked,
  selectButtonText = 'Select',
  onAddressChange,
  isPreferredResult,
  preferredBadge,
  selectedLocationIndex,
  shouldDisplayNewPatientsIcon,
}: MiniResultCardProps) {
  const [selectedAddress, setSelectedAddress] = useState(
    selectedLocationIndex ? addresses[selectedLocationIndex] : addresses[0],
  );
  const addressPayload: AddressPayload = {
    items: addresses,
    selectedAddress,
    value: '',
    onChange: (newAddress) => {
      setSelectedAddress(newAddress);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onAddressChange && onAddressChange(newAddress);
    },
  };

  return (
    <div>
      <div
        className={classNames('mini-result-card', {
          'preferred-provider-card': isPreferredResult,
        })}
      >
        {isPreferredResult &&
          (preferredBadge || (
            <div className="preferred-provider-card__header">
              <Text
                className="preferred-provider-card__header__title"
                text="Preferred provider"
                size={SizeEnum['12px']}
              />
              <div className="preferred-provider-card__header__icon">
                <PreferredIcon />
              </div>
            </div>
          ))}
        <div className="card-body">
          {topSectionMainValue && (
            <div className="card-top-section">
              <Text
                weight={WeightEnum.bold}
                size={SizeEnum['14px']}
                align={AlignEnum.left}
                colorName={ColorNameEnum.ultraDark}
                text={topSectionMainValue}
              />
              {topSectionSecondaryValue && (
                <>
                  <Text
                    size={SizeEnum['12px']}
                    align={AlignEnum.left}
                    colorName={ColorNameEnum.ultraDark}
                    text={topSectionSecondaryValue}
                    inline
                  />
                  {topSectionTooltip && (
                    <Tooltip
                      bgColor="light"
                      tooltipContent={topSectionTooltip}
                      className="card-top-info-tooltip"
                    >
                      <Text
                        className="card-top-info-icon"
                        size={SizeEnum['12px']}
                        align={AlignEnum.left}
                        colorName={ColorNameEnum.theme}
                        inline
                      >
                        <i className="icon-help" />
                      </Text>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          )}

          <div className="card-main-section">
            <Text
              weight={WeightEnum.bold}
              size={SizeEnum['14px']}
              align={AlignEnum.left}
              colorName={ColorNameEnum.ultraDark}
              text={name ?? ''}
            />
            {secondaryName && (
              <Text
                size={SizeEnum['14px']}
                align={AlignEnum.left}
                colorName={ColorNameEnum.ultraDark}
                text={secondaryName}
              />
            )}

            <Text
              size={SizeEnum['12px']}
              align={AlignEnum.left}
              colorName={ColorNameEnum.ultraDark}
            >
              {specialties?.join(', ') ?? ''}
            </Text>

            <AddressContainer
              address={addressPayload}
              shouldDisplayNewPatientsIcon={shouldDisplayNewPatientsIcon}
            />
            <div className="result-bottom-section">
              <div className="action-button">
                <Tooltip
                  tooltipContent={selectButtonTooltip}
                  hideTooltip={!selectButtonTooltip}
                  placement="top"
                  className="select-button-tooltip-disabled"
                  bgColor="light"
                >
                  <Button
                    text={selectButtonText}
                    buttonType="tiny"
                    width="small"
                    disabled={selectButtonDisabled}
                    onClick={() => onSelectButtonClicked(selectedAddress)}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          {badges?.length > 0 && <div className="result-badges">{badges}</div>}
        </div>
      </div>
    </div>
  );
}

export default MiniResultCard;
