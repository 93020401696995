import { logger } from '@getvim/vim-connect-logger';
import { internalApi } from '../../../../../internal-api';
import type { EncodedPDFFile, OrderAssistResultsPDFInput, WidgetSource } from '../../../types';
import { wrapWithExecutionLogger } from '../../../utils/wrapWithExecutionLogger';
import base64ToBlob from '../../../../../utils/base64ToBlob';
import downloadBlob from '../../../utils/downloadBlob';
import { ReferralRequestPDFInput } from '../../../components/referral-request/types';

const createPDFLogger = logger.scope('Create PDF');

export const downloadPDF = async (
  getEncodedFile: Promise<EncodedPDFFile | undefined>,
  filename: string,
): Promise<boolean> => {
  try {
    const encodedFile = await wrapWithExecutionLogger('generate pdf file', getEncodedFile);
    if (!encodedFile) {
      throw new Error('error sending graphql mutation of createPDF');
    }
    createPDFLogger.info('finished generating pdf - downloading blob...', { filename });
    const blob = base64ToBlob(encodedFile.encoded_string, 'application/pdf');
    downloadBlob(blob, filename);

    createPDFLogger.info('successfully downloaded pdf file', { filename });
    return true;
  } catch (error) {
    createPDFLogger.error('error creating pdf', { error });
    return false;
  }
};

export const downloadResultsPDF = async (
  input: OrderAssistResultsPDFInput,
  filename: string,
): Promise<boolean> => {
  createPDFLogger.info('sending request to generate results pdf file...', { input });
  return await downloadPDF(internalApi.orderAssistBff.createResultsPdf(input), filename);
};

export const downloadReferralRequestPDF = async (
  input: ReferralRequestPDFInput,
  filename: string,
  source?: WidgetSource,
): Promise<boolean> => {
  if (!source) {
    createPDFLogger.error('download referral request PDF failed - missing source', { source });
    return false;
  }
  createPDFLogger.info('sending request to generate referral request pdf file...', { input });
  return await downloadPDF(
    internalApi.orderAssistBff.createReferralRequestPdf(input, source),
    filename,
  );
};
