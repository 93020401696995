import React from 'react';
import NoResultsFound from '../../../../../assets/no-results-found.svg';
import ImageMessageView from './ImageMessageView';

interface Props {
  onNewSearch?: () => void;
  searchDistance: number;
  patientName: string;
  zipCode: string;
}

const NoResultsWithDistance: React.FC<Props> = ({
  onNewSearch,
  searchDistance,
  patientName,
  zipCode,
}) => {
  return (
    <ImageMessageView
      imageSrc={NoResultsFound}
      title={`No results found within ${searchDistance} miles of ${patientName}'s ZIP code: ${zipCode}`}
      details="Please try adjusting your search criteria to find what you're looking for"
      buttonProps={onNewSearch ? { text: 'New Search', onClick: onNewSearch } : undefined}
    />
  );
};

export default NoResultsWithDistance;
