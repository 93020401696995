import classNames from 'classnames';
import React, { useMemo } from 'react';

import {
  AnalyticsHover,
  ColorNameEnum,
  SizeEnum,
  Text,
  Tooltip,
  WeightEnum,
} from '@getvim/atomic-ui';
import { trackPreferredProvidersInformationTooltipHovered } from '../../../../../analytics';
import { analyticsManager } from '../../../../../analytics/analyticsManager';
import { preferredProvidersTexts } from '../../../consts/preferredProviderStrings';
import URLS from '../../../consts/urls';
import { PreferredProvidersState } from '../../../types';
import { UhcPremiumPhysicianProgramModal } from '../../modals/UhcPremiumPhysicianProgamModal';
import './ListTitle.less';

export interface ListTitleProps {
  title: string;
  tooltip?: string;
  count?: number;
  withUhcPremiumPhysicianProgram?: boolean;
}

const SearchResultsListTitle: React.FC<ListTitleProps> = ({
  title,
  tooltip,
  count,
  withUhcPremiumPhysicianProgram,
}) => (
  <div
    className={classNames('search-results__item-title-container', count === 0 ? 'no-results' : '')}
  >
    <div className={classNames('search-results__item-title')}>
      <Text
        inline
        weight={WeightEnum.bold}
        colorName={ColorNameEnum.ultraDark}
        size={SizeEnum['12px']}
      >
        {title}
        {!!count && <span className="search-results__item-title__count">({count})</span>}
      </Text>
      {tooltip && (
        <Tooltip tooltipContent={tooltip} bgColor="light" placement="bottom" width="large">
          <Text size={SizeEnum['12px']} colorName={ColorNameEnum.ultraDark} className="help-icon">
            <AnalyticsHover
              // eslint-disable-next-line react/jsx-no-bind
              onHoverAction={() => trackPreferredProvidersInformationTooltipHovered(tooltip)}
            >
              <i className="icon-help i-va-fix-2" />
            </AnalyticsHover>
          </Text>
        </Tooltip>
      )}
    </div>
    {withUhcPremiumPhysicianProgram && <UhcPremiumPhysicianProgramModal />}
  </div>
);

const learnMoreOnClick = () => {
  analyticsManager.track('preferred_providers_guide_clicked', {});
};

export const PreferredProviderListTitle: React.FC<{
  count?: number;
  organizationHasPreferredProviders?: boolean;
}> = ({ count = 0, organizationHasPreferredProviders = false }) => {
  const titleState: PreferredProvidersState = useMemo(() => {
    if (!organizationHasPreferredProviders) return PreferredProvidersState.NOT_CONFIGURED;
    if (count) return PreferredProvidersState.MATCHING_PROVIDERS;
    return PreferredProvidersState.NOT_FOUND;
  }, [count, organizationHasPreferredProviders]);

  return (
    <div className="preferred-providers-list__container">
      <SearchResultsListTitle
        title={preferredProvidersTexts[titleState].title}
        tooltip={preferredProvidersTexts[titleState].tooltip}
        count={count}
      />
      {titleState === PreferredProvidersState.NOT_CONFIGURED && (
        <Text
          className="preferred-providers-list__button"
          underline
          weight={WeightEnum.semibold}
          colorName={ColorNameEnum.ultraDark}
          size={SizeEnum['12px']}
        >
          <a
            href={URLS.preferredProvidersInstructions}
            target="_blank"
            rel="noopener noreferrer"
            onClick={learnMoreOnClick}
          >
            Learn how
          </a>
        </Text>
      )}
    </div>
  );
};

export default SearchResultsListTitle;
