import { ProductSource } from '@getvim-core-apps/organizations';
import { Toast } from '@getvim/atomic-ui';
import { useFlowState } from '@getvim/flow-context-provider';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { analyticsManager } from '../../../../analytics/analyticsManager';
import { UserConfig, useSearchNotifications, useSpecialtyNotifications } from '../../../../hooks';
import { calculateSource, useSourceConfig } from '../../../../hooks/useSourceConfig';

import { extractMemberTokenForSource } from '../../../../utils/memberToken';
import { orderAssistLogger } from '../../logger';
import { fetchIsReferralRequestRequired } from '../../logic/actions';
import { getSearchFormValidator } from '../../source/form/searchForm';
import { sendOutcomeBySource } from '../../source/outcome';
import {
  DataFilters,
  FreeTextType,
  HandleNotificationActionProps,
  NuccFreeTextResult,
  OrderAssistFormData,
  OrderAssistFormInputs,
  QuickSpecialtySearchParams,
  SetAddressChangePayload,
  WriteBackProvider,
} from '../../types';

import { DEFAULT_MAPPING } from '../../../../utils/sourceMapping';
import { SourceConfigWrapper } from '../SourceConfigWrapper';
import { SearchForm } from '../search-form';
import { useSearchFormValidations } from '../search-form/hooks';
import { SearchFormValidator } from '../search-form/types';
import { SearchResultsContainer } from '../search-results';
import {
  useEventHandlers,
  useFreeText,
  useGoogleApi,
  useProviderSelection,
  useReferralViewedAnalytics,
  useSearch,
  useSearchFormDirtiness,
} from './hooks';
import { usePopulateOrderAssist } from './hooks/usePopulateOrderAssist';
import { useSearchViewed } from './hooks/useSearchViewed';
import { useSelectProviderButton } from './hooks/use-select-provider-button';
import './order-assist-app.less';
import { AppActionType, appReducer } from './reducers/appReducer';
import { SearchActionType, defaultState, searchReducer } from './reducers/searchReducer';
import { isEmpty } from 'lodash-es';
import { useIsAppOpen } from '../../../../hooks/useIsAppOpen';
import { useOrderAssistAppFeatureFlags } from './OrderAssistFFWrapper';

const { ToastContainer, Slide } = Toast;

export const OrderAssistApp: React.FC = () => {
  const { userConfig } = useFlowState<{
    userConfig: UserConfig;
  }>();

  const [
    {
      patient,
      isGoogleApiLoaded,
      referral,
      order,
      isReferralRequestRequired,
      lastUpdatedReferralId,
    },
    appDispatch,
  ] = useReducer(appReducer, { isGoogleApiLoaded: false });

  const {
    shouldUseAdapterConfigFF,
    useAppGlobalState,
    useUserExternalIdForSearchIds,
    useDiQuickSpecialtyFF,
    useReferralQuickSpecialtyFF,
    shouldFloridaBlueSupportFacility,
    isReadonlyMode,
  } = useOrderAssistAppFeatureFlags();

  const isAppOpen = useIsAppOpen();
  const searchViewed = useSearchViewed();

  useEffect(() => {
    if (isAppOpen) {
      searchViewed();
    }
  }, [isAppOpen, searchViewed]);

  const updateReferralAllowed = useMemo(() => {
    const adapterSupportsWriteback =
      !shouldUseAdapterConfigFF ||
      !!userConfig.adapterConfig?.vimConnectCapabilities?.supportsUpdateReferral;

    const isInReferral = !!referral?.vimReferralId;

    return isInReferral && adapterSupportsWriteback;
  }, [
    referral?.vimReferralId,
    shouldUseAdapterConfigFF,
    userConfig.adapterConfig?.vimConnectCapabilities?.supportsUpdateReferral,
  ]);

  const [
    {
      formData,
      currentSearchInput,
      isCostAvailable,
      lastProviderCacheId,
      filtersData,
      searchQuery,
      benefits,
      disclaimers,
      icdQuery,
      cptQuery,
      specialtyQuery,
      preferredProviders,
      displayPreferredProvidersListFF,
      organizationHasPreferredProviders,
      selectedProvider,
      formDataMetadata,
    },
    searchDispatch,
  ] = useReducer(searchReducer, defaultState);

  const { source, setSourceConfigParams, patientSourceConfig } = useSourceConfig(
    shouldFloridaBlueSupportFacility,
  );

  const {
    onProviderSelectedWithVim,
    onProviderSelectedWithVimFailed,
    onReferralViewedAnalytics,
    onReferralClosedAnalytics,
  } = useReferralViewedAnalytics();

  const onFreeTextQueryDetached = useFreeText({
    source, // patch for icd and cpt, data not supporting free text
    sourceConfig: patientSourceConfig.sourceConfig,
  });

  const onSelectProvider = useCallback(
    async (provider: WriteBackProvider, isReferralUpdated: boolean) => {
      const providerSpecialty = provider.specialty?.[0];

      if (isReferralUpdated && referral?.vimReferralId) {
        appDispatch({
          type: AppActionType.SET_LAST_UPDATED_REFERRAL_ID,
          payload: referral.vimReferralId,
        });
      }

      if (!providerSpecialty) {
        orderAssistLogger.error('Provider does not have specialty', { provider });
      }

      const specialtiesResult = providerSpecialty?.description
        ? ((
            await onFreeTextQueryDetached({
              type: FreeTextType.Nucc,
              freeText: providerSpecialty.description,
            })
          )?.result as NuccFreeTextResult[])
        : undefined;

      const specailty = specialtiesResult?.[0];

      searchDispatch({
        type: SearchActionType.SET_SELECTED_PROVIDER,
        payload: {
          ...provider,
          nativeSpecialties: provider.specialty,
          specialty: [
            {
              description: specailty?.description,
              vimSpecialty: {
                id: specailty?.id ?? 'no-specialty',
                description: specailty?.description ?? 'no-specialty',
              },
            },
          ],
        },
      });
    },
    [onFreeTextQueryDetached, referral?.vimReferralId],
  );

  useEffect(() => {
    analyticsManager.setDatasource(source);
  }, [source]);

  useEffect(() => {
    if (!patient?.memberTokens || !patientSourceConfig.sourceConfig) return;

    const setIsReferralRequestRequired = async () => {
      if (patientSourceConfig.sourceConfig?.supportReferralRequest) {
        const memberToken = extractMemberTokenForSource(
          patient.memberTokens,
          ProductSource.BlueCrossBlueShield,
        );
        const referralRequestRequired = await fetchIsReferralRequestRequired(memberToken, source);
        appDispatch({
          type: AppActionType.SET_IS_REFERRAL_REQUEST_REQUIRED,
          payload: referralRequestRequired,
        });

        orderAssistLogger.info('Fetched isReferralRequestRequired', {
          memberToken,
          source,
          referralRequestRequired,
        });
      }
    };

    setIsReferralRequestRequired();
  }, [patient?.memberTokens, source, patientSourceConfig.sourceConfig]);

  useEffect(() => {
    if (userConfig.product) {
      const calculatedSource = calculateSource(patient, userConfig);
      setSourceConfigParams(calculatedSource, patient);
    }
  }, [patient, setSourceConfigParams, userConfig]);

  const onFormDataClear = useCallback(
    () => searchDispatch({ type: SearchActionType.RESET_STATE }),
    [],
  );

  const onFormDataChange = useCallback(
    (payload: Partial<OrderAssistFormData>) =>
      searchDispatch({
        type: SearchActionType.UPDATE_FORM_DATA,
        payload,
      }),
    [],
  );

  const {
    results: searchResults,
    isLoading: isSearchLoading,
    isModifiedSearch,
    shouldModifySearch,
    hasError: isSearchError,
  } = searchQuery || {};

  const showResults = useMemo<boolean>(
    () => !!(searchResults || isSearchLoading || isSearchError),
    [searchResults, isSearchLoading, isSearchError],
  );

  const sendOutcomeFn = useCallback(
    (props) => sendOutcomeBySource(source, { ...props, patient }),
    [patient, source],
  );

  const sendOutcomeProps = useMemo(() => {
    return {
      sendOutcomeFn,
      extraInfo: { distanceInfo: searchResults?.distanceInfo },
    };
  }, [sendOutcomeFn, searchResults?.distanceInfo]);

  const { select: providerSelectionAction, getSelectionType } = useProviderSelection({
    updateReferralAllowed,
    onSelectionFinally: onSelectProvider,
    onWriteBackStart: onProviderSelectedWithVim,
    onWriteBackFailed: onProviderSelectedWithVimFailed,
    referralViewedPayload: referral,
    isReferralRequestRequired,
    searchFormData: currentSearchInput,
    sendOutcomeProps,
  });

  const backToSearch = useCallback(() => {
    searchDispatch({ type: SearchActionType.SET_SELECTED_PROVIDER, payload: undefined });
    searchDispatch({ type: SearchActionType.RESET_SEARCH_RESULTS });
    searchDispatch({
      type: SearchActionType.ON_SEARCH_FILTERS_SAVE,
      payload: {
        filtersData: { resultsFilters: filtersData?.userFilters },
      },
    });
  }, [filtersData]);

  const {
    notifyProviderResult,
    hideNotification: hideSearchNotification,
    handleNotificationAction: handleSearchNotificationActions,
  } = useSearchNotifications({
    providerSelectionAction,
    searchResults,
    preferredProviders,
    backToSearch,
    isReadonlyMode,
  });

  const {
    notify: notifyFailedSpecialtyMapping,
    hide: hideSpecialtyMappingNotification,
    handleNotificationAction: handleSpecialtyNotificationActions,
  } = useSpecialtyNotifications();

  useEffect(() => {
    if (!referral?.vimReferralId && !order?.id) {
      hideSearchNotification();
      hideSpecialtyMappingNotification();
    }
  }, [
    referral?.vimReferralId,
    order?.id,
    hideSearchNotification,
    hideSpecialtyMappingNotification,
  ]);
  const { inputsDirtiness, setAllDirty, resetDirtiness } = useSearchFormDirtiness(formData);

  const onFreeTextQuery = useFreeText(
    {
      source,
      sourceConfig: patientSourceConfig.sourceConfig,
    },
    {
      onEmpty: () => searchDispatch({ type: SearchActionType.RESET_SELECT_OPTIONS }),
      onStart: (payload) =>
        searchDispatch({
          type: SearchActionType.ON_FREE_TEXT_START,
          payload: { freeTextType: payload.type },
        }),
      onFinish: (response) =>
        searchDispatch({
          type: SearchActionType.ON_FREE_TEXT_FINISH,
          payload: {
            freeTextType: response.type,
            results: response.result,
            isError: response.result === undefined,
          },
        }),
    },
  );

  const userExternalId = useMemo(
    () => (useUserExternalIdForSearchIds ? userConfig.vimConnectUser?.externalId : undefined),
    [useUserExternalIdForSearchIds, userConfig],
  );

  const onSearch = useSearch({
    searchDispatch,
    patient,
    isCostAvailable,
    lastProviderCacheId,
    userConfig,
    source,
    referringProviderNpi: referral?.referringProvider?.npi,
    vimReferralId: referral?.vimReferralId,
    isWiderDistanceAvailable:
      patientSourceConfig.sourceConfig?.supportWiderDistanceFilter ??
      DEFAULT_MAPPING.supportWiderDistanceFilter,
    defaultDistanceFilter:
      patientSourceConfig.sourceConfig?.defaultDistanceFilter ??
      DEFAULT_MAPPING.defaultDistanceFilter,
    notifyResult: notifyProviderResult,
    onSearchStart: hideSearchNotification,
    userExternalId,
    formDataMetadata,
    useAppGlobalState,
  });

  useGoogleApi({ appDispatch });

  const searchFormValidator: SearchFormValidator = getSearchFormValidator(source);

  const { onPopulateOrderAssist } = usePopulateOrderAssist({
    searchDispatch,
    formData,
    filtersData,
    onSearch,
    searchFormValidator,
    setAllDirty,
    sourceConfig: patientSourceConfig.sourceConfig,
    referral,
    source,
    patient,
    organizationId: userConfig.organization?.id,
    onFailedSpecialtyMapping: notifyFailedSpecialtyMapping,
  });

  useSelectProviderButton({
    searchDispatch,
    onPopulateOrderAssist,
    patient,
    appDispatch,
    supportOrdersButton: !!patientSourceConfig.sourceConfig?.supportOrdersButton,
  });

  const handleNotificationAction = useCallback(
    (payload: HandleNotificationActionProps) => {
      handleSpecialtyNotificationActions(payload);
      handleSearchNotificationActions(payload);
    },
    [handleSpecialtyNotificationActions, handleSearchNotificationActions],
  );

  useEventHandlers({
    appDispatch,
    searchDispatch,
    onReferralViewedAnalytics,
    onReferralClosedAnalytics,
    onPopulateOrderAssist,
    userConfig,
    patient,
    referral,
    order,
    patientSourceConfig,
    organizationId: userConfig.organization?.id,
    lastUpdatedReferralId,
    handleNotificationAction,
    useAppGlobalState,
  });

  const onFiltersChanged = useCallback((newFilters: DataFilters) => {
    const { resultsFilters, userFilters } = newFilters;
    if (!resultsFilters && !userFilters) return;

    searchDispatch({
      type: SearchActionType.RESET_SEARCH_RESULTS,
    });
    searchDispatch({
      type: SearchActionType.ON_SEARCH_FILTERS_SAVE,
      payload: {
        filtersData: {
          ...(resultsFilters && { resultsFilters }),
          ...(userFilters && { userFilters }),
        },
      },
    });
  }, []);

  const { validateSearchForm } = useSearchFormValidations(formData, searchFormValidator);
  const onQuickSpecialtyChange = useCallback(
    (shouldTriggerSearch?: boolean) => {
      return (quickSpecialty: QuickSpecialtySearchParams) => {
        searchDispatch({
          type: SearchActionType.ON_QUICK_SPECIALTY_CHANGE,
          payload: quickSpecialty,
        });

        if (shouldTriggerSearch) {
          searchDispatch({
            type: SearchActionType.RESET_SEARCH_RESULTS,
          });

          const newFormData: OrderAssistFormInputs = { ...formData, ...quickSpecialty };
          setAllDirty();
          const hasErrors = validateSearchForm(newFormData);
          if (hasErrors) return;
          if (isEmpty(formData)) {
            orderAssistLogger.debug('formData is empty. skipping search');
            return;
          }
          onSearch({
            searchFormData: newFormData,
            filtersData,
            preferredProviders: { fetchPreferredProviders: true },
          });
        }
      };
    },
    [filtersData, formData, onSearch, setAllDirty, validateSearchForm],
  );

  const onProviderAddressChange = useCallback((payload: SetAddressChangePayload) => {
    searchDispatch({
      type: SearchActionType.SET_SELECTED_ADDRESS,
      payload,
    });
  }, []);

  const resetSearchForm = useCallback(() => {
    searchDispatch({
      type: SearchActionType.RESET_FORM_CONDITIONAL_PARAMS,
    });
  }, []);

  const backToResultsScreen = useCallback(() => {
    searchDispatch({ type: SearchActionType.SET_SELECTED_PROVIDER, payload: undefined });
  }, []);

  const sendOutcomeCallback = useCallback(
    (props) => {
      sendOutcomeBySource(source, {
        ...props,
        patient,
      });
    },
    [patient, source],
  );

  return (
    <SourceConfigWrapper config={patientSourceConfig.sourceConfig}>
      <div className="order-assist-app">
        {!showResults ? (
          <SearchForm
            formData={formData}
            onFormDataChange={onFormDataChange}
            onFormDataClear={onFormDataClear}
            filtersData={filtersData}
            onFiltersChanged={onFiltersChanged}
            useDiQuickSpecialtyFF={useDiQuickSpecialtyFF}
            useReferralQuickSpecialtyFF={useReferralQuickSpecialtyFF}
            onQuickSpecialtyChange={onQuickSpecialtyChange(true)}
            onSearch={onSearch}
            patient={patient}
            isGoogleApiLoaded={isGoogleApiLoaded}
            onFreeTextQuery={onFreeTextQuery}
            icdQuery={icdQuery}
            cptQuery={cptQuery}
            specialtyQuery={specialtyQuery}
            searchFormValidator={searchFormValidator}
            inputsDirtiness={inputsDirtiness}
            setAllDirty={setAllDirty}
            source={source}
            resetDirtiness={resetDirtiness}
          />
        ) : (
          <SearchResultsContainer
            results={searchResults}
            benefits={benefits}
            disclaimers={disclaimers}
            costAvailable={isCostAvailable}
            searchFormData={currentSearchInput}
            filtersData={filtersData}
            onFiltersChanged={onFiltersChanged}
            formData={formData}
            useDiQuickSpecialtyFF={useDiQuickSpecialtyFF}
            useReferralQuickSpecialtyFF={useReferralQuickSpecialtyFF}
            onQuickSpecialtyChange={onQuickSpecialtyChange(true)}
            onSearch={onSearch}
            onFreeTextQuery={onFreeTextQueryDetached}
            backToResultsScreen={backToResultsScreen}
            backToSearch={backToSearch}
            resetSearchForm={resetSearchForm}
            loading={!!isSearchLoading}
            resultsError={!!isSearchError}
            referralViewedPayload={referral}
            onProviderSelect={providerSelectionAction}
            onProviderAddressChange={onProviderAddressChange}
            getSelectionType={getSelectionType}
            sendOutcome={sendOutcomeCallback}
            patient={patient}
            preferredProviders={preferredProviders}
            displayPreferredProvidersListFF={displayPreferredProvidersListFF}
            organizationHasPreferredProviders={organizationHasPreferredProviders}
            selectedProvider={selectedProvider}
            source={source}
            isReferralRequestRequired={isReferralRequestRequired}
            isModifiedSearch={isModifiedSearch}
            shouldModifySearch={shouldModifySearch}
            isReadonlyMode={isReadonlyMode}
            shouldUpdateReferral={updateReferralAllowed}
          />
        )}
        <ToastContainer
          className=""
          position="bottom-right"
          hideProgressBar
          autoClose={3000}
          pauseOnFocusLoss={false}
          transition={Slide}
        />
      </div>
    </SourceConfigWrapper>
  );
};
