import React, { useCallback, useMemo } from 'react';
import { FlowContextWrapper } from '@getvim/flow-context-provider';
import { Loader } from '@getvim/atomic-ui';
import { OrderAssistApp } from './OrderAssistApp';
import { useUserConfig, UserConfig } from '../../../../hooks';
import { orderAssistLogger } from '../../logger';
import { demoAccessToken, demoUserConfig } from '../../consts/demoMocks';
import { useOrderAssistAccessToken } from './hooks';
import { OrderAssistFeatureFlgasWrapper } from './OrderAssistFFWrapper';

export interface AppRequiredState {
  accessToken: string;
  userConfig: UserConfig;
}

export const OrderAssistAppWrapper: React.FC = () => {
  const userConfig = useUserConfig();
  const [stateAccessToken] = useOrderAssistAccessToken();

  const demoMode = window.$vim_environment.demo_mode;

  const state = useMemo(() => {
    return {
      userConfig: demoMode ? Promise.resolve(demoUserConfig) : Promise.resolve(userConfig),
      accessToken: demoMode ? Promise.resolve(demoAccessToken) : Promise.resolve(stateAccessToken),
    };
  }, [userConfig, stateAccessToken, demoMode]);

  const onError = useCallback((error) => {
    orderAssistLogger.error('Failed to fetch state in OrderAssistAppWrapper!', { error });
  }, []);

  return (
    <FlowContextWrapper loader={<Loader type="dots" />} state={state} onError={onError}>
      <OrderAssistFeatureFlgasWrapper>
        <OrderAssistApp />
      </OrderAssistFeatureFlgasWrapper>
    </FlowContextWrapper>
  );
};
