import { Insurer } from '@getvim-os/standard';
import {
  SearchFilters,
  OrderAssistFormData,
  Patient,
  QueryResults,
  OrderAssistFormErrors,
  FreeTextQueryPayload,
  OrderAssistFormInputs,
  FreeTextResult,
  NuccFreeTextResult,
  DataFilters,
  WidgetSource,
  QuickSpecialtySearchParams,
} from '../../types';

export interface SearchFormProps {
  searchFilters: SearchFilters | undefined;
  patient: Patient | undefined;
  isGoogleApiLoaded: boolean;
  icdQuery?: QueryResults<FreeTextResult[]>;
  cptQuery?: QueryResults<FreeTextResult[]>;
  specialtyQuery?: QueryResults<NuccFreeTextResult[]>;
  onFreeTextQuery: (payload: FreeTextQueryPayload, insurer?: Insurer) => void;
  onFiltersChanged: (newFilters: DataFilters) => void;
  onQuickSpecialtyChange: (quickSpecialty: QuickSpecialtySearchParams) => void;
  useDiQuickSpecialtyFF: boolean;
  useReferralQuickSpecialtyFF: boolean;
  isClearEnabled: boolean;
  onFormDataChange: (input: Partial<OrderAssistFormData>) => void;
  formData: OrderAssistFormData;
  validationErrors: OrderAssistFormErrors;
  clearSearch: () => void;
  search: () => void;
  inputsDirtiness: { [key in keyof OrderAssistFormInputs]?: boolean };
  source: WidgetSource | undefined;
  resetDirtiness: (key: keyof OrderAssistFormInputs) => void;
}

export interface SearchFormPropsValidation {
  formData: Partial<OrderAssistFormData>;
}

export type SearchFormValidation = (
  props: SearchFormPropsValidation,
) => [boolean, OrderAssistFormErrors];
export type AdvancedSearchValidation = (props: Partial<OrderAssistFormData>) => boolean;

export interface SearchFormValidator {
  validator: SearchFormValidation;
  isAdvancedSearchValid?: AdvancedSearchValidation;
}

export enum QuickSpecialtyTypes {
  DI = 'DI',
  REFERRAL = 'REFERRAL',
}
