import { useCallback, useEffect, useState } from 'react';

import { OrderAssistFormData, OrderAssistFormErrors } from '../../../types';
import { SearchFormValidator } from '../types';

export interface UseSearchFormValidations {
  validationErrors: OrderAssistFormErrors;
  validateSearchForm: (formData?: OrderAssistFormData) => boolean;
}

export const useSearchFormValidations = (
  formData: OrderAssistFormData,
  searchFormValidator: SearchFormValidator,
): UseSearchFormValidations => {
  const [validationErrors, setValidationErrors] = useState<OrderAssistFormErrors>({});

  const validateSearchForm = useCallback(
    (newFormData?: OrderAssistFormData) => {
      const [hasErrors, errors] = searchFormValidator.validator({
        formData: newFormData || formData,
      });
      setValidationErrors(errors);

      return hasErrors;
    },
    [formData, searchFormValidator],
  );

  useEffect(() => {
    validateSearchForm();
  }, [formData, validateSearchForm]);

  return { validationErrors, validateSearchForm };
};
