import React from 'react';
import { useField } from 'formik';
import { InputStyle, InputStyleWrapper, Select, InputValidationMessage } from '@getvim/atomic-ui';
import { SelectOption } from '../../../../../../types';
import { quantityTypeMapping } from '../../../consts';
import { getReferralRequestUIString } from '../../../../strings';

interface QuantityTypeInputFieldProps {
  itemIndex: number;
}

const quantityTypeOptions = Object.keys(quantityTypeMapping).map((key) => ({
  label: quantityTypeMapping[key],
  value: key,
}));

export const QuantityTypeInputField: React.FC<QuantityTypeInputFieldProps> = ({ itemIndex }) => {
  const [, meta, helpers] = useField(`procedures[${itemIndex}].quantity.type`);

  const onChange = ([option]: [SelectOption]) => {
    helpers.setValue(parseInt(option?.value, 10));
  };

  return (
    <div className="referral-request-input-container no-margin-bottom" style={{ width: '150px' }}>
      <InputStyleWrapper
        className="referral-request-select-input-style-wrapper"
        inputStyle={InputStyle.pillMedium}
        hasError={meta.touched && !!meta.error}
        rightIcon={<></>}
      >
        <div className="input ">
          <Select
            searchable={false}
            label={getReferralRequestUIString('referral_quantity_type_label')}
            placeholder=""
            id="quantity-select-input"
            name="quantity-select"
            labelField="label"
            valueField="value"
            optionLabelField="value"
            keepSelectedInList
            options={quantityTypeOptions}
            onChange={onChange}
            showChevronIcon
            closeOnSelect
            setTouched={() => helpers.setTouched(true, false)}
            {...(meta.value !== undefined
              ? { values: [{ label: quantityTypeMapping[meta.value], value: meta.value }] }
              : {})}
          />
          {meta.touched && !!meta.error && (
            <InputValidationMessage>
              {getReferralRequestUIString('referral_quantity_type_missing_label')}
            </InputValidationMessage>
          )}
        </div>
      </InputStyleWrapper>
    </div>
  );
};
