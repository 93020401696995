export enum FreeTextType {
  Icd = 'icd',
  Cpt = 'cpt',
  Nucc = 'nucc',
}

export interface FreeTextQueryPayload {
  type: FreeTextType;
  freeText: string;
}

export interface FreeTextQueryInput {
  freeText: string;
  types: FreeTextType[];
}

export interface SearchItem {
  code: string;
  description: string;
}

export interface NuccSearchItem {
  id: string;
  description: string;
}

export interface QueryFreeTextResult {
  nucc: NuccSearchItem[];
  cpt: SearchItem[];
  icd: SearchItem[];
}

export type FreeTextEventResult =
  | {
      result?: FreeTextResult[];
      type: FreeTextType.Icd | FreeTextType.Cpt;
    }
  | {
      result?: NuccFreeTextResult[];
      type: FreeTextType.Nucc;
    };

export interface NuccFreeTextResult {
  description: string;
  id: string;
}

export interface FreeTextResult {
  codes: string[];
  description: string;
}
