import { BadgeTypeEnum } from '@getvim/atomic-ui';
import { AddressWithDistanceAndPhone } from './entities';
import { PaginationResult, SpecialtyResponse, UHC } from './find';
import {
  UhcCommonOptionExtraInfo,
  UhcFacilityOptionExtraInfo,
  UhcProviderOptionExtraInfo,
} from './find/uhc/types';

export enum ActionStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  NOT_AVAILABLE = 'not_available',
  NOT_SUPPORTED = 'not_supported',
}

export enum OrderAssistOptionType {
  Provider = 'Provider',
  Facility = 'Facility',
}

export interface BaseDetails {
  acoName?: string;
  specialties: SpecialtyResponse[];
  npi: string;
  tin: string;
  mpin: string;
  preferredProvider?: boolean;
  highValuePerformance?: boolean;
  name: string;
}

export interface BadgeDetails {
  type: BadgeTypeEnum;
  description: string;
  title: string;
}

export interface ComplexName {
  firstName: string;
  lastName: string;
}

export interface GetOrderAssistResult {
  searchResults: OrderAssistOption[];
  alternativeResults?: OrderAssistOption[];
  disclaimers?: UHC.Disclaimer[];
  pagination: PaginationResult;
  providerCacheId?: string;
  distanceInfo?: string;
  costAvailable?: boolean;
  benefits?: UHC.Benefits;
  isOutOfNetworkResults: boolean;
}

export interface SearchResultsDetails {
  npi: string;
  name: string;
  type: OrderAssistOptionType;
  isPreferred: boolean;
}

interface ProviderDetails extends BaseDetails {
  complexName: ComplexName;
}

export interface CostDetail {
  message: string;
  cost: string;
}

export interface OrderAssistOption {
  details: BaseDetails;
  costDetail?: CostDetail;
  locations: AddressWithDistanceAndPhone[];
  selectedLocationIndex?: number;
  badges: BadgeDetails[];
  type: OrderAssistOptionType;
  extraInfo?: UhcCommonOptionExtraInfo;
  rank?: number;
}

export interface OrderAssistProviderOption extends OrderAssistOption {
  details: ProviderDetails;
  type: OrderAssistOptionType.Provider;
  extraInfo?: UhcProviderOptionExtraInfo;
}

export interface OrderAssistFacilityOption extends OrderAssistOption {
  details: BaseDetails;
  type: OrderAssistOptionType.Facility;
  extraInfo?: UhcFacilityOptionExtraInfo;
}
