import {
  ReferralSteps,
  ServiceInformation,
  UpdateCodeStatus,
} from '../../components/app/components/referral-request/types';
import { WidgetSource } from '../../components/app/types';
import { ExtendWithContext } from './utils';

export enum ReferralRequestEventNames {
  REFERRAL_REQUEST_START_SCREEN_VIEWED = 'referral_request_start_screen_viewed',
  REFERRAL_REQUEST_START_SCREEN_CLICKED = 'referral_request_start_screen_clicked',
  REQUESTING_PROVIDER_SCREEN_VIEWED = 'requesting_provider_screen_viewed',
  REQUESTING_PROVIDER_SCREEN_CLICKED = 'requesting_provider_screen_clicked',
  SERVICE_INFORMATION_SCREEN_VIEWED = 'service_information_screen_viewed',
  SERVICE_INFORMATION_SCREEN_CLICKED = 'service_information_screen_clicked',
  STATUS_SCREEN_VIEWED = 'status_screen_viewed',
  STATUS_SCREEN_MANUAL_COPY = 'status_screen_manual_copy',
  STATUS_SCREEN_COPY_BUTTON_CLICKED = 'status_screen_copy_button_clicked',
  STATUS_SCREEN_CLICKED = 'status_screen_clicked',
  TIMEOUT_SCREEN_VIEWED = 'timeout_screen_viewed',
  TIMEOUT_SCREEN_CLICKED = 'timeout_screen_clicked',
  ERROR_SCREEN_VIEWED = 'error_screen_viewed',
  ERROR_SCREEN_CLICKED = 'error_screen_clicked',
  LEAVE_CONFIRMATION_MODAL_VIEWED = 'leave_confirmation_modal_viewed',
  LEAVE_CONFIRMATION_MODAL_CLICKED = 'leave_confirmation_modal_clicked',
  REFERRAL_REQUEST_WRITEBACK = 'referral_request_writeback',
}

export enum ReferralRequestFlowButtonName {
  NEXT = 'Next',
  BACK = 'Back',
  SUBMIT = 'Submit',
  CLOSE = 'Close',
}

export enum ReferralRequestButtonName {
  CLOSE = 'Close',
  SUBMIT = 'Submit',
}

export enum TimeoutScreenButtonName {
  GO_TO_EXTERNAL = 'go_to_external',
  CLOSE = 'close',
}

export enum ErrorScreenButtonName {
  BACK_TO_REFERRAL_REQUEST_FLOW = 'back_to_referral_request_flow',
  BACK_TO_SEARCH_APP = 'back_to_search_app',
}

export enum LeaveConfirmationModalButtonName {
  YES = 'Yes',
  NO = 'No',
  X_ICON = 'x_icon',
  BACKDROP = 'Backdrop',
}

export interface AnalyticsProvider {
  npi?: string;
  firstName: string;
  lastName: string;
  specialty?: string;
  address?: string;
}
export type AnalyticsRequestingProvider = Omit<AnalyticsProvider, 'firstName'> & {
  firstName?: string;
};

export interface AnalyticsProcedure {
  code: string;
  description: string;
  status: string;
  statusReason?: string;
  startDate: string;
  expirationDate: string;
  quantity: string;
  quantityType: string;
}

export type AnalyticsContext = {
  referral_request_session_id?: string;
  current_screen?: keyof ReferralSteps;
  search_session_id?: string;
  search_id?: string;
  selected_provider?: AnalyticsProvider;
  datasource?: WidgetSource;
};

export interface ReferralRequestStartScreenClickedPayload {
  buttonName: ReferralRequestButtonName;
}

export interface RequestingProviderScreenViewedPayload {
  requestingProvider?: AnalyticsRequestingProvider;
}

export interface RequestingProviderScreenClickedPayload {
  requestingProvider?: AnalyticsRequestingProvider;
  buttonName: ReferralRequestFlowButtonName;
}

export interface ServiceInformationScreenViewedPayload {
  serviceInformation?: ServiceInformation;
}

export interface ServiceInformationScreenClickedPayload {
  serviceInformation?: ServiceInformation;
  buttonName: ReferralRequestFlowButtonName;
}

export interface LeaveConfirmationModalButtonClickedPayload {
  buttonName: LeaveConfirmationModalButtonName;
}

export interface StatusScreenPayload {
  requestStatus: string;
  statusReason?: string;
  certificationId?: string;
  cpts?: AnalyticsProcedure[];
}

export interface TimeoutScreenClickedPayload {
  buttonName: TimeoutScreenButtonName;
}

export interface ErrorScreenClickedPayload {
  buttonName: ErrorScreenButtonName;
}

export interface StatusScreenViewedPayload extends StatusScreenPayload {
  timeToLoadInSeconds: number;
}

export interface StatusScreenClickedPayload extends StatusScreenPayload {
  buttonName: ReferralRequestFlowButtonName;
}

export interface ReferralRequestWritebackPayload extends StatusScreenPayload {
  status: UpdateCodeStatus;
}

type AnalyticsEvents = {
  [ReferralRequestEventNames.REFERRAL_REQUEST_START_SCREEN_VIEWED];
  [ReferralRequestEventNames.REFERRAL_REQUEST_START_SCREEN_CLICKED]: ReferralRequestStartScreenClickedPayload;
  [ReferralRequestEventNames.REQUESTING_PROVIDER_SCREEN_VIEWED]: RequestingProviderScreenViewedPayload;
  [ReferralRequestEventNames.REQUESTING_PROVIDER_SCREEN_CLICKED]: RequestingProviderScreenClickedPayload;
  [ReferralRequestEventNames.SERVICE_INFORMATION_SCREEN_VIEWED]: ServiceInformationScreenViewedPayload;
  [ReferralRequestEventNames.SERVICE_INFORMATION_SCREEN_CLICKED]: ServiceInformationScreenClickedPayload;
  [ReferralRequestEventNames.STATUS_SCREEN_VIEWED]: StatusScreenViewedPayload;
  [ReferralRequestEventNames.STATUS_SCREEN_MANUAL_COPY]: StatusScreenPayload;
  [ReferralRequestEventNames.STATUS_SCREEN_COPY_BUTTON_CLICKED]: StatusScreenPayload;
  [ReferralRequestEventNames.STATUS_SCREEN_CLICKED]: StatusScreenClickedPayload;
  [ReferralRequestEventNames.TIMEOUT_SCREEN_VIEWED];
  [ReferralRequestEventNames.TIMEOUT_SCREEN_CLICKED]: TimeoutScreenClickedPayload;
  [ReferralRequestEventNames.ERROR_SCREEN_VIEWED];
  [ReferralRequestEventNames.ERROR_SCREEN_CLICKED]: ErrorScreenClickedPayload;
  [ReferralRequestEventNames.LEAVE_CONFIRMATION_MODAL_VIEWED];
  [ReferralRequestEventNames.LEAVE_CONFIRMATION_MODAL_CLICKED]: LeaveConfirmationModalButtonClickedPayload;
  [ReferralRequestEventNames.REFERRAL_REQUEST_WRITEBACK]: ReferralRequestWritebackPayload;
};

export type ReferralRequestAnalyticsEvents = ExtendWithContext<AnalyticsEvents, AnalyticsContext>;
