import { WriteBacks } from '@getvim/vim-connect';
import {
  ActionStatus,
  BadgeDetails,
  GetOrderAssistInput,
  OrderAssistOptionType,
  SearchInput,
  SearchOptionsInput,
  SearchResultsDetails,
  WidgetSource,
} from '../components/app/types';

export enum SelectProviderButtonSource {
  REFERRAL = 'referral',
  DI = 'di',
  LAB = 'lab',
}

export enum SearchResultsScreen {
  SEARCH_RESULTS = 'search_results',
  ERROR_MESSAGE = 'error_message',
  NO_RESULTS = 'no_results',
}

export interface AnalyticsResultsOutput {
  cardType?: OrderAssistOptionType;
  pageNumber: number;
  resultSize: number;
  preferredProviderResultSize: number;
  alternativeSize: number;
  costStatus: ActionStatus;
  searchStatus: ActionStatus;
  searchResultsDetails?: SearchResultsDetails[];
  currentScreen?: SearchResultsScreen;
}

export type AnalyticsSearchPayload = Omit<SearchInput, 'address'> & {
  options?: SearchOptionsInput;
  vimSpecialtyDescription?: string;
  ehrNativeSpecialty?: string;
};

export interface AnalyticsSearchResultsPayload extends Omit<GetOrderAssistInput, 'searchInput'> {
  searchInput: Omit<SearchInput, 'address'> & {
    vimSpecialtyDescription?: string;
    ehrNativeSpecialty?: string;
  };
}

export enum SearchMethod {
  MANUAL = 'manual',
  CHANGE_PAGE = 'change_page',
  AUTOMATIC = 'automatic',
}

interface BaseCardDetails {
  cardRank?: number;
  cardType: OrderAssistOptionType;
  isAlternativeResult: boolean;
  isPreferredResult: boolean;
}

export type TooltipHoveredDetails = BaseCardDetails;

export interface ChangeAddressCardDetails extends BaseCardDetails {
  name: string;
  addressRank: number;
}

export interface SelectWithVimCardDetails extends BaseCardDetails {
  selectAction: WriteBacks.SelectAction;
  status: WriteBacks.ActionStatus;
}

export interface ProvidersAnalyticsOutput {
  previous_provider?: ProviderAnalyticsProperties;
  selected_provider?: ProviderAnalyticsProperties;
  selected_facility?: ProviderAnalyticsProperties;
}

interface BaseProviderAnalyticsProperties {
  npi?: string;
  ehr_id?: string;
  address?: string;
  performance?: BadgeDetails[];
}

export interface FacilityAnalyticsProperties extends BaseProviderAnalyticsProperties {
  name?: string;
}

export interface ProviderAnalyticsProperties extends BaseProviderAnalyticsProperties {
  firstName?: string;
  lastName?: string;
  acceptNewPatients?: boolean;
}

export interface VimInlineButtonClickedProperties {
  buttonSource: SelectProviderButtonSource;
}

export enum ExportMenuOption {
  DOWNLOAD = 'download',
  COPY = 'copy',
  PRINT = 'print',
}

export enum CloseMethod {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export enum OrderAssistScreenName {
  SEARCH_RESULTS = 'search_results',
  REFERRAL_REQUEST_STATUS = 'referral_request_status_page',
}

export enum DropdownDismissTrigger {
  TOGGLE_BUTTON = 'toggle_button',
  OUTSIDE_CLICK = 'outside_click',
}

export interface AppEnablePayload {
  app_source_enabled?: WidgetSource[];
  app_source_displayed?: WidgetSource | undefined;
}

export enum EhrContext {
  OUT_OF_CONTEXT = 'out_of_context',
  REFERRAL = 'referral',
  ORDER = 'order',
}

export const IRRELEVANT_ANALYTIC_FIELD = 'not relevant';
