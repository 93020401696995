import React, { useEffect } from 'react';
import { Button, BkmdModal } from '@getvim/atomic-ui';
import './DownloadModal.less';
import { CloseMethod } from '../../../../analytics/types';

export interface DownloadState {
  title: string;
  subtitle?: string;
  image: string;
  showCloseButton: boolean;
  timeout?: number;
}

interface DownloadModalProps {
  downloadState?: DownloadState;
  onClose: (closeMethod: CloseMethod) => void;
  prefetchStateImages?: DownloadState[];
}

export const DownloadModal: React.FC<DownloadModalProps> = ({
  downloadState,
  onClose,
  prefetchStateImages,
}) => {
  useEffect(() => {
    if (downloadState?.timeout) {
      const timer = setTimeout(() => {
        onClose(CloseMethod.AUTOMATIC);
      }, downloadState.timeout);

      return () => clearTimeout(timer);
    }
  }, [downloadState?.timeout, onClose]);

  const manualClose = () => {
    onClose(CloseMethod.MANUAL);
  };

  // asynchronously prefetch the PDF loading images from S3 to avoid empty white space
  const perloadLinkTags = (state: DownloadState) => {
    return <link rel="preload" as="image" href={state.image} />;
  };

  return (
    <>
      {downloadState && (
        <div>
          <BkmdModal isOpen baseClassName="order-assist-download-modal-window" autoFocus={false}>
            <div id="order-assist-download-modal-inner">
              <h1>{downloadState.title}</h1>
              {downloadState.subtitle && <h2>{downloadState.subtitle}</h2>}
              <div>
                <img className="large-icon" src={downloadState.image} alt={downloadState.title} />
              </div>
              {downloadState.showCloseButton && (
                <Button
                  className="close-button"
                  onClick={manualClose}
                  buttonType="small"
                  text="Close"
                  bgColor="none"
                />
              )}
            </div>
          </BkmdModal>
        </div>
      )}
      {prefetchStateImages && prefetchStateImages.map(perloadLinkTags)}
    </>
  );
};
