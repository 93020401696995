import { useCallback, useEffect, useState } from 'react';
import { OrderAssistFormData, OrderAssistFormInputs } from '../../../types';

export interface UseSearchFormDirtiness {
  inputsDirtiness: Partial<{
    [key in keyof OrderAssistFormInputs]: boolean;
  }>;
  setAllDirty: () => void;
  resetDirtiness: (key: keyof OrderAssistFormInputs) => void;
}

export const useSearchFormDirtiness = (formData: OrderAssistFormData): UseSearchFormDirtiness => {
  const [inputsDirtiness, setInputsDirtiness] = useState<
    Partial<{
      [key in keyof OrderAssistFormInputs]: boolean;
    }>
  >({});

  useEffect(() => {
    const newDirtyInputs = {};
    Object.keys(formData).forEach((key) => {
      newDirtyInputs[key] = true;
    });

    setInputsDirtiness((prevInputDirtiness) => ({
      ...prevInputDirtiness,
      ...newDirtyInputs,
    }));
  }, [formData]);

  const setAllDirty = useCallback(() => {
    // Make everything dirty to show error validations
    setInputsDirtiness({
      address: true,
      specialty: true,
      icd: true,
      cpt: true,
      placeOfService: true,
      npi: true,
      firstName: true,
      lastName: true,
      facilityName: true,
    });
  }, []);

  const resetDirtiness = useCallback((key: keyof OrderAssistFormInputs) => {
    setInputsDirtiness((dirtiness) => ({ ...dirtiness, [key]: false }));
  }, []);

  return { inputsDirtiness, setAllDirty, resetDirtiness };
};
