import { InputStyle, InputStyleWrapper, LabeledInput } from '@getvim/atomic-ui';
import React, { useState, useEffect } from 'react';
import { InputWrapperProps } from '../../../types';
import './AdvancedSearchContainer.less';

const NpiInputWrapper: React.FC<InputWrapperProps<'npi'>> = ({ value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  // To disable non-numeric keys typing
  const onKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) event.preventDefault();
  };

  useEffect(() => {
    if (!value) {
      setIsFocused(false);
    }
  }, [value]);

  return (
    <div className="input">
      <InputStyleWrapper className="input-wrapper" inputStyle={InputStyle.pillMedium}>
        <LabeledInput
          id="npi-input"
          type="text"
          label="NPI"
          value={value ?? ''}
          onFocus={() => setIsFocused(true)}
          active={!!value || isFocused}
          onBlur={() => {
            if (!value) setIsFocused(false);
          }}
          onKeyPress={onKeyPress}
          className="advanced-search-input"
          onChange={(e) => onChange({ npi: e.target.value })}
          maxLength={10}
          autoComplete="off"
        />
      </InputStyleWrapper>
    </div>
  );
};

export default NpiInputWrapper;
