import { InputStyle, InputStyleWrapper, InputValidationMessage, Select } from '@getvim/atomic-ui';
import { useField } from 'formik';
import { isArray } from 'lodash-es';
import React, { useState } from 'react';
import { NO_RESULTS, START_SEARCH } from '../../../../consts/selectInputMessages';
import { FreeTextResult, FreeTextType, IcdSelectOption, SelectOption } from '../../../../types';
import { OnFreeTextQueryMethod } from '../../../order-assist-app/hooks';
import { ICD_MAX_AMOUNT } from '../consts';
import { getReferralRequestUIString } from '../../strings';

interface IcdInputFieldProps {
  onFreeTextQuery: OnFreeTextQueryMethod;
}

const formatValuesToFieldValues = (codes: string[]) =>
  codes.map((code: string) => ({ label: code, value: code }));

const IcdInputField: React.FC<IcdInputFieldProps> = ({ onFreeTextQuery }) => {
  const [, meta, helpers] = useField('diagnoses');
  const [options, setOptions] = useState<FreeTextResult[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatOptions = (freeTextResults: FreeTextResult[] | undefined): IcdSelectOption[] => {
    if (!freeTextResults) return [];
    return freeTextResults.map(({ codes, description }) => ({
      value: {
        code: codes[0],
        description,
      },
      label: `${codes[0]} - ${description}`,
      disabled:
        meta.value.find((value: IcdSelectOption['value']) => value.code === codes[0]) ||
        meta.value?.length >= ICD_MAX_AMOUNT,
    }));
  };

  const onSearch = async (searchString: string) => {
    if (!searchString) return setOptions(undefined);
    setIsLoading(true);
    const freeTextResult = await onFreeTextQuery({
      type: FreeTextType.Icd,
      freeText: searchString,
    });
    setOptions((freeTextResult?.result as FreeTextResult[]) ?? []);
    setIsLoading(false);
  };

  const onChange = (event: SelectOption[]) => {
    helpers.setValue(
      event.map((option: SelectOption) =>
        isArray(option?.value) ? option?.value[0] : option?.value,
      ),
    );
  };

  return (
    <div className="referral-request-input-container">
      <InputStyleWrapper
        className="referral-request-select-input-style-wrapper"
        inputStyle={InputStyle.pillMedium}
        hasError={meta.touched && !meta.value?.length}
        rightIcon={<></>}
      >
        <div className="input">
          <Select
            multi
            multiline
            clearable
            label={getReferralRequestUIString('referral_quantity_type_diagnosis_code_label')}
            placeholder=" "
            id="icd-input"
            name="icd-input"
            labelField="label"
            valueField="value"
            optionLabelField="value.code"
            keepSelectedInList
            options={formatOptions(options)}
            onChange={onChange}
            showChevronIcon
            searchFn={({ state }) => {
              onSearch(state.search);
            }}
            noDataLabel={options ? NO_RESULTS : START_SEARCH}
            noInputLabel={START_SEARCH}
            noResultsLabel={NO_RESULTS}
            setTouched={() => helpers.setTouched(true, false)}
            loading={isLoading}
            {...(meta.initialValue ? { values: formatValuesToFieldValues(meta.value) } : {})}
          />
          {meta.touched && !meta.value.length && (
            <InputValidationMessage>
              {getReferralRequestUIString('referral_quantity_type_diagnosis_code_missing_label')}
            </InputValidationMessage>
          )}
        </div>
      </InputStyleWrapper>
    </div>
  );
};

export default IcdInputField;
