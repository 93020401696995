import {
  InputStyle,
  InputStyleWrapper,
  InputStyleWrapperPropsType,
  Select,
  SelectInputProps,
  Tooltip,
} from '@getvim/atomic-ui';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

type SelectPlanProps = {
  disabled?: boolean;
  items: string[] | undefined;
  loading?: boolean;
  value: string | undefined;
  onChange: (newValue: string) => void;
  wrapperProps?: Partial<InputStyleWrapperPropsType>;
  selectProps: Partial<SelectInputProps> & Pick<SelectInputProps, 'id' | 'name' | 'placeholder'>;
};

interface Option {
  label: string;
  value: string;
}

const SelectOption: React.FC<SelectPlanProps> = ({
  disabled = false,
  items,
  onChange,
  loading = false,
  value,
  wrapperProps = {},
  selectProps,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  useEffect(() => {
    const [firstOption, ...restOptions] = options;
    if (!disabled && firstOption && !restOptions.length && firstOption.value !== value) {
      onChange(firstOption.value);
    }
  }, [options, onChange, value, disabled]);

  const filterOptionsByString = useCallback(
    (searchString: string) => {
      if (!searchString) {
        setFilteredOptions(options);
      } else {
        const filtered = options.filter((option) =>
          option.label.toLowerCase().includes(searchString.toLowerCase()),
        );
        setFilteredOptions(filtered);
      }
    },
    [options],
  );

  const onSearch = ({ state, methods }) => {
    const searchString = methods.safeString(state.search);
    filterOptionsByString(searchString);
  };

  useEffect(() => {
    if (value) {
      setSelectedOption({ value, label: value });
    } else {
      setFilteredOptions(options);
      setSelectedOption(undefined);
    }
  }, [value, options]);

  useEffect(() => {
    const initialOptions = items?.map((item) => ({ value: item, label: item })) ?? [];
    setOptions(initialOptions);
    setFilteredOptions(initialOptions);
  }, [items, onChange]);

  return (
    <InputStyleWrapper
      className={classNames(`input-wrapper ${wrapperProps.className}`)}
      leftIcon={wrapperProps.leftIcon}
      rightIcon={<></>}
      inputStyle={InputStyle.pillMedium}
    >
      <div className={classNames('input', { 'no-icon-input': !wrapperProps.leftIcon })}>
        <Tooltip
          tooltipContent={value}
          hideTooltip={!value}
          key={value}
          conditionalTooltip
          contentMaxLength={5}
        >
          <Select
            placeholder={selectProps.placeholder}
            id={selectProps.id}
            name={selectProps.name}
            options={filteredOptions}
            onChange={(newOptions) => {
              filterOptionsByString(newOptions?.[0]?.label);
              onChange(newOptions?.[0]?.value);
            }}
            noDataLabel={selectProps.noDataLabel}
            error={selectProps.error}
            noInputLabel={selectProps.noInputLabel}
            noResultsLabel={selectProps.noResultsLabel}
            isDisabled={disabled}
            loading={loading}
            searchFn={onSearch}
            values={selectedOption ? [selectedOption] : []}
            hideValueOnInput
            clearable
            closeOnSelect
            keepSelectedInList
            showChevronIcon
          />
        </Tooltip>
      </div>
    </InputStyleWrapper>
  );
};

export default SelectOption;
