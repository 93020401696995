import { Buffer } from 'buffer';

const base64ToBlob = (b64Data: string, contentType = '', sliceSize = 512): Blob => {
  const byteCharacters = Buffer.from(b64Data, 'base64');
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = Uint8Array.prototype.slice.call(byteCharacters, offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice[i];
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export default base64ToBlob;
