import { Insurer } from '@getvim-os/standard';
import { useCallback } from 'react';
import { logic } from '../../../logic';
import {
  SourceConfigurations,
  WidgetSource,
  FreeTextEventResult,
  FreeTextQueryPayload,
} from '../../../types';
import { orderAssistLogger } from '../../../logger';

export type OnFreeTextQueryMethod = (
  payload: FreeTextQueryPayload,
  insurer?: Insurer,
  source?: WidgetSource,
) => Promise<FreeTextEventResult | undefined>;
export type UseFreeTextHookType = (
  params: {
    source: WidgetSource | undefined;
    sourceConfig: SourceConfigurations | undefined;
  },
  callbacks?: {
    onEmpty?: () => void;
    onStart?: (payload: FreeTextQueryPayload) => void;
    onFinish?: (results: FreeTextEventResult) => void;
  },
) => OnFreeTextQueryMethod;

export const useFreeText: UseFreeTextHookType = (
  { source, sourceConfig },
  { onEmpty, onStart, onFinish } = {},
) => {
  return useCallback(
    async (payload, insurer, overrideSource): Promise<FreeTextEventResult | undefined> => {
      if (!sourceConfig) {
        orderAssistLogger.error('Cancelling useFreeText due to undefined sourceConfig');
        return;
      }

      const { freeText } = payload;
      if (!freeText) {
        onEmpty?.();
        return undefined;
      }

      onStart?.(payload);

      const useFreeTextSource = sourceConfig?.useSourceFreeText;

      const response: FreeTextEventResult = await logic.getFreeTextQuery(
        payload,
        insurer,
        overrideSource ?? (useFreeTextSource ? source : undefined),
      );

      onFinish?.(response);
      return response;
    },
    [source, sourceConfig, onEmpty, onStart, onFinish],
  );
};
