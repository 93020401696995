import { Address, WriteBackProvider } from './entities';
import { QueryResults, UHC } from './find';
import { GetOrderAssistResult, OrderAssistOption } from './results';
import { OrderAssistFormData, SearchOptionsInput } from './form';
import { FreeTextResult, NuccFreeTextResult } from './freeText';

export enum ScreenName {
  OrderAssistSearch = 'ORDER_ASSIST_SEARCH',
  OrderAssistResults = 'ORDER_ASSIST_RESULTS',
}

// resultsFilters is the filter that we are sending in the request
// userFilters exist to remember what the user filtered, in case the system modified the filter
export interface DataFilters {
  resultsFilters?: SearchFilters;
  userFilters?: SearchFilters;
  lastModifiedFromScreen?: ScreenName;
}
export type SearchFilters = { distance: number; language: string };

export interface SearchInput {
  address: Address;
  icd?: string[];
  cpt?: string[];
  vimSpecialtyId?: string;
  referredToPlaceOfService?: { code: string; display: string };
  advancedSearch?: {
    firstName?: string;
    lastName?: string;
    npi?: string;
    facilityName?: string;
  };
  insurer?: string;
  insurancePlan?: string;
}

export interface GetOrderAssistInput {
  memberToken: string | undefined;
  searchInput: SearchInput;
  options?: SearchOptionsInput;
}

export interface SearchState {
  formData: OrderAssistFormData;
  formDataMetadata?: { vimPatientId?: string; vimReferralId?: string }; // temporary for investigation purposes
  currentSearchInput: OrderAssistFormData;
  filtersData?: DataFilters;
  searchQuery?: QueryResults<GetOrderAssistResult>;
  icdQuery?: QueryResults<FreeTextResult[]>;
  cptQuery?: QueryResults<FreeTextResult[]>;
  specialtyQuery?: QueryResults<NuccFreeTextResult[]>;
  isCostAvailable: boolean;
  lastProviderCacheId?: string;
  benefits?: UHC.Benefits;
  disclaimers?: UHC.Disclaimer[];
  displayPreferredProvidersListFF: boolean;
  organizationHasPreferredProviders: boolean;
  preferredProviders: OrderAssistOption[];
  selectedProvider?: WriteBackProvider;
}

export interface QuickSpecialtySearchParams {
  specialty: OrderAssistFormData['specialty'];
  placeOfService?: OrderAssistFormData['placeOfService'];
}
