import { internalApi } from '../../../../internal-api';
import { orderAssistLogger } from '../../logger';
import { Address } from '../../types';

export const fetchGeo = async (address: Address): Promise<Address> => {
  const { address1, city, state } = address;
  if (!address1?.trim() || !city?.trim() || !state?.trim()) {
    orderAssistLogger.warning('Could not fetch geo, missing parameters', {
      address1,
      city,
      state: state && state.toUpperCase(),
    });
    return address;
  }

  return await internalApi.orderAssistBff.getGeo({
    addressLine1: address1,
    city,
    state: state.toUpperCase(),
  });
};
