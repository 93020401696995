import { OrderAssistFacilityOption } from '../components/app/types';
import { FacilityAnalyticsProperties } from './types';

export const parseOptionFacilityToAnalyticsFacility = (
  selectedOption: OrderAssistFacilityOption,
  selectedAddress?: string,
): FacilityAnalyticsProperties | undefined => {
  const { details, badges } = selectedOption;
  if (!details) {
    return undefined;
  }

  const { npi, name } = details;
  return {
    npi,
    name,
    address: selectedAddress,
    performance: badges,
  };
};
