import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { FlowContextWrapper } from '@getvim/flow-context-provider';
import { useVimCommunication } from '@getvim/vim-app-infra';
import { Entities } from '@getvim/vim-connect';
import { Patient } from '../app/types';
import { Loader } from './components';
import useMemoizedState from '../../hooks/useMemoizedState';
import { selectButtonLogger } from './logger';
import {
  EventPayload,
  IncomingEvents,
  isPayloadTypeOrders,
  SelectButtonIncomingEventName,
} from './types';
import { useWidgetHealthCheck } from './hooks';
import { useUserConfig } from '../../hooks';

export enum SelectButtonTypes {
  Referral,
  Orders,
}

export type SelectButtonProps =
  | {
      selectType: SelectButtonTypes.Referral;
    }
  | {
      selectType: SelectButtonTypes.Orders;
      ordersType: Entities.OrderType;
    };

const typeToListenerMap = new Map<SelectButtonTypes, SelectButtonIncomingEventName>([
  [SelectButtonTypes.Referral, SelectButtonIncomingEventName.ReferralViewed],
  [SelectButtonTypes.Orders, SelectButtonIncomingEventName.OrdersViewed],
]);

export const SelectButtonWrapper: React.FC<PropsWithChildren<SelectButtonProps>> = (props) => {
  useWidgetHealthCheck();
  const [patient, setPatient] = useMemoizedState<Patient | undefined>();
  const { vimCommunication } = useVimCommunication();
  const userConfig = useUserConfig();
  const { selectType, children } = props;
  const isDesktop = window.navigator?.userAgent?.includes('Electron');

  const eventHandler = useCallback(
    (data: EventPayload) => {
      const { loading, patient: patientPayload } = data;

      if (loading) {
        return;
      }

      if (isPayloadTypeOrders(data)) {
        if ('ordersType' in props) {
          const { ordersType } = props;
          if (!data.selectedOrders?.some((order) => order.type === ordersType)) {
            return;
          }
        } else {
          selectButtonLogger.error('ordersType prop is required for handling ordersViewed event!');
          return;
        }
      }

      setPatient(patientPayload);
    },
    [props, setPatient],
  );

  useEffect(() => {
    const eventType = typeToListenerMap.get(selectType);

    if (!eventType) {
      selectButtonLogger.error(`Could not find matching handler for event type`, { eventType });
      return;
    }

    return vimCommunication?.listenToEvent(eventType, (payload) => {
      selectButtonLogger.info(`Got event in inline select button - ${eventType}`, { payload });
      eventHandler(payload.data as IncomingEvents[typeof eventType]);
    });
  }, [selectType, vimCommunication, eventHandler]);

  const state = useMemo(() => {
    return { userConfig: Promise.resolve(userConfig), patient: Promise.resolve(patient) };
  }, [userConfig, patient]);

  const onError = useCallback(
    (error) => {
      selectButtonLogger.error('Failed to fetch state in SelectButtonWrapper!', {
        selectType,
        error,
      });
    },
    [selectType],
  );

  return (
    <div className={isDesktop ? 'small-widget' : 'vim-button-container'}>
      <div className="vim-button-id-wrapper">
        <FlowContextWrapper loader={<Loader isSmall={isDesktop} />} state={state} onError={onError}>
          {children}
        </FlowContextWrapper>
      </div>
    </div>
  );
};
