import { Address, Provider } from './entities';

export enum ActiveFilter {
  Distance = 'distance',
  Language = 'language',
}

export interface SearchOptionsInput {
  pagination?: {
    paginationNumber: number;
    providerCacheId?: string;
  };
  languageCode?: string;
  radius?: number;
  forceCost?: boolean;
}

export type OrderAssistFormErrors = {
  [key in keyof Pick<
    OrderAssistFormData,
    'specialty' | 'placeOfService' | 'cpt' | 'address'
  >]?: string;
} & { advancedSearch?: string };

export interface InputWrapperProps<KEYS extends keyof Partial<OrderAssistFormData>> {
  onChange: (input: Pick<OrderAssistFormData, KEYS>) => void;
  value: OrderAssistFormData[KEYS];
}

export interface OrderAssistFormInputs {
  address?: Address;
  specialty?: FormDataSpecialty;
  icd?: SelectOption[];
  cpt?: SelectOption[];
  placeOfService?: string;
  npi?: string;
  firstName?: string;
  lastName?: string;
  facilityName?: string;
  insurer?: string;
  insurancePlan?: string;
  referringProvider?: Provider;
  vimPatientId?: string;
  vimReferralId?: string;
}
export type OrderAssistFormData = OrderAssistFormInputs;

export interface OrderAssistFormDataWithOptions extends OrderAssistFormData {
  options: SearchOptionsInput;
}
export interface FormDataSpecialty {
  vimSpecialtyDescription?: string;
  vimSpecialtyId?: string;
  nuccCodes?: string[];
  searchDisplay: string;
}

export interface SelectOption {
  label: string;
  value: string;
}

export interface IcdSelectOption {
  label: string;
  value: {
    code: string;
    description: string;
  };
}

export interface NuccSelectOption {
  label: string;
  value: string[] | string;
}
