export enum ProviderType {
  PROVIDER = 'PROVIDER',
  FACILITY = `FACILITY`,
}

export interface SpokenLanguage {
  id: string;
  name: string;
  nativeName: string;
  iso3?: string;
}

interface ClinicalMatch {
  age?: boolean;
  gender?: string;
  taxonomy: string;
}

export interface Performance {
  code: string;
  name?: string;
  description: string;
  icon?: string;
}

interface GeoLocation {
  latitude: number;
  longitude: number;
}

interface BookingHorizon {
  value: number;
  threshold: number;
}

interface PhoneNumber {
  number: string;
  countryDialingCode: string;
}

enum NetworkStatus {
  IN_NETWORK = 'IN_NETWORK',
  OUT_OF_NETWORK = 'OUT_OF_NETWORK',
  UNVERIFIED_NETWORK = 'UNVERIFIED_NETWORK',
}

interface Network {
  status: NetworkStatus;
  tier?: string;
}

export interface CostEstimation {
  description?: string;
  min?: number;
  max?: number;
}

export interface Location {
  providerLocationId?: string;
  geo: GeoLocation;
  distance?: string;
  openHours?: string;
  bookingHorizon?: BookingHorizon;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  timezone?: string;
  officeName?: string;
  phoneNumber?: PhoneNumber;
  fax?: PhoneNumber;
  performance?: Performance[];
  internalProvider?: boolean;
  acceptNewPatients?: boolean;
  network?: Network;
  costEstimation?: CostEstimation;
  implementationSpecificFields?: {
    caterpillarNetwork?: boolean;
    [k: string]: any;
  };
  highValuePerformance?: boolean;
}

export interface SpecialtyResponse {
  id: string;
  description: string;
  isPrimary?: boolean;
}

export interface FindResult {
  providerType: ProviderType;
  npi: string;
  name: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  title?: string;
  gender?: string;
  profilePicture?: string;
  preferredProvider?: boolean;
  internalProvider?: boolean;
  languages: SpokenLanguage[];
  specialties: SpecialtyResponse[];
  clinicalMatch?: ClinicalMatch;
  scoring?: string;
  locations?: Location[];
  implementationSpecificFields?: unknown;
}

export interface PaginationResult {
  hasNextPage: boolean;
  pageNumber: number;
  totalPages?: number;
}

export interface FindResponse {
  results: FindResult[];
  alternativeResults: FindResult[];
  pagination: PaginationResult;
  implementationSpecificFields?: unknown;
}

export interface GetResultsOptions {
  results: FindResult[];
  rankStarter?: number;
}

export interface QueryResults<T> {
  results?: T;
  isLoading?: boolean;
  isModifiedSearch?: boolean;
  shouldModifySearch?: boolean;
  hasError?: boolean;
}
