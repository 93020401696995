import classNames from 'classnames';
import React from 'react';
import Tooltip from '../tooltip/index';
import DoesNotMeetPremiumQualityCriteriaSVG from './images/doesNotMeetPremiumQualityCriteria.svg';
import FreestandingFacilitySVG from './images/freestandingFacility.svg';
import LowerCostSVG from './images/lowerCost.svg';
import NotEvaluatedForPremiumCareSVG from './images/notEvaluatedForPremiumCare.svg';
import PreferredLabSVG from './images/preferredLab.svg';
import PremiumCarePhysicianSVG from './images/premiumCarePhysician.svg';
import QualityCarePhysicianSVG from './images/qualityCarePhysician.svg';
import Tier1SVG from './images/tier1.svg';
import Tier2SVG from './images/tier2.svg';
import HighPerformingSpecialistSVG from './images/highPerformingSpecialist.svg';
import AveragePerformingSpecialistSVG from './images/averagePerformingSpecialistSVG.svg';
import LowPerformingSpecialistSVG from './images/lowPerformingSpecialistSVG.svg';
import PerformanceUnscoredSVG from './images/performance_unscored.svg';
import AcceptingNewPatientsSvg from './images/acceptingNewPatients.svg';
import NotAcceptingNewPatientsSvg from './images/notAcceptingNewPatients.svg';
import CaterpillarNetworkSvg from './images/caterpillarNetworkIcon.svg';
import './index.less';

const DesignatedDiagnosticProviderSVG = LowerCostSVG;

export enum BadgeTypeEnum {
  Tier1 = 'Tier 1',
  Tier2 = 'Tier 2',
  PreferredLab = 'Preferred Lab',
  LowerCost = 'Lower Cost',
  FreeStandingFacility = 'Free Standing Facility',
  DesignatedDiagnosticProvider = 'Designated Diagnostic Provider',
  PremiumCarePhysician = 'Premium Care Physician',
  QualityCarePhysician = 'Quality Care Physician',
  NotEvaluatedForPremiumCare = 'Not Evaluated For Premium Care',
  DoesNotMeetPremiumQualityCriteria = 'Does Not Meet Premium Quality Criteria',
  high_tier = 'high_tier',
  middle_tier = 'middle_tier',
  low_tier = 'low_tier',
  unscored = 'unscored',
  acceptingNewPatients = 'Accepting new patients',
  notAcceptingNewPatients = 'Not accepting new patients',
  caterpillarNetwork = 'Caterpillar Network Providers',
}

const BadgeDisplayText: Record<BadgeTypeEnum, string> = {
  [BadgeTypeEnum.Tier1]: 'Tier 1',
  [BadgeTypeEnum.Tier2]: 'Tier 2',
  [BadgeTypeEnum.PreferredLab]: 'Preferred lab',
  [BadgeTypeEnum.LowerCost]: 'Lower cost',
  [BadgeTypeEnum.FreeStandingFacility]: 'Free standing facility',
  [BadgeTypeEnum.DesignatedDiagnosticProvider]: 'Designated diagnostic provider',
  [BadgeTypeEnum.PremiumCarePhysician]: 'Premium Care\nPhysician',
  [BadgeTypeEnum.QualityCarePhysician]: 'Quality Care\nPhysician',
  [BadgeTypeEnum.NotEvaluatedForPremiumCare]: 'Not evaluated for\nPremium care',
  [BadgeTypeEnum.DoesNotMeetPremiumQualityCriteria]: 'Does not meet Premium\nquality criteria',
  [BadgeTypeEnum.high_tier]: 'High performing specialist',
  [BadgeTypeEnum.middle_tier]: 'Average performing specialist',
  [BadgeTypeEnum.low_tier]: 'Low performing specialist',
  [BadgeTypeEnum.unscored]: 'No performance data available',
  [BadgeTypeEnum.acceptingNewPatients]: 'Accepting new\npatients',
  [BadgeTypeEnum.notAcceptingNewPatients]: 'Not accepting new\npatients',
  [BadgeTypeEnum.caterpillarNetwork]: 'Caterpillar Network Providers',
};

const BadgeToImage: Record<BadgeTypeEnum, any> = {
  [BadgeTypeEnum.Tier1]: Tier1SVG,
  [BadgeTypeEnum.Tier2]: Tier2SVG,
  [BadgeTypeEnum.PreferredLab]: PreferredLabSVG,
  [BadgeTypeEnum.LowerCost]: LowerCostSVG,
  [BadgeTypeEnum.FreeStandingFacility]: FreestandingFacilitySVG,
  [BadgeTypeEnum.DesignatedDiagnosticProvider]: DesignatedDiagnosticProviderSVG,
  [BadgeTypeEnum.PremiumCarePhysician]: PremiumCarePhysicianSVG,
  [BadgeTypeEnum.QualityCarePhysician]: QualityCarePhysicianSVG,
  [BadgeTypeEnum.NotEvaluatedForPremiumCare]: NotEvaluatedForPremiumCareSVG,
  [BadgeTypeEnum.DoesNotMeetPremiumQualityCriteria]: DoesNotMeetPremiumQualityCriteriaSVG,
  [BadgeTypeEnum.high_tier]: HighPerformingSpecialistSVG,
  [BadgeTypeEnum.middle_tier]: AveragePerformingSpecialistSVG,
  [BadgeTypeEnum.low_tier]: LowPerformingSpecialistSVG,
  [BadgeTypeEnum.unscored]: PerformanceUnscoredSVG,
  [BadgeTypeEnum.acceptingNewPatients]: AcceptingNewPatientsSvg,
  [BadgeTypeEnum.notAcceptingNewPatients]: NotAcceptingNewPatientsSvg,
  [BadgeTypeEnum.caterpillarNetwork]: CaterpillarNetworkSvg,
};

const badgesWithText: BadgeTypeEnum[] = [
  BadgeTypeEnum.PremiumCarePhysician,
  BadgeTypeEnum.QualityCarePhysician,
  BadgeTypeEnum.NotEvaluatedForPremiumCare,
  BadgeTypeEnum.DoesNotMeetPremiumQualityCriteria,
  BadgeTypeEnum.high_tier,
  BadgeTypeEnum.middle_tier,
  BadgeTypeEnum.low_tier,
  BadgeTypeEnum.unscored,
  BadgeTypeEnum.acceptingNewPatients,
  BadgeTypeEnum.notAcceptingNewPatients,
  BadgeTypeEnum.caterpillarNetwork,
];

const badgesWithLongText: BadgeTypeEnum[] = [BadgeTypeEnum.DoesNotMeetPremiumQualityCriteria];

const tierBadge: BadgeTypeEnum[] = [
  BadgeTypeEnum.low_tier,
  BadgeTypeEnum.middle_tier,
  BadgeTypeEnum.high_tier,
  BadgeTypeEnum.unscored,
];

export type BadgeProps = {
  badgeType: BadgeTypeEnum;
  title: string;
  tooltip: string;
  hideTooltip?: boolean;
  longTooltip?: boolean;
  onMouseEnter?: () => void;
  parseNewLinesTooltip?: boolean;
};

const Badge: React.FC<BadgeProps> = ({
  badgeType,
  title = '',
  tooltip,
  hideTooltip = false,
  longTooltip = true,
  onMouseEnter,
  parseNewLinesTooltip = false,
}) => (
  <>
    <div
      className={classNames('badge-wrap', { 'tier-badge': tierBadge.includes(badgeType) })}
      onMouseEnter={onMouseEnter}
    >
      <Tooltip
        tooltipContent={tooltip}
        pillStyle={!longTooltip}
        hideTooltip={hideTooltip}
        bgColor="light"
        className="badge-tooltip"
        referenceClassName="badge-tooltip-reference"
        parseNewLines={parseNewLinesTooltip}
      >
        <img src={BadgeToImage[badgeType]} alt={badgeType} />
        {badgesWithText.includes(badgeType) && (
          <div
            className={classNames('badge-inner-text', {
              wide: badgesWithLongText.includes(badgeType),
            })}
          >
            {title || BadgeDisplayText[badgeType]}
          </div>
        )}
      </Tooltip>
    </div>
  </>
);

export default Badge;
