import { Toast } from '@getvim/atomic-ui';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import formatForClipboard from '../../../utils/clipboardFormatter';
import { useClipboard } from './useClipboard';
import { orderAssistLogger } from '../../../logger';
import { GetOrderAssistResult, OrderAssistOption } from '../../../types';

function useCopyResultsToClipboard(): (
  results: GetOrderAssistResult,
  displayPreferredProvidersList: boolean,
  preferredProviders: OrderAssistOption[],
) => Promise<{
  success: boolean;
}> {
  const [shouldImproveCopyExportSearchResults] = useFeatureFlag({
    flagName: 'shouldImproveCopyExportSearchResults',
    defaultValue: false,
  });
  const copy = useClipboard();
  const { createToast, ToastTypes } = Toast;

  const enum CopyResultsText {
    SuccessTitle = 'All done!',
    SuccessMessage = 'The details were copied to the clipboard, you can now paste them to any text field',
  }

  const getDetailsFromResults = (options: OrderAssistOption[]): string => {
    const text = options
      .map((item) => {
        const formattedResult = formatForClipboard(item, shouldImproveCopyExportSearchResults);
        return `\n\n${formattedResult}`;
      })
      .join();
    return text;
  };

  const copyResultsToClipboard = async (
    results: GetOrderAssistResult,
    displayPreferredProvidersList: boolean,
    preferredProviders: OrderAssistOption[],
  ) => {
    let text = `Order Assist\n\n`;

    if (preferredProviders?.length && displayPreferredProvidersList) {
      if (shouldImproveCopyExportSearchResults) {
        text += `In-network featured search results (${preferredProviders.length})`;
      } else {
        text += `Featured Results (${preferredProviders.length})`;
      }
      text += `${getDetailsFromResults(preferredProviders)}\n\n`;
    }

    if (results.searchResults?.length) {
      if (shouldImproveCopyExportSearchResults) {
        text += `In-network search results (${results.searchResults.length})`;
      } else {
        text += `Search Results (${results.searchResults.length})`;
      }
      text += `${getDetailsFromResults(results.searchResults)}\n\n`;
    }

    if (results.alternativeResults?.length) {
      if (shouldImproveCopyExportSearchResults) {
        text += `In-network alternative recommendations`;
      } else {
        text += `Alternative Recommendations`;
      }
      text += `${getDetailsFromResults(results.alternativeResults)}\n`;
    }

    const success = await copy(text);
    orderAssistLogger.info(`finished copying all results to clipboard`, { success, text });

    if (success) {
      createToast({
        title: CopyResultsText.SuccessTitle,
        message: CopyResultsText.SuccessMessage,
        type: ToastTypes.SUCCESS,
        html: true,
      });
    }

    return { success };
  };

  return copyResultsToClipboard;
}

export default useCopyResultsToClipboard;
