import { ClickableDiv } from '@getvim/atomic-ui';
import { useField } from 'formik';
import { cloneDeep, random } from 'lodash-es';
import React, { useCallback } from 'react';
import { Procedure, QuantityType } from '../../../types';
import {
  CPT_ADD_MSG,
  CPT_MAX_AMOUNT,
  CPT_MAX_MSG_FIRST_LINE,
  CPT_MAX_MSG_SECOND_LINE,
} from '../../consts';
import { CptInputField } from './input-fields/CptInputField';
import { DateInputField } from './input-fields/DateInputField';
import { QuantityTypeInputField } from './input-fields/QuantityTypeInputField';
import { QuantityValueInputField } from './input-fields/QuantityValueInputField';
import { getReferralRequestUIString } from '../../../strings';

interface ProcedureActionLineProps {
  addProcedure: () => void;
  procedures: Procedure[];
}
const ProcedureActionLine: React.FC<ProcedureActionLineProps> = ({ addProcedure, procedures }) => {
  if (!procedures[procedures?.length - 1]?.cpt?.code) return <></>;
  return procedures.length < CPT_MAX_AMOUNT ? (
    <>
      <ClickableDiv className="add-procedure-container" onClick={addProcedure}>
        <span className="add-procedure">{CPT_ADD_MSG}</span>
      </ClickableDiv>
    </>
  ) : (
    <div>
      <p className="max-cpt bold">{CPT_MAX_MSG_FIRST_LINE}</p>
      <p className="max-cpt">{CPT_MAX_MSG_SECOND_LINE}</p>
    </div>
  );
};

const Procedures: React.FC = () => {
  const [, meta, helpers] = useField('procedures');

  const addProcedure = useCallback(
    () =>
      helpers.setValue([
        ...meta.value,
        { date: new Date(), quantity: { value: 1, type: QuantityType.VISITS } },
      ]),
    [helpers, meta.value],
  );

  const removeProcedure = useCallback(
    (index: number) => {
      const proceduresCopy = cloneDeep(meta.value);
      const newProcedures = proceduresCopy.filter((_, i: number) => i !== index);
      // force react-select-dropdown re-render to compensate the components visual bug
      if (newProcedures[index]) newProcedures[index].key = random(true);
      helpers.setValue(newProcedures);
    },
    [helpers, meta.value],
  );

  const getProceduresInput = useCallback(() => {
    return meta.value.map((procedure: Procedure, index: number) => (
      <div className="referral-request-input-container" key={procedure.key}>
        <div className="procedure-header-container">
          <div className="title">Procedure {index > 0 && `#${index + 1}`}</div>
          {meta.value.length > 1 && (
            <ClickableDiv onClick={() => removeProcedure(index)} className="close-btn-container">
              <i className="icon-x" />
            </ClickableDiv>
          )}
        </div>
        <div className="text-ultra-dark text-12">
          {getReferralRequestUIString('referral_quantity_type_procedures_subtitle')}
        </div>
        <CptInputField itemIndex={index} />
        {!!meta?.value[index]?.cpt?.code && (
          <div>
            <DateInputField fieldName={`procedures[${index}].date`} />
            <div className="quantity-fields-container no-margin-bottom">
              <QuantityValueInputField itemIndex={index} />
              <QuantityTypeInputField itemIndex={index} />
            </div>
          </div>
        )}
      </div>
    ));
  }, [meta.value, removeProcedure]);

  return (
    <>
      {getProceduresInput()}
      <ProcedureActionLine addProcedure={addProcedure} procedures={meta.value} />
    </>
  );
};

export default Procedures;
