import { isEmpty } from 'lodash-es';
import { SearchFormPropsValidation } from '../../components/search-form/types';
import { requiredCptSpecialtyId } from '../../consts/specialtyMapping';
import { OrderAssistFormData, OrderAssistFormErrors } from '../../types';
import { isSpecialtyRequiresPlaceOfService } from '../../utils/search';

const errorMessages = (...params: string[]) => ({
  missingInput: `Please enter ${params[0]}`,
  advancedSearchMissingInput: 'Last Name or Facility Name are mandatory',
  invalidInput: `Please enter a valid ${params[0]}`,
});

export const isAdvancedSearchValid = ({
  npi,
  firstName,
  facilityName,
  lastName,
}: Partial<OrderAssistFormData>): boolean =>
  !((!!firstName || !!npi) && !facilityName && !lastName);

export const validateSearchForm = ({
  formData,
}: SearchFormPropsValidation): [boolean, OrderAssistFormErrors] => {
  const { specialty, placeOfService, cpt, address } = formData;

  const errors: OrderAssistFormErrors = {};

  const vimSpecialtyId = specialty?.vimSpecialtyId;
  const showPlaceOfService = !!vimSpecialtyId && isSpecialtyRequiresPlaceOfService(vimSpecialtyId);

  if (!vimSpecialtyId) {
    errors.specialty = errorMessages('specialty').missingInput;
  } else if (requiredCptSpecialtyId.includes(vimSpecialtyId) && !cpt?.length) {
    errors.cpt = errorMessages('CPT').missingInput;
  }
  if (showPlaceOfService && !placeOfService) {
    errors.placeOfService = errorMessages('Place Of Service').missingInput;
  }

  if (!isAdvancedSearchValid(formData)) {
    const advancedSearchError = errorMessages().advancedSearchMissingInput;
    errors.advancedSearch = advancedSearchError;
  }

  if (!address?.zipCode) {
    errors.address = errorMessages('address').invalidInput;
  }

  const hasErrors = !isEmpty(errors);
  return [hasErrors, errors];
};
