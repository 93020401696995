import { useProductMetadata } from '@getvim/vim-app-infra';
import { RuntimeContract } from '@getvim/vim-connect';
import { useMemo } from 'react';
import { widgetId } from '../../consts';
import { UserConfig } from './types';

const extractProductConfig = (
  config?: RuntimeContract.Outgoing.InitialDataPayload,
): UserConfig | undefined => {
  if (!config) return undefined;

  return {
    workspaceConfig: config.workspaceConfig,
    organization: config?.organizationConfig,
    product: config?.products?.find(({ name }) => name === widgetId),
    adapterName: config.adapterName,
    adapterConfig: config.adapterConfig,
    vimConnectUser: config.vimConnectUser,
    isVimOsApp: false,
  };
};

export const useRuntimeUserConfig = (): UserConfig | undefined => {
  const { productConfig: productsConfig } = useProductMetadata();

  return useMemo(() => extractProductConfig(productsConfig), [productsConfig]);
};
