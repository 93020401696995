import { ProductSource } from '@getvim-core-apps/organizations';
import { SearchFormValidator } from '../../components/search-form/types';
import { WidgetSource } from '../../types';
import { validateSearchForm as validateGenericForm } from '../generic';
import {
  isAdvancedSearchValid as isUHCAdvancedSearchValid,
  validateSearchForm as validateUHCSearchForm,
} from '../uhc/validateUhcSearchForm';

const searchFormValidator: Partial<Record<ProductSource, SearchFormValidator>> & {
  [ProductSource.UnitedHealthCare]: SearchFormValidator;
} = {
  [ProductSource.UnitedHealthCare]: {
    validator: validateUHCSearchForm,
    isAdvancedSearchValid: isUHCAdvancedSearchValid,
  },
  [ProductSource.BlueCrossBlueShield]: {
    validator: validateGenericForm,
  },
  [ProductSource.Cigna]: {
    validator: validateGenericForm,
  },
};

// Returns Validator according to insurer (default validator is UHC's).
export const getSearchFormValidator = (source: WidgetSource | undefined): SearchFormValidator => {
  return (source && searchFormValidator[source]) ?? searchFormValidator.united_health_care;
};
