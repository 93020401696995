import { isEmpty } from 'lodash-es';
import { OrderAssistFormErrors } from '../../types';
import { SearchFormPropsValidation } from '../../components/search-form/types';

const errorMessages = (...params: string[]) => ({
  missingInput: `Please enter ${params[0]}`,
  invalidInput: `Please enter a valid ${params[0]}`,
});

export const validateSearchForm = ({
  formData,
}: SearchFormPropsValidation): [boolean, OrderAssistFormErrors] => {
  const { specialty, address } = formData;

  const errors: OrderAssistFormErrors = {};

  if (!specialty?.nuccCodes?.length && !specialty?.vimSpecialtyId) {
    errors.specialty = errorMessages('specialty').missingInput;
  }

  if (!address?.zipCode) {
    errors.address = errorMessages('address').invalidInput;
  }

  const hasErrors = !isEmpty(errors);
  return [hasErrors, errors];
};
