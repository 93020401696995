import {
  ContentSupplierIdentifiersValues,
  PatientContentSupplierIdentifiers,
  ProductSource,
} from './types';

const namespaceToInsurerMap: Record<string, ProductSource> = {
  uhc: ProductSource.UnitedHealthCare,
  florida: ProductSource.BlueCrossBlueShield,
  anthem: ProductSource.AnthemBlueCross,
};

export const extractPatientSourceIdentifiers = (
  contentSupplierIdentifiers: PatientContentSupplierIdentifiers = {},
  namespace: string,
): ContentSupplierIdentifiersValues | undefined => {
  const source = namespaceToInsurerMap[namespace] ?? namespace;
  return contentSupplierIdentifiers[source];
};
