import React from 'react';
import AddressSelector from '../../molecules/address-selector';
import Text from '../../atoms/text';
import { SizeEnum, AlignEnum, ColorNameEnum, WeightEnum } from '../../types/enums';
import './index.less';
import { AddressPayload } from '../../types/AddressPayload';

export type AddressContainerProps = {
  address: AddressPayload;
  shouldDisplayNewPatientsIcon: boolean;
};

function AddressContainer({ address, shouldDisplayNewPatientsIcon }: AddressContainerProps) {
  const { selectedAddress } = address;

  return (
    <>
      <div className="result-address">
        <AddressSelector {...address} shouldDisplayNewPatientsIcon={shouldDisplayNewPatientsIcon} />
      </div>
      {selectedAddress ? (
        <>
          <div className="result-distance">
            <Text
              className="distance"
              weight={WeightEnum.bold}
              size={SizeEnum['12px']}
              align={AlignEnum.left}
              colorName={ColorNameEnum.ultraDark}
            >
              {Number.parseFloat(selectedAddress.distance).toFixed(2)}&nbsp;mi
            </Text>
          </div>
          {selectedAddress.phone ? (
            <div className="result-phone-number">
              <Text
                size={SizeEnum['16px']}
                align={AlignEnum.left}
                colorName={ColorNameEnum.themeSecondary}
                className="phone-num-icon icon-phone"
                inline
              />
              <Text
                size={SizeEnum['12px']}
                align={AlignEnum.left}
                colorName={ColorNameEnum.ultraDark}
                text={selectedAddress.phone}
                inline
              />
            </div>
          ) : undefined}
          {selectedAddress.fax ? (
            <div className="result-phone-number">
              <Text
                size={SizeEnum['16px']}
                align={AlignEnum.left}
                colorName={ColorNameEnum.themeSecondary}
                className="fax-num-icon icon-print-2"
                inline
              />
              <Text
                size={SizeEnum['12px']}
                align={AlignEnum.left}
                colorName={ColorNameEnum.ultraDark}
                text={selectedAddress.fax}
                inline
              />
            </div>
          ) : undefined}
        </>
      ) : null}
    </>
  );
}

export default AddressContainer;
