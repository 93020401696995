import { z } from 'zod';

export const osMessageResponseTypeSchema = z.enum([
  'validation_error',
  'authorization_error',
  'rate_limit_error',
  'internal_error',
  'preconditions_error',
]);

export const OSMessageResponseType = osMessageResponseTypeSchema.enum;

export type OSMessageResponseType = z.infer<typeof osMessageResponseTypeSchema>;
