import {
  ReferralRequestEventNames,
  RequestingProviderScreenClickedPayload,
  RequestingProviderScreenViewedPayload,
} from '../types/referralRequestAnalyticsEvents.types';
import { referralRequestAnalyticsClient } from '../clients/referralRequest.client';

export const trackRequestingProviderScreenViewed = (
  payload: RequestingProviderScreenViewedPayload,
): void => {
  const { requestingProvider } = payload;

  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.REQUESTING_PROVIDER_SCREEN_VIEWED,
    {
      requestingProvider,
    },
  );
};

export const trackRequestingProviderScreenClicked = (
  payload: RequestingProviderScreenClickedPayload,
): void => {
  const { requestingProvider, buttonName } = payload;
  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.REQUESTING_PROVIDER_SCREEN_CLICKED,
    {
      requestingProvider,
      buttonName,
    },
  );
};
