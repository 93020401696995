import { useFlowContext, useFlowControls } from '@getvim/flow-manager';
import React from 'react';
import { trackRequestingProviderScreenClicked } from '../../../../../analytics/referral-request';
import { requestingProviderToAnalyticsProvider } from '../../../../../analytics/referral-request/formatters';
import { ReferralRequestFlowButtonName } from '../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import { RequestingProviderDetails } from '../requesting-provider-details';
import { Stepper } from '../stepper';
import { ReferralContext, ReferralFlowManagerProps, ReferralStep, ReferralSteps } from '../types';
import { switchStep, updateIsDone } from './utils';

export const RequestingProviderStep: React.FC<
  Pick<ReferralFlowManagerProps, 'onFreeTextQuery'>
> = ({ onFreeTextQuery }) => {
  const { context, updateContext } = useFlowContext<ReferralContext>();
  const { next } = useFlowControls<ReferralSteps>();

  return (
    <>
      <Stepper steps={context.stepStatus} />
      <RequestingProviderDetails
        requestingProvider={context.requestingProvider}
        onFreeTextQuery={onFreeTextQuery}
        onNext={(data: ReferralContext['requestingProvider']) => {
          trackRequestingProviderScreenClicked({
            requestingProvider: requestingProviderToAnalyticsProvider(data),
            buttonName: ReferralRequestFlowButtonName.NEXT,
          });

          switchStep(
            ReferralStep.SERVICE_INFORMATION,
            { context, updateContext },
            { requestingProvider: data },
          );
          next('SERVICE_INFORMATION');
        }}
        updateIsDone={(isDone: boolean) =>
          updateIsDone(ReferralStep.REQUESTING_PROVIDER, isDone, {
            context,
            updateContext,
          })
        }
      />
    </>
  );
};
