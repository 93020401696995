import { ProductSource } from '@getvim-core-apps/organizations';
import { Infra } from '@getvim/vim-connect';
import { UserConfig } from '../../../hooks';

export const demoUserConfig: UserConfig = {
  organization: { id: 1, ehrSystem: 'demo', name: 'OrderAssistDemoMode' },
  adapterConfig: {
    vimConnectCapabilities: {},
    unsupportedWritebackFields: {},
  },
  product: {
    name: Infra.Apps.Product.OrderAssist,
    sources: [ProductSource.Direct],
  },
  workspaceConfig: {
    deviceId: '1234567890',
    activeWindow: { id: 1, url: 'http://demo-url:1234' },
    agentType: 'demo-agent',
    agentVersion: '1.0.0',
  },
  adapterName: 'demo-adapter',
  isVimOsApp: false,
};

export const demoAccessToken = 'demo-oa-access-token';
