import { PatientContentSupplierIdentifiers } from '@getvim-core-apps/organizations';
import { useResourcesWithPII } from '@getvim/internal-vim-os-sdk/react';
import { EhrStateWithPII } from '@getvim/internal-vim-os-sdk/types';
import { useMemo } from 'react';
import { convertPatientToRuntime } from './patient';
import { convertEncounterToRuntime } from './encounter';
import { convertOrdersToRuntime } from './orders';
import { convertReferralToRuntime } from './referral';
import { RuntimeEhrState } from '../../../types/api';

export const useEhrState = (): RuntimeEhrState => {
  const {
    ehrState: {
      patient: ehrPatient,
      encounter: ehrEncounter,
      referral: ehrReferral,
      orders: ehrOrders,
    },
  } = useResourcesWithPII();

  const patient = useMemo(
    () =>
      convertPatientToRuntime(
        ehrPatient as EhrStateWithPII['patient'] & {
          contentSupplierIdentifiers: PatientContentSupplierIdentifiers;
        },
      ),
    [ehrPatient],
  );
  const referral = useMemo(() => convertReferralToRuntime(ehrReferral), [ehrReferral]);
  const orders = useMemo(() => convertOrdersToRuntime(ehrOrders), [ehrOrders]);
  const encounter = useMemo(() => convertEncounterToRuntime(ehrEncounter), [ehrEncounter]);

  const requestNpi = useMemo(
    () =>
      ehrEncounter?.provider?.npi || ehrReferral?.referringProvider?.npi || ehrPatient?.pcp?.npi,
    [ehrEncounter?.provider?.npi, ehrReferral?.referringProvider?.npi, ehrPatient?.pcp?.npi],
  );

  return useMemo(() => {
    return {
      patient,
      encounter,
      referral,
      orders,
      requestNpi,
    };
  }, [patient, encounter, referral, orders, requestNpi]);
};
