export enum WidgetOutgoingEventName {
  OpenOrderAssist = 'OpenOrderAssist',
  ModifyAppViewState = 'ModifyAppViewState',
}

export enum WidgetIncomingEvent {
  AccessTokenChanged = 'AccessTokenChanged',
  PatientOutOfContext = 'patientOutOfContext',
  PatientInContext = 'patientInContext',
  ReferralViewed = 'referralViewed',
  ReferralClosed = 'referralClosed',
  ReferralSaved = 'referralSaved',
  OrdersViewed = 'ordersViewed',
  OrderViewed = 'orderViewed',
  OrderClosed = 'orderClosed',
  OrdersClosed = 'ordersClosed',
  PopulateOrderAssist = 'populateOrderAssist',
  NotificationActionButtonClick = 'notification-on-action-button-click',
}

// Events from widget to runtime
export enum RuntimeOutgoingEvents {
  CopyToClipboard = 'vim-connect-copy-to-clipboard',
  WriteBack = 'vim-connect-write-back',
}
