import { orderAssistLogger } from '../../../../logger';
import { AppState, SearchState } from '../../../../types';

type Action = { type: string; payload?: unknown };

export const logReducerAction = (
  reducerName: string,
  state: AppState | SearchState,
  action: Action,
): void => {
  orderAssistLogger.debug(`[${new Date().toISOString()}]: ${reducerName} - '${action.type}'`, {
    state,
    payload: action.payload,
  });
};
