import { SearchOptionsInput } from '../../types';
import { SearchInputFormatter } from '../formatter/types';

export const formatInputUhc: SearchInputFormatter = ({
  searchDispatch,
  searchFormData,
  options,
}) => {
  if (!searchDispatch) {
    throw new Error('UHC formatter is missing some props!');
  }

  const searchOptions: SearchOptionsInput = {
    pagination: {
      paginationNumber: options?.pagination?.paginationNumber ?? 1,
      ...(options?.pagination?.lastProviderCacheId && {
        providerCacheId: options?.pagination?.lastProviderCacheId,
      }),
    },
  };
  return { ...searchFormData, options: searchOptions };
};
