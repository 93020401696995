import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';
import { isUndefined } from 'lodash-es';
import { getAccessToken as getLegacyAccessToken } from '@getvim/vim-app-infra';
import { orderAssistLogger } from '../components/app/logger';
import { isVimOsAppMode } from '../vim-os-sdk-migration';

interface InternalApiConfig {
  shouldRetry: boolean;
}

const RETRIES_COUNT = 3;
const DEFAULT_CONFIG: InternalApiConfig = {
  shouldRetry: true,
};

const accessTokenTimeout = 2000;
const rejectTimeoutMS = 45_000;
const accessTokenPromise = new Promise<string>((resolve, reject) => {
  if (isVimOsAppMode) {
    return;
  }

  const rejectTimeout = setTimeout(() => {
    orderAssistLogger.warning('Timeout occurred while waiting for an access token');
    reject(new Error('Fetching access token timed out!'));
  }, rejectTimeoutMS);

  const waitForToken = () => {
    orderAssistLogger.info('Trying to get access token');
    const token = getLegacyAccessToken();
    if (token) {
      orderAssistLogger.info('Get access token succeed');

      clearTimeout(rejectTimeout);
      return resolve(token);
    }

    orderAssistLogger.info('Failed to get access token', { token });
    setTimeout(waitForToken, accessTokenTimeout);
  };
  waitForToken();
});

export class BaseInternalApi {
  private gqlClient: VimConnectGqlClient | undefined;
  private sdkGqlClient: VimConnectGqlClient | undefined;

  constructor(
    private url: string,
    private accessToken?: string,
    private config: InternalApiConfig = DEFAULT_CONFIG,
  ) {}

  protected async getClient(): Promise<VimConnectGqlClient> {
    return await (isVimOsAppMode ? this.getClientForSdk() : this.getLegacyClient());
  }

  private async getLegacyClient(): Promise<VimConnectGqlClient> {
    if (isUndefined(this.gqlClient)) {
      await accessTokenPromise;

      this.gqlClient = new VimConnectGqlClient({
        apiUrl: this.url,
        accessTokenCallback: this.accessToken
          ? () => this.accessToken ?? null
          : getLegacyAccessToken,
        retryOnNetworkError: this.config.shouldRetry,
        retryOptions: {
          delay: {
            initial: 1000,
            max: 2500,
            jitter: true,
          },
          attempts: (count, operation, error) => {
            orderAssistLogger.info('Retrying graphql request due network error', {
              attempt: count,
              error,
              operation,
            });
            return count < RETRIES_COUNT;
          },
        },
      });
    }

    return this.gqlClient;
  }

  /*
   * For SDK usage, we don't need to wait for `accessTokenPromise` to resolve, so we can have a synchronous `getClient`.
   * TODO: This should be the only `getClient` method once we'll remove the Runtime.
   * Then we can wrap the App with `ApolloClient` and get many React+Apollo capabilities. Also, this method can become private.
   */
  public getClientForSdk(): VimConnectGqlClient {
    if (!isVimOsAppMode) {
      throw new Error('This gqlClient should be used only in Vim-OS mode');
    }

    if (isUndefined(this.sdkGqlClient)) {
      this.sdkGqlClient = new VimConnectGqlClient({
        apiUrl: this.url,
        accessTokenCallback: () => null,
        retryOnNetworkError: this.config.shouldRetry,
        retryOptions: {
          delay: {
            initial: 1000,
            max: 2500,
            jitter: true,
          },
          attempts: (count, operation, error) => {
            orderAssistLogger.info('Retrying graphql request due network error', {
              attempt: count,
              error,
              operation,
            });
            return count < RETRIES_COUNT;
          },
        },
      });
    }

    return this.sdkGqlClient;
  }

  public setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;

    if (isVimOsAppMode) {
      this.getClientForSdk().setAccessToken(accessToken);
    }
  }
}
