import {
  find,
  getFreeTextQuery,
  getInsurers,
  lookup,
  getPlansByInsurer,
  getPreferred as getPreferredProviders,
  sendOutcome,
  getAppConfig,
  fetchGeo,
  getCptsByCodes,
  supportedPersonCapabilites,
} from './actions';

export const logic = {
  find,
  lookup,
  sendOutcome,
  getFreeTextQuery,
  getPreferredProviders,
  getInsurers,
  getPlansByInsurer,
  getAppConfig,
  fetchGeo,
  getCptsByCodes,
  supportedPersonCapabilites,
};
