import { useFlowControls } from '@getvim/flow-manager';
import React from 'react';
import { ReferralRequiredContainer } from '../referral-required';
import { ReferralFlowManagerProps, ReferralSteps } from '../types';

export const ReferralRequiredStep: React.FC<Pick<ReferralFlowManagerProps, 'backToResults'>> = ({
  backToResults,
}) => {
  const { next } = useFlowControls<ReferralSteps>();
  return (
    <ReferralRequiredContainer
      startFlow={() => {
        next('REQUESTING_PROVIDER');
      }}
      backToResults={backToResults}
    />
  );
};
