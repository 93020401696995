export enum NotificationType {
  'PROVIDERS_RESULTS' = 'search_results',
  'SPECIALTY_MAPPING' = 'failed_specialty_mapping',
}

export type NotificationTypeMetadata = {
  [NotificationType.PROVIDERS_RESULTS]: {
    highValue: boolean;
    preferred: boolean;
    inNetwork: boolean;
    zeroResults: boolean;
  };
  [NotificationType.SPECIALTY_MAPPING]: {
    mappingFailed: boolean;
  };
};

export type NotificationMetadata = {
  [NotificationType.PROVIDERS_RESULTS]: never;
  [NotificationType.SPECIALTY_MAPPING]: {
    ehrNativeSpecialty: string;
  };
};
