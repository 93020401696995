import { useVimAuth } from '@getvim/internal-vim-os-sdk/react';
import { useAccessToken } from '@getvim/vim-app-infra';
import { useEffect, useMemo } from 'react';
import { isVimOsAppMode } from '../../../../../vim-os-sdk-migration';
import { internalApi } from '../../../../../internal-api';

const useSdkAccessToken = () => {
  const { accessToken } = useVimAuth();

  return useMemo(() => {
    return [accessToken];
  }, [accessToken]);
};

const accessTokenHook = isVimOsAppMode ? useSdkAccessToken : useAccessToken;

export const useOrderAssistAccessToken = () => {
  const [accessToken] = accessTokenHook();

  useEffect(() => {
    if (accessToken) {
      internalApi.orderAssistBff.setAccessToken(accessToken);
    }
  }, [accessToken]);

  return [accessToken];
};
