export const iso6393languages = [
  { value: 'eng', text: 'English' },
  { value: 'spa', text: 'Spanish' },
  { value: 'zho', text: 'Chinese' },
  { value: 'tgl', text: 'Tagalog' },
  { value: 'vie', text: 'Vietnamese' },
  { value: 'ara', text: 'Arabic' },
  { value: 'fra', text: 'French' },
  { value: 'kor', text: 'Korean' },
  { value: 'rus', text: 'Russian' },
  { value: 'deu', text: 'German' },
  { value: 'hat', text: 'Haitian' },
  { value: 'hin', text: 'Hindi' },
  { value: 'por', text: 'Portuguese' },
  { value: 'ita', text: 'Italian' },
  { value: 'pol', text: 'Polish' },
  { value: 'yid', text: 'Yiddish' },
  { value: 'jpn', text: 'Japanese' },
];
