import { BadgeTypeEnum } from '@getvim/atomic-ui';
import { FloatingBadgesEnum, OrderAssistOptionType } from '../components/app/types';
import { analyticsManager } from './analyticsManager';

export const sendBackToSearchAnalytic = (): void => {
  analyticsManager.track('order_assist_back_to_search', undefined);
};

export const sendTooltipHoveredAnalytic = ({
  iconName,
  cardType,
  cardRank,
  isAlternativeResult,
  isPreferredResult,
}: {
  iconName: BadgeTypeEnum | FloatingBadgesEnum;
  cardType: OrderAssistOptionType;
  cardRank?: number;
  isAlternativeResult: boolean;
  isPreferredResult: boolean;
}): void => {
  analyticsManager.track('order_assist_tooltip_hovered', {
    card_details: { cardType, cardRank, isAlternativeResult, isPreferredResult },
    tooltip_item_title: iconName,
  });
};

export const sendAddressChangeAnalytic = ({
  name,
  addressRank,
  cardType,
  cardRank,
  isAlternativeResult,
  isPreferredResult,
}: {
  name: string;
  addressRank: number;
  cardType: OrderAssistOptionType;
  cardRank?: number;
  isAlternativeResult: boolean;
  isPreferredResult: boolean;
}): void => {
  analyticsManager.track('order_assist_change_address', {
    card_details: { name, cardType, cardRank, addressRank, isAlternativeResult, isPreferredResult },
  });
};

export const trackPreferredProvidersInformationTooltipHovered = (tooltipItemTitle: string): void =>
  analyticsManager.track('order_assist_preferred_providers_information_tooltip_hovered', {
    tooltip_item_title: tooltipItemTitle,
  });
