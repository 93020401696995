import { ClickableDiv } from '@getvim/atomic-ui';
import React from 'react';

interface PlusMinusIconsProps {
  value: number;
  setValue: (newValue: number) => void;
}

const PlusMinusIcons: React.FC<PlusMinusIconsProps> = ({ value, setValue }) => {
  return (
    <div className="quantity-input-icon-container">
      <ClickableDiv className="character" onClick={() => setValue(value + 1)}>
        +
      </ClickableDiv>
      <ClickableDiv
        className="character"
        onClick={() => {
          if (value - 1 > 0) setValue(value - 1);
        }}
      >
        -
      </ClickableDiv>
    </div>
  );
};

export default PlusMinusIcons;
