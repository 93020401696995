import { ModuleNames, VimCommunicationProvider } from '@getvim/vim-app-infra';
import React, { FC, PropsWithChildren } from 'react';
import { isVimOsAppMode } from '../vim-os-sdk-migration';

const communicationModules = [
  ModuleNames.OrderAssistWidget,
  ModuleNames.OrderAssistReferralsSelectButtonWidget,
  ModuleNames.OrderAssistDIOrdersSelectButtonWidget,
  ModuleNames.OrderAssistLabOrdersSelectButtonWidget,
  ModuleNames.Runtime,
];

export const VimCommunicationOAProvider: FC<PropsWithChildren<{ widgetId: string }>> = ({
  children,
  widgetId,
}) => {
  return isVimOsAppMode ? (
    <>{children}</>
  ) : (
    <VimCommunicationProvider widgetId={widgetId} communicationModules={communicationModules}>
      {children}
    </VimCommunicationProvider>
  );
};
