import { useFlowContext, useFlowControls } from '@getvim/flow-manager';
import React from 'react';
import { trackStatusScreenClicked } from '../../../../../analytics/referral-request';
import {
  StatusScreenPayload,
  ReferralRequestFlowButtonName,
} from '../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import { ReferralResultContainer } from '../result';
import { ReferralContext, ReferralFlowManagerProps, ReferralStep, ReferralSteps } from '../types';
import { switchStep } from './utils';

export const ResultStep: React.FC<Pick<ReferralFlowManagerProps, 'backToResults'>> = ({
  backToResults,
}) => {
  const { context, updateContext } = useFlowContext<ReferralContext>();
  const { skipTo } = useFlowControls<ReferralSteps>();

  return (
    <ReferralResultContainer
      onClose={(data?: StatusScreenPayload) => {
        if (data) {
          trackStatusScreenClicked({
            ...data,
            buttonName: ReferralRequestFlowButtonName.CLOSE,
          });
        }
        backToResults();
      }}
      onRetry={() => {
        switchStep(
          ReferralStep.REQUESTING_PROVIDER,
          { context, updateContext },
          {
            response: undefined,
          },
        );

        skipTo(ReferralStep.REQUESTING_PROVIDER);
      }}
      status={context.response.status}
      procedures={context.response.procedures ?? []}
      statusReason={context.response.reason}
      certificationNumber={context.response.certificationNumber}
      requestSubmissionTime={context.requestSubmissionTime ?? 0}
      updateCodeStatus={context.updateCodeStatus}
    />
  );
};
