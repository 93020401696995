import { Entities } from '@getvim/vim-connect';
import { Buffer } from 'buffer';
import dayjs from 'dayjs';
import { UserConfig } from '../../../../../hooks';
import getInsurerLogoURL from '../../../../../utils/getInsurerLogoURL';
import {
  OrderAssistOption,
  OrderAssistResultsPDFInput,
  Patient,
  PDFProviderDetails,
} from '../../../types';
import addressFormat from '../../../utils/addressFormatter';
import { capitalizeAsName, isProviderResult } from '../../../utils/general';
import { getSelectedItemAddress } from '../../../utils/search/getSelectedAddress';

const getProviderName = (option: OrderAssistOption): string => {
  if (isProviderResult(option)) {
    return capitalizeAsName(
      `${option.details.complexName.firstName} ${option.details.complexName.lastName}`,
    );
  }
  if (option.details.acoName) {
    return capitalizeAsName(option.details.acoName);
  }
  return capitalizeAsName(option.details.name);
};

const getProviderDetailsPDFInput = (item: OrderAssistOption): PDFProviderDetails => {
  const selectedAddress = getSelectedItemAddress(item);
  const fullAddress = addressFormat(selectedAddress);
  const providerName = getProviderName(item);
  const specialties = item.details.specialties.map((x) => capitalizeAsName(x.description));
  const pdfDetails: PDFProviderDetails = {
    providerName,
    specialties,
    officeName: selectedAddress.officeName || undefined,
    address: fullAddress || undefined,
    phoneNumber: selectedAddress.phone,
    faxNumber: selectedAddress.fax,
    npi: item.details.npi,
  };
  return pdfDetails;
};

export const resultsPDFFormatters = {
  formatFilename: (patient?: Patient): string => {
    const date = dayjs().format('MM-DD-YY');
    const prefix = patient?.demographics.firstName ? `${patient?.demographics.firstName} - ` : '';
    return `${prefix}Order Assist Results - ${date}.pdf`;
  },
  formatInput: (
    shouldImproveCopyExportSearchResults: boolean,
    productConfig: UserConfig,
    patient?: Entities.Patient,
    searchResults?: OrderAssistOption[],
    featuredResults?: OrderAssistOption[],
    alternativeResults?: OrderAssistOption[],
  ): OrderAssistResultsPDFInput => {
    if (!productConfig.vimConnectUser) {
      throw new Error('cannot generated pdf input because vimConnectUser is undefined');
    }
    const { organization, vimConnectUser } = productConfig;
    const { firstName, lastName } = vimConnectUser;

    const logoURL = patient?.insurance?.insurer
      ? getInsurerLogoURL(patient?.insurance?.insurer)
      : undefined;
    const input: OrderAssistResultsPDFInput = {
      ...(shouldImproveCopyExportSearchResults
        ? {
            patient: patient
              ? {
                  name: `${patient.demographics.firstName} ${patient.demographics.lastName}`,
                  dateOfBirth: dayjs(patient.demographics.dateOfBirth).format('MMM D, YYYY'),
                  age: dayjs().diff(patient.demographics.dateOfBirth, 'y'),
                }
              : undefined,
          }
        : undefined),
      clinicName: organization.name,
      exportingUser: `${firstName} ${lastName}`,
      dateOfExporting: dayjs().format('MMM D YYYY'),
      results: searchResults?.map(getProviderDetailsPDFInput),
      featuredResults: featuredResults?.map(getProviderDetailsPDFInput),
      alternativeResults: alternativeResults?.map(getProviderDetailsPDFInput),
      datasourceLogo: logoURL ? Buffer.from(logoURL).toString('base64') : undefined,
    };
    return input;
  },
};
