import React from 'react';
import { useField } from 'formik';
import {
  Geo,
  InputStyle,
  InputStyleWrapper,
  InputValidationMessage,
  LocationSelect,
} from '@getvim/atomic-ui';
import '../InputField.less';
import './AddressInputField.less';
import addressFormat, { formatGeoToAddress } from '../../../../../utils/addressFormatter';

export const AddressInputField: React.FC = () => {
  const [, meta, helpers] = useField('address');

  const onChange = (geoInput: Geo | null) => {
    helpers.setTouched(true);
    const geoAsAddress = formatGeoToAddress(geoInput);
    helpers.setValue(geoAsAddress && { ...geoAsAddress, fullAddress: addressFormat(geoAsAddress) });
  };

  return (
    <div className="referral-request-input-container">
      <InputStyleWrapper
        className="location-input-wrapper input-wrapper"
        inputStyle={InputStyle.pillMedium}
        hasError={meta.touched && !!meta.error}
        rightIcon={<></>}
      >
        <div className="input">
          <LocationSelect
            label="Location"
            placeholder=" "
            isGoogleApiLoaded
            showChevronIcon
            onChange={(changedGeo) => onChange(changedGeo)}
            {...(meta.value ? { defaultAddressInput: meta.value.fullAddress } : {})}
          />
        </div>

        {meta.touched && meta.error && (
          <InputValidationMessage>Address is required</InputValidationMessage>
        )}
      </InputStyleWrapper>
    </div>
  );
};
