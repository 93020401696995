export interface AppState {
  isPatientEligible?: boolean;
}

export enum AppStateActionType {
  SET_PATIENT_ELIGIBLE = 'SET_PATIENT_ELIGIBLE',
  CLEAR_PATIENT = 'CLEAR_PATIENT',
}

export type AppStateAction =
  | {
      type: AppStateActionType.SET_PATIENT_ELIGIBLE;
      payload: boolean;
    }
  | {
      type: AppStateActionType.CLEAR_PATIENT;
    };
