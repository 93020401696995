import {
  ErrorScreenClickedPayload,
  ReferralRequestEventNames,
} from '../types/referralRequestAnalyticsEvents.types';
import { referralRequestAnalyticsClient } from '../clients/referralRequest.client';

export const trackErrorScreenViewed = (): void => {
  referralRequestAnalyticsClient.track(ReferralRequestEventNames.ERROR_SCREEN_VIEWED, {});
};

export const trackErrorScreenClicked = (payload: ErrorScreenClickedPayload): void => {
  const { buttonName } = payload;
  referralRequestAnalyticsClient.track(ReferralRequestEventNames.ERROR_SCREEN_CLICKED, {
    buttonName,
  });
};
