import {
  AlignEnum,
  ClickableDiv,
  DropdownMenuItem,
  PlacementEnum,
  SizeEnum,
  Text,
  Tooltip,
  WeightEnum,
} from '@getvim/atomic-ui';
import classNames from 'classnames';
import React from 'react';
import { OrderAssistScreenName } from '../../../../../analytics/types';
import { DISABLED_EXPORT_BUTTON_MESSAGE } from '../../../consts/titlebarMessages';
import { GetOrderAssistResult } from '../../../types';
import { DownloadState } from '../../modals/DownloadModal';
import OrderAssistExportMenu from './ExportMenu';
import { useFlowState } from '@getvim/flow-context-provider/build';
import { AppRequiredState } from '../../order-assist-app/OrderAssistAppWrapper';

interface OrderAssistResultsTitleProp {
  title: string;
  screenName: OrderAssistScreenName;
  searchDetails?: string;
  results: GetOrderAssistResult | undefined;
  onClick: () => void;
  dropdownMenuItems?: DropdownMenuItem<boolean>[];
  downloadState?: DownloadState;
  onExportModalClose?: () => void;
  isReadonlyMode?: boolean;
}

const SearchResultsTitle: React.FC<OrderAssistResultsTitleProp> = ({
  title,
  screenName,
  searchDetails,
  results,
  onClick,
  dropdownMenuItems,
  downloadState,
  onExportModalClose,
  isReadonlyMode,
}) => {
  const hasResults = !!results?.searchResults?.length || !!results?.alternativeResults?.length;

  return (
    <div className="search-results-container-title">
      <ClickableDiv onClick={onClick} className="search-results-container-inner">
        <i className="icon-chevron-left back-icon text-ultra-dark" />
        <div className="results-title-text">
          <Text
            className="label"
            size={SizeEnum['14px']}
            weight={WeightEnum.semibold}
            align={AlignEnum.left}
            text={title}
          />
          <Tooltip
            tooltipContent={searchDetails}
            contentMaxLength={hasResults ? 51 : 56}
            conditionalTooltip
            placement="bottom"
            bgColor="white"
            className="search-results-title-tooltip"
          >
            <Text
              className={classNames('label search-details', {
                'padding-right': !hasResults,
              })}
              ellipsis
              size={SizeEnum['12px']}
              align={AlignEnum.left}
              text={searchDetails}
            />
          </Tooltip>
        </div>
      </ClickableDiv>
      {hasResults && dropdownMenuItems && (
        <div className="dropdown-menu-outer">
          <Tooltip
            placement="left"
            tooltipContent={DISABLED_EXPORT_BUTTON_MESSAGE}
            className="export-menu-tooltip-disabled"
            bgColor="white"
            hideTooltip={!isReadonlyMode}
          >
            <OrderAssistExportMenu
              dropdownMenuItems={dropdownMenuItems}
              downloadState={downloadState}
              onExportModalClose={onExportModalClose}
              screenName={screenName}
              additionalInformation={{
                ...(screenName === OrderAssistScreenName.SEARCH_RESULTS && {
                  page_number: results?.pagination.pageNumber,
                }),
              }}
              isReadonlyMode={isReadonlyMode}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SearchResultsTitle;
