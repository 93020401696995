import { useIsAppOpenInHub } from '@getvim/components-hooks-vim-connect';
import { useAppState } from '@getvim/internal-vim-os-sdk/react';
import { isVimOsAppMode } from '../vim-os-sdk-migration';
import { widgetId } from '../consts';

const useRuntimeIsAppOpen = (initialState?: boolean) => {
  return useIsAppOpenInHub(widgetId, undefined, initialState);
};

const useSdkIsAppOpen = () => {
  const { isAppOpen } = useAppState();
  return isAppOpen;
};

export const useIsAppOpen: (initialState?: boolean) => boolean | undefined = isVimOsAppMode
  ? useSdkIsAppOpen
  : useRuntimeIsAppOpen;
