import { BrandedH1, Button, Tooltip } from '@getvim/atomic-ui';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import React from 'react';
import { trackReferralRequestStartScreenClicked } from '../../../../../analytics/referral-request';
import { ReferralRequestButtonName } from '../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import consts from '../consts';
import './ReferralRequired.less';

const REFERRAL_REQUEST_DISABLED_MESSAGE =
  'The in-app Availity referral approval is temporarily down. We expect this to be fixed shortly. In the meantime, please login to the external Availity portal to submit your referral requests.';

interface ReferralRequiredContainerProps {
  startFlow: () => void;
  backToResults: () => void;
}

const ReferralRequiredContainer: React.FC<ReferralRequiredContainerProps> = ({
  startFlow,
  backToResults,
}) => {
  const [disableReferralRequestSubmitButton] = useFeatureFlag({
    flagName: 'disableReferralRequestSubmitButton',
    defaultValue: false,
  });

  return (
    <div className="referral-required-container">
      <BrandedH1 text={consts.REFERRAL_REQUIRED_TITLE} size="small" />
      <div className="add-file-img-container">
        <img className="add-file-img" src="https://static.getvim.com/img/AddToChart.svg" alt="" />
      </div>
      <div className="response-buttons-container">
        <Button
          text="Close"
          buttonType="small"
          bgColor="themedOutline"
          width="small"
          onClick={() => {
            trackReferralRequestStartScreenClicked({ buttonName: ReferralRequestButtonName.CLOSE });
            backToResults();
          }}
        />
        <Tooltip
          tooltipContent={REFERRAL_REQUEST_DISABLED_MESSAGE}
          hideTooltip={!disableReferralRequestSubmitButton}
          key={REFERRAL_REQUEST_DISABLED_MESSAGE}
        >
          <Button
            text="Submit now"
            buttonType="small"
            width="smallMedium"
            disabled={disableReferralRequestSubmitButton}
            onClick={() => {
              trackReferralRequestStartScreenClicked({
                buttonName: ReferralRequestButtonName.SUBMIT,
              });
              startFlow();
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ReferralRequiredContainer;
