import React, { useEffect } from 'react';
import { Button, ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import './NoAnswer.less';
import consts from '../../consts';
import {
  trackTimeoutScreenViewed,
  trackTimeoutScreenClicked,
} from '../../../../../../analytics/referral-request';
import { TimeoutScreenButtonName } from '../../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import { getReferralRequestUIString } from '../../strings';

interface NotDeterminedProps {
  onClose: () => void;
}

const NoAnswer: React.FC<NotDeterminedProps> = ({ onClose }) => {
  const goToExternal = () => {
    window.open(consts.AVAILITY_EXTERNAL_PORTAL_URL, '_blank');
    trackTimeoutScreenClicked({ buttonName: TimeoutScreenButtonName.GO_TO_EXTERNAL });
  };

  useEffect(() => {
    trackTimeoutScreenViewed();
  }, []);

  return (
    <div className="referral-status-container">
      <img
        className="added-to-chart"
        src="https://static.getvim.com/img/added-to-chart.svg"
        alt=""
      />
      <div className="titles-container">
        <Text
          text={getReferralRequestUIString('referral_result_referral_submitted_title')}
          size={SizeEnum['14px']}
          weight={WeightEnum.semibold}
          colorName={ColorNameEnum.theme}
        />
        <Text
          text={getReferralRequestUIString('referral_result_no_answer_subtitle')}
          size={SizeEnum['14px']}
          weight={WeightEnum.light}
          colorName={ColorNameEnum.theme}
        />
      </div>
      <div className="not-determined-buttons">
        <Button
          onClick={goToExternal}
          text={getReferralRequestUIString(
            'referral_result_no_answer_go_to_external_portal_button',
          )}
          buttonType="small"
        />
        <Button
          className="closeButton margin-top-10"
          onClick={() => {
            trackTimeoutScreenClicked({ buttonName: TimeoutScreenButtonName.CLOSE });
            onClose();
          }}
          text="Close"
          buttonType="small"
          bgColor="themedOutline"
        />
      </div>
    </div>
  );
};

export default NoAnswer;
