import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { ProcedureNoteResponse, ProcedureStatusReasonsResponse } from '../../../types';
import ProcedureStatusCard from './ProcedureStatusCard';
import ProcedureStatusCardItem from './ProcedureStatusCardItem';
import { getReferralRequestUIString } from '../../../strings';
import { getStatusToUiStatus } from '../../../ReferraRequestStates';

interface ProcedureStatusProps {
  status: string;
  cpt: { code: string; description: string };
  serviceQuantity: string;
  quantityType: string;
  statusReasons?: ProcedureStatusReasonsResponse[];
  notes?: ProcedureNoteResponse[];
  startDate?: string;
  expirationDate?: string;
}

const ProcedureStatus: React.FC<ProcedureStatusProps> = ({
  status,
  cpt,
  serviceQuantity,
  quantityType,
  statusReasons,
  notes,
  startDate,
  expirationDate,
}) => {
  const statusReason = statusReasons?.[0].value;
  const description = notes?.[0]?.message;

  return (
    <div className="text-center padding-box-5 user-select-text">
      <ProcedureStatusCard
        procedureCode={`${cpt.code} - ${cpt.description}`}
        subHeader={
          <span className={classNames(status, 'status')}> {getStatusToUiStatus(status)}</span>
        }
      >
        {statusReason && (
          <ProcedureStatusCardItem
            label={getReferralRequestUIString('referral_result_status_reason_label')}
            value={statusReason}
          />
        )}
        {startDate && (
          <ProcedureStatusCardItem
            label={getReferralRequestUIString('referral_procedure_status_start_date_label')}
            value={`${dayjs(startDate).format('MM/DD/YYYY')}`}
          />
        )}
        {expirationDate && (
          <ProcedureStatusCardItem
            label={getReferralRequestUIString('referral_procedure_status_expiration_date_label')}
            value={`${dayjs(expirationDate).format('MM/DD/YYYY')}`}
          />
        )}
        <ProcedureStatusCardItem
          label={getReferralRequestUIString('referral_procedure_status_procedure_quantity_label')}
          value={`${serviceQuantity} ${quantityType}`}
        />
        {description && <ProcedureStatusCardItem label={description} value="" />}
      </ProcedureStatusCard>
    </div>
  );
};

export default ProcedureStatus;
