import { SearchInputFormatter } from './types';
import { SearchOptionsInput } from '../../types';

export const noCptIcdFormatter: SearchInputFormatter = ({ searchFormData, options }) => {
  const searchOptions: SearchOptionsInput = {
    pagination: {
      paginationNumber: options?.pagination?.paginationNumber ?? 1,
    },
  };

  return { ...searchFormData, cpt: [], icd: [], options: searchOptions };
};
