import { CPT, ICD, Order, OrderType } from '@getvim-os/types';
import type { Diagnosis, ProceduresCodes, OrderWithPII } from '@getvim/internal-vim-os-sdk/types';

export const convertOrdersToRuntime = (
  ehrOrders: OrderWithPII[] | undefined,
): Order[] | undefined => {
  if (!ehrOrders) {
    return;
  }

  const orders = ehrOrders.map<Order>((order) => {
    const {
      identifiers,
      basicInformation,
      procedureCodes,
      assessments
    } = order;

    return {
      id: identifiers?.ehrOrderId,
      encounterId: basicInformation?.ehrEncounterId,
      icd: convertIcds(assessments?.assessments),
      cpts: convertCpts(procedureCodes?.procedureCodes),
      createdDate: basicInformation?.createdDate,
      type: basicInformation?.type as OrderType,
    };
  });
  return orders;
};

const convertIcds = (icds: Diagnosis[] = []): ICD[] =>
  icds.map<ICD>(({ code, description }) => ({
    name: description,
    code,
  }));

const convertCpts = (cpts: ProceduresCodes[] = []): CPT[] =>
  cpts.map<CPT>(({ code, description }) => ({
    name: description,
    code,
  }));
