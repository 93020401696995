import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { FlowContextWrapper } from '@getvim/flow-context-provider';
import { Loader } from '@getvim/atomic-ui';
import { SourceConfigurations } from '../types';
import { orderAssistLogger } from '../logger';
import { AppRequiredState } from './order-assist-app/OrderAssistAppWrapper';

interface Props {
  config: SourceConfigurations | undefined;
}

export type SearchRequiredState = AppRequiredState & {
  sourceConfig: SourceConfigurations;
};

export const SourceConfigWrapper: React.FC<PropsWithChildren<Props>> = ({ config, children }) => {
  const state = useMemo(() => {
    return {
      sourceConfig: Promise.resolve(config),
    };
  }, [config]);

  const onError = useCallback((error) => {
    orderAssistLogger.error('Failed to fetch state in SourceConfigWrapper!', { error });
  }, []);

  return (
    <FlowContextWrapper state={state} loader={<Loader type="dots" />} onError={onError}>
      {children}
    </FlowContextWrapper>
  );
};
