import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Standard } from '@getvim/vim-connect';
import { ActionButtonAction } from '@getvim/utils-vim-connect-communication';
import { trackSpecialtyMappingNotificationTriggered } from '../../../analytics';
import { HandleNotificationActionProps } from '../../../components/app/types';
import { orderAssistLogger } from '../../../components/app/logger';
import { useEhrState } from '../../../stores/ehr-state/EhrState.store';
import { useAutoPopup } from '../../useAutoPopupApp';
import { isVimOsAppMode } from '../../../vim-os-sdk-migration';
import { getSpecialtyNotificationConfig } from './config';
import { useNotification } from '../use-notifications';

interface UseSpecialtyNotificationsReturn {
  notify: (specialty: string) => void;
  hide: () => void;
  handleNotificationAction: ({ action, notificationId }: HandleNotificationActionProps) => void;
}

export const useSpecialtyNotifications = (): UseSpecialtyNotificationsReturn => {
  const { state } = useEhrState();
  const [lastNotifiedId, setLastId] = useState<string>();
  const [lastNotifiedReferralId, setLastNotifiedReferralId] = useState<string>();

  const autoPopup = useAutoPopup();
  const { triggerNotification, triggerHideNotification } = useNotification();

  const hide = useCallback(() => {
    if (lastNotifiedId) {
      triggerHideNotification(lastNotifiedId);
      setLastId(undefined);
    }
  }, [lastNotifiedId, triggerHideNotification]);

  const notify = useCallback(
    (ehrSpecialty: string) => {
      if (lastNotifiedReferralId && state.referral?.vimReferralId === lastNotifiedReferralId) {
        orderAssistLogger.debug('skipping notification - referral already notified');
        return;
      }

      setLastNotifiedReferralId(state.referral?.vimReferralId);
      const id = uuid();
      trackSpecialtyMappingNotificationTriggered(id, ehrSpecialty);
      triggerNotification(getSpecialtyNotificationConfig(id, autoPopup));
      setLastId(id);
    },
    [lastNotifiedReferralId, state.referral?.vimReferralId, triggerNotification, autoPopup],
  );

  const handleRuntimeNotificationAction = useCallback(
    ({ action, notificationId }: HandleNotificationActionProps) => {
      if (isVimOsAppMode || notificationId !== lastNotifiedId) {
        return;
      }

      switch (action) {
        case ActionButtonAction.OPEN_APP: {
          autoPopup({
            expandingType: Standard.Entities.UIElements.ExpandingType.VIM_NOTIFICATION_MANUAL,
          });
          break;
        }
        default: {
          orderAssistLogger.warning('Got unexpected action in specialty notification!', {
            action,
            notificationId,
          });
        }
      }
    },
    [lastNotifiedId, autoPopup],
  );

  return { notify, hide, handleNotificationAction: handleRuntimeNotificationAction };
};
