import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Dialog, DialogBackdrop, DialogDisclosure, DialogStateReturn } from 'reakit/Dialog';
import { SizeEnum, WeightEnum } from '../../types/enums';
import Button from '../button/index';
import Text from '../text/index';
import './index.less';

interface CustomAtomicDialog {
  clasName?: string;
  title?: string;
  showHeader?: boolean;
  lightBackdrop?: boolean;
  showContentPadding?: boolean;
  footerContent?: JSX.Element;
  disclosure?: any;
  dialog: DialogStateReturn;
  className?: string | string[];
  dialogStyle?: string | 'sidebar-style';
  onCloseClick?: () => void;
}

const AtomicDialog: React.FC<CustomAtomicDialog> = ({
  dialog,
  disclosure,
  className,
  title,
  showHeader = true,
  lightBackdrop = true,
  showContentPadding = true,
  footerContent,
  children,
  dialogStyle,
  onCloseClick,
  ...props
}) => {
  const handleCloseClick = useCallback(() => {
    if (onCloseClick) {
      onCloseClick();
    }
    dialog.hide();
  }, [dialog, onCloseClick]);

  return (
    <>
      {disclosure && (
        <DialogDisclosure {...dialog} ref={disclosure.ref} {...disclosure.props}>
          {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
        </DialogDisclosure>
      )}

      <DialogBackdrop
        className={classNames(
          'atomic-dailog-backdrop',
          { 'light-backdrop': lightBackdrop },
          dialogStyle,
        )}
        {...dialog}
      >
        <Dialog
          {...dialog}
          {...props}
          className={classNames('atomic-dialog', className, dialogStyle)}
        >
          {showHeader && (
            <div className="atomic-dialog-header">
              <Text
                text={title}
                className="atomic-dialog-title"
                size={SizeEnum['14px']}
                weight={WeightEnum.semibold}
              />
              <Button
                className="atomic-dialog-close-icon"
                buttonType="link"
                onClick={handleCloseClick}
              >
                <i className="icon-x" />
              </Button>
            </div>
          )}
          <div
            className={classNames('atomic-dialog-content', {
              'padding-box-20': showContentPadding,
            })}
          >
            {children}
          </div>
          {footerContent && <div className="atomic-dialog-footer">{footerContent}</div>}
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default AtomicDialog;
export { useDialogState, type DialogStateReturn } from 'reakit/Dialog';
