import {
  AlignEnum,
  ColorNameEnum,
  SizeEnum,
  Text,
  WeightEnum,
  Popover,
  usePopoverState,
} from '@getvim/atomic-ui';
import type { PopoverStateReturn } from '@getvim/atomic-ui';
import { capitalize } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { iso6393languages } from '../../../../utils/languages';
import { ActiveFilter, DataFilters, SearchFilters } from '../../types';
import './FiltersWrapper.less';
import LanguagePopover from './LanguagePopover';
import DistancePopover from './DistancePopover';
import { analyticsManager } from '../../../../analytics/analyticsManager';
import { DropdownDismissTrigger } from '../../../../analytics/types';
import { getDefaultSearchFilters, getDistanceMarks } from '../../consts/searchFilters';

interface FiltersWrapperProps {
  onApply: (newFilters: DataFilters) => void;
  lastUsedSearchFilters?: SearchFilters;
  isLanguageDisabled: boolean;
  widerDistance: boolean;
  defaultDistanceFilter: number;
}

const FiltersWrapper: React.FC<FiltersWrapperProps> = ({
  onApply,
  lastUsedSearchFilters,
  isLanguageDisabled,
  widerDistance,
  defaultDistanceFilter,
}: FiltersWrapperProps) => {
  const defaultFilters = getDefaultSearchFilters(widerDistance, defaultDistanceFilter);
  const initialFilters = useMemo<SearchFilters>(
    () => lastUsedSearchFilters ?? getDefaultSearchFilters(widerDistance, defaultDistanceFilter),
    [lastUsedSearchFilters, widerDistance, defaultDistanceFilter],
  );

  const [filters, setFilters] = useState<SearchFilters>(initialFilters);

  const distancePopover = usePopoverState({
    placement: 'bottom-start',
    gutter: 7,
  });

  const languagePopover = usePopoverState({
    placement: 'bottom-start',
    gutter: 7,
  });

  const filtersPopovers: Record<ActiveFilter, PopoverStateReturn> = {
    [ActiveFilter.Distance]: distancePopover,
    [ActiveFilter.Language]: languagePopover,
  };

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const distanceMarks = getDistanceMarks(widerDistance);
  const currentDistanceMark = +(
    Object.keys(distanceMarks).find((key) => distanceMarks[+key] === filters.distance) || 1
  );
  const filtersCount = Object.keys(initialFilters).filter(
    (filterKey) => initialFilters[filterKey] !== defaultFilters[filterKey],
  ).length;

  const handleApply = useCallback(
    (newFilters: SearchFilters) => {
      onApply({ userFilters: newFilters, resultsFilters: newFilters });
    },
    [onApply],
  );

  const handleLanguageChange = useCallback(
    (language: string) => {
      const newFilters = { ...filters, language };
      setFilters(newFilters);
      handleApply(newFilters);
      const languageTitle = iso6393languages.find((x) => x.value === language)?.text || language;
      analyticsManager.track('filters_selected', {
        filter_type: ActiveFilter.Language,
        filter_value: languageTitle,
      });
    },
    [filters, handleApply],
  );

  const handleDistanceChange = useCallback(
    (value: number) => {
      const distance = distanceMarks[value];
      const newFilters = { ...filters, distance };
      setFilters(newFilters);
      handleApply(newFilters);
      analyticsManager.track('filters_selected', {
        filter_type: ActiveFilter.Distance,
        filter_value: distance,
      });
    },
    [distanceMarks, filters, handleApply],
  );

  const getFilterValue = (filterType: ActiveFilter): number | string => {
    if (filterType === ActiveFilter.Language) {
      return iso6393languages.find((x) => x.value === filters.language)?.text || filters.language;
    }
    return filters[filterType.toString()];
  };

  const onToggleFilter = (filterType: ActiveFilter) => {
    const popover = filtersPopovers[filterType];
    const filterValue = getFilterValue(filterType);
    const eventProps = { filter_type: filterType, filter_value: filterValue };

    if (popover.visible) {
      analyticsManager.track('filters_closed', {
        ...eventProps,
        filter_close_method: DropdownDismissTrigger.TOGGLE_BUTTON,
      });
      popover.hide();
    } else {
      analyticsManager.track('filters_clicked', eventProps);
      popover.show();
    }
  };

  const onDismissFilter = (filterType: ActiveFilter) => {
    const filterValue = getFilterValue(filterType);
    const eventProps = {
      filter_type: filterType,
      filter_value: filterValue,
      filter_close_method: DropdownDismissTrigger.OUTSIDE_CLICK,
    };
    analyticsManager.track('filters_closed', eventProps);
    filtersPopovers[filterType].hide();
  };

  const onFilterSelection = (filterType: ActiveFilter, event: React.MouseEvent) => {
    // propagate the click and manually close the popover so "onDismissFilter" won't
    // be invoked after selecting an option
    event.stopPropagation();
    filtersPopovers[filterType].hide();
  };

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation();

    setFilters(defaultFilters);
    handleApply(defaultFilters);

    Object.entries(filtersPopovers).forEach(([filterType, popover]) => {
      if (popover.visible) {
        onDismissFilter(filterType as ActiveFilter);
      }
    });
  };

  const distancePopoverContent = (
    <div id="distance-popover" className="popover-wrapper">
      <div
        className="distance-popover-content"
        onClick={(event) => onFilterSelection(ActiveFilter.Distance, event)}
        tabIndex={0}
        role="button"
        onKeyDown={() => {}}
      >
        <DistancePopover
          onDistanceChange={handleDistanceChange}
          items={distanceMarks}
          value={currentDistanceMark.toString()}
        />
      </div>
    </div>
  );

  const languagePopoverContent = (
    <div id="language-popover" className="popover-wrapper language-popover-wrapper">
      <Text
        size={SizeEnum['14px']}
        weight={WeightEnum.medium}
        colorName={ColorNameEnum.theme}
        align={AlignEnum.left}
      />

      <div style={{ position: 'relative' }}>
        <div
          is="tooltip"
          className={isLanguageDisabled ? 'disabled-tooltip' : ''}
          tooltip-data={isLanguageDisabled ? 'Not supported for this payer' : ''}
          onClick={(event) => onFilterSelection(ActiveFilter.Language, event)}
          tabIndex={0}
          role="button"
          onKeyDown={() => {}}
        >
          <LanguagePopover
            items={iso6393languages}
            onLanguageChange={handleLanguageChange}
            value={filters.language}
            searchPlaceholder="Search a language"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div role="none" className="filters-wrapper">
      <Text
        size={SizeEnum['16px']}
        weight={WeightEnum.light}
        colorName={ColorNameEnum.ultraDark}
        className="icon-filter-sliders filters-icon"
      />
      <div
        role="none"
        className={`filter-button-wrapper ${distancePopover.visible ? 'active' : ''}`}
        onClick={() => onToggleFilter(ActiveFilter.Distance)}
      >
        <Popover
          popover={distancePopover}
          hide={() => onDismissFilter(ActiveFilter.Distance)}
          disclosure={
            <div className="filter-button">
              <Text size={SizeEnum['12px']} colorName={ColorNameEnum.ultraDark} text="Distance:" />
              <Text
                size={SizeEnum['12px']}
                colorName={ColorNameEnum.ultraDark}
                text={`${filters.distance ? filters.distance : initialFilters.distance} mi`}
                weight={WeightEnum.semibold}
                className="filter-value"
              />
              <Text
                className="icon-chevron-down filters-arrow"
                colorName={ColorNameEnum.ultraDark}
              />
            </div>
          }
          tabIndex={0}
          className="distance-popover-main"
        >
          {distancePopoverContent}
        </Popover>
      </div>
      {!isLanguageDisabled && (
        <div
          role="none"
          className={`filter-button-wrapper language-button ${
            languagePopover.visible ? 'active' : ''
          }`}
          onClick={() => onToggleFilter(ActiveFilter.Language)}
        >
          <Popover
            popover={languagePopover}
            hide={() => onDismissFilter(ActiveFilter.Language)}
            disclosure={
              <div className="filter-button">
                <Text
                  size={SizeEnum['12px']}
                  colorName={ColorNameEnum.ultraDark}
                  text="Language: "
                />
                <Text
                  size={SizeEnum['12px']}
                  colorName={ColorNameEnum.ultraDark}
                  text={capitalize(
                    `${filters.language ? filters.language : initialFilters.language}`,
                  )}
                  weight={WeightEnum.semibold}
                  className="filter-value"
                />
                <Text
                  className="icon-chevron-down filters-arrow"
                  colorName={ColorNameEnum.ultraDark}
                  inline
                />
              </div>
            }
            tabIndex={0}
            className="language-popover-main"
          >
            {languagePopoverContent}
          </Popover>
        </div>
      )}
      {filtersCount > 0 && (
        <div role="none" className="filters-clear" onClick={handleClear}>
          <Text size={SizeEnum['12px']} colorName={ColorNameEnum.ultraDark} text="Clear" />
          <Text colorName={ColorNameEnum.ultraDark} className="icon-x filters-clear-icon" />
        </div>
      )}
    </div>
  );
};

export default FiltersWrapper;
