import { SearchFilters } from '../types';

const DISTANCE_MARKS: Record<number, number> = {
  1: 5,
  2: 10,
  3: 20,
  4: 50,
};

const WIDER_DISTANCE_MARKS: Record<number, number> = {
  1: 10,
  2: 20,
  3: 50,
  4: 100,
};

export const getDistanceMarks = (supportWider: boolean): Record<number, number> =>
  supportWider ? WIDER_DISTANCE_MARKS : DISTANCE_MARKS;

export const getLongestDistance = (supportWider: boolean): number => {
  const distanceMarks = getDistanceMarks(supportWider);
  const distanceKeys = Object.keys(distanceMarks);
  const biggestIndex = distanceKeys[distanceKeys.length - 1];
  return distanceMarks[biggestIndex];
};

export const getDefaultSearchFilters = (
  supportWiderDistance: boolean,
  defaultDistanceFilter: number,
): SearchFilters => {
  const distanceMarks = getDistanceMarks(supportWiderDistance);
  const isExist = Object.keys(distanceMarks).some(
    (mark) => distanceMarks[mark] === defaultDistanceFilter,
  );

  return {
    language: 'eng', // iso 639-2,
    distance: isExist ? defaultDistanceFilter : getDistanceMarks(supportWiderDistance)[3],
  };
};
