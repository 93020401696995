import { isEqual } from 'lodash-es';
import { useState } from 'react';

const useMemoizedState = <T>(initialValue?: T): [T | undefined, (val: T) => void] => {
  const [state, setState] = useState<T | undefined>(initialValue);

  const setMemoizedState = (newState: T) => {
    setState((prev) => {
      if (!isEqual(newState, prev)) {
        return newState;
      }
      return prev;
    });
  };

  return [state, setMemoizedState];
};

export default useMemoizedState;
