import { useEffect } from 'react';
import { logic } from '../../../logic';
import { OrderAssistFormData, AddressWithFull } from '../../../types';
import { isEmpty } from 'lodash-es';

interface UsePatientAddressProps {
  formData: OrderAssistFormData;
  patientAddress?: AddressWithFull;
  onFormDataChange: (input: Partial<OrderAssistFormData>) => void;
}

const shouldFetchFromGeoService = (address: AddressWithFull): boolean => {
  const { address1, city, state, zipCode } = address;
  return !!address1 && !!city && !!state && !zipCode;
};

export const usePatientAddress = ({
  formData,
  patientAddress,
  onFormDataChange,
}: UsePatientAddressProps): void => {
  useEffect(() => {
    const initAddress = async () => {
      if (patientAddress && isEmpty(formData)) {
        if (shouldFetchFromGeoService(patientAddress)) {
          try {
            const result = await logic.fetchGeo(patientAddress);
            onFormDataChange({ address: { ...patientAddress, zipCode: result.zipCode } });
            return;
          } catch {
            onFormDataChange({ address: patientAddress });
            return;
          }
        }
        onFormDataChange({ address: patientAddress });
      }
    };

    initAddress();
  }, [formData, onFormDataChange, patientAddress]);
};
