import { Procedure } from '../../components/app/components/referral-request/types';
import {
  ReferralRequestEventNames,
  ServiceInformationScreenClickedPayload,
  ServiceInformationScreenViewedPayload,
} from '../types/referralRequestAnalyticsEvents.types';
import { referralRequestAnalyticsClient } from '../clients/referralRequest.client';

const serviceInformationProcedureToAnalyticsProcedure = (procedure: Procedure) => {
  const { cpt, date, quantity } = procedure;
  return {
    cptCode: cpt?.code,
    cptDescription: cpt?.description,
    date,
    quantityType: quantity?.type,
    quantityValue: quantity?.value,
  };
};

export const trackServiceInformationScreenViewed = (
  payload: ServiceInformationScreenViewedPayload,
): void => {
  const { serviceInformation } = payload;
  const { procedures } = serviceInformation ?? {};

  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.SERVICE_INFORMATION_SCREEN_VIEWED,
    {
      serviceInformation: {
        ...serviceInformation,
        procedures: procedures?.map((procedure) =>
          serviceInformationProcedureToAnalyticsProcedure(procedure),
        ),
      },
    },
  );
};

export const trackServiceInformationScreenClicked = (
  payload: ServiceInformationScreenClickedPayload,
): void => {
  const { serviceInformation, buttonName } = payload;
  const { procedures } = serviceInformation ?? {};

  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.SERVICE_INFORMATION_SCREEN_CLICKED,
    {
      serviceInformation: {
        ...serviceInformation,
        procedures: procedures?.map((procedure) =>
          serviceInformationProcedureToAnalyticsProcedure(procedure),
        ),
      },
      buttonName,
    },
  );
};
