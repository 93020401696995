type OrderAssistReferralRequestStringMap =
  | 'referral_status_approved'
  | 'referral_status_approved_and_modified'
  | 'referral_status_pending'
  | 'referral_status_cancelled'
  | 'referral_no_action_needed'
  | 'referral_requesting_provider_title'
  | 'referral_requesting_provider_first_name_label'
  | 'referral_requesting_provider_last_name_label'
  | 'referral_quantity_type_label'
  | 'referral_quantity_type_missing_label'
  | 'referral_quantity_type_procedure_code_label'
  | 'referral_quantity_type_procedure_code_missing_label'
  | 'referral_quantity_type_diagnosis_code_label'
  | 'referral_quantity_type_diagnosis_code_missing_label'
  | 'referral_quantity_type_procedures_subtitle'
  | 'referral_quantity_value_label'
  | 'referral_quantity_value_missing_label'
  | 'referral_quantity_service_information_title'
  | 'referral_quantity_service_information_service_type'
  | 'referral_quantity_service_information_place_of_service'
  | 'referral_procedure_status_start_date_label'
  | 'referral_procedure_status_expiration_date_label'
  | 'referral_procedure_status_procedure_quantity_label'
  | 'referral_result_status_reason_label'
  | 'referral_result_status_certification_number_label'
  | 'referral_result_referral_submitted_title'
  | 'referral_result_no_answer_subtitle'
  | 'referral_result_no_answer_go_to_external_portal_button'
  | 'referral_result_error_title'
  | 'referral_result_error_subtitle'
  | 'referral_result_error_review_referral_request_button'
  | 'referral_result_error_back_to_order_assist_button';

const STRINGS: Record<OrderAssistReferralRequestStringMap, string> = {
  // Referral Request Status
  referral_status_approved: 'Approved',
  referral_status_approved_and_modified: 'Approved and modified',
  referral_status_pending: 'Pending',
  referral_status_cancelled: 'Cancelled',
  referral_no_action_needed: 'No action required',

  // Requesting provider details
  referral_requesting_provider_title: 'Requesting provider',
  referral_requesting_provider_first_name_label: 'First name (optional)',
  referral_requesting_provider_last_name_label: 'Last name',

  // Requested quantity details
  referral_quantity_type_label: 'Quantity type',
  referral_quantity_type_missing_label: 'Quantity Type is required',
  referral_quantity_type_procedure_code_label: 'Procedure code (CPT-4)',
  referral_quantity_type_procedure_code_missing_label: 'At least one CPT code is required',
  referral_quantity_type_diagnosis_code_label: 'Diagnosis code (ICD-10-CM)',
  referral_quantity_type_diagnosis_code_missing_label: 'At least one diagnosis code is required',
  referral_quantity_type_procedures_subtitle: 'Referral CPT codes only',
  referral_quantity_value_label: 'Quantity',
  referral_quantity_value_missing_label: 'Quantity is required',

  // Service information
  referral_quantity_service_information_title: 'Service information',
  referral_quantity_service_information_service_type: 'Service type',
  referral_quantity_service_information_place_of_service: 'Place of service',

  // Referral result details
  referral_result_status_reason_label: 'Status reason',
  referral_result_status_certification_number_label: 'Referral authorization code',
  referral_procedure_status_start_date_label: 'Start date',
  referral_procedure_status_expiration_date_label: 'Expiration date',
  referral_procedure_status_procedure_quantity_label: 'Procedure quantity',

  // Referral Request not approved edge cases
  referral_result_referral_submitted_title: 'The referral request has been submitted!',
  referral_result_no_answer_subtitle:
    'We have not yet received an answer regarding the referral status. You can check the status on the external portal',
  referral_result_no_answer_go_to_external_portal_button: 'Go to external portal',
  referral_result_error_title: 'Something went wrong',
  referral_result_error_subtitle: 'Review the referral request and try again',
  referral_result_error_review_referral_request_button: 'Review referral request',
  referral_result_error_back_to_order_assist_button: 'Back to Order Assist',
};

export const getReferralRequestUIString = (key: keyof typeof STRINGS): string => {
  return STRINGS[key];
};
