import { logger } from '@getvim/vim-connect-logger';
import { useSdkConfig } from './use-sdk-config';
import { useRuntimeUserConfig } from './useRuntimeConfig';
import { UserConfig } from './types';
import { isVimOsAppMode } from '../../vim-os-sdk-migration';

export { type UserConfig } from './types';

const configHook = isVimOsAppMode ? useSdkConfig : useRuntimeUserConfig;

export const useUserConfig = (): UserConfig | undefined => {
  const config = configHook();

  if (config?.workspaceConfig) {
    logger.setMetadata({
      deviceId: config.workspaceConfig.deviceId ?? '',
      version: window?.$vim_environment?.version,
      windowId: config.workspaceConfig.activeWindow?.id ?? '',
      adapterName: config.organization?.ehrSystem,
      organizationId: config.organization?.id,
      organization_name: config.organization?.name,
    });
  }

  return config;
};
