import { ColorNameEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import React from 'react';
import './Loader.less';

const ReferralRequestLoader: React.FC = () => (
  <div className="loader-container">
    <img
      className="loader-img no-user-select"
      src="https://static.getvim.com/img/Add2ChartAnimation.svg"
      alt=""
    />
    <Text
      text="Loading..."
      colorName={ColorNameEnum.theme}
      weight={WeightEnum.light}
      className="no-user-select"
    />
  </div>
);
export default ReferralRequestLoader;
