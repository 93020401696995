import { ReferralRequestStatus } from './types';
import { getReferralRequestUIString } from './strings';

export const statusToUiStatus: Record<
  Exclude<ReferralRequestStatus, ReferralRequestStatus.ERROR | ReferralRequestStatus.NO_ANSWER>,
  string
> = {
  [ReferralRequestStatus.APPROVED]: getReferralRequestUIString('referral_status_approved'),
  [ReferralRequestStatus.APPROVED_AND_MODIFIED]: getReferralRequestUIString(
    'referral_status_approved_and_modified',
  ),
  [ReferralRequestStatus.PENDING]: getReferralRequestUIString('referral_status_pending'),
  [ReferralRequestStatus.CANCELLED]: getReferralRequestUIString('referral_status_cancelled'),
  [ReferralRequestStatus.NO_ACTION_REQUIRED]: getReferralRequestUIString(
    'referral_no_action_needed',
  ),
};

export const getStatusToUiStatus = (selector: string): string => {
  const referralRequestStatus = statusToUiStatus[selector];
  if (referralRequestStatus) {
    return referralRequestStatus;
  }
  throw new Error('Passed illegal referral request status value!');
};
