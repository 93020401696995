import { ProductSource } from '@getvim-core-apps/organizations';
import { orderAssistLogger } from '../../logger';
import { logic } from '../../logic';
import { SendOutcomeFunction } from '../../types';
import { isProviderResult } from '../../utils/general';
import { extractMemberTokenForSource } from '../../../../utils/memberToken';

export const sendUHCOutcome: SendOutcomeFunction = ({
  item,
  isAlternative,
  selectedLocation,
  currentSearchState,
  extraInfo,
  patient,
}) => {
  const memberToken = extractMemberTokenForSource(
    patient?.memberTokens,
    ProductSource.UnitedHealthCare,
  );
  if (!memberToken) return;

  if (!currentSearchState.specialty) {
    orderAssistLogger.error('Specialty cannot be undefined for sendOutcome query', {
      currentSearchState,
    });
    return;
  }

  logic.sendOutcome(
    {
      isProvider: isProviderResult(item),
      cpts: currentSearchState.cpt,
      isAlternative,
      location: selectedLocation,
      item,
      specialty: currentSearchState.specialty,
      extraInfo,
    },
    memberToken,
  );
};
