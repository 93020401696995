import React from 'react';

export const Loader: React.FC<{ isSmall: boolean }> = ({ isSmall }: { isSmall: boolean }) => (
  <div className="vim-loading-id widget-inline-size">
    <img
      id="inline-button-loader"
      src={
        isSmall
          ? 'https://static.getvim.com/img/dotsLoaderSmall.svg'
          : 'https://static.getvim.com/img/dotsLoader.svg'
      }
      alt="loading logo"
    />
  </div>
);
