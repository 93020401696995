import { EHR } from '@getvim/vim-os-sdk-api';
import { EHRResource } from '@getvim/vim-os-api';
import ResourceUpdateBuilder from './resourceUpdateBuilder';

type UpdatableReferral = EHR.UpdateReferralParams;

type UpdatableBasicInformation = NonNullable<UpdatableReferral['basicInformation']>;

type UpdatableCpts = NonNullable<UpdatableReferral['procedureCodes']>['cpts'];

type UpdatableDiagnosis = NonNullable<UpdatableReferral['conditions']>['diagnosis'];

type UpdatableTargetProvider = UpdatableReferral['targetProvider'];

type UpdatableSpecialty = NonNullable<UpdatableBasicInformation['specialty']>;

export default class ReferralUpdateBuilder extends ResourceUpdateBuilder<
  typeof EHRResource.referral
> {
  protected readonly ehrResource = EHRResource.referral;
  public setSpecialty(specialty: UpdatableSpecialty) {
    return this.setField('specialty', { description: specialty });
  }

  public setDiagnosis(diagnosis: UpdatableDiagnosis) {
    return this.setField('diagnosis', diagnosis);
  }

  public setReasons(reasons: UpdatableBasicInformation['reasons']) {
    return this.setField('reasons', reasons);
  }
  public appendReasons(reasons: UpdatableBasicInformation['reasons']) {
    return this.appendField('reasons', reasons);
  }
  public setStartDate(startDate: UpdatableBasicInformation['startDate']) {
    return this.setField('startDate', startDate);
  }
  public setEndDate(endDate: UpdatableBasicInformation['endDate']) {
    return this.setField('endDate', endDate);
  }
  public setNotes(notes: UpdatableBasicInformation['notes']) {
    return this.setField('notes', notes);
  }
  public appendNotes(notes: UpdatableBasicInformation['notes']) {
    return this.appendField('notes', notes);
  }
  public setPriority(priority: UpdatableBasicInformation['priority']) {
    return this.setField('priority', priority);
  }

  public setCpts(cpts: UpdatableCpts) {
    return this.setField('cpts', cpts);
  }

  public setNumberOfVisits(numberOfVisits: UpdatableBasicInformation['numberOfVisits']) {
    return this.setField('numberOfVisits', numberOfVisits);
  }
  public setAuthCode(authCode: UpdatableBasicInformation['authCode']) {
    return this.setField('authCode', authCode);
  }

  public appendAuthCode(authCode: UpdatableBasicInformation['authCode']) {
    return this.appendField('authCode', authCode);
  }

  public setTargetProvider(updatableTargetProvider: UpdatableTargetProvider) {
    if (!updatableTargetProvider) {
      return this.setField('targetProvider', updatableTargetProvider);
    }
    const { facility, ...targetProvider } = updatableTargetProvider;
    return this.setField('targetProvider', {
      ...targetProvider,
      ...(facility
        ? {
            organization: {
              address: facility.address,
              name: facility.name,
              contact_info: facility.contact_info,
              ehrId: facility.facilityEhrId,
            },
          }
        : {}),
      specialty: targetProvider?.specialty?.map((s) => ({ description: s })),
    });
  }
}
