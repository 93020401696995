type OrderAssistStringMap =
  | 'search_form_title'
  | 'procedure_code_select_label'
  | 'procedure_code_select_placeholder'
  | 'diagnosis_code_select_label'
  | 'diagnosis_code_select_placeholder'
  | 'codings_no_data_label'
  | 'codings_no_results_label'
  | 'codings_input_label'
  | 'advanced_search_label'
  | 'advanced_search_optional_label'
  | 'results_title'
  | 'results_benefits_message'
  | 'phone_number_not_available_label'
  | 'search_results_list_title'
  | 'search_results_out_of_network_results_list_title'
  | 'alternative_search_suggestions_title'
  | 'result_card_no_cost_available_label'
  | 'result_card_estimated_out_of_pocket_label'
  | 'result_card_specialty_unavailable_label'
  | 'result_card_copy_details_button'
  | 'result_card_select_button'
  | 'search_failed_title'
  | 'search_failed_details_prompt'
  | 'search_failed_try_again_button'
  | 'export_pdf_in_progress_title'
  | 'export_pdf_in_progress_subtitle'
  | 'export_pdf_successful_title'
  | 'export_pdf_failed_title';

const STRINGS: Record<OrderAssistStringMap, string> = {
  // Search Form
  search_form_title: 'Order Assist in-network search',
  procedure_code_select_label: 'Procedure code (CPT-4)',
  procedure_code_select_placeholder: 'Procedure (CPT-4)',
  diagnosis_code_select_label: 'Diagnosis code (ICD-10-CM)',
  diagnosis_code_select_placeholder: 'Condition (ICD-10-CM)',
  codings_no_data_label: 'No options',
  codings_no_results_label: 'No options',
  codings_input_label: 'Type to start searching',
  advanced_search_label: 'Advanced search',
  advanced_search_optional_label: 'Advanced search (optional)',

  // Search results
  results_title: 'Back to Order Assist search',
  results_benefits_message: 'Place Of Service Differential Benefit',
  phone_number_not_available_label: 'Not available',
  search_results_list_title: 'In-network search results',
  search_results_out_of_network_results_list_title: 'Search results',
  alternative_search_suggestions_title: 'In-network alternative recommendations',
  result_card_no_cost_available_label: 'Estimated cost not available',
  result_card_estimated_out_of_pocket_label: 'Estimated out of pocket cost',
  result_card_specialty_unavailable_label: 'Specialty unavailable',
  result_card_copy_details_button: 'Copy details',
  result_card_select_button: 'Select',
  search_failed_title: 'Error getting results',
  search_failed_details_prompt: 'Try again in a few moments',
  search_failed_try_again_button: 'Try again',

  // Export search results
  export_pdf_in_progress_title: 'Export in process',
  export_pdf_in_progress_subtitle: 'Please wait',
  export_pdf_successful_title: 'Export successful',
  export_pdf_failed_title: 'Export failed',
};

export const getOrderAssistUIString = (key: keyof typeof STRINGS): string => {
  return STRINGS[key];
};
