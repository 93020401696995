import { PatientContentSupplierIdentifiers } from '@getvim-core-apps/organizations';
import { orderAssistLogger } from '../components/app/logger';

class VimPatientIdsManager {
  private vimPatientId: string | undefined;
  private patientContentSupplierIdentifiers: PatientContentSupplierIdentifiers | undefined;

  public getVimPatientId(): string | undefined {
    return this.vimPatientId;
  }

  public getPatientContentSupplierIdentifiers(): PatientContentSupplierIdentifiers | undefined {
    return this.patientContentSupplierIdentifiers;
  }

  public handleUpdateIds(
    vimPatientId?: string,
    contentSupplierIdentifiers?: PatientContentSupplierIdentifiers,
  ): void {
    if (!vimPatientId || !contentSupplierIdentifiers) {
      orderAssistLogger.warning('vimPatientId or contentSupplierIdentifiers undefined', {
        vimPatientId,
        contentSupplierIdentifiers,
      });
    }
    this.vimPatientId = vimPatientId;
    this.patientContentSupplierIdentifiers = contentSupplierIdentifiers;
  }

  public resetIds() {
    this.vimPatientId = undefined;
    this.patientContentSupplierIdentifiers = undefined;
  }
}

export const vimPatientIdsManager = new VimPatientIdsManager();
