import { useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { useMemo } from 'react';
import { UserConfig } from '../types';

export const useSdkOrganizationConfig = (): UserConfig['organization'] | undefined => {
  const { ehrVendor: ehrSystem, organization } = useVimUserMetadata();
  const { id, name } = organization?.identifiers ?? {};

  return useMemo(() => {
    if (!id || !name || !ehrSystem) return undefined;

    return {
      id,
      name,
      ehrSystem,
    };
  }, [id, name, ehrSystem]);
};
