import { useFeatureFlag } from '@getvim/feature-flags-react';
import uniqBy from 'lodash-es/uniqBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { referralRequestAnalyticsClient } from '../../../../analytics/referral-request';
import { trackLeaveConfirmationModalViewed } from '../../../../analytics/referral-request/trackLeaveConfirmationModal';
import { ExportMenuOption } from '../../../../analytics/types';
import { extractMemberTokenForSource } from '../../../../utils/memberToken';
import { downloadReferralRequestPDF } from '../../logic/actions';
import { referralRequestPdfFormatters } from '../../logic/actions/export/referralRequest.formatter';
import { Patient, WidgetSource, WriteBackProvider } from '../../types';

import { useFlowState } from '@getvim/flow-context-provider/build';
import { exportAnalyticsClient } from '../../../../analytics/clients/exportAnalytics.client';
import { ExportAnalyticsEventNames } from '../../../../analytics/types/exportAnalytics.types';
import { DownloadState } from '../modals/DownloadModal';
import { AppRequiredState } from '../order-assist-app/OrderAssistAppWrapper';
import { OnFreeTextQueryMethod } from '../order-assist-app/hooks';
import { pdfDownloadStates } from '../search-results/components/PdfDownloadStates';
import { TitleBar, TitleBarProps } from '../search-results/components/Titlebar';
import ConfirmationPopup from '../search-results/components/confirmation-popup/ConfirmationPopup';
import { SearchResultsContainerProps } from '../search-results/container/types';
import { ReferralRequestFlowManager } from './ReferralRequestFlowManager';
import { referringProviderFormatter } from './formatters';
import { ReferralRequestExportContext, ReferralRequestStatus } from './types';

interface ReferralRequestContainerProps {
  onFreeTextQuery: OnFreeTextQueryMethod;
  selectedProvider: WriteBackProvider;
  patient?: Patient;
  source?: WidgetSource;
  titleBarProps: Omit<TitleBarProps, 'onClick'>;
  resetSearchForm: () => void;
  backToSearch: () => void;
  backToResults: () => void;
  referralViewedPayload: SearchResultsContainerProps['referralViewedPayload'];
  shouldUpdateReferral: boolean;
}

const TitleContainer = ({
  titleBarProps,
  backToSearch,
}: Pick<ReferralRequestContainerProps, 'titleBarProps' | 'backToSearch'>) => {
  const [displayConfirmationPopup, setDisplayConfirmationPopup] = useState(false);
  return (
    <>
      <TitleBar
        {...titleBarProps}
        onClick={() => {
          trackLeaveConfirmationModalViewed();
          setDisplayConfirmationPopup(true);
        }}
      />
      {displayConfirmationPopup && (
        <ConfirmationPopup
          onClickYes={() => backToSearch()}
          onClickNo={() => setDisplayConfirmationPopup(false)}
        />
      )}
    </>
  );
};

export const ReferralRequestContainer: React.FC<ReferralRequestContainerProps> = ({
  onFreeTextQuery,
  patient,
  source,
  selectedProvider,
  titleBarProps,
  resetSearchForm,
  backToSearch,
  backToResults,
  referralViewedPayload,
  shouldUpdateReferral,
}) => {
  const { userConfig } = useFlowState<AppRequiredState>();

  const [exportContext, setExportContext] = useState<ReferralRequestExportContext>({
    patient,
    userConfig,
  });
  const [PDFState, setPDFState] = useState<DownloadState>();

  const [shouldUseNewOrganizationAddressPayloadForReferralRequest] = useFeatureFlag({
    flagName: 'shouldUseNewOrganizationAddressPayloadForReferralRequest',
    defaultValue: false,
    flagContext: {
      organizationId: userConfig?.organization?.id,
      adapterName: userConfig?.adapterName,
    },
  });

  const onPDFStart = useCallback(() => {
    setPDFState(pdfDownloadStates.loading);
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_DROPDOWN_SELECTED, {
      option: ExportMenuOption.DOWNLOAD,
    });
  }, []);

  const onPDFSuccess = useCallback(() => {
    setPDFState(pdfDownloadStates.success);
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
      action_type: ExportMenuOption.DOWNLOAD,
      status: 'successful',
    });
  }, []);

  const onPDFError = useCallback(() => {
    setPDFState(pdfDownloadStates.error);
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
      action_type: ExportMenuOption.DOWNLOAD,
      status: 'failed',
    });
  }, []);

  const onPDFFinish = useCallback(
    (success: boolean) => {
      if (success) {
        onPDFSuccess();
      } else {
        onPDFError();
      }
    },
    [onPDFError, onPDFSuccess],
  );

  const onExportModalClose = () => {
    setPDFState(undefined);
  };

  const updateExportContext = useCallback((context: Partial<ReferralRequestExportContext>) => {
    setExportContext((prevState) => ({ ...prevState, ...context }));
  }, []);

  useEffect(() => {
    referralRequestAnalyticsClient.setDatasource(source);
  }, [source]);

  useEffect(() => {
    referralRequestAnalyticsClient.initiateSession(selectedProvider);
  }, [selectedProvider]);

  const generateReferralRequestPdfAction = useCallback(async (): Promise<boolean> => {
    const success = await downloadReferralRequestPDF(
      referralRequestPdfFormatters.formatInput(exportContext),
      referralRequestPdfFormatters.formatFilename(patient),
      source,
    );
    return success;
  }, [exportContext, patient, source]);

  const onBackToSearch = useCallback(() => {
    backToSearch();
  }, [backToSearch]);

  const onBackToResults = useCallback(() => {
    resetSearchForm();
    backToResults();
  }, [resetSearchForm, backToResults]);

  const shouldUpdateAuthCode = useMemo(() => {
    return (
      shouldUpdateReferral &&
      !userConfig.adapterConfig?.unsupportedWritebackFields?.updateReferral?.includes('authCode')
    );
  }, [shouldUpdateReferral, userConfig.adapterConfig?.unsupportedWritebackFields?.updateReferral]);

  return (
    <div className="referral-request-container no-user-select">
      <div className="search-results-container">
        <TitleContainer
          titleBarProps={{
            ...titleBarProps,
            ...(exportContext?.response?.status &&
              exportContext?.response?.status !== ReferralRequestStatus.ERROR && {
                dropdownMenuItems: [
                  {
                    text: 'Download',
                    value: ExportMenuOption.DOWNLOAD,
                    icon: 'icon-export-pdf',
                    onClick: generateReferralRequestPdfAction,
                    onClickCallback: {
                      onStart: onPDFStart,
                      onFinish: onPDFFinish,
                    },
                  },
                ],
              }),
            onExportModalClose,
            downloadState: PDFState,
          }}
          backToSearch={onBackToSearch}
        />
        <div className="search-results-container__results">
          <ReferralRequestFlowManager
            backToResults={onBackToResults}
            onFreeTextQuery={onFreeTextQuery}
            onResponse={updateExportContext}
            targetProvider={selectedProvider}
            memberToken={extractMemberTokenForSource(patient?.memberTokens, source)}
            source={source}
            requestingProvider={referringProviderFormatter(
              shouldUseNewOrganizationAddressPayloadForReferralRequest,
              referralViewedPayload?.referringProvider,
            )}
            serviceInformation={{
              diagnoses: uniqBy(referralViewedPayload?.diagnosis, 'code'),
            }}
            organizationId={userConfig.organization?.id}
            shouldUpdateAuthCode={shouldUpdateAuthCode}
          />
        </div>
      </div>
    </div>
  );
};
