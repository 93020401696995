import { internalApi } from '../../../../internal-api';
import { Patient, SupportPersonCapabilityInput } from '../../types';
import { UserConfig } from '../../../../hooks';
import { calculateSource } from '../../../../hooks/useSourceConfig';
import { extractMemberTokenForSource } from '../../../../utils/memberToken';
import { orderAssistLogger } from '../../logger';

export const supportedPersonCapabilites = async (
  patient: Patient,
  config: UserConfig,
): Promise<boolean> => {
  const source = calculateSource(patient, config);
  if (!source) {
    orderAssistLogger.info('Couldnt calculate sources for patient, probably only ProviderSources', {
      patientInsurance: patient.insurance,
      config: config,
    });
    return false;
  }
  const memberToken = extractMemberTokenForSource(patient.memberTokens, source);
  if (!memberToken) {
    orderAssistLogger.info(
      'Failed to extract member token for source in supportedPersonCapabilites query',
      {
        memberTokens: patient.memberTokens,
        source,
      },
    );
    return false;
  }

  const input: SupportPersonCapabilityInput = {
    encryptedPatientDetails: {
      encryptedIdentifyInput: memberToken,
    },
    user: {
      id: config.vimConnectUser?.externalId || '',
      userOrganization: {
        id: config.organization.id.toString(),
        name: config.organization.name,
        ehrVendor: config.organization.ehrSystem,
      },
    },
  };
  try {
    const result = await internalApi.orderAssistBff.supportedPersonCapabilities(input, source);
    return result;
  } catch (error) {
    orderAssistLogger.error('Failed to perform supportedPersonCapabilites query', {
      error,
      input,
      source,
    });
    return false;
  }
};
