import React from 'react';
import './AdvancedSearchContainer.less';
import { getOrderAssistUIString } from '../../../consts/strings';

interface AdvancedSearchHeaderProps {
  onClick: () => void;
  isExpanded: boolean;
}

const AdvancedSearchHeader = ({ onClick, isExpanded }: AdvancedSearchHeaderProps): JSX.Element => (
  <div className="advanced-search-header" onClick={onClick}>
    <div className="advanced-search-icon">
      <i className={isExpanded ? 'icon-minus-c' : 'icon-plus-c'} />
    </div>
    <div className="advanced-search-text">
      {getOrderAssistUIString('advanced_search_optional_label')}
    </div>
    <i className={`${isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'} select-chevron-icon`} />
  </div>
);

export default AdvancedSearchHeader;
