import {
  ActionStatus,
  DataFilters,
  GetOrderAssistResult,
  OrderAssistOption,
  SearchResultsDetails,
} from '../components/app/types';
import getCostRequestStatus from '../components/app/utils/cost';
import { analyticsManager } from './analyticsManager';
import {
  AnalyticsSearchPayload,
  AnalyticsSearchResultsPayload,
  SearchMethod,
  SearchResultsScreen,
} from './types';

const decreaseSearchPayloadDepth = (
  searchPayload: AnalyticsSearchResultsPayload,
): AnalyticsSearchPayload => {
  const { searchInput, options: searchOptions } = searchPayload ?? {};
  const { forceCost, pagination, ...options } = searchOptions ?? {};
  return {
    ...searchInput,
    options,
  };
};
const extractProvidersDetails = (
  providersResultArray: OrderAssistOption[],
  isPreferred: boolean,
) => {
  return providersResultArray.map((result: OrderAssistOption) => {
    const { npi, name } = result?.details || {};
    return {
      npi,
      name,
      type: result.type,
      isPreferred,
    };
  });
};
const parseResultsToSearchResultsDetails = (
  searchResults: OrderAssistOption[] | undefined,
  preferredProviders: OrderAssistOption[],
): SearchResultsDetails[] | undefined => {
  if (!searchResults) {
    return;
  }
  const preferredProvidersArray = preferredProviders;
  const providersArray = searchResults;
  const extractedPreferred = extractProvidersDetails(preferredProvidersArray, true);
  const extractedProviders = extractProvidersDetails(providersArray, false);
  return [...extractedPreferred, ...extractedProviders];
};

const getCurrentScreenBySearchResults = (
  searchStatus: ActionStatus,
  resultSize: number,
): SearchResultsScreen => {
  if (searchStatus === ActionStatus.FAILED) {
    return SearchResultsScreen.ERROR_MESSAGE;
  }
  return resultSize ? SearchResultsScreen.SEARCH_RESULTS : SearchResultsScreen.NO_RESULTS;
};

export const trackSearchResults = ({
  method,
  searchPayload,
  result,
  costAvailable,
  resultsError,
  referringProviderNpi,
  currentPreferredProvidersCount,
  preferredProviders,
  filtersData,
  isModifiedSearch,
  addCurrentScreenToSearchPerformed,
}: {
  method: SearchMethod;
  searchPayload: AnalyticsSearchResultsPayload;
  result?: GetOrderAssistResult;
  costAvailable: boolean;
  resultsError: boolean;
  referringProviderNpi?: string;
  currentPreferredProvidersCount?: number;
  preferredProviders: OrderAssistOption[];
  filtersData?: DataFilters;
  isModifiedSearch: boolean;
  addCurrentScreenToSearchPerformed: boolean;
}): void => {
  const cardType = result?.searchResults?.[0]?.type ?? result?.alternativeResults?.[0]?.type;
  const pageNumber = searchPayload.options?.pagination?.paginationNumber ?? 1;
  const costStatus = getCostRequestStatus(costAvailable, result);
  const searchStatus = resultsError ? ActionStatus.FAILED : ActionStatus.SUCCESS;
  const decreasedSearchPayloadDepth = decreaseSearchPayloadDepth(searchPayload);
  const resultSize = result?.searchResults?.length ?? 0;
  const currentScreen = getCurrentScreenBySearchResults(searchStatus, resultSize);

  analyticsManager.track('order_assist_search_performed', {
    search_details: {
      searchResultsDetails: parseResultsToSearchResultsDetails(
        result?.searchResults,
        preferredProviders,
      ),
      method,
      searchPayload: decreasedSearchPayloadDepth,
      resultsOutput: {
        cardType,
        pageNumber,
        resultSize,
        preferredProviderResultSize: currentPreferredProvidersCount || 0,
        alternativeSize: result?.alternativeResults?.length ?? 0,
        costStatus,
        searchStatus,
        ...(addCurrentScreenToSearchPerformed ? { currentScreen } : {}),
      },
      isExtendedRadiusSearch: isModifiedSearch,
      referring_provider_npi: referringProviderNpi,
    },
  });
  if (isModifiedSearch) {
    const { options, ehrNativeSpecialty, vimSpecialtyDescription, vimSpecialtyId } =
      decreasedSearchPayloadDepth;
    const { distance, language } = filtersData?.userFilters || {};
    analyticsManager.track('order_assist_search_automated_adjusted_performed', {
      search_details: {
        method,
        searchPayload: {
          options,
          ehrNativeSpecialty,
          vimSpecialtyDescription,
          vimSpecialtyId,
          previousOptions: {
            radius: distance,
            languageCode: language,
          },
        },
        resultsOutput: {
          cardType,
          resultSize: result?.searchResults?.length ?? 0,
          searchStatus,
        },
      },
    });
  }
};
