import { SelectOption } from '../types';

const placeOfServiceMap = {
  '21': 'Inpatient Hospital',
  '22': 'On Campus-Outpatient Hospital',
  '24': 'Ambulatory Surgical Center',
};

export const specialtyIdToPlaceOfServiceMap: Record<string, string[]> = {
  f_84_53: ['22', '24'],
  f_84_52: ['22', '24'],
  f_84_38: ['22', '24'],
  f_265_0: ['21', '22'],
  f_289_0: ['21', '22'],
  f_289_2: ['21', '22'],
  f_289_1: ['21', '22'],
  f_1008_0: ['21', '22'],
};

export const getPlaceOfServiceOptions = (specialtyId: string): SelectOption[] => {
  const options = specialtyIdToPlaceOfServiceMap[specialtyId];
  return options ? options.map((item) => ({ value: item, label: placeOfServiceMap[item] })) : [];
};

export const requiredCptSpecialtyId = [
  'f_84_30',
  'f_84_16',
  'md_924_0',
  'f_822_1',
  'f_843_0',
  ...Object.keys(specialtyIdToPlaceOfServiceMap),
];
