import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import {
  InputStyle,
  InputStyleWrapper,
  InputValidationMessage,
  LabeledInput,
} from '@getvim/atomic-ui';
import './InputField.less';

interface NameInputFieldProps {
  displayLabel: string;
  fieldName: string;
}

export const NameInputField: React.FC<NameInputFieldProps> = ({ displayLabel, fieldName }) => {
  const [field, meta, helpers] = useField(fieldName);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => setIsFocus(!!field.value), [field.value]);

  // To disable non-numeric keys typing
  // To disable space typing before characters
  const onKeyPress = (event) => {
    if (!/[a-z A-Z]/.test(event.key) || (event.key === ' ' && !field.value)) {
      return event.preventDefault();
    }
  };

  const onDelete = () => {
    helpers.setValue('');
  };

  return (
    <div className="referral-request-input-container">
      <InputStyleWrapper inputStyle={InputStyle.pillMedium} hasError={meta.touched && !!meta.error}>
        <LabeledInput
          label={displayLabel}
          active={field.value || isFocus}
          type="text"
          onFocus={() => setIsFocus(true)}
          onBlurCapture={() => setIsFocus(false)}
          onKeyPress={onKeyPress}
          autoComplete="off"
          {...field}
        />
        {meta.touched && !!meta.error && (
          <InputValidationMessage>{meta.error}</InputValidationMessage>
        )}
        {(isFocus || field.value) && (
          <button className="close-icon" type="button" onClick={onDelete}>
            <i className="icon-x" />
          </button>
        )}
      </InputStyleWrapper>
    </div>
  );
};
