import { z } from 'zod';

export enum ActionNames {
  FIND_PATIENT = 'FIND_PATIENT',
  GET_PATIENT = 'GET_PATIENT',
  GET_ENCOUNTER_DATA = 'GET_ENCOUNTER_DATA',
  GET_PATIENT_ENCOUNTERS = 'GET_PATIENT_ENCOUNTERS',
  GET_PROVIDER_DAILY_SUMMARY = 'GET_PROVIDER_DAILY_SUMMARY',
  GET_PATIENT_SCHEDULED_APPOINTMENTS = 'GET_PATIENT_SCHEDULED_APPOINTMENTS',
  GET_CURRENT_EHR_USER_INFORMATION = 'GET_CURRENT_EHR_USER_INFORMATION',
  PRINT_ENCOUNTER = 'PRINT_ENCOUNTER',
  PRINT_ENCOUNTERS = 'PRINT_ENCOUNTERS',
  GET_ENCOUNTER_LAB_RESULTS = 'GET_ENCOUNTER_LAB_RESULTS',
  GET_ENCOUNTER_VITALS = 'GET_ENCOUNTER_VITALS',
  SEARCH_PROVIDERS = 'SEARCH_PROVIDERS',
  NAVIGATE_TO_ENCOUNTER = 'NAVIGATE_TO_ENCOUNTER',
  GET_GAPS_FROM_SHARED_REQUEST = 'GET_GAPS_FROM_SHARED_REQUEST',
}

export const executeActionSchema = z.object({
  action: z.nativeEnum(ActionNames),
  input: z.unknown(),
}) as z.ZodType<{ action: ActionNames; input: unknown }>;

export type ExecuteActionPayload = z.infer<typeof executeActionSchema>;
