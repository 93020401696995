import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useField } from 'formik';
import { InputStyle, InputStyleWrapper, InputValidationMessage } from '@getvim/atomic-ui';
import '../Procedures.less';
import './DateInputField.less';

interface DateInputFieldProps {
  label?: string;
  fieldName?: string;
}

export const DateInputField: React.FC<DateInputFieldProps> = ({
  label = 'Date',
  fieldName = 'date',
}) => {
  const [, meta, helpers] = useField(fieldName);

  const onChange = (date: Date) => {
    helpers.setValue(date || new Date());
  };

  return (
    <div className="referral-request-input-container no-margin-bottom">
      <InputStyleWrapper
        inputStyle={InputStyle.pillMedium}
        hasError={meta.touched && !meta.value}
        rightIcon={<i className="icon-chevron-down" />}
      >
        <label className="atomic-label input-active" htmlFor="_">
          {label}
        </label>
        <DatePicker
          selected={meta.value}
          onChange={(date: Date) => {
            onChange(date || new Date());
          }}
          dateFormat="MM/dd/yyyy"
          calendarStartDay={1}
          popperPlacement="top"
          maxDate={new Date('12-31-2999')}
        />
        {meta.touched && !meta.value && (
          <InputValidationMessage>{meta.error}</InputValidationMessage>
        )}
      </InputStyleWrapper>
    </div>
  );
};
