import { CloseMethod, ExportMenuOption, OrderAssistScreenName } from '../types';

export enum ExportAnalyticsEventNames {
  EXPORT_MENU_BUTTON_HOVERED = 'export_menu_button_hovered',
  EXPORT_MENU_BUTTON_CLICKED = 'export_menu_button_clicked',
  EXPORT_MENU_DROPDOWN_SELECTED = 'export_menu_dropdown_selected',
  EXPORT_MENU_ACTION_COMPLETED = 'export_menu_action_completed',
  EXPORT_MENU_MODAL_CLOSED = 'export_menu_modal_closed',
}

export type ExportMenuAnalyticsContext = {
  export_menu_id?: string;
  screen_name?: OrderAssistScreenName;
  search_id?: string;
  search_session_id?: string;
  additional_information?: Record<string, unknown>;
};

export interface ExportMenuButtonClickedPayload {
  interaction_type: 'opened' | 'closed';
}

export interface ExportMenuDropdownSelectedPayload {
  option: ExportMenuOption;
}

export interface ExportMenuActionCompletedPayload {
  status: 'successful' | 'failed';
  action_type: ExportMenuOption;
}

export interface ExportMenuModalClosedPayload {
  close_method: CloseMethod;
}

export type ExportAnalyticsEvents = {
  [ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_HOVERED];
  [ExportAnalyticsEventNames.EXPORT_MENU_BUTTON_CLICKED]: ExportMenuButtonClickedPayload;
  [ExportAnalyticsEventNames.EXPORT_MENU_DROPDOWN_SELECTED]: ExportMenuDropdownSelectedPayload;
  [ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED]: ExportMenuActionCompletedPayload;
  [ExportAnalyticsEventNames.EXPORT_MENU_MODAL_CLOSED]: ExportMenuModalClosedPayload;
};
