import classNames from 'classnames';
import React from 'react';
import './Step.less';

export interface StepProps {
  text: string;
  isActive: boolean;
  isDone: boolean;
}

const Step: React.FC<StepProps> = ({ text, isActive, isDone }) => (
  <div className={classNames('step-container', { active: isActive })}>
    <div className="step-contents-container">
      <div className="step">{text}</div>
      <div className="check-container">{isDone && <i className="icon-check" />}</div>
    </div>
    <div className="underline" />
  </div>
);
export default Step;
