import {
  ProcedureResponse,
  RequestingProvider,
} from '../../components/app/components/referral-request/types';
import { WriteBackProvider } from '../../components/app/types';
import { getStatusToUiStatus } from '../../components/app/components/referral-request/ReferraRequestStates';
import {
  AnalyticsProcedure,
  AnalyticsProvider,
  AnalyticsRequestingProvider,
} from '../types/referralRequestAnalyticsEvents.types';

export const writebackProviderToAnalyticsProvider = (
  provider?: WriteBackProvider,
): AnalyticsProvider | undefined => {
  if (!provider) return undefined;
  return {
    address: provider.organizations?.[0]?.address?.fullAddress,
    firstName: provider.demographics.firstName,
    lastName: provider.demographics.lastName,
    npi: provider.npi,
    specialty: provider.specialty?.[0].description,
  };
};

export const procedureResponseToAnalyticsProcedure = (
  procedure: ProcedureResponse,
): AnalyticsProcedure => {
  const { cpt, status, statusReasons, startDate, expirationDate, serviceQuantity, quantityType } =
    procedure;
  return {
    code: cpt.code,
    description: cpt.description,
    status: getStatusToUiStatus(status),
    statusReason: statusReasons?.[0]?.value,
    startDate,
    expirationDate,
    quantity: serviceQuantity,
    quantityType,
  };
};

export const requestingProviderToAnalyticsProvider = (
  provider?: RequestingProvider,
): AnalyticsRequestingProvider | undefined => {
  if (!provider) return undefined;

  const { firstName, lastName, npi, address, specialty } = provider;

  return {
    firstName,
    lastName: lastName ?? '',
    npi,
    address: address?.fullAddress,
    specialty: specialty?.description,
  };
};
