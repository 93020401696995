import { Provider } from '@getvim/react-app-infra';
import { Specialty } from '@getvim-os/types';
import type { Provider as SdkProvider } from '@getvim/internal-vim-os-sdk/types';

type LegacyOrganization = Provider['organization'];

export const convertProvider = (ehrProvider: SdkProvider | undefined): Provider | undefined => {
  if (!ehrProvider) {
    return;
  }

  const {
    ehrProviderId: providerEhrId,
    npi,
    demographics,
    facility,
    specialty,
    providerDegree: degree,
  } = ehrProvider;

  const organization = facility && convertFacility(facility);

  return {
    providerEhrId,
    npi,
    demographics,
    specialty: convertSpecialty(specialty),
    organization,
    organizations: organization ? [organization] : undefined,
    degree,
  };
};

const convertSpecialty = (pcpSpecialties: string[] | undefined): Specialty[] | undefined => {
  return pcpSpecialties?.map((specialty) => ({ description: specialty }));
};

const convertFacility = (facility: NonNullable<SdkProvider['facility']>): LegacyOrganization => {
  const { facilityEhrId: ehrId, name, address, contact_info } = facility;

  return {
    ehrId,
    name,
    isPrimary: true,
    address,
    contact_info,
  };
};
