import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { UpdatableReferral } from '@getvim/internal-vim-os-sdk/types';
import { useCallback } from 'react';
import { WritebackReferralCallback, WritebackReferralInput } from './types';
import { WriteBackProvider } from '../../../../types';
import { orderAssistLogger } from '../../../../logger';

export const useSdkWriteback = (): WritebackReferralCallback => {
  const { resourceUpdate } = useApi();

  return useCallback<WritebackReferralCallback>(
    async ({ authCode, notes, targetProvider }: WritebackReferralInput) => {
      orderAssistLogger.info('Writing back referral data', { authCode, notes, targetProvider });

      const builder = resourceUpdate.referralBuilder();
      let shouldCommit = false;

      if (authCode && resourceUpdate.updatableFields.referral?.authCode) {
        builder.setAuthCode(authCode);
        shouldCommit = true;
      }

      if (targetProvider && resourceUpdate.updatableFields.referral?.targetProvider) {
        builder.setTargetProvider(convertTargetProviderToSdk(targetProvider)).setNotes(notes);
        shouldCommit = true;
      }

      if (shouldCommit) {
        await builder.commit();
      }
    },
    [resourceUpdate],
  );
};

const convertTargetProviderToSdk = (
  targetProvider: WriteBackProvider,
): UpdatableReferral['targetProvider'] => {
  const { npi, specialty, demographics, organization, degree } = targetProvider;
  const { ehrId, name, address, contact_info } = organization ?? {};

  const specialties: string[] | undefined = specialty
    ?.filter(({ description }) => description)
    ?.map(({ description }) => description as string);

  return {
    npi,
    demographics,
    specialty: specialties,
    facility: {
      facilityEhrId: ehrId,
      name,
      address,
      contact_info,
    },
    providerDegree: degree,
  };
};
