import { Patient, WidgetSource } from '../components/app/types';

import { calculateSource } from '../hooks/useSourceConfig';
import { extractMemberTokenForSource } from './memberToken';

import { orderAssistLogger } from '../components/app/logger';
import { UserConfig } from '../hooks';
import { logic } from '../components/app/logic';
import { featureFlagsClientOrderOptimization } from '../services/featureFlags';

export const isPatientEligibleForSource = (
  patient: Patient | undefined,
  source: WidgetSource | undefined,
): boolean => {
  return !!source && !!extractMemberTokenForSource(patient?.memberTokens, source);
};

export const isPatientEligible = async (
  patient: Patient | undefined,
  userConfig: UserConfig | undefined,
): Promise<boolean> => {
  if (!patient) {
    orderAssistLogger.warning('Patient is not defined');
    return false;
  }
  if (!userConfig) {
    orderAssistLogger.error('userConfig is undefined, cannot check eligibility for patient', {
      userConfig,
      patient,
    });
    return false;
  }

  const supportedPersonCapabilitesFlag = await featureFlagsClientOrderOptimization.getFlag({
    flagName: 'useSupportedPersonCapabilitiesEndpoint',
    defaultValue: false,
    flagContext: {
      organizationId: userConfig.organization?.id,
    },
  });

  if (supportedPersonCapabilitesFlag) {
    const supportedPersonCapabilites = await logic.supportedPersonCapabilites(patient, userConfig);
    if (!supportedPersonCapabilites) {
      orderAssistLogger.info('Patient does not have any supported person capabilities', {
        userConfig,
      });
      return false;
    }
  }

  const source = calculateSource(patient, userConfig);
  return isPatientEligibleForSource(patient, source);
};
