import { useVimAuth, useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { useMemo } from 'react';
import { UserConfig } from '../types';

export const useSdkWorkspaceConfig = (): UserConfig['workspaceConfig'] => {
  const { deviceId, bareboneType: agentType, bareboneVersion: agentVersion } = useVimUserMetadata();
  const { accessToken } = useVimAuth();

  return useMemo(() => {
    return {
      deviceId,
      accessToken,
      agentType,
      agentVersion,
    };
  }, [deviceId, accessToken, agentType, agentVersion]);
};
