import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Overlay, Popover } from 'react-bootstrap';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import ClickableDiv from '../../atoms/clickableDiv';
import Badge, { BadgeTypeEnum } from '../../atoms/badge';
import Text from '../../atoms/text/index';
import { AddressItem, AddressPayload } from '../../types/AddressPayload';
import { AlignEnum, ColorNameEnum, SizeEnum } from '../../types/enums/textStyle';
import ButtonGroupComponent from '../button-group/index';
import './index.less';
import { Tooltip } from '../../atoms';

const ACCEPTING_NEW_PATIENTS_OLD_MESSAGE = 'Accepting new patients';
const NOT_ACCEPTING_NEW_PATIENTS_OLD_MESSAGE = 'Not accepting new patients';
const ACCEPTING_NEW_PATIENTS_MESSAGE = 'Accepting All Patients';
const NOT_ACCEPTING_NEW_PATIENTS_MESSAGE = 'Not Accepting New Patients';

const getDisplayAddress = (selectedAddress): AddressItem => {
  const address = selectedAddress || { text: 'Location unavailable' };
  return address as AddressItem;
};

function AddressSelector({
  selectedAddress,
  value,
  items,
  onChange,
  shouldDisplayNewPatientsIcon,
}: AddressPayload & { shouldDisplayNewPatientsIcon: boolean }) {
  const [displayedAddress, setDisplayedAddress] = useState<AddressItem>(
    getDisplayAddress(selectedAddress),
  );
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [useAcceptNewPatientsBadgesOnAddress] = useFeatureFlag({
    defaultValue: false,
    flagName: 'useAcceptNewPatientsBadgesOnAddress',
  });

  const [useNewPatientsIconsOnAddress] = useFeatureFlag({
    defaultValue: false,
    flagName: 'useNewPatientsIconsOnAddress',
  });

  const dropdown = items.length > 1;

  const addressesItems = useMemo(
    () =>
      items.map((item) => {
        if (
          useAcceptNewPatientsBadgesOnAddress &&
          item.acceptNewPatients !== undefined &&
          item.acceptNewPatients !== null
        ) {
          return {
            ...item,
            secondaryDescription: useNewPatientsIconsOnAddress ? (
              <Badge
                badgeType={
                  item.acceptNewPatients
                    ? BadgeTypeEnum.acceptingNewPatients
                    : BadgeTypeEnum.notAcceptingNewPatients
                }
                title={
                  item.acceptNewPatients
                    ? shouldDisplayNewPatientsIcon
                      ? ACCEPTING_NEW_PATIENTS_MESSAGE
                      : ACCEPTING_NEW_PATIENTS_OLD_MESSAGE
                    : shouldDisplayNewPatientsIcon
                    ? NOT_ACCEPTING_NEW_PATIENTS_MESSAGE
                    : NOT_ACCEPTING_NEW_PATIENTS_OLD_MESSAGE
                }
                tooltip=""
                hideTooltip={true}
              />
            ) : (
              <div>
                <span
                  className={classNames('address-accept-new-patients-bullet-container ', {
                    'address-accept-new-patients-blue-bullet': item.acceptNewPatients,
                    'address-accept-new-patients-gray-bullet': !item.acceptNewPatients,
                  })}
                >
                  ●
                </span>
                <span className="address-accept-new-patients-description">
                  {item.acceptNewPatients
                    ? ACCEPTING_NEW_PATIENTS_OLD_MESSAGE
                    : NOT_ACCEPTING_NEW_PATIENTS_OLD_MESSAGE}
                </span>
              </div>
            ),
          };
        }

        return item;
      }),
    [
      items,
      useAcceptNewPatientsBadgesOnAddress,
      useNewPatientsIconsOnAddress,
      shouldDisplayNewPatientsIcon,
    ],
  );

  return (
    <div ref={target}>
      <ClickableDiv
        onClick={() => {
          if (dropdown) {
            setShow(!show);
          }
        }}
        className={classNames(
          'address-selector-wrap',
          selectedAddress && selectedAddress.text !== 'Location unavailable'
            ? ''
            : 'location-unavailable',
          {
            dropdown,
          },
        )}
      >
        <Tooltip
          tooltipContent={displayedAddress?.text ?? ''}
          pillStyle
          contentMaxLength={47}
          conditionalTooltip
          placement="top"
          referenceClassName="tooltip-ref"
          bgColor="light"
        >
          <Text
            size={SizeEnum['16px']}
            align={AlignEnum.left}
            colorName={ColorNameEnum.themeSecondary}
            className="address-icon icon-map-pin-e"
            inline
          />
          <Text
            className="address-text"
            size={SizeEnum['12px']}
            align={AlignEnum.left}
            colorName={ColorNameEnum.ultraDark}
            text={displayedAddress?.text ?? ''}
            inline
          />
          {dropdown && (
            <Text
              size={SizeEnum['12px']}
              align={AlignEnum.left}
              colorName={ColorNameEnum.ultraDark}
              className="arrow-icon icon-chevron-down"
              inline
            />
          )}
        </Tooltip>
      </ClickableDiv>
      <Overlay
        show={show}
        target={target}
        rootClose
        onHide={() => setShow(false)}
        placement="bottom-start"
      >
        <Popover className="address-dropdown-popover btn-group-dropdown-popover" id="popover">
          <div className="popover-content">
            <ButtonGroupComponent
              bgColor="default"
              buttonType="dropdownOption"
              width="block"
              direction="column"
              color="extraDarkGray"
              onChange={(addressIndex) => {
                const address = items.find((item) => item.value === addressIndex);
                if (address) {
                  setDisplayedAddress(address);
                  if (onChange) onChange(address);
                }
                setShow(false);
              }}
              multiLines
              value={value}
              items={addressesItems}
            />
          </div>
        </Popover>
      </Overlay>
    </div>
  );
}

export default AddressSelector;
