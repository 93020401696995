import { MAX_CODE_PER_SEARCH } from '../consts/maxIcdCptPerSearch';
import { SelectOption } from '../types';

export const getOptionLabel = (code: string, name: string): string => {
  return `${code} - ${name}`;
};
export const getSelectOptions: (codes) => SelectOption[] = (codes) => {
  return codes
    .filter(({ code }) => !!code)
    .map(({ name, code }) => ({
      label: getOptionLabel(code, name),
      value: code,
    }))
    .slice(0, MAX_CODE_PER_SEARCH);
};
