import { DownloadState } from '../../modals/DownloadModal';
import { getOrderAssistUIString } from '../../../consts/strings';

export enum DownloadStatus {
  loading,
  success,
  error,
}

export const pdfDownloadStates: { [key in 'loading' | 'success' | 'error']: DownloadState } = {
  loading: {
    title: getOrderAssistUIString('export_pdf_in_progress_title'),
    subtitle: getOrderAssistUIString('export_pdf_in_progress_subtitle'),
    image: 'https://static.getvim.com/img/Add2ChartAnimation.svg',
    showCloseButton: false,
  },
  success: {
    title: getOrderAssistUIString('export_pdf_successful_title'),
    image: 'https://static.getvim.com/img/export_successful.svg',
    showCloseButton: true,
    timeout: 5000,
  },
  error: {
    title: getOrderAssistUIString('export_pdf_failed_title'),
    image: 'https://static.getvim.com/img/export_failed.svg',
    showCloseButton: true,
  },
};

export const getPdfDownloadState = (selector: DownloadStatus): DownloadState => {
  const downloadState = pdfDownloadStates[DownloadStatus[selector]];
  if (downloadState) {
    return downloadState;
  }
  throw new Error('Passed illegal download status value!');
};
