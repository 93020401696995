import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Popover from '../../atoms/popover';
import type { PopoverStateReturn } from '../../atoms/popover';
import ButtonGroupComponent from '../button-group';
import './index.less';

export interface DropdownMenuItem<T> {
  text: string;
  value: string;
  icon: string;
  disabled?: boolean;
  onClick?: (...args: any) => void | Promise<T>;
  onClickCallback?: {
    onStart?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
    onFinish?: (args: T) => void;
  };
  onClose?: () => void;
}

export interface DropdownMenuProps<T> {
  dropdownMenuItems: DropdownMenuItem<T>[];
  onDelayedMouseOver?: () => void;
  mouseHoverTime?: number;
  onSelectedOption?: (option: DropdownMenuItem<T>) => void | Promise<any>;
  onToggle?: (interaction: 'opened' | 'closed') => void;
  popover: PopoverStateReturn;
  disclosure?: any;
  disabled?: boolean;
  icon?: string;
  visibleIcon?: string;
}

export function DropdownMenu<T>({
  dropdownMenuItems,
  onDelayedMouseOver,
  mouseHoverTime = 2000,
  onSelectedOption,
  onToggle,
  popover,
  disclosure,
  disabled = false,
  icon,
  visibleIcon,
}: DropdownMenuProps<T>) {
  const timer = useRef<NodeJS.Timeout>();
  const [isHovering, setIsHovering] = useState<boolean>(false);

  useEffect(() => {
    if (!onDelayedMouseOver) {
      return;
    }

    // when mouse entered - starting timeout
    if (!timer.current && !popover.visible && isHovering) {
      timer.current = setTimeout(() => {
        // reached timeout - triggering hover event
        onDelayedMouseOver();

        if (timer.current !== undefined) {
          // clearing timer
          clearTimeout(timer.current);
          timer.current = undefined;
        }
      }, mouseHoverTime);

      // when mouse leaved or opened menu
    } else if (timer.current && (popover.visible || !isHovering)) {
      // clearing the mouse over timeout trigger before its invoke
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  }, [isHovering, popover.visible, onDelayedMouseOver, mouseHoverTime]);

  const changedDropdown = async (action: string) => {
    const dropdownItem = dropdownMenuItems.find((x) => x.value === action);
    if (dropdownItem) {
      await onSelectedOption?.(dropdownItem);
      popover.hide();
    }
  };

  const handleToggleButtonClick = () => {
    if (onToggle) {
      onToggle(popover.visible ? 'closed' : 'opened');
    }
  };

  const hideDropdown = () => {
    popover.hide();
    if (onToggle) {
      onToggle('closed');
    }
  };

  return (
    <Popover
      className="atomic-dropdown-menu-popover"
      popover={popover}
      disclosure={
        disclosure || (
          <button
            type="button"
            className={classNames('atomic-dropdown-btn close', {
              close: popover.visible,
              open: !popover.visible,
              'cursor-default': disabled,
            })}
            onClick={() => handleToggleButtonClick()}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            disabled={disabled}
          >
            <i className={getIcon(popover.visible, icon, visibleIcon)} />
          </button>
        )
      }
      hide={hideDropdown}
      tabIndex={0}
    >
      <div className="atomic-popover-content">
        <ButtonGroupComponent
          direction="column"
          buttonType="dropdownOption"
          width="block"
          color="extraDarkGray"
          items={dropdownMenuItems}
          itemClassName="atomic-dropdown-item"
          onChange={(selection: string) => {
            changedDropdown(selection);
          }}
        />
      </div>
    </Popover>
  );
}

function getIcon(visible: boolean, icon?: string, visibleIcon?: string) {
  if (icon && visibleIcon) {
    return visible ? visibleIcon : icon;
  }

  if (icon) {
    return icon;
  }

  if (visibleIcon) {
    throw new Error('invalid input - visibleIcon must be supplied together with icon!');
  }

  // TODO cleanup, keeping this here for backwards compatability
  return visible ? 'icon-x-2' : 'icon-export-2';
}
