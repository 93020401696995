import React from 'react';
import { UHC } from '../../../types';
import sortDisclaimersByCategory from '../../../utils/disclaimers';

interface SearchDisclaimersProps {
  disclaimers: UHC.Disclaimer[] | undefined;
}

const SearchDisclaimers: React.FC<SearchDisclaimersProps> = ({ disclaimers }) => {
  const disclaimersByCategory = sortDisclaimersByCategory(disclaimers);

  const disclaimerElements = Object.keys(disclaimersByCategory).flatMap((categoryName) => {
    const disclaimerMessages = disclaimersByCategory[categoryName].map((message) => (
      <li key={message}>
        <div className="search-disclaimers__message">{message}</div>
      </li>
    ));

    return (
      <div className="search-disclaimers__category" key={categoryName}>
        <div className="search-disclaimers__category-name">{categoryName}</div>
        <ul className="search-disclaimers__message-list">{disclaimerMessages}</ul>
      </div>
    );
  });

  return <div className="search-disclaimers">{disclaimerElements}</div>;
};

export default SearchDisclaimers;
