import React, { useState } from 'react';

interface AnalyticHoverProps {
  children: any;
  onHoverAction: any;
  onLeaveAction?: any;
  className?: string;
}

const AnalyticHover = ({
  children,
  onHoverAction,
  onLeaveAction,
  className,
}: AnalyticHoverProps) => {
  const HOVER_TIMER = 1000;
  const [appOpenedTime, setAppOpenedTime] = useState(0);
  const [timerId, setTimerId] = useState<any | undefined>();
  const calculateHoverTime = () => Math.floor((Date.now() - appOpenedTime) / 1000);

  const clearTimer = () => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(undefined);
    }
  };

  const setIsHover = () => {
    const hoverScheduleId = setTimeout(() => {
      onHoverAction();
    }, HOVER_TIMER);
    setTimerId(hoverScheduleId);
  };

  const onMouseEnter = () => {
    setAppOpenedTime(Date.now());
    setIsHover();
  };

  const onMouseLeave = () => {
    if (timerId) {
      const totalTime = calculateHoverTime();
      if (totalTime > 0) {
        onLeaveAction?.(totalTime);
      }
      setAppOpenedTime(0);
      clearTimer();
    }
  };

  return <div {...{ className, onMouseEnter, onMouseLeave }}>{children}</div>;
};

export default AnalyticHover;
