import { internalApi } from '../../../../internal-api';
import { WidgetSource } from '../../types';
import { orderAssistLogger } from '../../logger';

export const getInsurers = async (source: WidgetSource): Promise<string[]> => {
  try {
    const insurers = await internalApi.orderAssistBff.getInsurers(source);
    orderAssistLogger.info('Fetched insurers successfully', { source, insurers });
    return insurers;
  } catch (error) {
    orderAssistLogger.error('Failed to fetch insurers', { source, error });
    return [];
  }
};

export const getPlansByInsurer = async (
  insurer: string,
  source: WidgetSource,
): Promise<string[]> => {
  try {
    const plans = await internalApi.orderAssistBff.getPlansByInsurer(insurer, source);
    orderAssistLogger.info('Fetched insurance plans successfully', { source, insurer, plans });
    return plans;
  } catch (error) {
    orderAssistLogger.error('Failed to fetch insurance plans', { source, insurer, error });
    return [];
  }
};
