import React from 'react';
import CactusSVG from '../../../../../assets/cactus.svg';
import ImageMessageView from './ImageMessageView';

interface Props {
  onNewSearch?: () => void;
}

const NoResults: React.FC<Props> = ({ onNewSearch }) => {
  return (
    <ImageMessageView
      imageSrc={CactusSVG}
      title="No results found"
      details="Try adjusting your search or filter to find what you're looking for."
      buttonProps={onNewSearch ? { text: 'New Search', onClick: onNewSearch } : undefined}
    />
  );
};
export default NoResults;
