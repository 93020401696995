import { PreferredProvidersState } from '../types';

export const preferredProvidersTexts: Record<
  PreferredProvidersState,
  { title: string; tooltip: string }
> = {
  [PreferredProvidersState.NOT_CONFIGURED]: {
    title: 'No in-network preferred providers',
    tooltip:
      "Your clinic's in-network preferred providers can be displayed right here. Contact your clinic's admin to set this feature up.",
  },
  [PreferredProvidersState.NOT_FOUND]: {
    title: 'No in-network preferred providers to show',
    tooltip:
      'Your organization does not have any in-network preferred providers which match your search criteria',
  },
  [PreferredProvidersState.MATCHING_PROVIDERS]: {
    title: 'In-network preferred providers',
    tooltip: 'These providers are preferred by your organization',
  },
};
