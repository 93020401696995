import { Standard } from '@getvim/vim-connect';

export enum WidgetOutgoingEventName {
  openSearchProvider = 'open-search-provider',
}

export interface AppDataToWidgetPayload {
  isLoadingWidget: boolean;
  shouldDisplayWidget: boolean;
}

export enum SelectButtonIncomingEventName {
  ReferralViewed = 'referralViewed',
  OrdersViewed = 'ordersViewed',
}

export interface IncomingEvents {
  [SelectButtonIncomingEventName.ReferralViewed]: Standard.Events.TransformedReferralViewedPayload;
  [SelectButtonIncomingEventName.OrdersViewed]: Standard.Events.OrdersViewedPayload;
}

export type EventPayload = {
  [Event in keyof IncomingEvents]: IncomingEvents[Event];
}[keyof IncomingEvents];

export const isPayloadTypeOrders = (
  payload: EventPayload,
): payload is IncomingEvents[SelectButtonIncomingEventName.OrdersViewed] => {
  return 'selectedOrders' in payload;
};
