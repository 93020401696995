import { AnalyticsClient } from '@getvim/utils-vim-connect-communication';
import { useEffect, useMemo, useState } from 'react';
import { OrderAssistAppAnalyticsEvents } from '../types/orderAssistAppAnalytics.types';
import { widgetId } from '../../consts';
import { ExportAnalyticsEvents } from '../types/exportAnalytics.types';
import { ReferralRequestAnalyticsEvents } from '../types/referralRequestAnalyticsEvents.types';
import {
  AnalyticsManager,
  ExportAnalyticsManager,
  ReferralRequestAnalyticsManager,
} from '../clients';

export const useRuntimeAnalytics = () => {
  const [analyticsReady, setAnalyticsReady] = useState<boolean>(false);

  const analyticsClient = useMemo(
    () => new AnalyticsClient<OrderAssistAppAnalyticsEvents>(widgetId),
    [],
  );

  const exportAnalyticsClient = useMemo(
    () => new AnalyticsClient<ExportAnalyticsEvents>(widgetId),
    [],
  );

  const referralRequestAnalyticsClient = useMemo(
    () => new AnalyticsClient<ReferralRequestAnalyticsEvents>(widgetId),
    [],
  );

  useEffect(() => {
    if (!analyticsClient || !exportAnalyticsClient || !referralRequestAnalyticsClient) {
      return;
    }

    AnalyticsManager.setAnalyticsClient({
      trackAnalytics: ({ event, properties }) =>
        analyticsClient.track(event as keyof OrderAssistAppAnalyticsEvents, properties),
    });

    ExportAnalyticsManager.setAnalyticsClient({
      trackAnalytics: ({ event, properties }) =>
        exportAnalyticsClient.track(event as keyof ExportAnalyticsEvents, properties),
    });

    ReferralRequestAnalyticsManager.setAnalyticsClient({
      trackAnalytics: ({ event, properties }) =>
        referralRequestAnalyticsClient.track(
          event as keyof ReferralRequestAnalyticsEvents,
          properties,
        ),
    });

    setAnalyticsReady(true);
  }, [analyticsClient, exportAnalyticsClient, referralRequestAnalyticsClient]);

  return analyticsReady;
};
