import {
  StatusScreenClickedPayload,
  StatusScreenPayload,
  StatusScreenViewedPayload,
  ReferralRequestEventNames,
  ReferralRequestWritebackPayload,
} from '../types/referralRequestAnalyticsEvents.types';
import { referralRequestAnalyticsClient } from '../clients/referralRequest.client';

export const trackStatusScreenViewed = (payload: StatusScreenViewedPayload): void => {
  const { requestStatus, statusReason, timeToLoadInSeconds, certificationId, cpts } = payload;

  referralRequestAnalyticsClient.track(ReferralRequestEventNames.STATUS_SCREEN_VIEWED, {
    requestStatus,
    statusReason,
    timeToLoadInSeconds,
    certificationId,
    cpts,
  });
};

export const trackStatusScreenManualCopy = (payload: StatusScreenPayload): void => {
  const { requestStatus, statusReason, certificationId, cpts } = payload;

  referralRequestAnalyticsClient.track(ReferralRequestEventNames.STATUS_SCREEN_MANUAL_COPY, {
    requestStatus,
    statusReason,
    certificationId,
    cpts,
  });
};

export const trackStatusScreenCopyButtonClicked = (payload: StatusScreenPayload): void => {
  const { requestStatus, statusReason, certificationId, cpts } = payload;

  referralRequestAnalyticsClient.track(
    ReferralRequestEventNames.STATUS_SCREEN_COPY_BUTTON_CLICKED,
    {
      requestStatus,
      statusReason,
      certificationId,
      cpts,
    },
  );
};

export const trackStatusScreenClicked = (payload: StatusScreenClickedPayload): void => {
  const { buttonName, requestStatus, statusReason, certificationId, cpts } = payload;

  referralRequestAnalyticsClient.track(ReferralRequestEventNames.STATUS_SCREEN_CLICKED, {
    buttonName,
    requestStatus,
    statusReason,
    certificationId,
    cpts,
  });
};

export const trackReferralRequestWriteback = (payload: ReferralRequestWritebackPayload): void => {
  const { requestStatus, statusReason, certificationId, status } = payload;
  referralRequestAnalyticsClient.track(ReferralRequestEventNames.REFERRAL_REQUEST_WRITEBACK, {
    requestStatus,
    statusReason,
    certificationId,
    status,
  });
};
