import { AddressWithDistanceAndPhone, OrderAssistOption } from '../../types';
import { orderAssistLogger } from '../../logger';

export const getSelectedItemAddress = (item: OrderAssistOption): AddressWithDistanceAndPhone => {
  if (item.selectedLocationIndex && item.locations.length <= item.selectedLocationIndex) {
    orderAssistLogger.error('Selected location index is out of bounds, returning first location.', {
      item,
    });
    return item.locations[0];
  }
  return item.locations[item.selectedLocationIndex ?? 0];
};
