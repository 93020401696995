import React from 'react';
import './LanguagePopover.less';
import { ButtonGroupComponent } from '@getvim/atomic-ui';

interface DistancePopoverProps {
  items: Record<number, number>;
  onDistanceChange: (value: number) => void;
  value?: string;
}

const DistancePopover: React.FC<DistancePopoverProps> = ({ items, onDistanceChange, value }) => {
  const mappedItems = Object.keys(items).map((key) => {
    return { value: key, text: `${items[key]} mi` };
  });

  return (
    <ButtonGroupComponent
      direction="row"
      buttonType="small"
      bgColor="groupOptionOutline"
      width="default"
      items={mappedItems}
      onChange={onDistanceChange}
      value={value}
      noGaps
    />
  );
};

export default DistancePopover;
