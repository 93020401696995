import { isEqual } from 'lodash-es';
import React, { useCallback } from 'react';
import {
  DataFilters,
  FreeTextResult,
  NuccFreeTextResult,
  OrderAssistFormData,
  OrderAssistFormInputs,
  Patient,
  QueryResults,
  QuickSpecialtySearchParams,
  WidgetSource,
} from '../../types';
import { OnFreeTextQueryMethod, OnSearchMethod } from '../order-assist-app/hooks';
import CommonForm from './CommonForm';
import { usePatientAddress, useSearchFormValidations } from './hooks';
import './search-form.less';
import { SearchFormValidator } from './types';

export interface SearchFormProps {
  formData: OrderAssistFormData;
  onFormDataChange: (input: Partial<OrderAssistFormData>) => void;
  onFormDataClear: () => void;
  filtersData: DataFilters | undefined;
  patient: Patient | undefined;
  isGoogleApiLoaded: boolean;
  icdQuery?: QueryResults<FreeTextResult[]>;
  cptQuery?: QueryResults<FreeTextResult[]>;
  specialtyQuery?: QueryResults<NuccFreeTextResult[]>;
  onFreeTextQuery: OnFreeTextQueryMethod;
  onSearch: OnSearchMethod;
  onFiltersChanged: (newFilters: DataFilters) => void;
  onQuickSpecialtyChange: (quickSpecialty: QuickSpecialtySearchParams) => void;
  useDiQuickSpecialtyFF: boolean;
  useReferralQuickSpecialtyFF: boolean;
  searchFormValidator: SearchFormValidator;
  inputsDirtiness: Partial<{
    [key in keyof OrderAssistFormInputs]: boolean;
  }>;
  setAllDirty: () => void;
  resetDirtiness: (key: keyof OrderAssistFormInputs) => void;
  source: WidgetSource | undefined;
}

export const SearchForm: React.FC<SearchFormProps> = ({
  formData,
  onFormDataChange,
  onFormDataClear,
  filtersData,
  patient,
  isGoogleApiLoaded,
  icdQuery,
  cptQuery,
  specialtyQuery,
  onSearch,
  onFreeTextQuery,
  onFiltersChanged,
  onQuickSpecialtyChange,
  useDiQuickSpecialtyFF,
  useReferralQuickSpecialtyFF,
  searchFormValidator,
  setAllDirty,
  inputsDirtiness,
  source,
  resetDirtiness,
}) => {
  const patientAddress = patient?.demographics?.address;
  const isPatientAddress = isEqual(patientAddress, formData.address);
  const isClearEnabled = isPatientAddress
    ? Object.keys(inputsDirtiness).length > 1
    : Object.keys(inputsDirtiness).length > 0;

  // const searchFormValidator: SearchFormValidator = getSearchFormValidator(patientInsurer);

  const { validationErrors, validateSearchForm } = useSearchFormValidations(
    formData,
    searchFormValidator,
  );

  const search = useCallback(() => {
    setAllDirty();
    const hasErrors = validateSearchForm();

    if (!hasErrors) {
      onSearch({
        searchFormData: formData,
        filtersData,
        preferredProviders: { fetchPreferredProviders: true },
      });
    }
  }, [validateSearchForm, onSearch, formData, filtersData, setAllDirty]);

  usePatientAddress({
    formData,
    patientAddress,
    onFormDataChange,
  });

  return (
    <div className="search-form">
      <CommonForm
        searchFilters={filtersData?.resultsFilters}
        patient={patient}
        isGoogleApiLoaded={isGoogleApiLoaded}
        icdQuery={icdQuery}
        cptQuery={cptQuery}
        specialtyQuery={specialtyQuery}
        onFreeTextQuery={onFreeTextQuery}
        onFiltersChanged={onFiltersChanged}
        onQuickSpecialtyChange={onQuickSpecialtyChange}
        useDiQuickSpecialtyFF={useDiQuickSpecialtyFF}
        useReferralQuickSpecialtyFF={useReferralQuickSpecialtyFF}
        isClearEnabled={isClearEnabled}
        onFormDataChange={onFormDataChange}
        formData={formData || {}}
        validationErrors={validationErrors}
        clearSearch={onFormDataClear}
        search={search}
        inputsDirtiness={inputsDirtiness}
        source={source}
        resetDirtiness={resetDirtiness}
      />
    </div>
  );
};
