import { trim } from 'lodash-es';
import { internalApi } from '../../../../internal-api';
import { LookupInput } from '../../types/lookup';
import { WidgetSource } from '../../types/sourceConfigurations';
import { Patient } from '../../types';

export const lookup = async (patient: Patient, source: WidgetSource): Promise<string> => {
  const {
    patientId,
    demographics: { firstName, lastName, middleName, dateOfBirth },
    insurance: { memberId, insurer } = {},
  } = patient;

  const input: LookupInput = {
    patientDetails: {
      patientId,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      ...(memberId && insurer ? { insurance: { memberId: trim(memberId), insurer } } : {}),
    },
  };

  const result = await internalApi.orderAssistBff.lookup(input, source);
  return result.memberToken;
};
