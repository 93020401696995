import React from 'react';
import {
  AtomicDialog,
  Button,
  ColorNameEnum,
  SizeEnum,
  Text,
  useDialogState,
} from '@getvim/atomic-ui';
import './UhcPremiumPhysicianProgramModal.less';
import { analyticsManager } from '../../../../analytics/analyticsManager';

export const UhcPremiumPhysicianProgramModal = () => {
  const dialog = useDialogState();

  return (
    <AtomicDialog
      dialog={dialog}
      disclosure={
        <Text
          inline
          colorName={ColorNameEnum.ultraDark}
          size={SizeEnum['12px']}
          className="uhc-premium-physician-program-modal-disclosure"
          onClick={() => analyticsManager.track('performance_score_hyperlink_clicked', {})}
        >
          What is UnitedHealth Premium Care Physician?
        </Text>
      }
      title="UnitedHealth Premium® Program"
      footerContent={
        <a
          href="https://www.uhcprovider.com/en/reports-quality-programs/premium-designation.html"
          target="_blank"
          style={{ flex: '1 1 auto', display: 'flex' }}
          rel="noreferrer"
        >
          <Button
            buttonType="tiny"
            bgColor="themedOutline"
            style={{ flexGrow: 1 }}
            onClick={() => {
              analyticsManager.track('performance_score_modal_button_clicked', {});
              dialog.hide();
            }}
          >
            Learn more
          </Button>
        </a>
      }
    >
      <div style={{ fontSize: '12px' }}>
        <p>
          A Premium Care Physician meets the UnitedHealth Premium quality care criteria which
          includes safe, timely, effective and efficient care.
        </p>
        <p>
          The UnitedHealth Premium® program evaluates physicians based on quality criteria to help
          members make more informed and appropriate choices for their health care.{' '}
          <b>
            It's intended only as a guide and should not be the sole factor when selecting a
            physician. Designations have a risk of error and members should discuss designations
            with a physician before choosing one.
          </b>{' '}
          The fact that a physician does not have a Premium Care Physician designation does not mean
          that the physician does not provide quality health care services. All physicians in the
          UnitedHealthcare network have met certain minimum credentialing requirements (separate
          from the Premium program).
        </p>
      </div>
    </AtomicDialog>
  );
};
