import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '@getvim/atomic-ui/assets/styles/main.less';
import OrderAssistApp from './components/app';
import SelectButton from './components/select-button';
import { isVimOsAppMode } from './vim-os-sdk-migration';

const App: React.FC = () => (
  <Router basename={import.meta.env.BASE_URL}>
    <Switch>
      {!isVimOsAppMode ? (
        <Route path="/select-button">
          <SelectButton />
        </Route>
      ) : null}
      <Route path="/order-assist-app">
        <OrderAssistApp />
      </Route>
      <Route
        path="/order-assist-demo"
        render={() => {
          window.$vim_environment.demo_mode = true;
          return <OrderAssistApp />;
        }}
      />
    </Switch>
  </Router>
);

export default App;
