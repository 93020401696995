import {
  InputStyle,
  InputStyleWrapper,
  InputValidationMessage,
  Select,
  SelectRef,
} from '@getvim/atomic-ui';
import { SelectInputType } from '@getvim/atomic-ui/deprecated';
import React, { useMemo } from 'react';

import { ERROR, NO_RESULTS, START_SEARCH } from '../../consts/selectInputMessages';
import {
  FreeTextQueryPayload,
  FreeTextType,
  InputWrapperProps,
  NuccFreeTextResult,
  NuccSelectOption,
} from '../../types';

type SpecialtyInputProps = InputWrapperProps<'specialty'> & {
  onFreeTextQuery: (payload: FreeTextQueryPayload) => void;
  specialtyFreeTextQueryOptions: NuccFreeTextResult[] | undefined;
  validationError?: string;
  loading: boolean;
  error: boolean;
  isDirty?: boolean;
  selectRef?: SelectRef;
};

const formatOptions = (options: NuccFreeTextResult[]): NuccSelectOption[] => {
  return options.map((option) => ({
    value: option.id,
    label: option.description,
  }));
};

const SpecialtyInputWrapper: React.FC<SpecialtyInputProps> = ({
  value,
  onChange,
  onFreeTextQuery,
  specialtyFreeTextQueryOptions,
  validationError,
  loading,
  error,
  isDirty,
  selectRef,
}) => {
  const onInputChange: SelectInputType['onInputChange'] = (
    payload: FreeTextQueryPayload,
    event,
  ) => {
    if (event?.action !== 'input-change') return;
    onFreeTextQuery(payload);
  };

  const setSelectChange = (specialty?: NuccSelectOption) => {
    if (!specialty) {
      onChange({ specialty: undefined });
      return;
    }

    onChange({
      specialty: {
        searchDisplay: specialty.label,
        vimSpecialtyId: specialty.value as string,
        vimSpecialtyDescription: specialty.label,
      },
    });
  };

  const searchFn = ({ state, methods }) => {
    onInputChange(
      { freeText: methods.safeString(state.search), type: FreeTextType.Nucc },
      { action: 'input-change' },
    );
  };

  const noOptionsLabel = useMemo(() => {
    if (error) return ERROR;
    if (specialtyFreeTextQueryOptions === undefined) return START_SEARCH;
    return NO_RESULTS;
  }, [error, specialtyFreeTextQueryOptions]);

  return (
    <InputStyleWrapper
      className="input-wrapper"
      leftIcon={<i className="icon-stethoscope" />}
      rightIcon={<></>}
      inputStyle={InputStyle.pillMedium}
      hasError={isDirty && !!validationError}
    >
      <div className="input">
        <Select
          selectRef={selectRef}
          key={value?.vimSpecialtyId}
          id="specialty-input"
          name="specialty"
          label="Specialty"
          loading={loading}
          values={
            value
              ? [
                  {
                    label: value.searchDisplay,
                    value: value.vimSpecialtyId,
                  },
                ]
              : undefined
          }
          options={formatOptions(specialtyFreeTextQueryOptions ?? [])}
          clearable
          closeOnSelect
          keepSelectedInList
          hideValueOnInput
          showChevronIcon
          onChange={(items) => {
            setSelectChange(items?.[0]);
          }}
          searchFn={searchFn}
          noDataLabel={noOptionsLabel}
          error={error ? ERROR : undefined}
          noInputLabel={START_SEARCH}
          noResultsLabel={NO_RESULTS}
        />
      </div>
      {isDirty && !!validationError ? (
        <InputValidationMessage>{validationError}</InputValidationMessage>
      ) : null}
    </InputStyleWrapper>
  );
};

export default SpecialtyInputWrapper;
