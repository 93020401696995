import { InputStyle, InputStyleWrapper, InputValidationMessage, Select } from '@getvim/atomic-ui';
import React from 'react';
import { InputWrapperProps, SelectOption } from '../../types';

type PlaceOfServiceInputProps = InputWrapperProps<'placeOfService'> & {
  placeOfServiceOptions: SelectOption[];
  validationError?: string;
  isDirty?: boolean;
};

const PlaceOfServiceInputWrapper: React.FC<PlaceOfServiceInputProps> = ({
  value,
  placeOfServiceOptions,
  onChange,
  validationError,
  isDirty,
}) => {
  const setInputChange = (option: { placeOfService: string }) => {
    onChange(option);
  };
  const displayValue = placeOfServiceOptions.find((option) => option.value === value);
  return (
    <InputStyleWrapper
      className="input-wrapper"
      leftIcon={<i className="icon-hospital" />}
      rightIcon={<i className="icon-chevron-down select-chevron-icon" />}
      inputStyle={InputStyle.pillMedium}
      hasError={isDirty && !!validationError}
    >
      <div className="input">
        <Select
          key={value}
          id="place-of-service-input"
          label="Place of service"
          name="place-of-service"
          values={displayValue ? [displayValue] : []}
          options={placeOfServiceOptions}
          onChange={(selected: SelectOption[]) => {
            setInputChange({ placeOfService: selected[0]?.value });
          }}
          clearable={false}
        />
      </div>
      {isDirty && !!validationError ? (
        <InputValidationMessage>{validationError}</InputValidationMessage>
      ) : null}
    </InputStyleWrapper>
  );
};

export default PlaceOfServiceInputWrapper;
