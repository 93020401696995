import { BenefitsBadge, DropdownMenuItem } from '@getvim/atomic-ui';
import React from 'react';
import {
  DataFilters,
  GetOrderAssistResult,
  SearchFilters,
  SourceConfigurations,
} from '../../../types';

import { OrderAssistScreenName } from '../../../../../analytics/types';
import { getOrderAssistUIString } from '../../../consts/strings';
import FiltersWrapper from '../../filters/FiltersWrapper';
import { DownloadState } from '../../modals/DownloadModal';
import { SearchResultsContainerProps } from '../container/types';
import SearchResultsTitle from './SearchResultsContainerTitle';

export interface TitleBarProps {
  searchDetails: string;
  screenName: OrderAssistScreenName;
  results: GetOrderAssistResult | undefined;
  onClick: () => void;
  handleFiltersChanged: (newFilters: DataFilters) => void;
  searchFilters?: SearchFilters;
  supportLanguage: SourceConfigurations['supportLanguage'];
  supportWiderDistanceFilter: SourceConfigurations['supportWiderDistanceFilter'];
  defaultDistanceFilter: SourceConfigurations['defaultDistanceFilter'];
  benefits: SearchResultsContainerProps['benefits'];
  withBenefitsBar?: boolean;
  useConfirmationPopup?: boolean;
  dropdownMenuItems?: DropdownMenuItem<boolean>[];
  onExportModalClose?: () => void;
  downloadState?: DownloadState;
  isReadonlyMode?: boolean;
  hideFilters?: boolean;
}

export const TitleBar: React.FC<TitleBarProps> = ({
  searchDetails,
  screenName,
  results,
  onClick,
  handleFiltersChanged,
  searchFilters,
  supportLanguage,
  supportWiderDistanceFilter,
  defaultDistanceFilter,
  benefits,
  withBenefitsBar,
  dropdownMenuItems,
  downloadState,
  onExportModalClose,
  isReadonlyMode,
  hideFilters,
}) => {
  return (
    <div className="search-results-container__title">
      <SearchResultsTitle
        title={getOrderAssistUIString('results_title')}
        screenName={screenName}
        searchDetails={searchDetails}
        results={results}
        onClick={onClick}
        dropdownMenuItems={dropdownMenuItems}
        downloadState={downloadState}
        onExportModalClose={onExportModalClose}
        isReadonlyMode={isReadonlyMode}
      />
      {!hideFilters && (
        <FiltersWrapper
          onApply={handleFiltersChanged}
          lastUsedSearchFilters={searchFilters}
          isLanguageDisabled={!supportLanguage}
          widerDistance={supportWiderDistanceFilter}
          defaultDistanceFilter={defaultDistanceFilter}
        />
      )}

      {withBenefitsBar && (
        <div className="search-results-container__benefits-bar">
          {benefits?.designatedDiagnosticProvider && (
            <BenefitsBadge benefitsMessage={benefits?.designatedDiagnosticProvider} />
          )}
          {benefits?.placeOfServiceDifferential && (
            <BenefitsBadge
              benefitsMessage={getOrderAssistUIString('results_benefits_message')}
              tooltip={benefits?.placeOfServiceDifferential}
            />
          )}
        </div>
      )}
    </div>
  );
};
