import { internalApi } from '../../../../internal-api';
import { WidgetSource } from '../../types';
import { orderAssistLogger } from '../../logger';
import {
  ReferralRequestResponse,
  ReferralRequestStatus,
  RequestReferralInput,
} from '../../components/referral-request/types';

export const fetchIsReferralRequestRequired = async (
  memberToken: string | undefined,
  source: WidgetSource | undefined,
): Promise<boolean> => {
  if (!memberToken || !source) {
    orderAssistLogger.error(
      'isReferralRequired skipping api call - missing parameter - return value is false',
      {
        memberToken,
        source,
      },
    );
    return false;
  }

  const isRequired = await internalApi.orderAssistBff.getMatchedEligibilityPlan(
    memberToken,
    source,
  );
  return !!isRequired;
};

export const submitReferralRequest = async (
  input: RequestReferralInput,
  source: WidgetSource | undefined,
): Promise<ReferralRequestResponse> => {
  if (!source) {
    orderAssistLogger.error('submitReferralRequest skipping api call - source is missing', {
      input,
      source,
    });
    return { status: ReferralRequestStatus.ERROR };
  }

  return internalApi.orderAssistBff.requestReferral(input, source);
};
