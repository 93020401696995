import type { EhrStateWithPII } from '@getvim/internal-vim-os-sdk/types';
import { PatientContentSupplierIdentifiers } from '@getvim-core-apps/organizations';
import { Standard } from '@getvim/vim-connect';
import { Patient } from '@getvim/react-app-infra';
import { convertProvider } from './common';
import { isEmpty } from 'lodash-es';

export const convertPatientToRuntime = (
  ehrPatient: EhrStateWithPII['patient'],
): Patient | undefined => {
  if (!ehrPatient || !ehrPatient?.identifiers?.ehrPatientId) {
    return;
  }

  const {
    identifiers: { ehrPatientId, vimPatientId },
    pcp: ehrPcp,
    demographics: patientDemographics,
    ...patient
  } = ehrPatient;

  // todo - validate with zis - since we are optional now i assume internal apps wont handle non demographics input
  if (!patientDemographics) {
    return;
  }

  const demographics = { ...patientDemographics, address: patient.address };
  const memberTokens = buildMemberTokensObject(patient.contentSupplierIdentifiers);

  return {
    ...patient,
    patientId: ehrPatientId,
    vimPatientId,
    demographics,
    memberTokens,
    pcp: convertProvider(ehrPcp),
  };
};

const buildMemberTokensObject = (
  contentSupplierIdentifiers?: PatientContentSupplierIdentifiers,
): Standard.Events.MemberTokens => {
  const memberTokens = {};
  if (!contentSupplierIdentifiers || isEmpty(contentSupplierIdentifiers)) {
    return memberTokens;
  }

  Object.entries(contentSupplierIdentifiers).forEach(([source, { memberToken }]) => {
    memberTokens[source] = {
      newMemberTokenVersion: memberToken,
    };
  });

  return memberTokens;
};
