import { StandardEvents } from '@getvim/vim-connect';
import {
  ASTERISK_REGEX,
  LEFT_CURLY_REGEX,
  RIGHT_CURLY_REGEX,
  UNDERSCORE_IN_MIDDLE_REGEX,
  UNDERSCORE_REGEX,
} from './consts';
import { VimSpecialtyMap } from '../../../components/app/types';

interface SpecialtyMatch {
  description: string;
  bestVimSpecId: string;
}

export const getVimSpecialtyMetadata = (
  specialtyMap: VimSpecialtyMap,
  specialty: string,
): StandardEvents.TransformedSpecialty => {
  const sanitizedSpecialtyDescription: string = sanitizeSpecialtyInput(specialty);
  const specialtyMatch: SpecialtyMatch | undefined =
    specialtyMap?.[sanitizedSpecialtyDescription] ||
    specialtyMap?.[`${sanitizedSpecialtyDescription} referral`] ||
    specialtyMap?.[`${sanitizedSpecialtyDescription} follow up`];

  if (!specialtyMatch) {
    return {};
  }

  const { description, bestVimSpecId } = specialtyMatch;
  return {
    vimSpecialty: {
      description,
      id: bestVimSpecId,
    },
  };
};

const sanitizeSpecialtyInput = (specialty: string): string => {
  return specialty
    .trim()
    .replace(ASTERISK_REGEX, '')
    .replace(UNDERSCORE_REGEX, '')
    .replace(LEFT_CURLY_REGEX, '')
    .replace(RIGHT_CURLY_REGEX, '')
    .replace(UNDERSCORE_IN_MIDDLE_REGEX, ' ')
    .trim()
    .toLowerCase();
};
