import React from 'react';
import { useField } from 'formik';
import { InputStyle, InputStyleWrapper, Select, InputValidationMessage } from '@getvim/atomic-ui';
import { SelectOption } from '../../../../types';

interface MappingSelectInputFieldProps {
  fieldName: string;
  title: string;
  mapping: Record<number, string>;
}

const formatLabel = (value: number | string, description: string) => `${value} - ${description}`;

const formatOptions = (mapping: MappingSelectInputFieldProps['mapping']): SelectOption[] => {
  if (!mapping) return [];
  const keys: string[] = Object.keys(mapping);
  return keys.map((key: string) => ({
    label: formatLabel(key, mapping[key]),
    value: key,
  }));
};

const MappingSelectInputField: React.FC<MappingSelectInputFieldProps> = ({
  fieldName,
  mapping,
  title,
}) => {
  const [, meta, helpers] = useField(fieldName);

  const onChange = ([option]: [SelectOption]) => helpers.setValue(option?.value);

  return (
    <div className="referral-request-input-container">
      <InputStyleWrapper
        className="referral-request-select-input-style-wrapper"
        inputStyle={InputStyle.pillMedium}
        hasError={meta.touched && !!meta.error}
        rightIcon={<></>}
      >
        <div className="input">
          <Select
            searchable={false}
            label={title}
            placeholder=" "
            className="mapping-select-input"
            id="mapping-select-input"
            name="mapping-select"
            labelField="label"
            valueField="value"
            optionLabelField="value"
            keepSelectedInList
            options={formatOptions(mapping)}
            onChange={onChange}
            showChevronIcon
            closeOnSelect
            setTouched={() => helpers.setTouched(true)}
            {...(meta.value
              ? {
                  values: [
                    { label: formatLabel(meta.value, mapping[meta.value]), value: meta.value },
                  ],
                }
              : {})}
          />
          {meta.touched && !!meta.error && (
            <InputValidationMessage>{title} is required</InputValidationMessage>
          )}
        </div>
      </InputStyleWrapper>
    </div>
  );
};

export default MappingSelectInputField;
