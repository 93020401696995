import { useCallback, useState } from 'react';
import { SearchIdType, searchIdsManager } from '../../../../../utils/searchIdsManager';
import { analyticsManager } from '../../../../../analytics/analyticsManager';

export const useSearchViewed = (): (() => void) => {
  const [lastSearchId, setLastSearchId] = useState<string | undefined>(undefined);
  const searchId = searchIdsManager.getId(SearchIdType.SEARCH_ID);

  const searchViewed = useCallback(() => {
    if (searchId !== lastSearchId) {
      analyticsManager.track('order_assist_search_results_viewed', {});
      setLastSearchId(searchId);
    }
  }, [searchId, lastSearchId]);

  return searchViewed;
};
