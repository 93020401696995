import { BrandedH1, Button, ClickableDiv } from '@getvim/atomic-ui';
import React from 'react';
import { trackLeaveConfirmationModalClicked } from '../../../../../../analytics/referral-request/trackLeaveConfirmationModal';
import { LeaveConfirmationModalButtonName } from '../../../../../../analytics/types/referralRequestAnalyticsEvents.types';
import './ConfirmationPopup.less';

interface ConfirmationPopupProps {
  onClickNo: () => void;
  onClickYes: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ onClickNo, onClickYes }) => {
  return (
    <>
      <div className="confirmation-popup-container">
        <button
          className="close-icon"
          type="button"
          onClick={() => {
            trackLeaveConfirmationModalClicked({
              buttonName: LeaveConfirmationModalButtonName.X_ICON,
            });
            onClickNo();
          }}
        >
          <i className="icon-x" />
        </button>
        <BrandedH1
          size="small"
          text="Leave referral request submission?"
          className="popup-title no-user-select"
        />
        <div className="control-buttons-container">
          <Button
            text="No"
            buttonType="small"
            bgColor="themedOutline"
            width="smallMedium"
            onClick={() => {
              trackLeaveConfirmationModalClicked({
                buttonName: LeaveConfirmationModalButtonName.NO,
              });
              onClickNo();
            }}
          />
          <Button
            text="Yes"
            buttonType="small"
            width="smallMedium"
            onClick={() => {
              trackLeaveConfirmationModalClicked({
                buttonName: LeaveConfirmationModalButtonName.YES,
              });
              onClickYes();
            }}
          />
        </div>
      </div>
      <ClickableDiv
        className="confirmation-popup-backdrop"
        onClick={() => {
          trackLeaveConfirmationModalClicked({
            buttonName: LeaveConfirmationModalButtonName.BACKDROP,
          });
          onClickNo();
        }}
      />
    </>
  );
};
export default ConfirmationPopup;
