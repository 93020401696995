import React, { createContext, useContext, useReducer } from 'react';
import { EhrState, EhrStateAction, EhrStateActionType } from './ehrState.types';

const initialState: EhrState = {};

const EhrStateContext = createContext<{
  state: EhrState;
  dispatch: React.Dispatch<EhrStateAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const useEhrState = () => useContext(EhrStateContext);

const reducer = (state: EhrState, action: EhrStateAction): EhrState => {
  switch (action.type) {
    case EhrStateActionType.SET_ORDER:
      return state.order === action.payload ? state : { ...state, order: action.payload };

    case EhrStateActionType.CLEAR_ORDER:
      return state.order === undefined ? state : { ...state, order: undefined };

    case EhrStateActionType.SET_REFERRAL:
      return state.referral === action.payload ? state : { ...state, referral: action.payload };

    case EhrStateActionType.CLEAR_REFERRAL:
      return state.referral === undefined ? state : { ...state, referral: undefined };

    case EhrStateActionType.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export const EhrStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EhrStateContext.Provider value={{ state, dispatch }}>{children}</EhrStateContext.Provider>
  );
};
