import { AppToOSMessageTypes, ActionNames } from '@getvim/vim-os-api';
import { ActionFailedError } from '@getvim-os/errors';
import { ApplicationManifest } from '@getvim-os/types';
import { OsCommunicator } from '@getvim/dynamic-vim-os-sdk/sdk';
import { EhrActions } from '../types';

const MESSAGE_RESPONSE_TIMEOUT = 30_000; // 30 seconds

export class EhrActionsDispatcher implements EhrActions {
  constructor(
    private osCommunicator: OsCommunicator,
    private manifestSupport: ApplicationManifest,
  ) {}

  protected async dispatchAction(
    action: ActionNames,
    input: unknown,
    timeout = MESSAGE_RESPONSE_TIMEOUT,
    skipPermissionCheck = false,
  ): Promise<unknown> {
    if (!skipPermissionCheck && !(this.manifestSupport.EHR.actions ?? {})[action]) {
      throw new ActionFailedError(action, 'Action not supported in this EHR / App');
    }

    return await this.osCommunicator.sendAwaitedMessage(
      { type: AppToOSMessageTypes.EXECUTE_ACTION, payload: { action, input } },
      timeout,
    );
  }
  getPatient(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.GET_PATIENT, input, timeoutMS);
  }
  getEncounterData(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.GET_ENCOUNTER_DATA, input, timeoutMS);
  }
  searchProviders(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.SEARCH_PROVIDERS, input, timeoutMS);
  }
  getProviderDailySummary(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.GET_PROVIDER_DAILY_SUMMARY, input, timeoutMS);
  }
  getPatientScheduledAppointments(
    input: unknown,
    timeoutMS?: number | undefined,
  ): Promise<unknown> {
    return this.dispatchAction(ActionNames.GET_PATIENT_SCHEDULED_APPOINTMENTS, input, timeoutMS);
  }
  findPatient(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.FIND_PATIENT, input, timeoutMS);
  }
  printEncounter(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.PRINT_ENCOUNTER, input, timeoutMS);
  }
  printEncounters(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.PRINT_ENCOUNTERS, input, timeoutMS);
  }
  getPatientEncounters(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.GET_PATIENT_ENCOUNTERS, input, timeoutMS);
  }
  getCurrentEhrUserInformation(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.GET_CURRENT_EHR_USER_INFORMATION, input, timeoutMS);
  }
  getEncounterLabResults(input: unknown, timeoutMS?: number | undefined) {
    return this.dispatchAction(ActionNames.GET_ENCOUNTER_LAB_RESULTS, input, timeoutMS);
  }
  getEncounterVitals(input: unknown, timeoutMS?: number | undefined) {
    return this.dispatchAction(ActionNames.GET_ENCOUNTER_VITALS, input, timeoutMS);
  }
  navigateToEncounter(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    return this.dispatchAction(ActionNames.NAVIGATE_TO_ENCOUNTER, input, timeoutMS);
  }

  getGapsFromSharedRequest(input: unknown, timeoutMS?: number | undefined): Promise<unknown> {
    /* Skipping EHR manifest check
     * Application manifest will still be checked down the road before the action will be executed
     * So there's a validation in the app level and here we only skip EHR validation, as this action is EHR agnostic
     *
     * See https://bookmd.atlassian.net/browse/SAM-53682 for details
     */
    return this.dispatchAction(ActionNames.GET_GAPS_FROM_SHARED_REQUEST, input, timeoutMS, true);
  }
}
